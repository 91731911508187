import React, {
  createContext,
  useContext,
  useMemo,
  useCallback,
  useState,
} from 'react';

import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios';

import { useToast } from '@contexts/Toast';

interface RequestConfig extends AxiosRequestConfig {
  method: Method;
  url: string;
}

interface ApiContextValues {
  request: <Type>(config: RequestConfig) => Promise<AxiosResponse<Type>>;
  requestBrasilApi: <Type>(
    config: RequestConfig
  ) => Promise<AxiosResponse<Type>>;
  addInterceptors: (tokens: Tokens, onUnauthorized: () => any) => void;
  cleanInterceptors: () => void;
}

interface Interceptors {
  request: number;
  response: number;
}

const ApiContext = createContext<ApiContextValues>({} as ApiContextValues);

interface ApiProviderProps {
  children: React.ReactNode;
}

type OnUnauthorizedFunction = () => Promise<void> | void;

const ApiProvider: React.FC<ApiProviderProps> = ({ children }) => {
  const [interceptors, setInterceptors] = useState<Interceptors>();

  const { addToast } = useToast();

  const apiInstance = useMemo(
    () =>
      axios.create({
        baseURL: import.meta.env.VITE_API_BASE_URL,
        headers: {
          Accept: `application/json; version=${
            import.meta.env.VITE_API_VERSION
          }`,
        },
      }),
    []
  );

  const brasilApiInstance = useMemo(
    () =>
      axios.create({
        baseURL: import.meta.env.VITE_BRASIL_API_URL,
      }),
    []
  );

  const setAuthorizationHeader = useCallback(
    (tokens: Tokens) => {
      return apiInstance?.interceptors.request.use((config) => {
        if (tokens && config.headers) {
          config.headers.authorization = `Bearer ${tokens.access}`;
        }

        return config;
      });
    },
    [apiInstance?.interceptors.request]
  );

  const handleUnauthorized = useCallback(
    (onUnauthorized: OnUnauthorizedFunction) => {
      return apiInstance?.interceptors.response.use(
        (config) => config,
        async (responseError) => {
          if (responseError?.response?.status === 401) {
            await onUnauthorized();
            addToast('Sua sessão expirou. Por favor logue novamente.', 'info');
          }
          return Promise.reject(responseError);
        }
      );
    },
    [addToast, apiInstance?.interceptors.response]
  );

  const addInterceptors = useCallback(
    (tokens: Tokens, onUnauthorized: OnUnauthorizedFunction) => {
      const newRequestInterceptor = setAuthorizationHeader(tokens);
      const newResponseInterceptor = handleUnauthorized(onUnauthorized);
      setInterceptors({
        request: newRequestInterceptor,
        response: newResponseInterceptor,
      });
    },
    [setAuthorizationHeader, handleUnauthorized]
  );

  const cleanInterceptors = useCallback(() => {
    if (interceptors !== undefined) {
      apiInstance?.interceptors.request.eject(interceptors.request);
      apiInstance?.interceptors.response.eject(interceptors.response);
    }
  }, [
    apiInstance?.interceptors.request,
    apiInstance?.interceptors.response,
    interceptors,
  ]);

  return (
    <ApiContext.Provider
      value={{
        request: apiInstance.request,
        requestBrasilApi: brasilApiInstance.request,
        addInterceptors,
        cleanInterceptors,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

const useApi = () => {
  const context = useContext(ApiContext);
  return context;
};

export { ApiProvider, useApi };
