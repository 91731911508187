import RoutesPath from '@router/routes';
import { enums } from '@utils';

const getRoutes = (clientId: number, storeType: enums.StoreType) => {
  if (storeType === enums.StoreType.PHYSICAL) {
    return [
      {
        path: RoutesPath.private.clients.detail.physical.detail.replace(
          ':clientId',
          clientId.toString()
        ),
        label: 'Detalhes do cliente',
      },
      {
        path: RoutesPath.private.clients.detail.physical.posts.list.replace(
          ':clientId',
          clientId.toString()
        ),
        label: 'Anúncios do cliente',
      },
      {
        path: RoutesPath.private.clients.detail.physical.posts.detail.replace(
          ':clientId',
          clientId.toString()
        ),
        label: 'Detalhes do anúncio',
      },
    ];
  }
  return [
    {
      path: RoutesPath.private.clients.detail.legal.detail.replace(
        ':clientId',
        clientId.toString()
      ),
      label: 'Detalhes do cliente',
    },
    {
      path: RoutesPath.private.clients.detail.legal.posts.list.replace(
        ':clientId',
        clientId.toString()
      ),
      label: 'Anúncios do cliente',
    },
    {
      path: RoutesPath.private.clients.detail.legal.posts.detail.replace(
        ':clientId',
        clientId.toString()
      ),
      label: 'Detalhes do anúncio',
    },
  ];
};

export default getRoutes;
