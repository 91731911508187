import styled from 'styled-components';

import { sideBarWidth } from '../styles';

export const Container = styled.div`
  height: calc(100vh - 70px);
  padding: 16px 0px;
  width: ${sideBarWidth}px;
  background-color: ${(props) => props.theme.colors.white.main};
  border-right: 2px solid ${(props) => props.theme.colors.gray.one};
  position: fixed;
  overflow: auto;

  @media (max-width: 768px) {
    display: none;
  }
`;
