import RoutesPath from '@router/routes';

const routes = [
  {
    path: RoutesPath.private.plans.list,
    label: 'Lista de planos',
  },
  {
    path: RoutesPath.private.plans.create,
    label: 'Cadastro de plano',
  },
];

export default routes;
