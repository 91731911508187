import React from 'react';

import { Tooltip } from 'react-tooltip';

import Button from '@components/Button';

import { Container, Divider, Image, MainPhotoText, TrashIcon } from './styles';

interface CarPhotoProps {
  value: Image;
  onRemove: (value: Image) => void;
  onSetAsMain?: (value: Image) => void;
}

const CarPhoto: React.FC<CarPhotoProps> = ({
  value,
  onRemove,
  onSetAsMain,
}) => {
  return (
    <Container isMain={!!onSetAsMain} data-cy={`car-photo-${value.id}`}>
      <Tooltip id="photo-delete-tooltip" />
      <TrashIcon
        data-tooltip-id="photo-delete-tooltip"
        data-tooltip-content="Excluir foto"
        size={24}
        onClick={() => onRemove(value)}
        variant="Bold"
      />
      <Image src={value.medium} alt="Foto do veículo" />
      <Divider />
      <div className="flex items-center justify-between">
        {onSetAsMain ? (
          <Button variant="outline" onClick={() => onSetAsMain(value)}>
            Definir como capa
          </Button>
        ) : (
          <MainPhotoText>Foto da capa</MainPhotoText>
        )}
      </div>
    </Container>
  );
};

export default CarPhoto;
