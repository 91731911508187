import React from 'react';

import { useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { Container, Content, Label } from './styles';

interface SideBarItemProps extends SidebarItem {
  index: number;
}

const SideBarItem: React.FC<SideBarItemProps> = ({
  icon: Icon,
  index,
  name,
  route,
}) => {
  const theme = useTheme();
  const location = useLocation();

  const itemIsSelected =
    location.pathname.split('/')[1] === route.split('/')[1];

  return (
    <Container selected={itemIsSelected} index={index} to={route}>
      <Content selected={itemIsSelected} index={index}>
        <Icon size={theme.sidebarIconSize} />
        <Label selected={itemIsSelected} index={index}>
          {name}
        </Label>
      </Content>
    </Container>
  );
};

export default SideBarItem;
