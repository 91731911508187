import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
`;

export const Avatar = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 16px;
`;

export const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;

  @media (max-width: 500px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 16px;
  }
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.colors.black.two};
  font-size: ${({ theme }) => theme.fontSize.regular};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const Infos = styled.div`
  color: ${({ theme }) => theme.colors.main};
  display: flex;
  gap: 16px;
  font-size: ${({ theme }) => theme.fontSize.smaller};
  align-items: baseline;
`;

export const Divider = styled.hr`
  border-top: 1px solid ${({ theme }) => theme.colors.white.three};
  margin: 24px 0px;
`;

export const InfoLink = styled(Link)`
  color: ${({ theme }) => theme.colors.main};
  transition: color 0.2s;

  svg {
    font-size: ${({ theme }) => theme.fontSize.regular};
    color: ${({ theme }) => theme.colors.info};
    margin-left: 4px;
  }
`;
