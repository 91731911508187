import React from 'react';

import { B2Card } from 'react-b2components';

import DetailPerson from '@components/DetailPerson';

import { Divider } from './styles';

interface DetailPersonCardProps {
  avatar?: string;
  title: string;
  infos: Array<{
    label: string;
    value: string;
    link?: string;
  }>;
  isActive?: boolean;
  requestUpdateStatus?: () => void;
  children?: React.ReactNode;
}

const DetailPersonCard: React.FC<DetailPersonCardProps> = ({
  avatar,
  title,
  infos,
  isActive,
  requestUpdateStatus,
  children,
}) => {
  return (
    <B2Card>
      <DetailPerson
        avatar={avatar}
        title={title}
        infos={infos}
        isActive={isActive}
        requestUpdateStatus={requestUpdateStatus}
      />
      {children && <Divider />}
      {children}
    </B2Card>
  );
};

export default DetailPersonCard;
