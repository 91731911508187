import { B2Button } from 'react-b2components';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 0 32px 24px;
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 425px) {
    flex-direction: column;
  }
`;

export const CustomButton = styled(B2Button)`
  width: 100%;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const CancelButton = styled(CustomButton)`
  border-color: ${({ theme }) => theme.colors.error};
  color: ${({ theme }) => theme.colors.error};
`;
