import RoutesPath from '@router/routes';

const getRoutes = (id: number) => [
  {
    path: RoutesPath.private.clients.list.legal,
    label: 'Lista de clientes',
  },
  {
    path: RoutesPath.private.clients.detail.legal.detail.replace(
      ':clientId',
      id.toString()
    ),
    label: 'Detalhes do cliente',
  },
  {
    path: RoutesPath.private.clients.detail.legal.addPrivilege,
    label: 'Assinalar plano',
  },
];

export default getRoutes;
