import styled from 'styled-components';

import Select from '@components/Select';

export default styled(Select)`
  padding-right: 32px;
  width: fit-content;
  background-color: ${({ theme }) => theme.colors.white.main};
  border-radius: 16px;
  color: ${({ theme }) => theme.colors.gray.six};
`;
