import { B2Card } from 'react-b2components';
import styled from 'styled-components';

import Button from '@components/Button';

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 64px;
  gap: 24px;
`;

export const Card = styled(B2Card)`
  height: fit-content;
  margin: 0 0 80px 0;
  padding: 0 32px 24px;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  right: 12px;
  bottom: 0;
  padding: 10px;
  box-sizing: border-box;
  z-index: 1001;
`;

export const FormButton = styled(Button)`
  padding: 12px 64px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
`;
