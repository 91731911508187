import RoutesPath from 'router/routes';

const getRoutes = (faqId: string) => {
  return [
    {
      path: RoutesPath.private.faq.list,
      label: 'Lista de FAQ',
    },
    {
      path: RoutesPath.private.faq.detail.replace(':faqId', faqId.toString()),
      label: 'Detalhes do FAQ',
    },
    {
      path: RoutesPath.private.faq.edit,
      label: 'Editar FAQ',
    },
  ];
};

export default getRoutes;
