import styled from 'styled-components';

export const ForgotPasswordButton = styled.span`
  margin: 21px 0;
  color: ${(props) => props.theme.colors.gray.seven};
  font-family: ${(props) => props.theme.fontFamily};
  font-weight: ${(props) => props.theme.fontWeight.regular};
  cursor: pointer;
`;

export const ForgotPasswordButtonHighlight = styled.span`
  color: ${(props) => props.theme.colors.main};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  text-decoration: underline;
`;
