import React from 'react';

import moment from 'moment';
import { useMutation } from 'react-query';

import { useToast } from '@contexts/Toast';
import { usePosts } from '@hooks';

import VerifyIcon from '@components/VerifyIcon';

import {
  Container,
  MainCardContent,
  MainImage,
  PostTitle,
  PostTitleContainer,
  Subtitle,
  Switch,
  Text,
} from './styles';

interface MainCardProps {
  post: Post;
  onChangeStatus: () => void;
}

const MainCard: React.FC<MainCardProps> = ({ post, onChangeStatus }) => {
  const { inactivatePost, activatePost } = usePosts();
  const { addToast } = useToast();

  const requestUpdatePostStatus = useMutation(
    () => {
      if (post.is_active) {
        return inactivatePost(post.id);
      }

      return activatePost(post.id);
    },
    {
      onSuccess: () => {
        addToast('Status alterado com sucesso', 'success');
        onChangeStatus();
      },
      onError: () => {
        addToast('Falha ao alterar o status', 'error');
      },
    }
  );

  return (
    <Container>
      <MainImage
        data-cy="post-image"
        src={post.main_image?.image.medium ?? '/images/logo.svg'}
        alt="Imagem do anúncio"
      />
      <div>
        <MainCardContent>
          <PostTitleContainer>
            <PostTitle>
              {post.vehicle_brand.name} - {post.vehicle_model.name}
              {post.inspection_document && (
                <VerifyIcon size="32" variant="Bold" />
              )}
            </PostTitle>
          </PostTitleContainer>
          <Subtitle>{post.vehicle_version.name}</Subtitle>
          <Text>
            Última atualização: {moment(post.modified).format('DD/MM/YYYY')}
          </Text>
          <Text>{post.description || 'Anúncio sem descrição'}</Text>
        </MainCardContent>
      </div>
      {post.is_complete && (
        <Switch
          isActive={post.is_active}
          onChange={requestUpdatePostStatus.mutate}
        />
      )}
    </Container>
  );
};

export default MainCard;
