import React from 'react';

import { FiExternalLink } from 'react-icons/fi';

import { InactivateSwitch } from '@components/InactivateSwitch';

import {
  Avatar,
  CardHeader,
  Container,
  InfoContainer,
  InfoLink,
  Infos,
  Title,
} from './styles';

interface DetailPersonProps {
  avatar?: string;
  title: string;
  infos: Array<{
    label: string;
    value: string;
    link?: string;
  }>;
  link?: string;
  isActive?: boolean;
  requestUpdateStatus?: () => void;
}

const DetailPerson: React.FC<DetailPersonProps> = ({
  avatar,
  title,
  infos,
  link,
  isActive,
  requestUpdateStatus,
}) => {
  return (
    <Container>
      <Avatar data-cy="avatar" src={avatar ?? '/images/icon.svg'} />
      <InfoContainer>
        <CardHeader>
          <div>
            <Title>{title}</Title>
            {link && (
              <InfoLink to={link} target="_blank">
                <FiExternalLink />
              </InfoLink>
            )}
          </div>
          {isActive !== undefined && requestUpdateStatus !== undefined && (
            <InactivateSwitch
              isActive={isActive}
              onChange={requestUpdateStatus}
            />
          )}
        </CardHeader>
        <Infos>
          {infos.map((info) => {
            if (info.link?.length) {
              return (
                <InfoLink key={info.label} to={info.link} target="_blank">
                  <b>{info.label}:</b> {info.value}
                  <FiExternalLink />
                </InfoLink>
              );
            }

            return (
              <span key={info.label}>
                <b>{info.label}:</b> {info.value}
              </span>
            );
          })}
        </Infos>
      </InfoContainer>
    </Container>
  );
};

export default DetailPerson;
