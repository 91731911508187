import React, { useRef, useState } from 'react';

import { B2Menu } from 'react-b2components';
import { MdPerson, MdExitToApp } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '@contexts/Auth';

import RoutesPath from '@router/routes';

import Header from './Header';
import Sidebar from './Sidebar';
import {
  Container,
  MainContent,
  PrivateBaseContent,
  ProfileMenuItem,
  ProfileMenuItemText,
} from './styles';

interface PrivateBaseProps {
  children: React.ReactNode;
}

const PrivateBase: React.FC<PrivateBaseProps> = ({ children }) => {
  const menu = useRef<HTMLDivElement | null>(null);

  const [showMenu, setShowMenu] = useState(false);

  const { logout } = useAuth();
  const navigate = useNavigate();

  return (
    <Container>
      <Header />
      <MainContent>
        <Sidebar />
        <PrivateBaseContent>{children}</PrivateBaseContent>
      </MainContent>
      <B2Menu
        isShowing={showMenu}
        onHide={() => setShowMenu(false)}
        anchor={menu.current}
        offset={-5}
      >
        <ProfileMenuItem onClick={() => navigate(RoutesPath.private.profile)}>
          <MdPerson />
          <ProfileMenuItemText>Profile</ProfileMenuItemText>
        </ProfileMenuItem>
        <ProfileMenuItem onClick={() => logout()}>
          <MdExitToApp />
          <ProfileMenuItemText>Logout</ProfileMenuItemText>
        </ProfileMenuItem>
      </B2Menu>
    </Container>
  );
};

export default PrivateBase;
