import React, { ReactNode, useRef, useState } from 'react';

import { useToast } from 'contexts/Toast';
import { useAssets } from 'hooks';
import { useMutation } from 'react-query';

import { Input } from './styles';

interface ChildrenParams {
  fileName: string;
  openFileExplorer: () => void;
  isLoading: boolean;
}

type ChildrenFunction = (params: ChildrenParams) => ReactNode;

export interface BaseImagePickerProps {
  onAddImages: (image: Array<Image>) => void;
  inputAccept: string;
  disabled?: boolean;
  multiple?: boolean;
  dataCy?: string;
  children: ChildrenFunction;
}
const BaseImagePicker: React.FC<BaseImagePickerProps> = ({
  onAddImages,
  inputAccept,
  disabled = false,
  multiple = false,
  dataCy,
  children,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [fileName, setFileName] = useState<string>(
    'Nenhuma imagem selecionada'
  );
  const [isLoading, setIsLoading] = useState(false);

  const { getPresignedImageUrl, uploadS3Image, uploadImage } = useAssets();
  const { addToast } = useToast();

  const { mutateAsync: fetchPresignedUrl } = useMutation(getPresignedImageUrl);

  const { mutateAsync: sendS3UrlToApi } = useMutation(
    (presignedUrl: PresignedUrl) => uploadImage(presignedUrl),
    {
      onSuccess: () => {
        setFileName('Selecione uma nova imagem');
      },
      onMutate: () => setFileName('Carregando imagem...'),
      onError: () => addToast('Não foi possível salvar a sua imagem', 'error'),
      onSettled: () => {
        setFileName('Selecione uma nova imagem');
        if (inputRef.current) {
          inputRef.current.value = '';
        }
      },
    }
  );

  const { mutateAsync: requestUploadS3Image } = useMutation(
    ({ presignedUrl, file }: { presignedUrl: PresignedUrl; file: File }) =>
      uploadS3Image(presignedUrl, file)
  );

  const handleInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { files } = event.target;

    if (!files?.length) {
      return;
    }

    setIsLoading(true);

    const presignedUrls = await Promise.all(
      Array.from(files).map(() => fetchPresignedUrl())
    );
    await Promise.all(
      presignedUrls.map((presignedUrl, index) =>
        requestUploadS3Image({ presignedUrl, file: files[index] })
      )
    );
    const images = await Promise.all(
      presignedUrls.map((presignedUrl) => sendS3UrlToApi(presignedUrl))
    );
    onAddImages(images);

    setIsLoading(false);
  };

  return (
    <>
      {children({
        fileName,
        openFileExplorer: () => inputRef.current?.click(),
        isLoading,
      })}
      <Input
        data-cy={`image-input-${dataCy}`}
        type="file"
        accept={inputAccept}
        onChange={handleInputChange}
        ref={inputRef}
        multiple={multiple}
        disabled={disabled}
      />
    </>
  );
};

export default BaseImagePicker;
