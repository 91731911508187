import React from 'react';

import { Add } from 'iconsax-react';
import { Tooltip } from 'react-tooltip';

import BaseImagePicker from '@components/BaseImagePicker';
import Spinner from '@components/Spinner';

import BaseCarPhoto from '../BaseCarPhoto';

interface CarPhotoPickerProps {
  onUpload: (data: Array<Image>) => void;
}

const CarPhotoPicker: React.FC<CarPhotoPickerProps> = ({ onUpload }) => {
  return (
    <BaseImagePicker
      multiple
      onAddImages={onUpload}
      inputAccept="image/JPEG,image/PNG"
      dataCy="car-photo-picker"
    >
      {({ openFileExplorer, isLoading }) => (
        <div>
          <Tooltip id="photo-tooltip" />
          <BaseCarPhoto
            data-tooltip-id="photo-tooltip"
            data-tooltip-content="Clique para adicionar uma foto"
            onClick={openFileExplorer}
          >
            {isLoading ? <Spinner /> : <Add size={100} />}
          </BaseCarPhoto>
        </div>
      )}
    </BaseImagePicker>
  );
};

export default CarPhotoPicker;
