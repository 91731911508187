import React from 'react';

import useAnalytics from 'hooks/Analytics';
import { Tag } from 'iconsax-react';
import { useQuery } from 'react-query';
import { useTheme } from 'styled-components';

import InformativeCard from '@components/InformativeCard';

interface SubscriptionsCountProps {
  period: Period;
}

const SubscriptionsCount: React.FC<SubscriptionsCountProps> = ({ period }) => {
  const theme = useTheme();
  const { getSubscriptionsCount } = useAnalytics();

  const fetchSubscriptionsCount = useQuery(['subscriptionsCount', period], () =>
    getSubscriptionsCount(period)
  );

  return (
    <InformativeCard
      dataCy="count-card"
      title="Nº total de assinantes"
      value={`${fetchSubscriptionsCount.data?.count ?? 0} clientes`}
      icon={{
        component: Tag,
        color: theme.colors.info,
      }}
      request={fetchSubscriptionsCount}
    />
  );
};

export default SubscriptionsCount;
