import RoutesPath from '@router/routes';

const routes = [
  {
    path: RoutesPath.private.faq.list,
    label: 'Lista de FAQ',
  },
  {
    path: RoutesPath.private.faq.detail,
    label: 'Detalhes do FAQ',
  },
];

export default routes;
