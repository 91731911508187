import React from 'react';

import { ClientsSearchSelectProps } from '@components/ClientsSearchSelect';

import { Select } from './styles';

type StoreFilterProps = Pick<
  ClientsSearchSelectProps,
  'value' | 'onChange' | 'onClear'
>;

const StoreFilter: React.FC<StoreFilterProps> = (props) => {
  return (
    <Select name="storeFilter" {...props} placeholder="Filtrar por cliente" />
  );
};

export default StoreFilter;
