import React, { useRef } from 'react';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { useToast } from '@contexts/Toast';
import { usePayments } from '@hooks';

import BaseForm from '@components/BaseForm';
import PageHeader from '@components/PageHeader';
import RequestContainer from '@components/RequestContainer';

import RoutesPath from '@router/routes';

import EditPlanInfo from '../Form/EditPlanInfo';
import getRoutes from './routes';

const EditPlan: React.FC = () => {
  const planInfoRef = useRef<FormStepRef>(null);

  const { getPlan, updatePlan } = usePayments();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { planId } = useParams<{
    planId: string;
  }>();

  const fetchPlan = useQuery(['plan', planId], () => getPlan(Number(planId)), {
    enabled: !!planId,
  });

  const updateRequest = useMutation(
    (plan: FormValuesEditPlanInfo) => updatePlan(Number(planId!), plan),
    {
      onSuccess: () => {
        addToast('Plano atualizado com sucesso', 'success');
        queryClient.invalidateQueries(['plan', planId]);
        navigate(RoutesPath.private.plans.detail.replace(':planId', planId!));
      },
    }
  );

  return (
    <>
      <PageHeader title="Edição de plano" routes={getRoutes(planId!)} />
      <RequestContainer requests={[fetchPlan]}>
        <BaseForm
          initialValues={{
            name: fetchPlan.data?.name,
            description: fetchPlan.data?.description || '',
            isHidden: fetchPlan.data?.is_hidden,
          }}
          steps={[
            {
              title: 'Dados do plano',
              ref: planInfoRef,
              component: EditPlanInfo,
            },
          ]}
          createRequest={updateRequest}
        />
      </RequestContainer>
    </>
  );
};

export default EditPlan;
