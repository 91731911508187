import { useCallback } from 'react';

import { useApi, endpoints } from '@contexts/Api';

interface RequestChangePasswordResponse {
  message: string;
}

type UserFormValues = Pick<UserInfo, 'avatar' | 'name'>;

const useAuthentication = () => {
  const { request } = useApi();

  const performLogin = useCallback(
    async (email: string, password: string) => {
      const data = {
        email,
        password,
      };

      const response = await request<LoginResponse>({
        method: 'post',
        url: endpoints.auth.login,
        data,
      });

      return response.data;
    },
    [request]
  );

  const getProfile = useCallback(async () => {
    const response = await request<UserInfo>({
      method: 'get',
      url: endpoints.auth.profile,
    });

    return response.data;
  }, [request]);

  const requestChangePassword = useCallback(
    async (email: string) => {
      const response = await request<RequestChangePasswordResponse>({
        method: 'post',
        url: endpoints.auth.resetPassword,
        data: { email },
      });

      return response.data;
    },
    [request]
  );

  const changePassword = useCallback(
    async (password: string, confirm_password: string, token: string) => {
      const response = await request({
        method: 'post',
        url: endpoints.auth.updatePassword,
        data: { password, confirm_password },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    },
    [request]
  );

  const updateProfile = useCallback(
    async (user: UserFormValues) => {
      const data = {
        ...user,
        avatar: user.avatar?.id ?? null,
      };

      const response = await request<UserInfo>({
        method: 'patch',
        url: endpoints.auth.profile,
        data,
      });

      return response.data;
    },
    [request]
  );

  const confirmEmail = useCallback(
    async (token: string) => {
      const response = await request({
        method: 'post',
        url: endpoints.auth.confirmEmail,
        data: { token },
      });

      return response.data;
    },
    [request]
  );

  const updatePassword = useCallback(
    async (password: string, confirm_password: string) => {
      const response = await request({
        method: 'post',
        url: endpoints.auth.updatePassword,
        data: { password, confirm_password },
      });

      return response.data;
    },
    [request]
  );

  const deleteAccount = useCallback(async () => {
    const response = await request<void>({
      method: 'delete',
      url: endpoints.auth.profile,
    });

    return response.data;
  }, [request]);

  const listSystemManagers = useCallback(
    async <SystemManagerFields extends keyof SystemManager>(
      params: SystemManagersQuery
    ) => {
      const response = await request<
        Paginated<SystemManager, SystemManagerFields>
      >({
        method: 'get',
        url: endpoints.auth.systemManagers.list,
        params: {
          page: params.page,
          search: params.search || undefined,
          role: params.role || undefined,
          ordering: params.ordering || undefined,
          fields: params.fields?.join(','),
        },
      });

      return response.data;
    },
    [request]
  );

  const getSystemManager = useCallback(
    async (id: number) => {
      const response = await request<SystemManager>({
        method: 'get',
        url: endpoints.auth.systemManagers.get.replace(':id', id.toString()),
      });

      return response.data;
    },
    [request]
  );

  const createSystemManager = useCallback(
    async (data: FormValuesUserInfo) => {
      const response = await request<SystemManager>({
        method: 'post',
        url: endpoints.auth.systemManagers.post,
        data,
      });

      return response.data;
    },
    [request]
  );

  const updateSystemManager = useCallback(
    async (id: number, data: FormValuesUserInfo) => {
      const response = await request<SystemManager>({
        method: 'patch',
        url: endpoints.auth.systemManagers.patch.replace(':id', id.toString()),
        data,
      });

      return response.data;
    },
    [request]
  );

  const inactivateSystemManagers = useCallback(
    async (id: number) => {
      const response = await request<void>({
        method: 'post',
        url: endpoints.auth.systemManagers.inactivate.replace(
          ':id',
          id.toString()
        ),
      });

      return response.data;
    },
    [request]
  );

  const activateSystemManagers = useCallback(
    async (id: number) => {
      const response = await request<void>({
        method: 'post',
        url: endpoints.auth.systemManagers.activate.replace(
          ':id',
          id.toString()
        ),
      });

      return response.data;
    },
    [request]
  );

  const listStoreUsers = useCallback(
    async <StoreUserFields extends keyof StoreUser>(
      params: StoreUsersQuery
    ) => {
      const response = await request<Paginated<StoreUser, StoreUserFields>>({
        method: 'get',
        url: endpoints.auth.storeUsers.list,
        params: {
          fields: params.fields.join(','),
          page: params.page,
          search: params.search || undefined,
          role: params.role || undefined,
          ordering: params.ordering || undefined,
          store: params.store,
        },
      });

      return response.data;
    },
    [request]
  );

  const getStoreUser = useCallback(
    async (id: number) => {
      const response = await request<StoreUser>({
        method: 'get',
        url: endpoints.auth.storeUsers.get.replace(':id', id.toString()),
      });

      return response.data;
    },
    [request]
  );

  const inactivateStoreUser = useCallback(
    async (id: number) => {
      const response = await request<void>({
        method: 'post',
        url: endpoints.auth.storeUsers.inactivate.replace(':id', id.toString()),
      });

      return response.data;
    },
    [request]
  );

  const activateStoreUser = useCallback(
    async (id: number) => {
      const response = await request<void>({
        method: 'post',
        url: endpoints.auth.storeUsers.activate.replace(':id', id.toString()),
      });

      return response.data;
    },
    [request]
  );

  const updateEmail = useCallback(
    async (email: string, password: string) => {
      const response = await request<void>({
        method: 'post',
        url: endpoints.auth.updateEmail,
        data: { email, password },
      });

      return response.data;
    },
    [request]
  );

  return {
    getProfile,
    performLogin,
    requestChangePassword,
    changePassword,
    updateProfile,
    confirmEmail,
    updatePassword,
    deleteAccount,
    listSystemManagers,
    getSystemManager,
    createSystemManager,
    updateSystemManager,
    inactivateSystemManagers,
    activateSystemManagers,
    listStoreUsers,
    getStoreUser,
    inactivateStoreUser,
    activateStoreUser,
    updateEmail,
  };
};

export default useAuthentication;
