import React from 'react';

import errorAnimation from './errorAnimation.json';
import { Container, Title, Text, Card, ErrorLottie } from './styles';

interface IErrorBoundaryProps {
  children: React.ReactNode;
}

interface IErrorBoundaryStates {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<
  IErrorBoundaryProps,
  IErrorBoundaryStates
> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container>
          <Card>
            <Title>Ops!</Title>
            <ErrorLottie loop={true} animationData={errorAnimation} />
            <Text>Ocorreu um erro inesperado durante a navegação.</Text>
            <Text>
              Por favor, tente novamente ou entre em contato com o suporte.
            </Text>
          </Card>
        </Container>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
