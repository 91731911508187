import styled, { css } from 'styled-components';

const CollapsibleSection = styled.div<{ $isCollapsed: boolean }>`
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.3s ease-in-out;

  div {
    overflow: hidden;
  }

  ${({ $isCollapsed }) =>
    !$isCollapsed &&
    css`
      grid-template-rows: 1fr;
    `};
`;

export default CollapsibleSection;
