import { MdCheckCircle, MdError } from 'react-icons/md';
import styled from 'styled-components';

import Loading from '@components/Loading';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`;

export const Message = styled.span`
  color: ${(props) => props.theme.colors.gray.twelve};
  font-size: ${(props) => props.theme.fontSize.big};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  text-align: center;
`;

export const CustomLoading = styled(Loading)`
  margin-top: 25px;
`;

export const ErrorIcon = styled(MdError)`
  color: ${(props) => props.theme.colors.error};
  font-size: ${(props) => props.theme.fontSize.big};
  flex-shrink: 0;
`;

export const SuccessIcon = styled(MdCheckCircle)`
  color: ${(props) => props.theme.colors.success};
  font-size: ${(props) => props.theme.fontSize.big};
  flex-shrink: 0;
`;
