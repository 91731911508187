import React, { useEffect, useState } from 'react';

import { cnpj, cpf, phone } from 'b2utils';
import moment from 'moment';
import { B2TableDataCell, B2TableRow } from 'react-b2components';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useStores } from '@hooks';

import { Content } from '@components/BaseForm/styles';
import DateRangeCalendarFilter from '@components/DateRangeCalendarFilter';
import FiltersContainer from '@components/FiltersContainer';
import OrderingButton from '@components/OrderingButton';
import SearchFilter from '@components/SearchFilter';
import SelectFilter from '@components/SelectFilter';
import Status from '@components/Status';
import Table from '@components/Table';

import RoutesPath from '@router/routes';
import { enums } from '@utils';

interface ClientsProps {
  type: enums.StoreType;
}

const fields = [
  'id',
  'name',
  'phone',
  'document',
  'owner_info',
  'created',
  'is_active',
] as const;

type ListStoreFields = (typeof fields)[number];

const Clients: React.FC<ClientsProps> = ({ type }) => {
  const [queryParams, setQueryParams] = useState<StoresQuery>({
    fields: fields as unknown as Array<keyof Store>,
    page: 1,
    type,
  });

  useEffect(() => {
    setQueryParams((prevState) => ({
      ...prevState,
      type,
    }));
  }, [type]);

  const { listStores } = useStores();

  const navigate = useNavigate();

  const fetchStores = useQuery(['stores', queryParams], () =>
    listStores<ListStoreFields>(queryParams)
  );

  const onClickStore = (id: number) => {
    if (type === enums.StoreType.PHYSICAL) {
      navigate(
        RoutesPath.private.clients.detail.physical.detail.replace(
          ':clientId',
          id.toString()
        )
      );
      return;
    }

    navigate(
      RoutesPath.private.clients.detail.legal.detail.replace(
        ':clientId',
        id.toString()
      )
    );
  };

  const onSearch = (search: string) => {
    setQueryParams((prevState) => ({
      ...prevState,
      search,
      page: 1,
    }));
  };

  const handleStatusFilterChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setQueryParams((prevState) => ({
      ...prevState,
      isActive:
        event.target.value === '' ? undefined : event.target.value === 'true',
    }));
  };

  const handleDateRangeFilterSelected = (startDate?: Date, endDate?: Date) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      createdGte: startDate ? moment(startDate).format() : undefined,
      createdLte: endDate ? moment(endDate).format() : undefined,
    }));
  };

  const handleOrderingFilterChange = () => {
    setQueryParams((prevState) => ({
      ...prevState,
      ordering: prevState.ordering === 'name' ? '' : 'name',
    }));
  };

  const statusFilterValue =
    queryParams.isActive === undefined ? '' : String(queryParams.isActive);

  return (
    <Content>
      <FiltersContainer>
        <SearchFilter onSearch={onSearch} />
        <SelectFilter
          onChange={handleStatusFilterChange}
          value={statusFilterValue}
        >
          <option value="">Status: Todos</option>
          <option value="true">Ativo</option>
          <option value="false">Inativo</option>
        </SelectFilter>
        <DateRangeCalendarFilter
          clearableDateRange
          onDateRangeFilterSelected={handleDateRangeFilterSelected}
        />
        <OrderingButton
          isActive={queryParams.ordering === 'name'}
          onClick={handleOrderingFilterChange}
        />
      </FiltersContainer>
      <Table
        fetchData={fetchStores}
        headerData={[
          'Nome',
          'E-mail',
          'Telefone',
          type === enums.StoreType.PHYSICAL ? 'CPF' : 'CNPJ',
          'Data de criação',
          'Status',
        ]}
        renderRow={(store) => (
          <B2TableRow key={store.id} onClick={() => onClickStore(store.id)}>
            <B2TableDataCell>{store.name}</B2TableDataCell>
            <B2TableDataCell>{store.owner_info.email}</B2TableDataCell>
            <B2TableDataCell>
              {phone.fromObject({
                countryCode: store.phone.country_code,
                areaCode: store.phone.area_code,
                number: store.phone.number,
              })}
            </B2TableDataCell>
            <B2TableDataCell>
              {type === enums.StoreType.PHYSICAL
                ? cpf.mask(store.document)
                : cnpj.mask(store.document)}
            </B2TableDataCell>
            <B2TableDataCell>
              {moment(store.created).format('DD/MM/YYYY')}
            </B2TableDataCell>
            <B2TableDataCell>
              <Status isActive={store.is_active} />
            </B2TableDataCell>
          </B2TableRow>
        )}
        paginator
        changePage={(newPage) =>
          setQueryParams((prevState) => ({
            ...prevState,
            page: newPage,
          }))
        }
        currentPage={queryParams.page}
      />
    </Content>
  );
};

export default Clients;
