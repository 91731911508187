import { color } from 'b2utils';
import styled from 'styled-components';

import BaseCard from '../BaseCard';

export const Card = styled(BaseCard)`
  display: flex;
  justify-content: space-between;
`;

export const CardTitle = styled.p`
  color: ${(props) => props.theme.colors.gray.eleven};
  font-weight: ${(props) => props.theme.fontWeight.regular};
`;

export const CardValue = styled.p`
  color: ${(props) => props.theme.colors.black.two};
  font-size: ${(props) => props.theme.fontSize.big};
  font-weight: ${(props) => props.theme.fontWeight.bold};
`;

export const CardIconContainer = styled.div<{
  color: string;
}>`
  background-color: ${(props) => color.addOpacityOnHexColor(props.color, 0.21)};
  color: ${(props) => props.color};
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 14px;
`;
