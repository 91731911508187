import React from 'react';

import { BaseCardProps } from '@components/BaseCard';

import { Card, CardTitle } from './styles';

interface ChartCardProps {
  title: string;
  request: BaseCardProps['request'];
  children: React.ReactNode;
  dataCy?: string;
}

const ChartCard: React.FC<ChartCardProps> = ({
  title,
  request,
  children,
  dataCy,
}) => {
  return (
    <Card dataCy={dataCy} request={request}>
      <>
        <CardTitle>{title}</CardTitle>
        {children}
      </>
    </Card>
  );
};

export default ChartCard;
