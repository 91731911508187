import React from 'react';

import { IB2Modal } from 'react-b2components';

import { Modal, Body, CloseButton, Container, Header } from './styles';

export interface BaseModalProps extends IB2Modal {
  title: string;
  onClose: () => void;
}

const BaseModal: React.FC<BaseModalProps> = ({
  title,
  onClose,
  children,
  ...props
}) => {
  return (
    <Modal {...props} onBackdropClick={onClose}>
      <Container>
        <Header>
          {title}
          <CloseButton onClick={() => onClose?.()} />
        </Header>
        <Body>{children}</Body>
      </Container>
    </Modal>
  );
};

export default BaseModal;
