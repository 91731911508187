import React, { ReactNode, useRef, useState } from 'react';

import { useToast } from 'contexts/Toast';
import { useAssets } from 'hooks';
import { useMutation } from 'react-query';

import { Input } from './styles';

interface ChildrenParams {
  fileName: string;
  openFileExplorer: () => void;
  isLoading: boolean;
}

type ChildrenFunction = (params: ChildrenParams) => ReactNode;

export interface BaseFilePickerProps {
  onAddFile: (file: ApiFile) => void;
  inputAccept?: string;
  disabled?: boolean;
  children: ChildrenFunction;
  dataCy?: string;
}

const BaseFilePicker: React.FC<BaseFilePickerProps> = ({
  onAddFile,
  inputAccept,
  disabled = false,
  children,
  dataCy,
}) => {
  const [fileName, setFileName] = useState<string>(
    'Nenhum arquivo selecionado'
  );

  const inputRef = useRef<HTMLInputElement>(null);

  const { getPresignedFileUrl, uploadS3File, uploadFile } = useAssets();
  const { addToast } = useToast();

  const { isLoading: isLoadingURL, mutateAsync: fetchPresignedUrl } =
    useMutation(({ extension }: { extension: string }) =>
      getPresignedFileUrl(extension)
    );

  const { isLoading: isLoadingAPI, mutate: sendS3UrlToApi } = useMutation(
    uploadFile,
    {
      onSuccess: (file) => {
        setFileName('Selecione um novo arquivo');
        onAddFile(file);
      },
      onMutate: () => setFileName('Carregando arquivo...'),
      onError: () => addToast('Não foi possível salvar seu arquivo', 'error'),
      onSettled: () => {
        setFileName('Selecione um novo arquivo');
        if (inputRef.current) {
          inputRef.current.value = '';
        }
      },
    }
  );

  const { mutate: requestUploadS3File, isLoading: isLoadingS3 } = useMutation(
    ({ presignedUrl, file }: { presignedUrl: PresignedUrl; file: File }) =>
      uploadS3File(presignedUrl, file),
    {
      onSuccess: (_, variables) => {
        sendS3UrlToApi(variables.presignedUrl);
      },
    }
  );

  const handleInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];

    if (!file) {
      return;
    }

    const extension = file.type.split('/')[1];

    const presignedUrl = await fetchPresignedUrl({ extension });
    requestUploadS3File({ presignedUrl, file });
  };

  return (
    <>
      {children({
        fileName,
        openFileExplorer: () => inputRef.current?.click(),
        isLoading: isLoadingAPI || isLoadingS3 || isLoadingURL,
      })}
      <Input
        data-cy={`image-input-${dataCy}`}
        type="file"
        accept={inputAccept}
        onChange={handleInputChange}
        ref={inputRef}
        disabled={disabled}
      />
    </>
  );
};

export default BaseFilePicker;
