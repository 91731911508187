import { color } from 'b2utils';
import styled from 'styled-components';

interface InputGroupProps {
  checked: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const RadioButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.four};
  padding-top: ${({ theme }) => theme.spacing.five};
`;

export const InputGroup = styled.div<InputGroupProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 140px;
  max-width: 360px;
  gap: ${({ theme }) => theme.spacing.three};
  padding: ${({ theme }) => theme.spacing.three};
  border-radius: ${({ theme }) => theme.borderRadius};
  transition: all 0.5s ease-in-out;

  &,
  label {
    cursor: pointer;
  }

  background-color: ${({ theme, checked }) =>
    checked
      ? color.addOpacityOnHexColor(theme.colors.main, 0.3)
      : theme.colors.white.three};
`;

export const IconGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.one};
  color: ${({ theme }) => theme.colors.main};

  img {
    width: ${({ theme }) => theme.spacing.twelve};
    height: ${({ theme }) => theme.spacing.twelve};
  }
`;

export const Label = styled.label`
  margin-bottom: ${({ theme }) => theme.spacing.ten};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;
