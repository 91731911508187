import React from 'react';

import {
  Container,
  LeftPanel,
  RightPanel,
  Logo,
  Card,
  LeftPanelTop,
  LeftPanelCenter,
  LeftPanelBottom,
  LeftPanelContainer,
  LeftPanelBottomBtn,
  LeftPanelCenterBold,
} from './styles';

interface AuthBaseProps {
  children: React.ReactNode;
}

const AuthBase: React.FC<AuthBaseProps> = ({ children }) => {
  return (
    <Container>
      <LeftPanel>
        <LeftPanelContainer>
          <LeftPanelTop>
            <Logo src="/images/logo-white.svg" alt="Logo" />
          </LeftPanelTop>
          <LeftPanelCenter>
            <span>Um sistema.</span>
            <LeftPanelCenterBold>Muitas possibilidades.</LeftPanelCenterBold>
          </LeftPanelCenter>
          <LeftPanelBottom>
            <LeftPanelBottomBtn variant="transparent">
              Termos de serviço
            </LeftPanelBottomBtn>
            {'|'}
            <LeftPanelBottomBtn variant="transparent">
              Politica de privacidade
            </LeftPanelBottomBtn>
          </LeftPanelBottom>
        </LeftPanelContainer>
      </LeftPanel>
      <RightPanel>
        <Card>
          <Logo src="/images/logo.svg" alt="Logo" />
          {children}
        </Card>
      </RightPanel>
    </Container>
  );
};

export default AuthBase;
