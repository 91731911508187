import React from 'react';

import { AxiosError } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { useToast } from '@contexts/Toast';
import { useAuthentication } from '@hooks';

import DetailPersonCard from '@components/DetailPersonCard';
import PageHeader from '@components/PageHeader';
import RequestContainer from '@components/RequestContainer';

import { enums } from '@utils';

import getRoutes from './routes';

const DetailStoreUser: React.FC = () => {
  const { clientId, storeUserId } = useParams<{
    clientId: string;
    storeUserId: string;
  }>();
  const { getStoreUser, inactivateStoreUser, activateStoreUser } =
    useAuthentication();

  const { addToast } = useToast();

  const fetchStoreUser = useQuery(
    ['storeUsers', Number(storeUserId)],
    () => getStoreUser(Number(storeUserId)),
    {
      enabled: !!storeUserId,
    }
  );

  const requestUpdateUserStatus = useMutation(
    () => {
      if (fetchStoreUser.data?.is_active) {
        return inactivateStoreUser(Number(storeUserId));
      }

      return activateStoreUser(Number(storeUserId));
    },
    {
      onSuccess: () => {
        addToast('Status alterado com sucesso', 'success');
        fetchStoreUser.refetch();
      },
      onError: ({ response }: AxiosError) => {
        if (!response?.data) {
          addToast('Falha ao alterar o status', 'error');
          return;
        }
        (response?.data as Array<string>).forEach((error) => {
          addToast(error, 'error');
        });
      },
    }
  );

  return (
    <RequestContainer requests={[fetchStoreUser]}>
      <>
        <PageHeader
          title="Detalhes do usuário da loja"
          routes={getRoutes(Number(clientId))}
        />
        {fetchStoreUser.data && (
          <DetailPersonCard
            avatar={fetchStoreUser.data.avatar?.medium}
            title={fetchStoreUser.data.name}
            infos={[
              {
                label: 'Permissão',
                value: enums.StoreUserRoleMap[fetchStoreUser.data.role],
              },
              {
                label: 'E-mail',
                value: fetchStoreUser.data.email,
              },
            ]}
            isActive={!!fetchStoreUser.data.is_active}
            requestUpdateStatus={requestUpdateUserStatus.mutate}
          />
        )}
      </>
    </RequestContainer>
  );
};

export default DetailStoreUser;
