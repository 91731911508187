import React, { useState } from 'react';

import { useFormikContext } from 'formik';
import { useInfiniteQuery } from 'react-query';
import { getNextPageParam } from 'utils/helpers';

import { useToast } from '@contexts/Toast';
import { usePosts } from '@hooks';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import Label from '@components/Label';
import SearchSelect from '@components/SearchSelect';

interface BrandSelectProps {
  category: number;
}

const BrandSelect: React.FC<BrandSelectProps> = ({ category }) => {
  const [search, setSearch] = useState('');

  const formik = useFormikContext<FormValuesVehicleInfo>();

  const { listBrands } = usePosts();
  const { addToast } = useToast();

  const {
    data: brands,
    isLoading,
    fetchNextPage,
  } = useInfiniteQuery(
    ['brands', search],
    ({ pageParam = 1 }) =>
      listBrands({
        fields: ['id', 'name'],
        page: pageParam,
        search,
        categories: [category],
      }),
    {
      getNextPageParam,
      onError: () => {
        addToast('Falha ao carregar as marcas de veículos.', 'error');
      },
    }
  );

  return (
    <FormGroup>
      <Label htmlFor="brand">Marca</Label>
      <SearchSelect
        name="brand"
        onSearch={setSearch}
        options={
          brands?.pages
            .flatMap((page) => page.results)
            .map((brand) => ({
              label: brand.name,
              value: brand.id.toString(),
            })) || []
        }
        value={formik.values.brand.label}
        onChange={(value) => {
          setSearch('');
          formik.setValues({
            ...formik.values,
            brand: value,
            model: { label: '', value: '', manufacturingYears: [] },
            manufacturingYear: '',
            modelYear: '',
            version: { label: '', value: '' },
          });
        }}
        isLoading={isLoading}
        fetchMore={fetchNextPage}
        onBlur={() => formik.setFieldTouched('brand.value', true)}
        invalidValue={
          !!formik.touched.brand?.value && !!formik.errors.brand?.value
        }
      />
      <FormError name="brand.value" />
    </FormGroup>
  );
};

export default BrandSelect;
