import React from 'react';

import { useFormikContext } from 'formik';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';
import TextArea from '@components/TextArea';
import YesOrNoSwitch from '@components/YesOrNoSwitch';

const BasePlanInfo = () => {
  const formik = useFormikContext<FormValuesPlanInfo>();

  return (
    <>
      <FormRow>
        <FormGroup>
          <Label htmlFor="name">Nome</Label>
          <Input
            type="text"
            name="name"
            placeholder="Digite aqui"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            invalidValue={!!formik.touched.name && !!formik.errors.name}
          />
          <FormError name="name" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="description">Descrição</Label>
          <TextArea
            name="description"
            placeholder="Digite aqui"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.description}
            invalidValue={
              !!formik.touched.description && !!formik.errors.description
            }
          />
          <FormError name="description" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="isHidden">Plano público?</Label>
          <YesOrNoSwitch
            isChecked={!formik.values.isHidden}
            onChange={() =>
              formik.setFieldValue('isHidden', !formik.values.isHidden)
            }
          />
        </FormGroup>
      </FormRow>
    </>
  );
};

export default BasePlanInfo;
