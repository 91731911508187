import styled from 'styled-components';

import Button from '@components/Button';

export const StyledButton = styled(Button)<{ isActive: boolean }>`
  min-width: fit-content;
  background-color: ${({ theme }) => theme.colors.white.main};
  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.main : theme.colors.gray.six};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
`;
