import { MdArrowDropDown } from 'react-icons/md';
import styled, { css } from 'styled-components';

interface MenuProps {
  $isOpen: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.five};
  padding-left: ${({ theme }) => theme.spacing.five};
  border-left: 0.75px solid ${({ theme }) => theme.colors.gray.two};
  position: relative;
  cursor: pointer;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: ${({ theme }) => theme.spacing.one};
`;

export const Username = styled.span`
  color: ${({ theme }) => theme.colors.gray.ten};
  font-size: ${({ theme }) => theme.fontSize.regular};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;

export const Role = styled.span`
  color: ${({ theme }) => theme.colors.gray.six};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

export const ArrowDropDown = styled(MdArrowDropDown)`
  font-size: ${({ theme }) => theme.spacing.five};
  color: ${({ theme }) => theme.colors.gray.five};
  transition: transform 0.3s ease-in-out;
`;

export const UserAvatar = styled.img`
  width: 40px;
  height: 40px;
  border: 2px solid ${({ theme }) => theme.colors.secondary};
  border-radius: 50%;
  padding: 3px;
  margin-right: 10px;
`;

export const ProfileMenu = styled.div<MenuProps>`
  min-width: 100%;
  padding: 0;
  border-radius: 8px;
  position: absolute;
  top: 60px;
  right: 0;
  background-color: ${({ theme }) => theme.colors.white.main};
  box-shadow: ${({ theme }) => theme.boxShadow};
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.3s ease-in-out;

  div {
    &:first-child {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }
    &:last-child {
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    overflow: hidden;
  }

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      grid-template-rows: 1fr;
    `};
`;
