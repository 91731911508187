import React, { useState } from 'react';

import { useAuthentication } from 'hooks';
import { useMutation } from 'react-query';

import { useAuth } from '@contexts/Auth';
import { useToast } from '@contexts/Toast';

import DeleteModal from '@components/DeleteModal';
import SectionHeader from '@components/SectionHeader';

import { ProfileCard } from '../styles';
import { Container, DeleteButton, Warning } from './styles';

const DeleteAccount = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { deleteAccount } = useAuthentication();
  const { logout } = useAuth();
  const { addToast } = useToast();

  const requestDeleteAccount = useMutation(deleteAccount, {
    onSuccess: () => {
      setIsModalOpen(false);
      logout();
    },
    onError: () => {
      setIsModalOpen(false);
      addToast(
        'Não foi possível deletar sua conta, tente novamente mais tarde',
        'error'
      );
    },
  });

  return (
    <ProfileCard>
      <SectionHeader title="Apagar conta" />
      <Container>
        <Warning>
          Essa ação é irreversível e todos os seus dados serão apagados
        </Warning>
        <div>
          <DeleteButton variant="outline" onClick={() => setIsModalOpen(true)}>
            Apagar conta
          </DeleteButton>
        </div>
        <DeleteModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onConfirm={() => {
            setIsModalOpen(false);
            requestDeleteAccount.mutate();
          }}
          isLoading={requestDeleteAccount.isLoading}
          title="Apagar conta"
          message="Você tem certeza de quer apagar sua conta?"
        />
      </Container>
    </ProfileCard>
  );
};

export default DeleteAccount;
