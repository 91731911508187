import React, { useCallback, useState } from 'react';

import { transformObjectInArray } from 'b2utils';
import { UseMutationResult } from 'react-query';

import { useToast } from '@contexts/Toast';

import { BaseContainer } from '@components/Base/PrivateBase/styles';
import FormSteps from '@components/BaseForm/FormSteps';
import PageHeader from '@components/PageHeader';

import { Card, Content, ButtonsContainer, FormButton } from './styles';

interface BaseFormProps {
  steps: Array<Step>;
  createRequest: UseMutationResult<any, unknown, any, unknown>;
  routes?: Array<RoutePath>;
  title?: string;
  subtitle?: string;
  initialValues?: any;
  saveEveryStep?: boolean;
}

const BaseForm: React.FC<BaseFormProps> = ({
  steps,
  createRequest,
  routes,
  title,
  subtitle,
  initialValues,
  saveEveryStep = false,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [form, setForm] = useState<any>(initialValues);

  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async <Type,>(data: Type) => {
      const newForm = { ...form, ...data };
      setForm(newForm);
      const isLastStep = currentStep === steps.length - 1;
      const currentStepSave = steps[currentStep].saveStepData;
      try {
        if (saveEveryStep && currentStepSave) {
          await currentStepSave.mutateAsync(newForm);
        }
        if (isLastStep) {
          await createRequest.mutateAsync(newForm);
        } else {
          setCurrentStep(currentStep + 1);
        }
      } catch (error: any) {
        if (error.response?.data) {
          const messages = transformObjectInArray(error.response.data);
          messages.forEach((message) => addToast(message, 'error'));
        }
      }
    },
    [addToast, createRequest, currentStep, form, saveEveryStep, steps]
  );

  const renderCurrentStep = () => {
    const step = steps[currentStep];

    return (
      <step.component
        ref={step.ref}
        initialValues={form}
        onFinish={handleSubmit}
        {...step.props}
      />
    );
  };

  return (
    <BaseContainer>
      {title && (
        <PageHeader title={title} subtitle={subtitle} routes={routes} />
      )}
      <Content>
        {steps.length > 1 && (
          <FormSteps
            title={steps[currentStep].title}
            subtitle={steps[currentStep].subtitle}
            currentStep={currentStep}
            stepsLength={steps.length}
          />
        )}
        <Card>{renderCurrentStep()}</Card>
      </Content>
      <ButtonsContainer>
        {!createRequest.isLoading && currentStep !== 0 && (
          <FormButton
            variant="transparent"
            onClick={() => setCurrentStep(currentStep - 1)}
          >
            Voltar
          </FormButton>
        )}
        <FormButton
          type="button"
          onClick={() => steps[currentStep].ref.current?.submit()}
          isLoading={createRequest.isLoading}
        >
          {currentStep === steps.length - 1 ? 'Salvar' : 'Próximo'}
        </FormButton>
      </ButtonsContainer>
    </BaseContainer>
  );
};

export default BaseForm;
