import React, { useMemo } from 'react';

import { cep, cnpj, cpf, phone } from 'b2utils';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { StoreType } from 'utils/enums';

import { useToast } from '@contexts/Toast';
import { usePayments, useStores } from '@hooks';

import DetailPersonCard from '@components/DetailPersonCard';
import RequestContainer from '@components/RequestContainer';

import RoutesPath from '@router/routes';
import { enums } from '@utils';

import {
  AddressContainer,
  AddressGroup,
  AddressLabel,
  AddressText,
  AddressTitle,
} from './styles';

interface StoreDetailProps {
  store: Store;
  storeType: StoreType;
  privilege?: Pick<Privilege, 'id' | 'plan'>;
  subscription?: Pick<Subscription, 'id' | 'plan'>;
}

const StoreDetail: React.FC<StoreDetailProps> = ({
  store,
  storeType,
  privilege,
  subscription,
}) => {
  const { clientId } = useParams<{ clientId: string }>();
  const { inactivateStore, activateStore } = useStores();
  const { getPlan, getStoreCreditsBalance } = usePayments();
  const { addToast } = useToast();

  const queryClient = useQueryClient();

  const fetchCredits = useQuery(['credits', store.id], () =>
    getStoreCreditsBalance(store.id)
  );

  const plan = useMemo(
    () => subscription?.plan || privilege?.plan,
    [subscription?.plan, privilege?.plan]
  );

  const fetchPlan = useQuery(['plan', plan], () => getPlan(plan!), {
    enabled: !!plan,
  });

  const clientInfo = useMemo(() => {
    const baseInfo = [
      {
        label: 'E-mail',
        value: store.owner_info.email,
      },
      {
        label: 'Telefone',
        value: phone.fromObject({
          countryCode: store.phone.country_code,
          areaCode: store.phone.area_code,
          number: store.phone.number,
        }),
      },
      storeType === enums.StoreType.PHYSICAL
        ? {
            label: 'CPF',
            value: cpf.mask(store.document),
          }
        : {
            label: 'CNPJ',
            value: cnpj.mask(store.document),
          },
      {
        label: 'Créditos',
        value: (fetchCredits.data?.count ?? 0).toString(),
      },
    ];

    if (fetchPlan.data) {
      return [
        ...baseInfo,
        {
          label: 'Plano',
          value: fetchPlan.data.name,
          link: RoutesPath.private.plans.detail.replace(
            ':planId',
            fetchPlan.data.id.toString()
          ),
        },
        {
          label: 'Forma de assinatura',
          value: privilege ? 'Manual' : 'Sistema',
        },
      ];
    }

    if (storeType === enums.StoreType.LEGAL) {
      return [
        ...baseInfo,
        {
          label: 'Plano',
          value: 'Sem plano',
        },
      ];
    }

    return baseInfo;
  }, [fetchCredits.data?.count, fetchPlan.data, privilege, store, storeType]);

  const requestUpdateStoreStatus = useMutation(
    () => {
      if (store.is_active) {
        return inactivateStore(Number(clientId));
      }

      return activateStore(Number(clientId));
    },
    {
      onSuccess: () => {
        addToast('Status alterado com sucesso', 'success');
        queryClient.invalidateQueries(['store', store.id]);
      },
      onError: () => {
        addToast('Falha ao alterar o status', 'error');
      },
    }
  );

  return (
    <RequestContainer requests={[fetchPlan, fetchCredits]}>
      <DetailPersonCard
        avatar={store.logo?.medium}
        title={store.name}
        infos={clientInfo}
        isActive={store.is_active}
        requestUpdateStatus={requestUpdateStoreStatus.mutate}
      >
        <AddressContainer>
          <AddressTitle>Localização</AddressTitle>
          <AddressGroup>
            <AddressLabel>Endereço</AddressLabel>
            {store.address ? (
              <>
                <AddressText>
                  {store.address.street && `${store.address.street}, `}
                  {store.address.number && `${store.address.number}, `}
                  {store.address.zip_code &&
                    `${cep.mask(store.address.zip_code)}, `}
                  {store.address.district && `${store.address.district}, `}
                  {`${store.address.city.name} - ${store.address.city.state}`}
                </AddressText>
                <AddressText>{store.address.additional_info}</AddressText>
              </>
            ) : (
              <AddressText>Endereço não informado</AddressText>
            )}
          </AddressGroup>
        </AddressContainer>
      </DetailPersonCard>
    </RequestContainer>
  );
};

export default StoreDetail;
