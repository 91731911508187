import React, { forwardRef, useImperativeHandle } from 'react';

import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import IntegrationSelect from './IntegrationSelect';

interface IntegrationProps {
  initialValues?: IntegrationFormValues;
  onFinish: (data: IntegrationFormValues) => void;
}

const Integration: React.ForwardRefRenderFunction<
  FormStepRef,
  IntegrationProps
> = ({ initialValues, onFinish }, ref) => {
  const formikInitialValues: IntegrationFormValues = {
    integration: {
      label: '',
      value: '',
    },
    ...initialValues,
  };

  const formikValidationSchema = yup.object().shape({
    integration: yup.object().shape({
      value: yup.string(),
    }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  return (
    <FormikProvider value={formik}>
      <IntegrationSelect />
    </FormikProvider>
  );
};

export default forwardRef(Integration);
