import Label from 'components/Label';
import { B2Button } from 'react-b2components';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Warning = styled(Label)`
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWeight.bold};
`;

export const DeleteButton = styled(B2Button)`
  color: ${(props) => props.theme.colors.error};
  border-color: ${(props) => props.theme.colors.error};

  &:hover {
    background-color: ${(props) => props.theme.colors.error};
    color: ${(props) => props.theme.colors.white.one};
  }
`;
