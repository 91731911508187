import React, { useRef } from 'react';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { useToast } from '@contexts/Toast';
import { usePayments, useStores } from '@hooks';

import BaseForm from '@components/BaseForm';
import PageHeader from '@components/PageHeader';
import RequestContainer from '@components/RequestContainer';

import RoutesPath from '@router/routes';

import ChoosePlan from './Form/ChoosePlan';
import getRoutes from './routes';

const AddPrivilege: React.FC = () => {
  const choosePlanRef = useRef<FormStepRef>(null);

  const { clientId } = useParams<{ clientId: string }>();
  const { createPrivilege } = usePayments();
  const { getStore } = useStores();
  const { addToast } = useToast();

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const fetchStore = useQuery(
    ['store', Number(clientId)],
    () => getStore(Number(clientId)),
    {
      enabled: !!clientId,
    }
  );

  const createRequest = useMutation(
    ({ plan }: FormValuesChoosePlan) =>
      createPrivilege(Number(clientId!), Number(plan.value)),
    {
      onSuccess: () => {
        addToast('Plano assinalado com sucesso', 'success');
        navigate(
          RoutesPath.private.clients.detail.legal.detail.replace(
            ':clientId',
            clientId!
          ),
          { replace: true }
        );
        queryClient.invalidateQueries(['store', Number(clientId)]);
      },
    }
  );

  return (
    <>
      <PageHeader
        title="Assinalar plano"
        routes={getRoutes(Number(clientId!))}
      />
      <RequestContainer requests={[fetchStore]}>
        <>
          {fetchStore.data && (
            <BaseForm
              steps={[
                {
                  title: 'Escolha do plano',
                  ref: choosePlanRef,
                  component: ChoosePlan,
                  props: {
                    store: fetchStore.data,
                  },
                },
              ]}
              createRequest={createRequest}
            />
          )}
        </>
      </RequestContainer>
    </>
  );
};

export default AddPrivilege;
