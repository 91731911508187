import React, { useCallback, useMemo, useRef } from 'react';

import { phone } from 'b2utils';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { StoreType } from 'utils/enums';

import { useToast } from '@contexts/Toast';
import { useStores } from '@hooks';

import BaseForm from '@components/BaseForm';
import PageHeader from '@components/PageHeader';
import RequestContainer from '@components/RequestContainer';

import RoutesPath from '@router/routes';
import { enums, helpers } from '@utils';

import Address from '../Form/Address';
import Integration from '../Form/Integration';
import StoreInfo from '../Form/StoreInfo';
import getRoutes from './routes';

const EditClient = () => {
  const clientUserInfoRef = useRef<FormStepRef>(null);
  const clientAddressInfoRef = useRef<FormStepRef>(null);
  const integrationsRef = useRef<FormStepRef>(null);

  const { getStore, updateStore } = useStores();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { clientId } = useParams<{ clientId: string }>();

  const fetchStore = useQuery(
    ['store', Number(clientId)],
    () => getStore(Number(clientId)),
    {
      enabled: !!clientId,
    }
  );

  const storeType = useMemo(
    () => helpers.getStoreType(fetchStore.data),
    [fetchStore.data]
  );

  const updateRequest = useMutation(
    (store: FormValuesStore) => updateStore(Number(clientId!), store),
    {
      onSuccess: () => {
        addToast('Cliente atualizado com sucesso', 'success');
        queryClient.invalidateQueries(['client', clientId]);
        if (storeType === StoreType.PHYSICAL) {
          navigate(
            RoutesPath.private.clients.detail.physical.detail.replace(
              ':clientId',
              clientId!
            )
          );
          return;
        }
        navigate(
          RoutesPath.private.clients.detail.legal.detail.replace(
            ':clientId',
            clientId!
          )
        );
      },
    }
  );

  const convertToStoreFormValues = useCallback(
    (store: Store): FormValuesStore => {
      return {
        type: storeType,
        document: store.document,
        storeName: store.name,
        phone: phone.fromObject({
          countryCode: store.phone.country_code,
          areaCode: store.phone.area_code,
          number: store.phone.number,
        }),
        phoneIsWhatsapp: store?.phone_is_whatsapp,
        zipCode: store.address?.zip_code ?? '',
        state: store.address?.city.state ?? '',
        city: store.address?.city ?? {
          id: 0,
          name: '',
        },
        district: store.address?.district ?? '',
        street: store.address?.street ?? '',
        number: store.address?.number ?? '',
        additionalInfo: store.address?.additional_info ?? '',
        integration: {
          label: '',
          value: '',
        },
      };
    },
    [storeType]
  );

  const steps = useMemo(() => {
    const baseSteps: Array<Step> = [
      {
        title: 'Dados do cliente',
        ref: clientUserInfoRef,
        component: StoreInfo,
      },
      {
        title: 'Dados da localização',
        ref: clientAddressInfoRef,
        component: Address,
      },
    ];

    if (storeType === enums.StoreType.LEGAL) {
      baseSteps.push({
        title: 'Integração',
        ref: integrationsRef,
        component: Integration,
      });
    }

    return baseSteps;
  }, [storeType]);

  return (
    <>
      <PageHeader
        title="Edição de cliente"
        routes={getRoutes(Number(clientId!), storeType)}
      />
      <RequestContainer requests={[fetchStore]}>
        <>
          {fetchStore.data && (
            <BaseForm
              initialValues={convertToStoreFormValues(fetchStore.data)}
              steps={steps}
              createRequest={updateRequest}
            />
          )}
        </>
      </RequestContainer>
    </>
  );
};

export default EditClient;
