import React from 'react';

import Tabs from '@components/Tabs';

import RoutesPath from '@router/routes';
import { enums } from '@utils';

import Charges from '../Charges';
import PostsOfStore from '../PostsOfStore';
import StoreDetail from '../StoreDetail';

interface PhysicalClientTabsProps {
  store: Store;
}

const PhysicalClientTabs: React.FC<PhysicalClientTabsProps> = ({ store }) => {
  return (
    <Tabs
      tabs={[
        {
          title: 'Loja',
          component: (
            <StoreDetail store={store} storeType={enums.StoreType.PHYSICAL} />
          ),
          path: RoutesPath.private.clients.detail.physical.detail.replace(
            ':clientId',
            store.id.toString()
          ),
        },
        {
          title: 'Anúncios',
          component: <PostsOfStore />,
          path: RoutesPath.private.clients.detail.physical.posts.list.replace(
            ':clientId',
            store.id.toString()
          ),
        },
        {
          title: 'Cobranças',
          component: (
            <Charges store={store.id} storeType={enums.StoreType.PHYSICAL} />
          ),
          path: RoutesPath.private.clients.detail.physical.charges.replace(
            ':clientId',
            store.id.toString()
          ),
        },
      ]}
    />
  );
};

export default PhysicalClientTabs;
