import styled, { css } from 'styled-components';

export const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.white.main};
  padding: 16px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  cursor: pointer;
  position: relative;
`;

export const CheckboxContainer = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacing.three};
  right: ${({ theme }) => theme.spacing.three};
  z-index: 10;
`;

export const PostImage = styled.img<{ applyOpacity: boolean }>`
  width: 100%;
  height: 155px;
  border-radius: 8px;
  object-fit: contain;

  ${({ applyOpacity }) =>
    applyOpacity &&
    css`
      opacity: 0.5;
    `}
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: 12px;
`;

export const CardRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CardTitle = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

export const HighlightText = styled.span`
  color: ${({ theme }) => theme.colors.black.two};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Subtitle = styled.span`
  color: ${({ theme }) => theme.colors.gray.seven};
  font-size: ${({ theme }) => theme.fontSize.smaller};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const Text = styled.span`
  color: ${({ theme }) => theme.colors.gray.seven};
  font-size: ${({ theme }) => theme.fontSize.smaller};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

export const StoreInfoContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.main};
  max-width: 50%;
`;

export const StoreInfoText = styled(Text)`
  color: inherit;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StoreLogo = styled.img`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  gap: 4px;
`;

export const StatusIndicatorContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 171px;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BaseStatusIndicator = styled.div`
  color: ${({ theme }) => theme.colors.white.main};
  padding: 8px;
  border-radius: 16px;
  text-align: center;
  width: 90px;
  font-size: ${({ theme }) => theme.fontSize.smaller};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const DraftIndicator = styled(BaseStatusIndicator)`
  background-color: ${({ theme }) => theme.colors.main};
`;

export const RejectIndicator = styled(BaseStatusIndicator)`
  background-color: ${({ theme }) => theme.colors.error};
`;

export const PendingIndicator = styled(BaseStatusIndicator)`
  background-color: ${({ theme }) => theme.colors.info};
`;
