import React from 'react';

import { B2Button } from 'react-b2components';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

import { Container, ContentContainer, Image, PassButton } from './styles';

interface ModalProps {
  selectedImage?: Image;
  onHide: () => void;
  onClickPrev: () => void;
  onClickNext: () => void;
}

const Modal: React.FC<ModalProps> = ({
  selectedImage,
  onHide,
  onClickPrev,
  onClickNext,
}) => {
  return (
    <Container isOpen={!!selectedImage}>
      <PassButton onClick={onClickPrev}>
        <MdKeyboardArrowLeft size={30} />
      </PassButton>
      <ContentContainer>
        <B2Button onClick={onHide}>Fechar</B2Button>
        {selectedImage && <Image src={selectedImage.high} />}
      </ContentContainer>
      <PassButton onClick={onClickNext}>
        <MdKeyboardArrowRight size={30} />
      </PassButton>
    </Container>
  );
};

export default Modal;
