import React from 'react';

import emptyAnimation from './emptyAnimation.json';
import { AnimationContainer, EmptyLottie } from './styles';

interface EmptyAnimationProps {
  className?: string;
}

const EmptyAnimation: React.FC<EmptyAnimationProps> = ({ className }) => {
  return (
    <AnimationContainer className={className}>
      <h1>Nada por aqui</h1>
      <EmptyLottie loop={true} animationData={emptyAnimation} />
    </AnimationContainer>
  );
};

export default EmptyAnimation;
