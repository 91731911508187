import { useCallback } from 'react';

import { useApi, endpoints } from '@contexts/Api';

const usePayments = () => {
  const { request } = useApi();

  const listPlans = useCallback(
    async <PlanFields extends keyof Plan>(params: PlansQuery) => {
      const response = await request<Paginated<Plan, PlanFields>>({
        method: 'get',
        url: endpoints.payments.plans.list,
        params: {
          fields: params.fields.join(','),
          page: params.page,
          search: params.search,
        },
      });

      return response.data;
    },
    [request]
  );

  const getPlan = useCallback(
    async (id: number) => {
      const response = await request<Plan>({
        method: 'get',
        url: endpoints.payments.plans.get.replace(':id', id.toString()),
      });

      return response.data;
    },
    [request]
  );

  const inactivatePlan = useCallback(
    async (id: number) => {
      const response = await request<void>({
        method: 'post',
        url: endpoints.payments.plans.inactivate.replace(':id', id.toString()),
      });

      return response.data;
    },
    [request]
  );

  const activatePlan = useCallback(
    async (id: number) => {
      const response = await request<void>({
        method: 'post',
        url: endpoints.payments.plans.activate.replace(':id', id.toString()),
      });

      return response.data;
    },
    [request]
  );

  const createPlan = useCallback(
    async (data: FormValuesPlanInfo) => {
      const response = await request<Plan>({
        method: 'post',
        url: endpoints.payments.plans.post,
        data: {
          name: data.name,
          trial_period_days:
            data.hasTrialPeriod === 'true'
              ? Number(data.trialPeriodDays)
              : undefined,
          interval: data.interval,
          interval_count: Number(data.intervalCount),
          cycles: Number(data.cycles),
          price: Number(data.price.replace(/\D/g, '')),
          installments: [Number(data.installments)],
          max_number_of_posts: Number(data.maxNumberOfPosts),
          max_number_active_posts: Number(data.maxNumberActivePosts),
          description: data.description.trim(),
          is_hidden: data.isHidden,
        },
      });

      return response.data;
    },
    [request]
  );

  const updatePlan = useCallback(
    async (id: number, data: FormValuesEditPlanInfo) => {
      const response = await request<Plan>({
        method: 'patch',
        url: endpoints.payments.plans.patch.replace(':id', id.toString()),
        data: {
          name: data.name,
          description: data.description.trim(),
          is_hidden: data.isHidden,
        },
      });

      return response.data;
    },
    [request]
  );

  const listSubscriptions = useCallback(
    async <SubscriptionsFields extends keyof Subscription>(
      params: SubscriptionsQuery
    ) => {
      const response = await request<
        Paginated<Subscription, SubscriptionsFields>
      >({
        method: 'get',
        url: endpoints.payments.subscriptions.list,
        params: {
          ...params,
          fields: params.fields.join(','),
        },
      });

      return response.data;
    },
    [request]
  );

  const listVouchers = useCallback(
    async <VouchersFields extends keyof Voucher>(
      fields: Array<keyof Voucher>
    ) => {
      const response = await request<Paginated<Voucher, VouchersFields>>({
        method: 'get',
        url: endpoints.payments.vouchers.list,
        params: {
          fields: fields.join(','),
        },
      });

      return response.data;
    },
    [request]
  );

  const updateVoucher = useCallback(
    async (id: string, price: string) => {
      const response = await request<Voucher>({
        method: 'patch',
        url: endpoints.payments.vouchers.patch.replace(':id', id),
        data: {
          price: price.replace(/\D/g, ''),
        },
      });

      return response.data;
    },
    [request]
  );

  const listCharges = useCallback(
    async <ChargesFields extends keyof Charge>(params: ChargesQuery) => {
      const response = await request<Paginated<Charge, ChargesFields>>({
        method: 'get',
        url: endpoints.payments.charges.list,
        params: {
          fields: params.fields.join(','),
          store: params.store,
          created__gte: params.period?.since,
          created__lte: params.period?.until,
        },
      });

      return response.data;
    },
    [request]
  );

  const createPrivilege = useCallback(
    async (store: number, plan: number) => {
      const response = await request<Privilege>({
        method: 'post',
        url: endpoints.payments.privileges.post,
        data: {
          store,
          plan,
        },
      });

      return response.data;
    },
    [request]
  );

  const listPrivileges = useCallback(
    async <PrivilegesFields extends keyof Privilege>(
      params: PrivilegesQuery
    ) => {
      const response = await request<Paginated<Privilege, PrivilegesFields>>({
        method: 'get',
        url: endpoints.payments.privileges.list,
        params: {
          fields: params.fields.join(','),
          store: params.store,
        },
      });

      return response.data;
    },
    [request]
  );

  const deletePrivilege = useCallback(
    async (id: number) => {
      const response = await request<void>({
        method: 'delete',
        url: endpoints.payments.privileges.delete.replace(':id', id.toString()),
      });

      return response.data;
    },
    [request]
  );

  const getStoreCreditsBalance = useCallback(
    async (store: number) => {
      const response = await request<CreditsBalance>({
        method: 'get',
        url: endpoints.payments.credits.balance.get,
        params: {
          store,
        },
      });

      return response.data;
    },
    [request]
  );

  const doCharity = useCallback(
    async (quantity: number, store: number) => {
      const response = await request<void>({
        method: 'post',
        url: endpoints.payments.credits.charity,
        data: {
          quantity,
          store,
        },
      });

      return response.data;
    },
    [request]
  );

  const deleteSubscription = useCallback(
    async (id: number) => {
      const response = await request<void>({
        method: 'delete',
        url: endpoints.payments.subscriptions.delete.replace(
          ':id',
          id.toString()
        ),
      });

      return response.data;
    },
    [request]
  );

  return {
    listPlans,
    getPlan,
    createPlan,
    inactivatePlan,
    activatePlan,
    updatePlan,
    listSubscriptions,
    listVouchers,
    updateVoucher,
    listCharges,
    createPrivilege,
    listPrivileges,
    deletePrivilege,
    getStoreCreditsBalance,
    doCharity,
    deleteSubscription,
  };
};

export default usePayments;
