import { B2Card } from 'react-b2components';
import styled from 'styled-components';

export const Container = styled(B2Card)`
  background-color: ${(props) => props.theme.colors.white.main};
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
`;

export const TitlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Title = styled.span`
  color: ${(props) => props.theme.colors.black.two};
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWeight.bold};
`;

export const Subtitle = styled.span`
  color: ${(props) => props.theme.colors.gray.ten};
  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: ${(props) => props.theme.fontWeight.regular};
`;
