import { MdKeyboardArrowDown } from 'react-icons/md';
import styled, { css } from 'styled-components';

export const CollapsedButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export const CardTitle = styled.span`
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.black.two};
`;

export const Arrow = styled(MdKeyboardArrowDown)<{ $isCollapsed: boolean }>`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.main};
  transition: transform 0.5s;
  transform: rotate(180deg);
  font-size: ${({ theme }) => theme.fontSize.large};
  flex-shrink: 0;

  ${({ $isCollapsed }) =>
    $isCollapsed &&
    css`
      transform: rotate(0deg);
    `}
`;

export const Divider = styled.hr`
  border-top: 1px solid ${({ theme }) => theme.colors.white.three};
  margin: 20px 0px;
`;
