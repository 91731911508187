import React from 'react';

import moment from 'moment';
import { useQuery } from 'react-query';
import { ufOptions } from 'utils/helpers';

import { useCore } from '@hooks';

import DateRangeCalendarFilter from '@components/DateRangeCalendarFilter';
import FiltersContainer from '@components/FiltersContainer';
import SearchFilter from '@components/SearchFilter';

import { PostSelectFilter } from './styles';

interface FiltersProps {
  onChange: React.Dispatch<React.SetStateAction<PostsQuery>>;
  values: PostsQuery;
  children?: React.ReactNode;
}

const fields = ['id', 'name'] as const;

type ListCitiesFields = (typeof fields)[number];

const Filters: React.FC<FiltersProps> = ({ onChange, values, children }) => {
  const { getCitiesByState } = useCore();

  const cityQueryParams: CitiesQuery = {
    fields: fields as unknown as Array<keyof City>,
    state: values.state!,
  };

  const { data: cities } = useQuery(
    ['cities', cityQueryParams],
    () => getCitiesByState<ListCitiesFields>(cityQueryParams!),
    {
      enabled: !!values.state,
    }
  );

  const handleDateRangeFilterSelected = (startDate?: Date, endDate?: Date) => {
    onChange((prevState) => ({
      ...prevState,
      page: 1,
      modifiedGte: startDate ? moment(startDate).format() : undefined,
      modifiedLte: endDate ? moment(endDate).format() : undefined,
    }));
  };

  return (
    <FiltersContainer>
      <SearchFilter
        placeholder="Pesquise pelo nome do cliente"
        onSearch={(search) => {
          onChange((prevState) => ({
            ...prevState,
            search,
            page: 1,
          }));
        }}
      />
      <PostSelectFilter
        data-cy="state-filter"
        onChange={(event) => {
          onChange((prevState) => ({
            ...prevState,
            state: event.target.value,
          }));
        }}
        value={values.state}
      >
        <option value="">Estado: Todos</option>
        {ufOptions.slice(1).map((state) => (
          <option key={state.value} value={state.value}>
            {state.label}
          </option>
        ))}
      </PostSelectFilter>
      <PostSelectFilter
        data-cy="city-filter"
        onChange={(event) => {
          onChange((prevState) => ({
            ...prevState,
            city: event.target.value.length ? Number(event.target.value) : 0,
          }));
        }}
        value={values.city}
        disabled={!values.state}
      >
        <option value="0">Cidade: Todas</option>
        {cities?.map((city) => (
          <option key={city.id} value={city.id}>
            {city.name}
          </option>
        ))}
      </PostSelectFilter>
      {children}
      <DateRangeCalendarFilter
        clearableDateRange
        onDateRangeFilterSelected={handleDateRangeFilterSelected}
      />
    </FiltersContainer>
  );
};

export default Filters;
