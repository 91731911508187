import RoutesPath from '@router/routes';

const routes = [
  {
    path: RoutesPath.private.users.list,
    label: 'Lista de usuários',
  },
  {
    path: RoutesPath.private.users.create,
    label: 'Cadastro de usuário',
  },
];

export default routes;
