import React from 'react';

import { useMutation } from 'react-query';

import { useToast } from '@contexts/Toast';
import { usePayments } from '@hooks';

import ConfirmationModal from '@components/ConfirmationModal';

interface DeletePrivilegeConfirmationModalProps {
  isOpen: boolean;
  privilege: number;
  onConfirm: () => void;
  onCancel: () => void;
}

const DeletePrivilegeConfirmationModal: React.FC<
  DeletePrivilegeConfirmationModalProps
> = ({ isOpen, privilege, onConfirm, onCancel }) => {
  const { deletePrivilege } = usePayments();
  const { addToast } = useToast();

  const { mutate: requestDeletePrivilege, isLoading: isLoadingDelete } =
    useMutation(() => deletePrivilege(privilege), {
      onSuccess: onConfirm,
      onError: () => {
        addToast(
          'Não foi possível desassinalar o plano, tente novamente',
          'error'
        );
      },
    });

  return (
    <ConfirmationModal
      title="Tem certeza que deseja desassinalar o plano?"
      isOpen={isOpen}
      isLoading={isLoadingDelete}
      confirmButtonText="Sim"
      onConfirm={() => requestDeletePrivilege()}
      cancelButtonText="Cancelar"
      onClose={onCancel}
      onCancel={onCancel}
    />
  );
};

export default DeletePrivilegeConfirmationModal;
