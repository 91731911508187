import React, { forwardRef, useEffect, useImperativeHandle } from 'react';

import { cep } from 'b2utils';
import { FormikProvider, useFormik } from 'formik';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import * as yup from 'yup';

import { useToast } from '@contexts/Toast';
import { useBrasilApi, useCore } from '@hooks';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';
import Select from '@components/Select';

import { errors, helpers } from '@utils';

interface AddressProps {
  initialValues?: FormValuesAddress;
  onFinish: (data: FormValuesAddress) => void;
}

const fields = ['id', 'name'] as const;

type ListCitiesFields = (typeof fields)[number];

const Address: React.ForwardRefRenderFunction<FormStepRef, AddressProps> = (
  { initialValues, onFinish },
  ref
) => {
  const { getCitiesByState } = useCore();
  const { addToast } = useToast();
  const { getAddressByCep } = useBrasilApi();
  const queryClient = useQueryClient();

  const formikInitialValues: FormValuesAddress = {
    zipCode: '',
    state: '',
    city: {
      id: 0,
      name: '',
    },
    district: '',
    street: '',
    number: '',
    additionalInfo: '',
    ...initialValues,
  };

  const formikValidationSchema = yup.object().shape({
    zipCode: yup.string().trim().required(errors.required),
    state: yup.string().trim().required(errors.required),
    city: yup.object().shape({
      name: yup.string().trim().required(errors.required),
    }),
    district: yup.string().trim().required(errors.required),
    street: yup.string().trim().required(errors.required),
    number: yup.string().trim().required(errors.required),
    additionalInfo: yup.string().trim(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: onFinish,
  });

  const cityQueryParams: CitiesQuery = {
    fields: fields as unknown as Array<keyof City>,
    state: formik.values.state,
  };

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  const fetchAddressByCep = useMutation(
    ['addressByCep', formik.values.zipCode],
    () => getAddressByCep(formik.values.zipCode),
    {
      onMutate: () => addToast('Carregando dados pelo CEP...', 'info'),
      onError: () => {
        formik.setValues({
          ...formik.values,
          state: '',
          city: {
            id: 0,
            name: '',
          },
          district: '',
          street: '',
        });
        addToast(
          'Não foi possível carregar o endereço baseado no CEP informado',
          'error'
        );
      },
      onSuccess: (address: BrasilApiAddress) => {
        queryClient.invalidateQueries([
          'cityByState',
          { fields, state: formik.values.state },
        ]);
        formik.setValues({
          ...formik.values,
          state: address.state ?? '',
          district: address.neighborhood ?? '',
          street: address.street ?? '',
        });
      },
    }
  );

  const fetchCitiesByState = useQuery(
    ['cityByState', cityQueryParams],
    () => getCitiesByState<ListCitiesFields>(cityQueryParams),
    {
      onError: () => {
        formik.setFieldValue('city', {
          id: 0,
          name: '',
        });
        addToast('Não foi possível carregar as cidades desse estado', 'error');
      },
      onSuccess: (cities) => {
        if (cities.length) {
          const citySelected = cities.find(
            (city) =>
              city.name ===
              (fetchAddressByCep.data?.city ?? formik.initialValues.city.name)
          );
          formik.setFieldValue(
            'city',
            citySelected ?? {
              id: 0,
              name: '',
            }
          );
        } else {
          addToast(
            'Não foi possível carregar as cidades desse estado',
            'error'
          );
        }
      },
      enabled: !!formik.values.state.length,
    }
  );

  useEffect(() => {
    if (fetchCitiesByState.isLoading) {
      addToast('Carregando cidades...', 'info');
    }
  }, [addToast, fetchCitiesByState.isLoading]);

  return (
    <FormikProvider value={formik}>
      <FormRow>
        <FormGroup>
          <Label htmlFor="zipCode">CEP</Label>
          <Input
            type="text"
            name="zipCode"
            placeholder="Digite aqui"
            onChange={formik.handleChange}
            onBlur={() => {
              if (formik.values.zipCode?.replace(/\D/g, '').length === 8) {
                fetchAddressByCep.mutate();
              }
              formik.handleBlur('zipCode');
            }}
            value={cep.mask(formik.values.zipCode)}
            invalidValue={!!formik.touched.zipCode && !!formik.errors.zipCode}
            maxLength={10}
            disabled={fetchAddressByCep.isLoading}
          />
          <FormError name="zipCode" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="state">Estado</Label>
          <Select
            name="state"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.state}
            invalidValue={!!formik.touched.state && !!formik.errors.state}
          >
            {helpers.ufOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
          <FormError name="state" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="city">Cidade</Label>
          <Select
            name="city"
            onChange={(event) => {
              formik.setFieldValue('city', {
                id: event.target.value,
                name: event.target.options[event.target.selectedIndex].text,
              });
            }}
            onBlur={formik.handleBlur}
            value={formik.values.city.id}
            invalidValue={!!formik.touched.city && !!formik.errors.city}
            disabled={
              !formik.values.state.length ||
              fetchAddressByCep.isLoading ||
              fetchCitiesByState.isLoading
            }
          >
            <option value="">Escolha uma cidade</option>
            {fetchCitiesByState.data?.map((city) => (
              <option key={city.id} value={city.id}>
                {city.name}
              </option>
            ))}
          </Select>
          <FormError name="city.name" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="district">Bairro</Label>
          <Input
            type="text"
            name="district"
            placeholder="Digite aqui"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.district}
            invalidValue={!!formik.touched.district && !!formik.errors.district}
            disabled={fetchAddressByCep.isLoading}
          />
          <FormError name="district" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="street">Rua</Label>
          <Input
            type="text"
            name="street"
            placeholder="Digite aqui"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.street}
            invalidValue={!!formik.touched.street && !!formik.errors.street}
            disabled={fetchAddressByCep.isLoading}
          />
          <FormError name="street" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="number">Número</Label>
          <Input
            type="text"
            name="number"
            placeholder="Digite aqui"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.number}
            invalidValue={!!formik.touched.number && !!formik.errors.number}
          />
          <FormError name="number" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="additionalInfo">Complemento</Label>
          <Input
            type="text"
            name="additionalInfo"
            placeholder="Digite aqui"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.additionalInfo}
            invalidValue={
              !!formik.touched.additionalInfo && !!formik.errors.additionalInfo
            }
          />
          <FormError name="additionalInfo" />
        </FormGroup>
      </FormRow>
    </FormikProvider>
  );
};

export default forwardRef(Address);
