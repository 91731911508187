import React from 'react';

import { useFormikContext } from 'formik';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import Input from '@components/Input';
import Label from '@components/Label';

interface InputCustomFieldProps {
  customField: CustomField;
}

const InputCustomField: React.FC<InputCustomFieldProps> = ({ customField }) => {
  const formik = useFormikContext<FormValuesExtraInfo>();

  return (
    <FormGroup>
      <Label>{customField.name}</Label>
      <Input
        type={customField.type.toLowerCase()}
        name={`customFields.${customField.id.toString()}.answer`}
        placeholder="Digite aqui"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.customFields[customField.id]?.answer ?? ''}
        invalidValue={
          !!formik.touched.customFields?.[customField.id]?.answer &&
          !!formik.errors.customFields?.[customField.id]?.answer
        }
      />
      <FormError name={`customFields.${customField.id.toString()}.answer`} />
    </FormGroup>
  );
};

export default InputCustomField;
