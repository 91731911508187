import React from 'react';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { useToast } from '@contexts/Toast';
import { useStores } from '@hooks';

import PageHeader from '@components/PageHeader';
import RequestContainer from '@components/RequestContainer';

import RoutesPath from '@router/routes';

import routes from './routes';
import { Answer, Content, MainCard } from './styles';

const DetailFaq: React.FC = () => {
  const { faqId } = useParams<{ faqId: string }>();
  const { getFaq, deleteFaq } = useStores();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const fetchFaq = useQuery(['faq', faqId], () => getFaq(Number(faqId)), {
    enabled: !!faqId,
  });

  const requestDeleteFaq = useMutation(() => deleteFaq(Number(faqId!)), {
    onSuccess: () => {
      addToast('Publicidade apagada com sucesso', 'success');
      navigate(RoutesPath.private.faq.list, {
        replace: true,
      });
      queryClient.invalidateQueries('faq');
    },
  });

  return (
    <RequestContainer requests={[fetchFaq]}>
      <>
        <PageHeader
          title="Detalhes do FAQ"
          routes={routes}
          primaryButton={{
            text: 'Editar FAQ',
            onClick: () =>
              navigate(
                RoutesPath.private.faq.edit.replace(':faqId', faqId!.toString())
              ),
          }}
          deleteProps={{
            button: {
              text: 'Apagar FAQ',
            },
            modal: {
              title: 'Apagar FAQ',
              message: 'Tem certeza que deseja apagar esse FAQ?',
              isLoading: requestDeleteFaq.isLoading,
              onConfirm: requestDeleteFaq.mutate,
            },
          }}
        />
        {fetchFaq.data && (
          <Content>
            <MainCard>
              <h1>{fetchFaq.data.question}</h1>
              <Answer>{fetchFaq.data.answer}</Answer>
            </MainCard>
          </Content>
        )}
      </>
    </RequestContainer>
  );
};

export default DetailFaq;
