import React from 'react';

import { useMutation } from 'react-query';

import { usePosts } from '@hooks';

import FileSinglePicker from '@components/FileSinglePicker';
import Label from '@components/Label';

import CollapsibleCard from '../CollapsibleCard';

interface InspectionProps {
  post: number;
  inspectionDocument: ApiFile;
  onChangeFile: () => void;
}

const Inspection: React.FC<InspectionProps> = ({
  post,
  inspectionDocument,
  onChangeFile,
}) => {
  const { inspectPost, deleteInspection } = usePosts();

  const { mutate: requestUpdateInspection } = useMutation(
    ({ document }: { document: number }) => inspectPost(post, document),
    {
      onSuccess: onChangeFile,
    }
  );

  const { mutate: requestDeleteInspection } = useMutation(
    () => deleteInspection(post),
    {
      onSuccess: onChangeFile,
    }
  );

  return (
    <CollapsibleCard title="Vistoria" dataCy="Inspection">
      <div>
        <Label>PDF</Label>
        <FileSinglePicker
          dataCy="inspection"
          onAddFile={(file) =>
            requestUpdateInspection({
              document: file.id,
            })
          }
          onDeleteFile={() => requestDeleteInspection()}
          file={inspectionDocument}
        />
      </div>
    </CollapsibleCard>
  );
};

export default Inspection;
