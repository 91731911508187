import React from 'react';

import { currency } from 'b2utils';
import useAnalytics from 'hooks/Analytics';
import { Moneys } from 'iconsax-react';
import { useQuery } from 'react-query';
import { useTheme } from 'styled-components';

import InformativeCard from '@components/InformativeCard';

interface ChargesBalanceProps {
  period: Period;
}

const ChargesBalance: React.FC<ChargesBalanceProps> = ({ period }) => {
  const theme = useTheme();
  const { getChargesBalance } = useAnalytics();

  const fetchChargesBalance = useQuery(['chargesBalance', period], () =>
    getChargesBalance(period)
  );

  return (
    <InformativeCard
      dataCy="balance-card"
      title="Receita total"
      value={currency.centsToBrl(fetchChargesBalance.data?.balance ?? 0)}
      icon={{
        component: Moneys,
        color: theme.colors.success,
      }}
      request={fetchChargesBalance}
    />
  );
};

export default ChargesBalance;
