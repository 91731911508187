import React, { useState } from 'react';

import { B2Button, IB2Button } from 'react-b2components';

import DeleteModal from '@components/DeleteModal';
import RoutePaths from '@components/RoutePaths';

import {
  ButtonsContainer,
  Container,
  DeleteButton,
  MainContent,
  PageTitles,
  Subtitle,
  Title,
} from './styles';

export interface ButtonProps extends Omit<IB2Button, 'children'> {
  text: string;
}

interface DeleteProps {
  button: Omit<ButtonProps, 'onClick'>;
  modal: {
    title: string;
    message: string;
    isLoading: boolean;
    onConfirm: () => void;
  };
}

interface PageHeaderProps {
  title: string;
  subtitle?: string;
  deleteProps?: DeleteProps;
  primaryButton?: ButtonProps;
  secondaryButton?: ButtonProps;
  routes?: Array<RoutePath>;
  className?: string;
}

const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  subtitle,
  deleteProps,
  primaryButton,
  secondaryButton,
  routes,
  className,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Container className={className}>
      {routes && <RoutePaths routes={routes} />}
      <MainContent>
        <PageTitles>
          <Title>{title}</Title>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </PageTitles>
        <ButtonsContainer>
          {deleteProps && (
            <DeleteButton
              {...deleteProps.button}
              onClick={() => setIsModalOpen(true)}
            >
              {deleteProps.button.text}
            </DeleteButton>
          )}
          {secondaryButton && (
            <B2Button variant="outline" {...secondaryButton}>
              {secondaryButton.text}
            </B2Button>
          )}
          {primaryButton && (
            <B2Button {...primaryButton}>{primaryButton.text}</B2Button>
          )}
        </ButtonsContainer>
      </MainContent>
      {deleteProps && (
        <DeleteModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onConfirm={() => {
            setIsModalOpen(false);
            deleteProps.modal.onConfirm();
          }}
          isLoading={deleteProps.modal.isLoading}
          title={deleteProps.modal.title}
          message={deleteProps.modal.message}
        />
      )}
    </Container>
  );
};

export default PageHeader;
