import React, { forwardRef, useImperativeHandle } from 'react';

import { FormikProvider, useFormik } from 'formik';
import { useQuery } from 'react-query';
import * as yup from 'yup';

import { usePosts } from '@hooks';

import FormError from '@components/FormError';
import Input from '@components/Input';
import Label from '@components/Label';
import RequestContainer from '@components/RequestContainer';

import { errors } from '@utils';

import {
  Container,
  IconGroup,
  InputGroup,
  RadioButtonsContainer,
} from './styles';

interface VehicleCategoriesProps {
  initialValues?: FormValuesVehicleCategories;
  onFinish: (data: FormValuesVehicleCategories) => void;
}

const fields = ['id', 'name', 'icon', 'has_suggested_prices'] as const;

type ListVehicleCategoryFields = (typeof fields)[number];

const VehicleCategories: React.ForwardRefRenderFunction<
  FormStepRef,
  VehicleCategoriesProps
> = ({ initialValues, onFinish }, ref) => {
  const { listVehicleCategories } = usePosts();

  const fetchVehicleCategories = useQuery(['vehicleCategories'], () =>
    listVehicleCategories<ListVehicleCategoryFields>({
      fields: fields as unknown as Array<keyof VehicleCategory>,
    })
  );

  const formikInitialValues: FormValuesVehicleCategories = {
    category: undefined,
    ...initialValues,
  };

  const formikValidationSchema = yup.object().shape({
    category: yup.object().required(errors.required),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  return (
    <RequestContainer requests={[fetchVehicleCategories]}>
      <FormikProvider value={formik}>
        <Container>
          <RadioButtonsContainer>
            {fetchVehicleCategories.data?.map((category) => {
              return (
                <InputGroup
                  key={category.id}
                  data-cy="vehicle-category-radio-button"
                  checked={category?.id === formik.values.category?.id}
                  onClick={() => formik.setFieldValue('category', category)}
                >
                  <Input
                    type="radio"
                    checked={category.id === formik.values.category?.id}
                    onChange={() => formik.setFieldValue('category', category)}
                  />
                  <IconGroup>
                    <img
                      src={category.icon.path}
                      alt="Ícone do tipo de veiculo"
                    />
                    <Label>{category.name}</Label>
                  </IconGroup>
                </InputGroup>
              );
            })}
          </RadioButtonsContainer>
          <FormError name="category" />
        </Container>
      </FormikProvider>
    </RequestContainer>
  );
};

export default forwardRef(VehicleCategories);
