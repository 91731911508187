import React from 'react';

import { MdSwapVert } from 'react-icons/md';

import { StyledButton } from './styles';

interface OrderingButtonProps {
  isActive: boolean;
  onClick: () => void;
}

const OrderingButton: React.FC<OrderingButtonProps> = ({
  isActive,
  onClick,
}) => {
  return (
    <StyledButton
      data-cy="ordering-filter"
      isActive={isActive}
      onClick={onClick}
    >
      A-Z <MdSwapVert size={24} />
    </StyledButton>
  );
};

export default OrderingButton;
