import { B2Card } from 'react-b2components';
import styled from 'styled-components';

import { InactivateSwitch } from '@components/InactivateSwitch';

export const Container = styled(B2Card)`
  display: flex;
  gap: 24px;
  position: relative;

  @media (max-width: 930px) {
    flex-direction: column;
  }
`;

export const MainImage = styled.img`
  height: 200px;
  max-width: 400px;
  object-fit: contain;
  border-radius: 8px;

  @media (max-width: 930px) {
    align-self: center;
  }
`;

export const MainCardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const PostTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-right: 160px;
  width: fit-content;

  @media (max-width: 930px) {
    margin-right: 0;
  }
`;

export const PostTitle = styled.span`
  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.black.two};
`;

export const Subtitle = styled.span`
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.black.two};
`;

export const Text = styled.span`
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.gray.seven};
`;

export const Switch = styled(InactivateSwitch)`
  position: absolute;
  top: 20px;
  right: 20px;

  @media (max-width: 930px) {
    position: relative;
    top: auto;
    right: auto;
    align-self: center;
  }
`;
