import React, { useCallback, useRef } from 'react';

import moment from 'moment';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { useToast } from '@contexts/Toast';
import { useAdvertisements } from '@hooks';

import BaseForm from '@components/BaseForm';
import PageHeader from '@components/PageHeader';
import RequestContainer from '@components/RequestContainer';

import RoutesPath from '@router/routes';

import AdvertisementInfo from '../Form/AdvertisementInfo';
import getRoutes from './routes';

const EditAdvertisement = () => {
  const advertisementInfoRef = useRef<FormStepRef>(null);

  const { advertisementId } = useParams<{ advertisementId: string }>();
  const { getAdvertisement, updateAdvertisement } = useAdvertisements();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const fetchAdvertisement = useQuery(
    ['advertisement', advertisementId],
    () => getAdvertisement(Number(advertisementId)),
    {
      enabled: !!advertisementId,
    }
  );

  const updateRequest = useMutation(
    (advertisement: FormValuesAdvertisement) => {
      const newAdvertisement: EditFormValuesAdvertisement = {
        ...advertisement,
      };

      if (
        advertisement.startDate ===
        moment(fetchAdvertisement.data?.starts_at).format('YYYY-MM-DD')
      ) {
        newAdvertisement.startDate = undefined;
      }

      if (
        advertisement.endDate ===
        moment(fetchAdvertisement.data?.ends_at).format('YYYY-MM-DD')
      ) {
        newAdvertisement.endDate = undefined;
      }

      return updateAdvertisement(Number(advertisementId!), newAdvertisement);
    },
    {
      onSuccess: () => {
        addToast('Publicidade atualizada com sucesso', 'success');
        navigate(
          RoutesPath.private.advertisements.detail.replace(
            ':advertisementId',
            advertisementId!
          )
        );
        queryClient.invalidateQueries([
          'advertisement',
          Number(advertisementId!),
        ]);
      },
    }
  );

  const convertAdvertisementToFormValues = useCallback(
    (advertisement: Advertisement): FormValuesAdvertisement => ({
      bigImage: advertisement.image,
      smallImage: advertisement.mobile_image,
      title: advertisement.title,
      index: advertisement.index.toString(),
      startDate: moment(advertisement.starts_at).format('YYYY-MM-DD'),
      endDate: moment(advertisement.ends_at).format('YYYY-MM-DD'),
      link: advertisement.link,
      category: advertisement.category,
    }),
    []
  );

  return (
    <>
      <PageHeader
        title="Edição de publicidade"
        routes={getRoutes(advertisementId!)}
      />
      <RequestContainer requests={[fetchAdvertisement]}>
        <>
          {fetchAdvertisement.data && (
            <BaseForm
              initialValues={convertAdvertisementToFormValues(
                fetchAdvertisement.data
              )}
              steps={[
                {
                  title: 'Dados da publicidade',
                  ref: advertisementInfoRef,
                  component: AdvertisementInfo,
                },
              ]}
              createRequest={updateRequest}
            />
          )}
        </>
      </RequestContainer>
    </>
  );
};

export default EditAdvertisement;
