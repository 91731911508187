import RoutesPath from '@router/routes';

const routes = [
  {
    path: RoutesPath.private.posts.list.complete,
    label: 'Lista de anúncios',
  },
  {
    path: RoutesPath.private.posts.create,
    label: 'Cadastro de anúncios',
  },
];

export default routes;
