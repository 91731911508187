import React from 'react';

import { useNavigate } from 'react-router-dom';

import { Container, RouteLabel, Separator } from './styles';

interface RoutePathsProps {
  routes: Array<RoutePath>;
}

const RoutePaths: React.FC<RoutePathsProps> = ({ routes }) => {
  const navigate = useNavigate();

  return (
    <Container>
      {routes.map(({ path, label }, index) => {
        const isLastRoute = index === routes.length - 1;
        return (
          <RouteLabel
            key={path}
            onClick={() => !isLastRoute && navigate(path)}
            isLast={isLastRoute}
          >
            {label}
            {!isLastRoute && <Separator>{'>'}</Separator>}
          </RouteLabel>
        );
      })}
    </Container>
  );
};

export default RoutePaths;
