import React from 'react';

import { B2Card } from 'react-b2components';
import { UseQueryResult } from 'react-query';

import RequestContainer from '@components/RequestContainer';

export interface BaseCardProps {
  className?: string;
  request: UseQueryResult<any, unknown>;
  children: React.ReactElement;
  dataCy?: string;
}

const BaseCard: React.FC<BaseCardProps> = ({
  className,
  request,
  children,
  dataCy,
}) => {
  return (
    <B2Card className={className} data-cy={dataCy}>
      <RequestContainer requests={[request]}>{children}</RequestContainer>
    </B2Card>
  );
};

export default BaseCard;
