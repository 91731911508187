import React, { useState } from 'react';

import { B2Card } from 'react-b2components';

import CollapsibleSection from '@components/CollapsibleSection';

import { Arrow, CardTitle, CollapsedButton, Divider } from './styles';

interface CollapsibleCardProps {
  title: string;
  children: React.ReactNode;
  startOpen?: boolean;
  dataCy?: string;
}

const CollapsibleCard: React.FC<CollapsibleCardProps> = ({
  title,
  children,
  startOpen = false,
  dataCy,
}) => {
  const [isOpen, setIsOpen] = useState(startOpen);

  return (
    <B2Card data-cy={`collapsibleCard${dataCy}`}>
      <CollapsedButton data-cy={dataCy} onClick={() => setIsOpen(!isOpen)}>
        <CardTitle>{title}</CardTitle>
        <Arrow $isCollapsed={!isOpen} />
      </CollapsedButton>
      <Divider />
      <CollapsibleSection $isCollapsed={!isOpen}>{children}</CollapsibleSection>
    </B2Card>
  );
};

export default CollapsibleCard;
