import React from 'react';

import { IB2Table } from 'react-b2components';
import { UseQueryResult } from 'react-query';

import {
  TableEmpty,
  TableError,
  TableLoading,
  TableStyled,
  TableTotalCount,
  TableTotalCountContainer,
} from './styles';

interface TableProps<Type extends Paginated<any, any>>
  extends Omit<
    IB2Table<Type['results'][number]>,
    'data' | 'emptyTableComponent' | 'total'
  > {
  fetchData: UseQueryResult<Type, any>;
  disableHover?: boolean;
}

const Table = <Type extends Paginated<any, any>>({
  fetchData,
  disableHover,
  ...props
}: TableProps<Type>) => {
  return (
    <>
      {!!fetchData.data?.count && (
        <TableTotalCountContainer>
          <TableTotalCount>
            Total: {fetchData.data?.count}{' '}
            {fetchData.data?.count > 1 ? 'registros' : 'registro'}
          </TableTotalCount>
        </TableTotalCountContainer>
      )}
      <TableStyled
        canHover={
          !fetchData.isLoading &&
          !!fetchData.data?.results.length &&
          !disableHover
        }
        data={fetchData.data?.results ?? []}
        emptyTableComponent={() => {
          if (fetchData.isLoading) {
            return <TableLoading />;
          }

          if (fetchData.isError) {
            return <TableError />;
          }

          return <TableEmpty />;
        }}
        amountPerPage={20}
        total={fetchData.data?.count || 0}
        {...props}
      />
    </>
  );
};

export default Table;
