import RoutesPath from '@router/routes';

const routes = [
  {
    path: RoutesPath.private.clients.list.physical,
    label: 'Lista de clientes',
  },
  {
    path: RoutesPath.private.clients.create,
    label: 'Cadastro de cliente',
  },
];

export default routes;
