import React, { useState } from 'react';

import { useFormikContext } from 'formik';
import { useInfiniteQuery } from 'react-query';
import { getNextPageParam } from 'utils/helpers';

import { useToast } from '@contexts/Toast';
import { usePosts } from '@hooks';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import Label from '@components/Label';
import SearchSelect from '@components/SearchSelect';

import { enums } from '@utils';

interface TagSelectProps {
  label: string;
  formikKey: keyof Pick<
    FormValuesVehicleInfo,
    'color' | 'fuel' | 'numberOfDoors'
  >;
  type: enums.TagType;
}

const TagSelect: React.FC<TagSelectProps> = ({ label, formikKey, type }) => {
  const [search, setSearch] = useState('');

  const formik = useFormikContext<FormValuesVehicleInfo>();

  const { listPaginatedTags } = usePosts();
  const { addToast } = useToast();

  const {
    data: tags,
    isLoading,
    fetchNextPage,
  } = useInfiniteQuery(
    ['tags', type, search],
    ({ pageParam = 1 }) =>
      listPaginatedTags({
        fields: ['id', 'name'],
        page: pageParam,
        search,
        type,
      }),
    {
      getNextPageParam,
      onError: () => {
        addToast(`Falha ao carregar ${label.toLowerCase()}`, 'error');
      },
    }
  );

  return (
    <FormGroup>
      <Label htmlFor={type}>{label}</Label>
      <SearchSelect
        name={formikKey}
        onSearch={setSearch}
        options={
          tags?.pages
            .flatMap((page) => page.results)
            .map((tag) => ({
              label: tag.name,
              value: tag.id.toString(),
            })) || []
        }
        value={formik.values[formikKey].label}
        onChange={(value) => {
          formik.setFieldValue(formikKey, value);
          setSearch('');
        }}
        isLoading={isLoading}
        fetchMore={fetchNextPage}
        onBlur={() => formik.setFieldTouched(`${formikKey}.value`, true)}
        invalidValue={
          !!formik.touched[formikKey]?.value &&
          !!formik.errors[formikKey]?.value
        }
      />
      <FormError name={`${formikKey}.value`} />
    </FormGroup>
  );
};

export default TagSelect;
