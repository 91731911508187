import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';

import { FormikProvider, useFormik } from 'formik';
import { useQuery } from 'react-query';
import * as yup from 'yup';

import { usePosts } from '@hooks';

import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import RequestContainer from '@components/RequestContainer';

import CarPhoto from './CarPhoto';
import CarPhotoPicker from './CarPhotoPicker';
import { Grid } from './styles';

interface PhotosProps {
  initialValues?: FormValuesPhotos;
  onFinish: (data: FormValuesPhotos) => void;
  post: number;
}

const fields = ['id', 'image', 'type'] as const;

type ListPostImageFields = (typeof fields)[number];

const Photos: React.ForwardRefRenderFunction<FormStepRef, PhotosProps> = (
  { initialValues, onFinish, post },
  ref
) => {
  const [excludedPhotos, setExcludedPhotos] = useState<Array<FormPostImage>>(
    []
  );

  const { listPostImages } = usePosts();

  const fetchPostImages = useQuery('postImages', () =>
    listPostImages<ListPostImageFields>({
      fields: fields as unknown as Array<keyof PostImage>,
      post,
    })
  );

  const formikInitialValues: FormValuesPhotos = {
    mainPhoto: null,
    photos: [],
    ...initialValues,
    excludedPhotos: [],
  };

  const formikValidationSchema = yup.object().shape({
    mainPhoto: yup.object().nullable(),
    photos: yup.array(),
    excludedPhotos: yup.array(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: (values) => {
      let newMainPhoto = values.mainPhoto;

      if (
        initialValues?.mainPhoto &&
        values.mainPhoto?.id === initialValues?.mainPhoto?.id
      ) {
        newMainPhoto = {
          ...initialValues.mainPhoto,
          new: false,
        };
      }

      const newPhotos = values.photos.map((photo) => {
        const isOldImage = initialValues?.photos?.some(
          (item) => item.id === photo.id
        );

        if (isOldImage) {
          return {
            ...photo,
            new: false,
          };
        }

        return {
          ...photo,
          new: true,
        };
      });

      const newValues: FormValuesPhotos = {
        mainPhoto: newMainPhoto,
        photos: newPhotos,
        excludedPhotos,
      };

      onFinish(newValues);
    },
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  const handleAddPhoto = useCallback(
    (images: Array<Image>) => {
      const hasMainPhoto = !!formik.values.mainPhoto;

      const newImages = images.map((image) => ({
        ...image,
        new: true,
      }));

      if (hasMainPhoto) {
        formik.setFieldValue('photos', [...formik.values.photos, ...newImages]);
      } else if (newImages.length === 1) {
        formik.setFieldValue('mainPhoto', newImages[0]);
      } else {
        const [mainPhoto, ...otherPhotos] = newImages;
        formik.setFieldValue('mainPhoto', mainPhoto);
        formik.setFieldValue('photos', [
          ...formik.values.photos,
          ...otherPhotos,
        ]);
      }
    },
    [formik]
  );

  const handleDeletePhoto = useCallback(
    (image: number) => {
      const imageExists = initialValues?.photos?.some(
        (item) => item.id === image
      );

      if (imageExists) {
        const postImage = fetchPostImages.data?.find(
          (item) => item.image.id === image
        );

        setExcludedPhotos((prevState) => [...prevState, postImage!]);
      }

      const newPhotos = formik.values.photos.filter(
        (photo) => photo.id !== image
      );
      formik.setFieldValue('photos', newPhotos);
    },
    [fetchPostImages.data, formik, initialValues?.photos]
  );

  const setFirstPhotoAsMain = useCallback(() => {
    const firstPhoto = formik.values.photos[0];

    if (firstPhoto) {
      formik.setFieldValue('mainPhoto', { ...firstPhoto, new: true });
      handleDeletePhoto(firstPhoto.id);
    } else {
      formik.setFieldValue('mainPhoto', null);
    }
  }, [formik, handleDeletePhoto]);

  const handleDeleteMainPhoto = useCallback(
    async (image: number) => {
      const imageExists = initialValues?.mainPhoto?.id === image;

      if (imageExists) {
        const postImage = fetchPostImages.data?.find(
          (item) => item.image.id === image
        );

        setExcludedPhotos((prevState) => [...prevState, postImage!]);
      }
    },
    [fetchPostImages.data, initialValues?.mainPhoto?.id]
  );

  const handleSetAsMain = useCallback(
    (image: Image) => {
      const newPhotos = formik.values.photos.filter(
        (photo) => photo.id !== image.id
      );

      if (formik.values.mainPhoto) {
        newPhotos.unshift(formik.values.mainPhoto);
      }

      handleDeleteMainPhoto(formik.values.mainPhoto!.id);
      handleDeletePhoto(image.id);

      formik.setFieldValue('photos', newPhotos);
      formik.setFieldValue('mainPhoto', { ...image, new: true });
    },
    [formik, handleDeleteMainPhoto, handleDeletePhoto]
  );

  return (
    <RequestContainer requests={[fetchPostImages]}>
      <FormikProvider value={formik}>
        <FormRow>
          <FormGroup>
            <Grid>
              {formik.values.mainPhoto && (
                <CarPhoto
                  onRemove={(image) => {
                    handleDeleteMainPhoto(image.id);
                    setFirstPhotoAsMain();
                  }}
                  value={formik.values.mainPhoto}
                />
              )}
              {formik.values.photos.map((photo, index) => (
                <CarPhoto
                  key={photo.id}
                  onRemove={(image) => handleDeletePhoto(image.id)}
                  value={formik.values.photos[index]}
                  onSetAsMain={handleSetAsMain}
                />
              ))}
              <CarPhotoPicker onUpload={handleAddPhoto} />
            </Grid>
          </FormGroup>
        </FormRow>
      </FormikProvider>
    </RequestContainer>
  );
};

export default forwardRef(Photos);
