import React from 'react';

import { currency } from 'b2utils';

import { enums } from '@utils';

import CollapsibleCard from '../CollapsibleCard';
import {
  ExtraInfValue,
  ExtraInfoContent,
  ExtraInfoGrid,
  ExtraInfoLabel,
  OptionalTag,
  OptionalsTitle,
} from './styles';

interface VehicleInfoProps {
  post: Post;
}

const VehicleInfo: React.FC<VehicleInfoProps> = ({ post }) => {
  return (
    <CollapsibleCard dataCy="VehicleInfo" title="Informações do veículo">
      <div>
        <ExtraInfoGrid>
          <ExtraInfoContent>
            <ExtraInfoLabel>Blindado:</ExtraInfoLabel>
            <ExtraInfValue>{post.is_armored ? 'Sim' : 'Não'}</ExtraInfValue>
          </ExtraInfoContent>
          <ExtraInfoContent>
            <ExtraInfoLabel>Ano de fabricação:</ExtraInfoLabel>
            <ExtraInfValue>{post.vehicle_manufacturing_year}</ExtraInfValue>
          </ExtraInfoContent>
          <ExtraInfoContent>
            <ExtraInfoLabel>Ano do modelo:</ExtraInfoLabel>
            <ExtraInfValue>{post.vehicle_year}</ExtraInfValue>
          </ExtraInfoContent>
          <ExtraInfoContent>
            <ExtraInfoLabel>Preço:</ExtraInfoLabel>
            <ExtraInfValue>
              {post.vehicle_price
                ? currency.centsToBrl(post.vehicle_price)
                : 'Sem preço'}
            </ExtraInfValue>
          </ExtraInfoContent>
          {post.tags
            .filter((tag) => tag.type !== enums.TagType.OPTIONAL)
            .map((tag) => (
              <ExtraInfoContent key={tag.id} data-cy={`tag${tag.id}`}>
                <ExtraInfoLabel>{enums.TagTypeMap[tag.type]}:</ExtraInfoLabel>
                <ExtraInfValue>{tag.name}</ExtraInfValue>
              </ExtraInfoContent>
            ))}
        </ExtraInfoGrid>
        <OptionalsTitle>Opcionais:</OptionalsTitle>
        <ExtraInfoGrid>
          {post.tags
            .filter((tag) => tag.type === enums.TagType.OPTIONAL)
            .map((tag) => (
              <OptionalTag key={tag.id} data-cy={`tag${tag.id}`}>
                {tag.name}
              </OptionalTag>
            ))}
        </ExtraInfoGrid>
      </div>
    </CollapsibleCard>
  );
};

export default VehicleInfo;
