import moment from 'moment';
import * as yup from 'yup';

import { errors } from '@utils';

import { StoreType } from './enums';

export const ufOptions = [
  { value: '', label: 'Selecione um estado' },
  { value: 'AC', label: 'AC' },
  { value: 'AL', label: 'AL' },
  { value: 'AP', label: 'AP' },
  { value: 'AM', label: 'AM' },
  { value: 'BA', label: 'BA' },
  { value: 'CE', label: 'CE' },
  { value: 'DF', label: 'DF' },
  { value: 'ES', label: 'ES' },
  { value: 'GO', label: 'GO' },
  { value: 'MA', label: 'MA' },
  { value: 'MT', label: 'MT' },
  { value: 'MS', label: 'MS' },
  { value: 'MG', label: 'MG' },
  { value: 'PA', label: 'PA' },
  { value: 'PB', label: 'PB' },
  { value: 'PR', label: 'PR' },
  { value: 'PE', label: 'PE' },
  { value: 'PI', label: 'PI' },
  { value: 'RJ', label: 'RJ' },
  { value: 'RN', label: 'RN' },
  { value: 'RS', label: 'RS' },
  { value: 'RO', label: 'RO' },
  { value: 'RR', label: 'RR' },
  { value: 'SC', label: 'SC' },
  { value: 'SP', label: 'SP' },
  { value: 'SE', label: 'SE' },
  { value: 'TO', label: 'TO' },
];

export const getNextPageParam = (
  previousData: Paginated<any, any>,
  pages: Array<Paginated<any, any>>
) => {
  if (previousData.next) {
    return pages.length + 1;
  }
};

export const dateValidation = (initialValue: string) => {
  return yup
    .date()
    .when((values, schema) => {
      if (!moment(values[0]).isSame(moment(initialValue), 'day')) {
        return schema.min(moment().format('YYYY-MM-DD'), errors.pastDate);
      }
      return schema;
    })
    .required(errors.required);
};

export const getStoreType = (store?: Store) => {
  if (store?.document.length === 14) {
    return StoreType.LEGAL;
  }
  return StoreType.PHYSICAL;
};
