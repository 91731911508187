import React from 'react';

import { useNavigate } from 'react-router-dom';

import PageHeader from '@components/PageHeader';
import Tabs from '@components/Tabs';

import RoutesPath from '@router/routes';
import { enums } from '@utils';

import Clients from './Clients';

const ListClients = () => {
  const navigate = useNavigate();

  return (
    <>
      <PageHeader
        title="Clientes"
        subtitle="Aqui estão todos os clientes cadastrados no sistema"
        primaryButton={{
          text: 'Cadastrar cliente',
          onClick: () => {
            navigate(RoutesPath.private.clients.create);
          },
        }}
      />
      <Tabs
        tabs={[
          {
            title: 'Cliente Pessoa Física',
            component: <Clients type={enums.StoreType.PHYSICAL} />,
            path: RoutesPath.private.clients.list.physical,
          },
          {
            title: 'Cliente Pessoa Jurídica',
            component: <Clients type={enums.StoreType.LEGAL} />,
            path: RoutesPath.private.clients.list.legal,
          },
        ]}
      />
    </>
  );
};

export default ListClients;
