import React, { useState } from 'react';

import moment from 'moment';
import { B2TableDataCell, B2TableRow } from 'react-b2components';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useToast } from '@contexts/Toast';
import { useStores } from '@hooks';

import { Content } from '@components/BaseForm/styles';
import DateRangeCalendarFilter from '@components/DateRangeCalendarFilter';
import FiltersContainer from '@components/FiltersContainer';
import PageHeader from '@components/PageHeader';
import SearchFilter from '@components/SearchFilter';
import SuccessModal from '@components/SuccessModal';
import Table from '@components/Table';

import RoutesPath from '@router/routes';

import StoreFilter from './StoreFilter';

const CustomerFields = ['id', 'full_name', 'phone_number', 'email'] as const;

type ListCustomerFields = (typeof CustomerFields)[number];

const ListLeads: React.FC = () => {
  const [queryParams, setQueryParams] = useState<CustomerQuery>({
    page: 1,
    fields: CustomerFields as unknown as Array<keyof Customer>,
  });
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  const { listCustomers, exportCustomers } = useStores();
  const { addToast } = useToast();

  const navigate = useNavigate();

  const fetchCustomers = useQuery(['customers', queryParams], () =>
    listCustomers<ListCustomerFields>(queryParams)
  );

  const requestExportCustomers = useMutation(exportCustomers, {
    onSuccess: () => setIsExportModalOpen(true),
    onError: () =>
      addToast('Falha ao solicitar a exportação dos dados', 'error'),
  });

  const handleDateRangeFilterSelected = (startDate?: Date, endDate?: Date) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : undefined,
      endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : undefined,
    }));
  };

  return (
    <>
      <PageHeader
        title="Leads"
        subtitle="Aqui estão todos os leads cadastrados no sistema"
        primaryButton={{
          text: 'Solicitar exportação de dados',
          onClick: () => requestExportCustomers.mutate(),
        }}
      />
      <Content>
        <FiltersContainer>
          <SearchFilter
            onSearch={(search) => {
              setQueryParams((prevState) => ({
                ...prevState,
                search,
              }));
            }}
          />
          <DateRangeCalendarFilter
            clearableDateRange
            onDateRangeFilterSelected={handleDateRangeFilterSelected}
          />
          <StoreFilter
            value={queryParams.store?.label ?? ''}
            onChange={(store) =>
              setQueryParams((prevState) => ({
                ...prevState,
                page: 1,
                store,
              }))
            }
            onClear={() =>
              setQueryParams((prevState) => ({
                ...prevState,
                page: 1,
                store: {
                  label: '',
                  value: '',
                },
              }))
            }
          />
        </FiltersContainer>
        <Table
          fetchData={fetchCustomers}
          headerData={['Nome', 'Telefone', 'E-mail']}
          renderRow={(customer) => (
            <B2TableRow
              key={customer.id}
              onClick={() =>
                navigate(
                  RoutesPath.private.leads.details.leadInfo.replace(
                    ':leadId',
                    customer.id.toString()
                  )
                )
              }
            >
              <B2TableDataCell>{customer.full_name}</B2TableDataCell>
              <B2TableDataCell>{customer.phone_number}</B2TableDataCell>
              <B2TableDataCell>{customer.email}</B2TableDataCell>
            </B2TableRow>
          )}
          paginator
          changePage={(newPage) => {
            setQueryParams((prevState) => ({
              ...prevState,
              page: newPage,
            }));
          }}
          currentPage={queryParams.page}
        />
      </Content>
      <SuccessModal
        isOpen={isExportModalOpen}
        onClose={() => setIsExportModalOpen(false)}
        title="Exportação solicitada com sucesso"
        message="A exportação dos leads foi solicitada, você receberá um email com o arquivo em breve."
      />
    </>
  );
};

export default ListLeads;
