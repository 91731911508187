import { color } from 'b2utils';
import styled from 'styled-components';

const BaseCarPhoto = styled.div`
  height: 244px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 2px solid ${({ theme }) => theme.colors.main};
  outline: 2px solid
    ${({ theme }) => color.addOpacityOnHexColor(theme.colors.main, 0.1)};
  padding: 16px;
  border-radius: 8px;
  cursor: pointer;
`;

export default BaseCarPhoto;
