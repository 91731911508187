import styled, { keyframes } from 'styled-components';

const rotating = keyframes`
  to {
    transform: rotate(1turn);
  }
`;

const CircleLoading = styled.span`
  animation: ${rotating} 1s infinite;
  border: 3px solid ${(props) => props.theme.colors.white.one};
  border-radius: 50%;
  border-top-color: ${(props) => props.theme.colors.main};
  height: 24px;
  width: 24px;
`;

export default CircleLoading;
