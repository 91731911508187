import { B2Card } from 'react-b2components';
import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const MainCard = styled(B2Card)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Answer = styled.pre`
  white-space: pre-wrap;
`;
