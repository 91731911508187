import React from 'react';

import { Link } from 'react-router-dom';

import RoutesPath from '@router/routes';

import HeaderMenu from './HeaderMenu';
import MobileMenu from './MobileMenu';
import { Container, Logo } from './styles';

const Header: React.FC = () => {
  return (
    <Container>
      <MobileMenu />
      <Link to={RoutesPath.private.home}>
        <Logo src="/images/logo.svg" alt="Logo" />
      </Link>
      <HeaderMenu />
    </Container>
  );
};

export default Header;
