import React from 'react';

import { useDebounce } from 'hooks';

import { Container, CustomInput, Loading } from './styles';

interface SearchFilterProps {
  onSearch: (value: string) => void;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
}

const SearchFilter: React.FC<SearchFilterProps> = ({
  onSearch,
  placeholder = 'Pesquise...',
  className,
  disabled,
  isLoading,
}) => {
  const debouncedSearch = useDebounce((search: string) => {
    onSearch(search);
  }, 1000);

  return (
    <Container>
      <CustomInput
        data-cy="search"
        className={className}
        type="text"
        placeholder={placeholder}
        onChange={(event) => debouncedSearch(event.target.value)}
        disabled={disabled}
      />
      {isLoading && <Loading />}
    </Container>
  );
};

export default SearchFilter;
