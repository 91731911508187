import React, { useMemo, useState } from 'react';

import { currency } from 'b2utils';
import moment from 'moment';
import { B2TableDataCell, B2TableRow } from 'react-b2components';
import { useQuery } from 'react-query';

import { usePayments } from '@hooks';

import Table from '@components/Table';

import { enums } from '@utils';

const fields = [
  'id',
  'created',
  'subscription_info',
  'amount',
  'status',
] as const;

type ChargesFields = (typeof fields)[number];

interface ChargesProps {
  store: number;
  storeType: enums.StoreType;
}

const Charges: React.FC<ChargesProps> = ({ store, storeType }) => {
  const [queryParams, setQueryParams] = useState<ChargesQuery>({
    fields: fields as unknown as Array<keyof Charge>,
    store,
    page: 1,
  });

  const { listCharges } = usePayments();

  const fetchCharges = useQuery(['charges', queryParams], () =>
    listCharges<ChargesFields>(queryParams)
  );

  const headerData = useMemo(() => {
    if (storeType === enums.StoreType.LEGAL) {
      return ['Data', 'Plano', 'Valor', 'Status'];
    }
    return ['Data', 'Valor', 'Status'];
  }, [storeType]);

  return (
    <Table
      disableHover
      fetchData={fetchCharges}
      headerData={headerData}
      renderRow={(charge) => (
        <B2TableRow key={charge.id}>
          <B2TableDataCell>
            {moment(charge.created).format('DD/MM/YYYY')}
          </B2TableDataCell>
          {storeType === enums.StoreType.LEGAL && (
            <B2TableDataCell>
              {charge.subscription_info?.plan.name}
            </B2TableDataCell>
          )}
          <B2TableDataCell>
            {currency.centsToBrl(charge.amount)}
          </B2TableDataCell>
          <B2TableDataCell>
            {enums.ChargeStatusMap[charge.status]}
          </B2TableDataCell>
        </B2TableRow>
      )}
      paginator
      changePage={(newPage) =>
        setQueryParams((prevState) => ({
          ...prevState,
          page: newPage,
        }))
      }
      currentPage={queryParams.page}
    />
  );
};

export default Charges;
