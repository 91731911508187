import React, { forwardRef, useState } from 'react';

import { CustomFormGroup, CustomInput, CustomLabel } from './styles';

export interface AuthInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  invalidValue?: boolean;
  className?: string;
}

const AuthInput: React.ForwardRefRenderFunction<
  HTMLInputElement,
  AuthInputProps
> = (
  { label, invalidValue, className, onFocus, onBlur, placeholder, ...props },
  ref
) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <CustomFormGroup>
      <CustomLabel isShowing={isFocused || !!props.value}>{label}</CustomLabel>
      <CustomInput
        className={className}
        invalidValue={invalidValue}
        ref={ref}
        onFocus={(event) => {
          setIsFocused(true);
          onFocus?.(event);
        }}
        onBlur={(event) => {
          setIsFocused(false);
          onBlur?.(event);
        }}
        placeholder={isFocused ? placeholder : label ?? placeholder}
        {...props}
      />
    </CustomFormGroup>
  );
};
export default forwardRef(AuthInput);
