import React from 'react';

import * as Sentry from '@sentry/react';
import { Formik } from 'formik';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';
import * as yup from 'yup';

import { useApi } from '@contexts/Api';
import { useAuth } from '@contexts/Auth';
import { useToast } from '@contexts/Toast';
import { useAuthentication } from '@hooks';

import AuthInput from '@components/AuthInput';
import AuthPasswordInput from '@components/AuthPasswordInput';
import { AuthButton, CardTitle, Form } from '@components/Base/AuthBase/styles';
import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';

import RoutesPath from '@router/routes';
import { enums, errors } from '@utils';

import { ForgotPasswordButton, ForgotPasswordButtonHighlight } from './styles';

const Login: React.FC = () => {
  const navigate = useNavigate();

  const { saveTokens, saveUserInfo } = useAuth();
  const { performLogin } = useAuthentication();
  const { addToast } = useToast();
  const { cleanInterceptors } = useApi();

  const onLoginFormSubmit = useMutation(
    async ({ email, password }: Login) => performLogin(email, password),
    {
      onSuccess: (data) => {
        if (data.user.type === enums.UserType.STORE) {
          addToast('Você não tem permissão para acessar essa página', 'error');
          return;
        }

        saveTokens(data.tokens);
        saveUserInfo(data.user);
        Sentry.setUser({
          id: data.user.id.toString(),
          email: data.user.email,
        });
      },
      onError: (error: any) => {
        addToast(error.response.data.detail, 'error');
      },
    }
  );

  return (
    <>
      <CardTitle>Entrar</CardTitle>
      <Formik
        initialValues={{ email: '', password: '' }}
        onSubmit={(values) => {
          cleanInterceptors();
          onLoginFormSubmit.mutate(values);
        }}
        validationSchema={yup.object({
          email: yup.string().email(errors.email).required(errors.required),
          password: yup.string().required(errors.required),
        })}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <FormGroup>
              <AuthInput
                label="E-mail"
                placeholder="Digite aqui"
                type="email"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              <FormError name="email" />
            </FormGroup>
            <FormGroup>
              <AuthPasswordInput
                label="Senha"
                placeholder="Digite aqui"
                type="password"
                name="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
              <FormError name="password" />
            </FormGroup>
            <AuthButton
              variant="primary"
              type="submit"
              isLoading={onLoginFormSubmit.isLoading}
            >
              Entrar
            </AuthButton>
          </Form>
        )}
      </Formik>
      <ForgotPasswordButton
        onClick={() => navigate(RoutesPath.auth.forgotPassword)}
      >
        Esqueceu sua senha?{' '}
        <ForgotPasswordButtonHighlight>
          Acesse aqui
        </ForgotPasswordButtonHighlight>
      </ForgotPasswordButton>
    </>
  );
};

export default Login;
