import styled from 'styled-components';

import Input from '@components/Input';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
  }
`;

export const DateInput = styled(Input)`
  background-color: ${({ theme }) => theme.colors.white.main};
`;
