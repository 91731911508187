const RoutesPath = {
  auth: {
    root: '/login/',
    login: '/login/',
    forgotPassword: '/esqueci-a-senha/',
    changePassword: '/trocar-senha/',
  },
  private: {
    home: '/',
    profile: '/perfil/',
    clients: {
      list: {
        physical: '/clientes/pessoa-fisica/',
        legal: '/clientes/pessoa-juridica/',
      },
      detail: {
        physical: {
          detail: '/clientes/pessoa-fisica/:clientId/',
          posts: {
            list: '/clientes/pessoa-fisica/:clientId/anuncios/',
            detail: '/clientes/pessoa-fisica/:clientId/anuncios/:postId/',
          },
          charges: '/clientes/pessoa-fisica/:clientId/cobrancas/',
          addCredits: '/clientes/pessoa-fisica/:clientId/adicionar-creditos/',
        },
        legal: {
          detail: '/clientes/pessoa-juridica/:clientId/',
          posts: {
            list: '/clientes/pessoa-juridica/:clientId/anuncios/',
            detail: '/clientes/pessoa-juridica/:clientId/anuncios/:postId/',
          },
          users: {
            list: '/clientes/pessoa-juridica/:clientId/usuarios/',
            detail:
              '/clientes/pessoa-juridica/:clientId/usuarios/:storeUserId/',
          },
          charges: '/clientes/pessoa-juridica/:clientId/cobrancas/',
          addPrivilege: '/clientes/pessoa-juridica/:clientId/assinar-plano/',
        },
      },
      create: '/clientes/criar/',
      edit: '/clientes/:clientId/editar/',
    },
    posts: {
      list: {
        complete: '/anuncios/completos',
        draft: '/anuncios/rascunhos',
      },
      detail: '/anuncios/:postId/',
      create: '/anuncios/criar/',
      edit: '/anuncios/:postId/editar/',
    },
    advertisements: {
      list: '/publicidades/',
      detail: '/publicidades/:advertisementId/',
      create: '/publicidades/criar/',
      edit: '/publicidades/:advertisementId/editar/',
    },
    plans: {
      list: '/planos/',
      detail: '/planos/:planId/',
      create: '/planos/criar/',
      edit: '/planos/:planId/editar/',
    },
    voucher: '/plano-pre-pago/',
    financial: '/financeiro/',
    users: {
      list: '/usuarios/',
      detail: '/usuarios/:userId/',
      create: '/usuarios/criar/',
      edit: '/usuarios/:userId/editar/',
    },
    faq: {
      list: '/faq/',
      detail: '/faq/:faqId/',
      create: '/faq/criar/',
      edit: '/faq/:faqId/editar/',
    },
    leads: {
      list: '/leads/',
      details: {
        leadInfo: '/leads/:leadId/',
        posts: '/leads/:leadId/anuncios',
      },
    },
  },
  public: {
    confirmEmail: '/confirmar-email/',
  },
};

export default RoutesPath;
