import RoutesPath from '@router/routes';

const getRoutes = (postIsComplete: boolean) => {
  if (postIsComplete) {
    return [
      {
        path: RoutesPath.private.posts.list.complete,
        label: 'Lista de anúncios',
      },
      {
        path: RoutesPath.private.posts.detail,
        label: 'Detalhes do anúncio',
      },
    ];
  }

  return [
    {
      path: RoutesPath.private.posts.list.draft,
      label: 'Lista de anúncios',
    },
    {
      path: RoutesPath.private.posts.detail,
      label: 'Detalhes do anúncio',
    },
  ];
};

export default getRoutes;
