import React, { forwardRef, useImperativeHandle } from 'react';

import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';
import TextArea from '@components/TextArea';

import { errors } from '@utils';

interface FaqInfoProps {
  initialValues?: FormValuesFaqInfo;
  onFinish: (data: FormValuesFaqInfo) => void;
}

const FaqInfo: React.ForwardRefRenderFunction<FormStepRef, FaqInfoProps> = (
  { initialValues, onFinish },
  ref
) => {
  const formikInitialValues: FormValuesFaqInfo = {
    question: '',
    answer: '',
    ...initialValues,
  };

  const formikValidationSchema = yup.object().shape({
    question: yup.string().trim().required(errors.required),
    answer: yup.string().trim().required(errors.required),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  return (
    <FormikProvider value={formik}>
      <FormRow>
        <FormGroup>
          <Label htmlFor="question">Pergunta</Label>
          <Input
            type="text"
            name="question"
            placeholder="Digite aqui"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.question}
            invalidValue={!!formik.touched.question && !!formik.errors.question}
          />
          <FormError name="question" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="answer">Resposta</Label>
          <TextArea
            name="answer"
            placeholder="Digite aqui"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.answer}
            invalidValue={!!formik.touched.answer && !!formik.errors.answer}
          />
          <FormError name="answer" />
        </FormGroup>
      </FormRow>
    </FormikProvider>
  );
};

export default forwardRef(FaqInfo);
