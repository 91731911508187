import React from 'react';

import moment from 'moment';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { useToast } from '@contexts/Toast';
import { useAdvertisements } from '@hooks';

import PageHeader from '@components/PageHeader';
import RequestContainer from '@components/RequestContainer';

import RoutesPath from '@router/routes';
import { enums } from '@utils';

import routes from './routes';
import {
  Content,
  Description,
  Divider,
  ExtraInfo,
  ExtraInfoContainer,
  MainCard,
  MainCardContent,
  MainImage,
  MetricCard,
  MetricIcon,
  MetricTitle,
  MetricValue,
  PostTitle,
  Subtitle,
} from './styles';

const DetailAdvertisement: React.FC = () => {
  const { advertisementId } = useParams<{ advertisementId: string }>();
  const { getAdvertisement, deleteAdvertisement } = useAdvertisements();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const fetchAdvertisement = useQuery(
    ['advertisement', advertisementId],
    () => getAdvertisement(Number(advertisementId)),
    {
      enabled: !!advertisementId,
    }
  );

  const requestDeleteAdvertisement = useMutation(
    () => deleteAdvertisement(Number(advertisementId!)),
    {
      onSuccess: () => {
        addToast('Publicidade apagada com sucesso', 'success');
        navigate(RoutesPath.private.advertisements.list, {
          replace: true,
        });
        queryClient.invalidateQueries('advertisements');
      },
    }
  );

  return (
    <RequestContainer requests={[fetchAdvertisement]}>
      <>
        <PageHeader
          title="Detalhes da publicidade"
          routes={routes}
          primaryButton={{
            text: 'Editar publicidade',
            onClick: () =>
              navigate(
                RoutesPath.private.advertisements.edit.replace(
                  ':advertisementId',
                  advertisementId!.toString()
                )
              ),
          }}
          deleteProps={{
            button: {
              text: 'Apagar publicidade',
            },
            modal: {
              title: 'Apagar publicidade',
              message: 'Tem certeza que deseja apagar essa publicidade?',
              isLoading: requestDeleteAdvertisement.isLoading,
              onConfirm: requestDeleteAdvertisement.mutate,
            },
          }}
        />
        {fetchAdvertisement.data && (
          <Content>
            <MainCard>
              <MainImage
                data-cy="advertisement-big-image"
                src={fetchAdvertisement.data.image.high ?? '/images/logo.svg'}
                alt="Imagem grande da publicidade"
              />
              <MainImage
                data-cy="advertisement-small-image"
                src={
                  fetchAdvertisement.data.mobile_image.high ??
                  '/images/logo.svg'
                }
                alt="Imagem pequena da publicidade"
              />
              <div>
                <MainCardContent>
                  <PostTitle>{fetchAdvertisement.data?.title}</PostTitle>
                  <Subtitle>
                    Criação:{' '}
                    {moment(fetchAdvertisement.data?.created).format(
                      'DD/MM/YYYY'
                    )}
                  </Subtitle>
                  <Description>
                    Link:{' '}
                    <a
                      href={fetchAdvertisement.data.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {fetchAdvertisement.data.link}
                    </a>
                  </Description>
                  <Divider />
                  <ExtraInfoContainer>
                    <ExtraInfo>
                      Categoria:{' '}
                      <b>
                        {
                          enums.AdvertisementCategoryMap[
                            fetchAdvertisement.data.category
                          ]
                        }
                      </b>
                    </ExtraInfo>
                    <ExtraInfo>
                      Posição: <b>{fetchAdvertisement.data.index}</b>
                    </ExtraInfo>
                    <ExtraInfo>
                      Lançamento:{' '}
                      <b>
                        {moment(fetchAdvertisement.data.starts_at).format(
                          'DD/MM/YYYY'
                        )}
                      </b>
                    </ExtraInfo>
                    <ExtraInfo>
                      Data de fim:{' '}
                      <b>
                        {moment(fetchAdvertisement.data.ends_at).format(
                          'DD/MM/YYYY'
                        )}
                      </b>
                    </ExtraInfo>
                  </ExtraInfoContainer>
                </MainCardContent>
              </div>
            </MainCard>
            <MetricCard data-cy="advertisement-metric">
              <MetricTitle>Nº de cliques</MetricTitle>
              <MetricValue>{fetchAdvertisement.data.clicks}</MetricValue>
              <MetricIcon size={32} />
            </MetricCard>
          </Content>
        )}
      </>
    </RequestContainer>
  );
};

export default DetailAdvertisement;
