import React, { useState } from 'react';

import { Title } from '@components/Base/PrivateBase/styles';

import DeleteAccount from './DeleteAccount';
import { ProfileInfo } from './ProfileInfo';
import { UpdateEmail } from './UpdateEmail';
import { UpdatePassword } from './UpdatePassword';
import { TabName, TabNames } from './VerticalTabs';
import { Container, Content } from './styles';

const tabs = [
  {
    name: 'Perfil',
    component: <ProfileInfo />,
  },
  {
    name: 'Atualizar e-mail',
    component: <UpdateEmail />,
  },
  {
    name: 'Atualizar senha',
    component: <UpdatePassword />,
  },
  {
    name: 'Apagar conta',
    component: <DeleteAccount />,
  },
];

const Profile: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <Container>
      <Title>Perfil</Title>
      <Content>
        <TabNames>
          {tabs.map((tab, index) => (
            <TabName
              key={tab.name}
              onClick={() => setSelectedTab(index)}
              selected={selectedTab === index}
            >
              {tab.name}
            </TabName>
          ))}
        </TabNames>
        {tabs[selectedTab].component}
      </Content>
    </Container>
  );
};

export default Profile;
