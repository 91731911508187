import React, { useMemo, useState } from 'react';

import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { usePosts } from '@hooks';

import PostsList, {
  ListPostFields,
  listPostsFields,
} from '@components/PostsList';
import SelectFilter from '@components/SelectFilter';

import RoutesPath from '@router/routes';
import { enums } from '@utils';

const PostsOfStore = () => {
  const { clientId } = useParams<{ clientId: string }>();

  const [queryParams, setQueryParams] = useState<PostsQuery>({
    fields: listPostsFields as unknown as Array<keyof Post>,
    page: 1,
    pageSize: 6,
    store: Number(clientId),
  });

  const { listPosts } = usePosts();
  const navigate = useNavigate();

  const fetchPosts = useQuery(['posts', queryParams], () =>
    listPosts<ListPostFields>(queryParams)
  );

  const onChangeStatus = (event: React.ChangeEvent<HTMLSelectElement>) => {
    let isComplete: boolean | undefined;
    let status: enums.PostStatus | undefined;

    if (event.target.value === '') {
      isComplete = undefined;
      status = undefined;
    } else if (event.target.value === 'draft') {
      isComplete = false;
      status = undefined;
    } else {
      isComplete = true;
      status = event.target.value as enums.PostStatus;
    }

    setQueryParams((prevState) => ({
      ...prevState,
      isComplete,
      status,
    }));
  };

  const status = useMemo(() => {
    if (
      queryParams.isComplete === undefined &&
      queryParams.status === undefined
    ) {
      return '';
    }

    if (queryParams.isComplete === false) {
      return 'draft';
    }

    return queryParams.status;
  }, [queryParams.isComplete, queryParams.status]);

  return (
    <>
      <SelectFilter
        data-cy="status-filter"
        onChange={onChangeStatus}
        value={status}
      >
        <option value="">Status: Todos</option>
        {Object.values(enums.PostStatus).map((status) => (
          <option key={status} value={status}>
            {enums.PostStatusMap[status]}
          </option>
        ))}
        <option value="draft">Rascunho</option>
      </SelectFilter>
      <PostsList
        fetchPosts={fetchPosts}
        page={queryParams.page}
        onChangePage={(page) => setQueryParams({ ...queryParams, page })}
        showStatusIndicator={true}
        onClick={(post) => {
          navigate(
            RoutesPath.private.clients.detail.legal.posts.detail
              .replace(':clientId', clientId!)
              .replace(':postId', post.toString())
          );
        }}
      />
    </>
  );
};

export default PostsOfStore;
