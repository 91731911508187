import React, { useRef, useState, useEffect, useMemo } from 'react';

import { useTheme } from 'styled-components';

import { Circle, Container } from './styles';

interface CircularProgress {
  percentage: number;
  text: string;
  className?: string;
}

const CircularProgress: React.FC<CircularProgress> = ({
  percentage,
  text,
  className,
}) => {
  const circleRef = useRef<SVGCircleElement>(null);

  const radius = 31.5;
  const circumference = useMemo(() => radius * 2 * Math.PI, []);
  const circleProps = useMemo(
    () => ({
      r: radius.toString(),
      cx: '35',
      cy: '35',
    }),
    []
  );

  const [offset, setOffset] = useState(circumference);

  const theme = useTheme();

  useEffect(() => {
    setOffset(circumference - (percentage / 100) * circumference);
  }, [circumference, percentage]);

  return (
    <Container width="70" height="70" className={className}>
      <circle
        stroke={theme.colors.gray.one}
        strokeWidth="2"
        fill="transparent"
        {...circleProps}
      />
      <Circle
        style={{
          strokeDasharray: `${circumference} ${circumference}`,
          strokeDashoffset: offset,
        }}
        ref={circleRef}
        stroke={theme.colors.main}
        strokeWidth="8"
        strokeLinecap="round"
        fill="transparent"
        {...circleProps}
      />
      <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle">
        {text}
      </text>
    </Container>
  );
};

export default CircularProgress;
