import styled from 'styled-components';

import BaseCard from '@components/BaseCard';

export const Card = styled(BaseCard)`
  grid-column: 1 / -1;
`;

export const CardTitle = styled.p`
  color: ${(props) => props.theme.colors.black.two};
  font-size: ${(props) => props.theme.fontSize.large};
  font-weight: ${(props) => props.theme.fontWeight.semiBold};
`;
