import { color } from 'b2utils';
import styled from 'styled-components';

export const Grid = styled.div`
  width: 100%;
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(auto-fit, 350px);
  gap: 16px 8px;
`;

export const ApproveCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) =>
    color.addOpacityOnHexColor(theme.colors.main, 0.3)};
  padding: ${({ theme }) => `${theme.spacing.four} ${theme.spacing.six}`};
  margin-bottom: ${({ theme }) => theme.spacing.four};
  animation: fade-in 0.5s ease-out;
`;

export const ApproveText = styled.span`
  color: ${({ theme }) => theme.colors.black.two};
  font-size: ${({ theme }) => theme.fontSize.small};
`;
