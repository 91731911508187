import {
  MdList,
  MdDashboard,
  MdFactCheck,
  MdGroups,
  MdManageAccounts,
  MdPaid,
  MdOutlineContactSupport,
  MdSell,
  MdCreditCard,
  MdPeople,
} from 'react-icons/md';
import RoutesPath from 'router/routes';

export const SidebarItems: Array<SidebarItem> = [
  {
    name: 'Dashboard',
    icon: MdDashboard,
    route: RoutesPath.private.home,
  },
  {
    name: 'Clientes',
    icon: MdGroups,
    route: RoutesPath.private.clients.list.physical,
  },
  {
    name: 'Anúncios',
    icon: MdList,
    route: RoutesPath.private.posts.list.complete,
  },
  {
    name: 'Publicidades',
    icon: MdFactCheck,
    route: RoutesPath.private.advertisements.list,
  },
  {
    name: 'Planos',
    icon: MdSell,
    route: RoutesPath.private.plans.list,
  },
  {
    name: 'Plano pré-pago',
    icon: MdCreditCard,
    route: RoutesPath.private.voucher,
  },
  {
    name: 'Financeiro',
    icon: MdPaid,
    route: RoutesPath.private.financial,
  },
  {
    name: 'Usuários',
    icon: MdManageAccounts,
    route: RoutesPath.private.users.list,
  },
  {
    name: 'FAQ',
    icon: MdOutlineContactSupport,
    route: RoutesPath.private.faq.list,
  },
  {
    name: 'Leads',
    icon: MdPeople,
    route: RoutesPath.private.leads.list,
  },
];
