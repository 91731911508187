import { B2Button, B2Card } from 'react-b2components';
import { MdCancel, MdCheckCircle } from 'react-icons/md';
import styled from 'styled-components';

export const AproveCardContainer = styled(B2Card)`
  background-color: ${({ theme }) => theme.colors.white.main};
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1000px) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const AproveCardTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  color: ${(props) => props.theme.colors.black.two};
`;

export const AproveButtonsContainer = styled.div`
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.regular} !important;
`;

export const BaseButton = styled(B2Button)`
  height: fit-content;
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.regular};
`;

export const AcceptButton = styled(BaseButton)`
  background-color: ${(props) => props.theme.colors.success};
  margin-right: 16px;
`;

export const RejectButton = styled(BaseButton)`
  background-color: ${(props) => props.theme.colors.error};
`;

export const CheckCircleIcon = styled(MdCheckCircle)`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

export const CancelIcon = styled(MdCancel)`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;
