import React, { forwardRef, useImperativeHandle } from 'react';

import { FormikProvider, useFormik } from 'formik';
import moment from 'moment';
import * as yup from 'yup';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import ImageSinglePicker from '@components/ImageSinglePicker';
import Input from '@components/Input';
import Label from '@components/Label';
import Select from '@components/Select';

import { enums, errors, helpers } from '@utils';

interface AdvertisementInfoProps {
  initialValues?: FormValuesAdvertisementInfo;
  onFinish: (data: FormValuesAdvertisementInfo) => void;
}

const AdvertisementInfo: React.ForwardRefRenderFunction<
  FormStepRef,
  AdvertisementInfoProps
> = ({ initialValues, onFinish }, ref) => {
  const formikInitialValues: FormValuesAdvertisementInfo = {
    bigImage: undefined,
    smallImage: undefined,
    title: '',
    index: '',
    startDate: '',
    endDate: '',
    link: '',
    category: '',
    ...initialValues,
  };

  const formikValidationSchema = yup.object().shape({
    bigImage: yup.object().required(errors.required),
    smallImage: yup.object().required(errors.required),
    title: yup.string().trim().required(errors.required),
    index: yup.number().min(1, errors.minValue(1)),
    startDate: helpers.dateValidation(initialValues?.startDate ?? ''),
    endDate: helpers
      .dateValidation(initialValues?.endDate ?? '')
      .test('is-after-start', errors.endDateBefore, (value, { parent }) => {
        if (!value) {
          return true;
        }

        return moment(parent.startDate).isBefore(value);
      }),
    link: yup.string().trim().url(errors.urlError).required(errors.required),
    category: yup
      .string()
      .trim()
      .oneOf(Object.values(enums.AdvertisementCategory))
      .required(errors.required),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  return (
    <FormikProvider value={formik}>
      <FormRow>
        <FormGroup>
          <Label htmlFor="bigImage">Imagem grande</Label>
          <ImageSinglePicker
            dataCy="advertisement-big-image"
            onAddImage={(image) => formik.setFieldValue('bigImage', image)}
            image={formik.values.bigImage}
            suggestedResolution="1440x344"
          />
          <FormError name="bigImage" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="smallImage">Imagem pequena</Label>
          <ImageSinglePicker
            dataCy="advertisement-small-image"
            onAddImage={(image) => formik.setFieldValue('smallImage', image)}
            image={formik.values.smallImage}
            suggestedResolution="560x280"
          />
          <FormError name="smallImage" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="title">Nome da publicidade</Label>
          <Input
            type="text"
            name="title"
            placeholder="Digite aqui"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.title}
            invalidValue={!!formik.touched.title && !!formik.errors.title}
          />
          <FormError name="title" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="index">Posição</Label>
          <Input
            type="number"
            name="index"
            placeholder="Digite aqui"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.index}
            invalidValue={!!formik.touched.index && !!formik.errors.index}
          />
          <FormError name="index" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="startDate">Data de lançamento</Label>
          <Input
            type="date"
            name="startDate"
            placeholder="Digite aqui"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.startDate}
            invalidValue={
              !!formik.touched.startDate && !!formik.errors.startDate
            }
            min={moment().format('YYYY-MM-DD')}
          />
          <FormError name="startDate" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="endDate">Data de expiração</Label>
          <Input
            type="date"
            name="endDate"
            placeholder="Digite aqui"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.endDate}
            invalidValue={!!formik.touched.endDate && !!formik.errors.endDate}
            min={moment().format('YYYY-MM-DD')}
          />
          <FormError name="endDate" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="link">Link</Label>
          <Input
            type="text"
            name="link"
            placeholder="Digite aqui"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.link}
            invalidValue={!!formik.touched.link && !!formik.errors.link}
          />
          <FormError name="link" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="category">Categoria</Label>
          <Select
            name="category"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.category}
            invalidValue={!!formik.touched.category && !!formik.errors.category}
          >
            <option value="">Selecione</option>
            {Object.values(enums.AdvertisementCategory).map((category) => (
              <option key={category} value={category}>
                {enums.AdvertisementCategoryMap[category]}
              </option>
            ))}
          </Select>
          <FormError name="category" />
        </FormGroup>
      </FormRow>
    </FormikProvider>
  );
};

export default forwardRef(AdvertisementInfo);
