import styled, { css } from 'styled-components';

interface SwitchButtonProps {
  isActive: boolean;
}

const borderRadius = '16px';

export const SwitchContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white.three};
  width: fit-content;
  height: 36px;
  display: flex;
  position: relative;
  border-radius: ${borderRadius};
  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: ${(props) => props.theme.fontWeight.medium};
`;

export const SwitchBackground = styled.div<SwitchButtonProps>`
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.success : theme.colors.error};
  width: 50%;
  height: calc(100% - 4px);
  position: absolute;
  top: 2px;
  left: ${({ isActive }) => (isActive ? '2px' : 'calc(50% - 2px)')};
  transition: left 0.7s;
  border-radius: ${borderRadius};
`;

export const SwitchButton = styled.div<SwitchButtonProps>`
  width: 70px;
  border-radius: ${borderRadius};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  z-index: 1;
  transition: color 0.7s;
  ${({ isActive, theme }) =>
    isActive
      ? css`
          color: ${theme.colors.white.one};
          cursor: default;
        `
      : css`
          color: ${theme.colors.gray.five};
          cursor: pointer;
        `}

  @media (max-width: 768px) {
    width: 50px;
  }
`;
