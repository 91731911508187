import styled, { css } from 'styled-components';

interface RouteProps {
  isLast: boolean;
}

export const Container = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

export const RouteLabel = styled.span<RouteProps>`
  ${(props) =>
    props.isLast
      ? css`
          color: ${props.theme.colors.main};
          font-weight: ${props.theme.fontWeight.semiBold};
          cursor: default;
        `
      : css`
          color: ${props.theme.colors.gray.eight};
          font-weight: ${props.theme.fontWeight.regular};
          cursor: pointer;
        `}
`;

export const Separator = styled.span`
  margin: 0px 12px;
`;
