import React, { forwardRef, useImperativeHandle, useState } from 'react';

import { currency } from 'b2utils';
import { FormikProvider, useFormik } from 'formik';
import { useQuery } from 'react-query';
import * as yup from 'yup';

import { usePosts } from '@hooks';

import ConfirmationModal from '@components/ConfirmationModal';
import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';
import RequestContainer from '@components/RequestContainer';
import TextArea from '@components/TextArea';

import { errors } from '@utils';

import SuggestedPricesTable from './SuggestedPricesTable';

interface VehicleValuesProps {
  initialValues?: FormValuesVehicleValues;
  onFinish: (data: FormValuesVehicleValues) => void;
  post: number;
  category: VehicleCategory;
}

const MAX_PRICE = 9223372036854776000;

const VehicleValues: React.ForwardRefRenderFunction<
  FormStepRef,
  VehicleValuesProps
> = ({ initialValues, onFinish, post, category }, ref) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const { getSuggestedPrices, getFipeTable } = usePosts();

  const fetchSuggestedPrices = useQuery(
    ['suggestedPrices', post],
    () => getSuggestedPrices(post),
    {
      enabled: category.has_suggested_prices,
    }
  );

  const fetchFipeTable = useQuery(
    ['fipeTable', post],
    () => getFipeTable(post),
    {
      enabled: category.has_suggested_prices,
    }
  );

  const formikInitialValues: FormValuesVehicleValues = {
    description: '',
    price: '0',
    isComplete: false,
    ...initialValues,
  };

  const formikValidationSchema = yup.object().shape({
    description: yup.string().trim(),
    price: yup
      .string()
      .test(
        'max',
        errors.maxPrice(MAX_PRICE),
        (value) => !!value && Number(value.replace(/\D/g, '')) <= MAX_PRICE
      )
      .required(errors.required),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: () => {
      setIsConfirmationModalOpen(true);
    },
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  return (
    <RequestContainer requests={[fetchSuggestedPrices, fetchFipeTable]}>
      <>
        <FormikProvider value={formik}>
          <FormRow>
            <FormGroup>
              <Label>Descrição</Label>
              <TextArea
                name="description"
                placeholder="Digite aqui"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
                invalidValue={
                  !!formik.touched.description && !!formik.errors.description
                }
              />
            </FormGroup>
          </FormRow>
          <FormRow>
            {fetchSuggestedPrices.data && fetchFipeTable?.data && (
              <SuggestedPricesTable
                systemPrices={fetchSuggestedPrices.data}
                fipePrice={fetchFipeTable?.data}
              />
            )}
          </FormRow>
          <FormRow>
            <FormGroup>
              <Label>Preço</Label>
              <Input
                type="text"
                name="price"
                placeholder="Digite aqui"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={
                  formik.values.price
                    ? currency.brlMask(formik.values.price)
                    : ''
                }
                invalidValue={!!formik.touched.price && !!formik.errors.price}
              />
              <FormError name="price" />
            </FormGroup>
          </FormRow>
        </FormikProvider>
        <ConfirmationModal
          title="Deseja publicar o anúncio?"
          isOpen={isConfirmationModalOpen}
          isLoading={false}
          confirmButtonText="Publicar"
          onConfirm={() => onFinish({ ...formik.values, isComplete: true })}
          cancelButtonText="Não"
          onCancel={() => onFinish({ ...formik.values, isComplete: false })}
          onClose={() => setIsConfirmationModalOpen(false)}
        />
      </>
    </RequestContainer>
  );
};

export default forwardRef(VehicleValues);
