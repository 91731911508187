import React from 'react';

import BaseModal from '@components/BaseModal';
import ModalFooterButtons from '@components/ModalFooterButtons';

import { CancelImage, BodyMessage, Body } from './styles';

interface DeleteModalProps {
  title: string;
  message?: string;
  isOpen: boolean;
  isLoading: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  title,
  message = 'Esta ação é irreversível',
  isOpen,
  isLoading,
  onConfirm,
  onClose,
}) => {
  return (
    <BaseModal isOpen={isOpen} title={title} onClose={onClose}>
      <Body>
        <CancelImage src="/images/cancel.svg" alt="Cancel image" />
        <BodyMessage>{message}</BodyMessage>
      </Body>
      <ModalFooterButtons
        isLoading={isLoading}
        confirmText="Sim, desejo deletar"
        onConfirm={onConfirm}
        cancelText="Não, cancelar"
        onCancel={onClose}
      />
    </BaseModal>
  );
};

export default DeleteModal;
