import React from 'react';

import CollapsibleCard from '../CollapsibleCard';
import Carousel from './Carousel';

interface PhotosProps {
  post: Post;
}

const Photos: React.FC<PhotosProps> = ({ post }) => {
  return (
    <CollapsibleCard dataCy="Photos" title="Fotos detalhadas">
      {post.images.length || post.main_image ? (
        <Carousel
          images={
            post.main_image
              ? [
                  post.main_image.image,
                  ...post.images.map((postsImage) => postsImage.image),
                ]
              : post.images.map((postsImage) => postsImage.image)
          }
        />
      ) : (
        <span>Este anúncio não possui fotos</span>
      )}
    </CollapsibleCard>
  );
};

export default Photos;
