import { color } from 'b2utils';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StatusActive = styled.div`
  background-color: ${(props) =>
    color.addOpacityOnHexColor(props.theme.colors.success, 0.2)};
  border-radius: 6px;
  padding: 8px 10px;
  color: ${(props) => props.theme.colors.success};
  font-size: 12px;
  width: 75%;
  min-width: 60px;
  max-width: 200px;
`;

export const StatusInactive = styled(StatusActive)`
  color: ${(props) => props.theme.colors.error};
  background-color: ${(props) =>
    color.addOpacityOnHexColor(props.theme.colors.error, 0.2)};
`;
