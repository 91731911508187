import RoutesPath from 'router/routes';

import { enums } from '@utils';

const getRoutes = (type: enums.StoreType) => {
  if (type === enums.StoreType.PHYSICAL) {
    return [
      {
        path: RoutesPath.private.clients.list.physical,
        label: 'Lista de clientes',
      },
      {
        path: RoutesPath.private.clients.detail.physical.detail,
        label: 'Detalhes do cliente',
      },
    ];
  }

  return [
    {
      path: RoutesPath.private.clients.list.legal,
      label: 'Lista de clientes',
    },
    {
      path: RoutesPath.private.clients.detail.legal.detail,
      label: 'Detalhes do cliente',
    },
  ];
};

export default getRoutes;
