import React, { forwardRef, useImperativeHandle } from 'react';

import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import FormGroup from '@components/FormGroup';
import Label from '@components/Label';

import { errors } from '@utils';

import PlansSelect from './PlansSelect';

interface ChoosePlanProps {
  initialValues?: FormValuesChoosePlan;
  onFinish: (data: FormValuesChoosePlan) => void;
  store: Store;
}

const ChoosePlan: React.ForwardRefRenderFunction<
  FormStepRef,
  ChoosePlanProps
> = ({ initialValues, onFinish, store }, ref) => {
  const formikInitialValues: FormValuesChoosePlan = {
    plan: {
      label: '',
      value: '',
    },
    ...initialValues,
  };

  const formikValidationSchema = yup.object().shape({
    plan: yup.object().shape({
      value: yup.string().required(errors.required),
    }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  return (
    <FormikProvider value={formik}>
      <FormGroup>
        <Label>Loja</Label>
        <p>{store.name}</p>
      </FormGroup>
      <PlansSelect />
    </FormikProvider>
  );
};

export default forwardRef(ChoosePlan);
