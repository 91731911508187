import { B2Modal } from 'react-b2components';
import { MdClose } from 'react-icons/md';
import styled from 'styled-components';

export const Modal = styled(B2Modal)`
  height: 100vh;
  max-width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: auto;
`;

export const Container = styled.div`
  max-height: 100%;
  height: fit-content;
  overflow: auto;
  min-width: 560px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.white.main};

  @media (max-width: 620px) {
    min-width: 90vw;
  }

  @media (max-height: 520px) {
    height: 90vh;
  }
`;

export const Header = styled.div`
  padding: 24px 32px;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const CloseButton = styled(MdClose)`
  color: ${({ theme }) => theme.colors.gray.four};
  font-size: ${({ theme }) => theme.sidebarIconSize};
  transition: opacity 0.3s !important;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
