import styled from 'styled-components';

export const BodyMessage = styled.p`
  padding: 24px 32px;
  margin-bottom: 32px;
  color: ${({ theme }) => theme.colors.gray.five};
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  border-bottom: 1px solid ${({ theme }) => theme.colors.white.three};
`;
