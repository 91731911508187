import React, { useRef } from 'react';

import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useToast } from '@contexts/Toast';
import { usePayments } from '@hooks';

import BaseForm from '@components/BaseForm';
import PageHeader from '@components/PageHeader';

import RoutesPath from '@router/routes';

import PlanInfo from '../Form/PlanInfo';
import routes from './routes';

const CreatePlan: React.FC = () => {
  const planInfoRef = useRef<FormStepRef>(null);

  const { createPlan } = usePayments();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const createRequest = useMutation(createPlan, {
    onSuccess: () => {
      addToast('Plano adicionado com sucesso', 'success');
      navigate(RoutesPath.private.plans.list, { replace: true });
      queryClient.invalidateQueries('plans');
    },
  });

  return (
    <>
      <PageHeader title="Cadastro de planos" routes={routes} />
      <BaseForm
        steps={[
          {
            title: 'Dados do plano',
            ref: planInfoRef,
            component: PlanInfo,
          },
        ]}
        createRequest={createRequest}
      />
    </>
  );
};

export default CreatePlan;
