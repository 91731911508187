import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { Value } from 'react-calendar/dist/cjs/shared/types';

import Calendar from '@components/Calendar';

import {
  CalendarFilterContainer,
  CalendarIcon,
  CloseIcon,
  SelectedDate,
  IconsContainer,
} from './styles';

const DateRangeCalendarFilter: React.ForwardRefRenderFunction<
  DateRangeCalendarFilterRef,
  DateRangeCalendarFilterProps
> = (
  {
    label,
    initialDateRange,
    maxDate,
    minDate,
    onDateRangeFilterSelected,
    clearableDateRange = false,
    className,
  },
  ref
) => {
  const [dateRangeSelected, setDateRangeSelected] = useState<
    [Date, Date] | undefined
  >(initialDateRange);
  const [shouldRenderCalendar, setShouldRenderCalendar] = useState(false);

  const calendarFilterContainer = useRef<HTMLDivElement | null>(null);

  const hide = useCallback((event: Event) => {
    const node = calendarFilterContainer.current;
    if (
      node &&
      event.target !== node &&
      !node.contains(event.target as HTMLElement) &&
      event.target !== calendarFilterContainer.current
    ) {
      setShouldRenderCalendar(false);
    }
  }, []);

  const handleCalendarClick = (
    event: React.MouseEvent<SVGElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setShouldRenderCalendar(!shouldRenderCalendar);
  };

  const handleClearDateRangeClick = (
    event: React.MouseEvent<SVGElement, MouseEvent>
  ) => {
    event.stopPropagation();
    onDateRangeFilterSelected();
    setDateRangeSelected(undefined);
    setShouldRenderCalendar(false);
  };

  const handleDateChange = (value: Value) => {
    const typedValue = value as [Date, Date];
    setDateRangeSelected(typedValue);
    onDateRangeFilterSelected(...typedValue);
    setShouldRenderCalendar(false);
  };

  useImperativeHandle(ref, () => ({
    changeSelectedDateRange: (startDate, endDate) => {
      if (startDate && endDate) {
        setDateRangeSelected([startDate, endDate]);
      } else {
        setDateRangeSelected(undefined);
      }
    },
  }));

  useEffect(() => {
    if (shouldRenderCalendar) {
      document.addEventListener('click', hide);
    }

    return () => document.removeEventListener('click', hide);
  }, [hide, shouldRenderCalendar]);

  return (
    <CalendarFilterContainer
      ref={calendarFilterContainer}
      isOpen={shouldRenderCalendar}
      onClick={() => setShouldRenderCalendar(true)}
      className={className}
    >
      <SelectedDate isSelectedDate={!!dateRangeSelected}>
        {dateRangeSelected
          ? dateRangeSelected[0].toLocaleDateString('pt-BR') +
            ' - ' +
            dateRangeSelected[1].toLocaleDateString('pt-BR')
          : label || 'Selecionar um intervalo de datas'}
      </SelectedDate>
      <IconsContainer>
        <CalendarIcon onClick={(event) => handleCalendarClick(event)} />
        {clearableDateRange && (
          <CloseIcon onClick={handleClearDateRangeClick} />
        )}
      </IconsContainer>
      {shouldRenderCalendar && (
        <Calendar
          value={dateRangeSelected}
          onChange={handleDateChange}
          onClickMonth={(_, event) => event.stopPropagation()}
          onClickYear={(_, event) => event.stopPropagation()}
          onClickDecade={(_, event) => event.stopPropagation()}
          maxDate={maxDate}
          minDate={minDate}
          returnValue="range"
          calendarType="gregory"
          locale="pt-br"
          selectRange
        />
      )}
    </CalendarFilterContainer>
  );
};

export default forwardRef(DateRangeCalendarFilter);
