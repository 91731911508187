import styled from 'styled-components';
import { commonInputStyle } from 'utils/styles';

interface StyledSelectProps {
  invalidValue?: boolean;
}

export const Container = styled.div`
  position: relative;
`;

export const StyledSelect = styled.select<StyledSelectProps>`
  ${commonInputStyle};

  background-image: url('/images/arrow-drop-down-circle.svg');
  background-repeat: no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: 50%;
  background-size: 15px 15px;
  appearance: none;
  cursor: pointer;

  option {
    color: ${(props) => props.theme.colors.gray.twelve};
    font-size: ${(props) => props.theme.fontSize.small};
    font-weight: ${(props) => props.theme.fontWeight.medium};
    background-color: ${(props) => props.theme.colors.white.one};
  }
`;
