import React, { forwardRef } from 'react';

import {
  CustomInput,
  RadioButtonsContainer,
  RadioButtonsGroup,
} from './styles';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  invalidValue?: boolean;
  className?: string;
}

const Input: React.ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  { invalidValue, className, ...props },
  ref
) => {
  return (
    <CustomInput
      className={className}
      invalidValue={invalidValue}
      ref={ref}
      {...props}
    />
  );
};

const forwardedInput = forwardRef(Input);

export { forwardedInput as default, RadioButtonsContainer, RadioButtonsGroup };
