import React from 'react';

import { useMutation } from 'react-query';

import { useToast } from '@contexts/Toast';
import { usePayments } from '@hooks';

import ConfirmationModal from '@components/ConfirmationModal';

interface DeleteSubscriptionConfirmationModalProps {
  isOpen: boolean;
  subscription: number;
  onConfirm: () => void;
  onCancel: () => void;
}

const DeleteSubscriptionConfirmationModal: React.FC<
  DeleteSubscriptionConfirmationModalProps
> = ({ isOpen, subscription, onConfirm, onCancel }) => {
  const { deleteSubscription } = usePayments();
  const { addToast } = useToast();

  const { mutate: requestDeleteSubscription, isLoading: isLoadingDelete } =
    useMutation(() => deleteSubscription(subscription), {
      onSuccess: onConfirm,
      onError: () => {
        addToast(
          'Não foi possível desassinalar o plano, tente novamente',
          'error'
        );
      },
    });

  return (
    <ConfirmationModal
      title="Tem certeza que deseja desassinalar o plano?"
      isOpen={isOpen}
      isLoading={isLoadingDelete}
      confirmButtonText="Sim"
      onConfirm={() => requestDeleteSubscription()}
      cancelButtonText="Cancelar"
      onClose={onCancel}
      onCancel={onCancel}
    />
  );
};

export default DeleteSubscriptionConfirmationModal;
