import { currency } from 'b2utils';

export const required = 'Campo obrigatório';
export const email = 'E-mail inválido';
export const emailConfirm = 'Os e-mails não conferem';
export const passwordConfirm = 'As senhas não conferem';
export const cpf = 'CPF inválido';
export const cnpj = 'CNPJ inválido';
export const rg = 'RG inválido';
export const cep = 'CEP inválido';
export const onlyLetters = 'Esse campos aceita apenas letras';
export const onlyNumbers = 'Esse campos aceita apenas números';
export const minLength = (value: number) =>
  `Esse campo deve ter no mínimo ${value} caracteres`;
export const maxLength = (value: number) =>
  `Esse campo deve ter no máximo ${value} caracteres`;
export const date = 'Data inválida';
export const time = 'Hora inválida';
export const minValue = (value: number) =>
  `Esse campo deve ser no mínimo ${value}`;
export const maxValue = (value: number) =>
  `Esse campo deve ser menor que ${value}`;
export const maxPrice = (value: number) =>
  `Esse campo deve ser menor que ${currency.centsToBrl(value)}`;
export const pastDate = 'Data no passado';
export const endDateBefore = 'Data antes do início';
export const phone = 'Telefone inválido';
export const urlError = 'Link inválido';
