import { B2Card } from 'react-b2components';
import styled from 'styled-components';

import { InactivateSwitch } from '@components/InactivateSwitch';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Card = styled(B2Card)`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  gap: 24px;
`;

export const PlanTitle = styled.span`
  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.black.two};
`;

export const Subtitle = styled.span`
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.black.two};
`;

export const ExtraInfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 200px);
  gap: 16px 24px;
`;

export const ExtraInfoContent = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
`;

export const ExtraInfoLabel = styled.span`
  margin-bottom: 8px;
  color: ${(props) => props.theme.colors.gray.twelve};
  font-weight: ${(props) => props.theme.fontWeight.semiBold};
`;

export const ExtraInfValue = styled.span`
  color: ${(props) => props.theme.colors.black.main};
  font-weight: ${(props) => props.theme.fontWeight.regular};
`;

export const SectionTitle = styled.span`
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.black.two};
`;

export const Switch = styled(InactivateSwitch)`
  position: absolute;
  top: 20px;
  right: 20px;

  @media (max-width: 930px) {
    position: relative;
    top: auto;
    right: auto;
    align-self: center;
  }
`;
