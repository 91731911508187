import React, { useMemo, useState } from 'react';

import ReactDOM from 'react-dom';

import { SidebarItems } from '../../SidebarItems';
import {
  CloseButton,
  HamburgerMenu,
  MenuItem,
  Modal,
  SideBarContainer,
} from './styles';

const MobileMenu: React.FC = () => {
  const [showMenu, setShowMenu] = useState(false);

  const container = useMemo(() => document.getElementById('app'), []);

  return (
    <>
      <HamburgerMenu onClick={() => setShowMenu(!showMenu)} />
      {ReactDOM.createPortal(
        <Modal isOpen={showMenu}>
          <CloseButton onClick={() => setShowMenu(false)} />
          <SideBarContainer>
            {SidebarItems.map(({ name, route }, index) => (
              <MenuItem
                key={index}
                to={route}
                onClick={() => setShowMenu(false)}
              >
                {name}
              </MenuItem>
            ))}
          </SideBarContainer>
        </Modal>,
        container!
      )}
    </>
  );
};

export default MobileMenu;
