import React from 'react';

import BaseModal from '@components/BaseModal';
import ModalFooterButtons from '@components/ModalFooterButtons';

import { BodyMessage } from './styles';

interface ConfirmationModalProps {
  title: string;
  message?: string;
  isOpen: boolean;
  isLoading: boolean;
  confirmButtonText: string;
  onConfirm: () => void;
  cancelButtonText: string;
  onClose: () => void;
  onCancel: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  title,
  message,
  isOpen,
  isLoading,
  confirmButtonText,
  onConfirm,
  cancelButtonText,
  onClose,
  onCancel,
}) => {
  return (
    <BaseModal isOpen={isOpen} title={title} onClose={onClose}>
      {message && <BodyMessage>{message}</BodyMessage>}
      <ModalFooterButtons
        isLoading={isLoading}
        confirmText={confirmButtonText}
        onConfirm={onConfirm}
        cancelText={cancelButtonText}
        onCancel={onCancel}
      />
    </BaseModal>
  );
};

export default ConfirmationModal;
