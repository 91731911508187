import { B2MenuItem } from 'react-b2components';
import styled from 'styled-components';

export const sideBarWidth = 232;

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
`;

export const Header = styled.div`
  height: 70px;
  width: 100%;
  min-width: 320px;
  padding: 20px;
  display: flex;
  align-items: center;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.06));
  background-color: ${(props) => props.theme.colors.white};
`;

export const MainContent = styled.div`
  background-color: #f2f2f2;
  display: flex;
  height: calc(100% - 70px);
`;

export const PrivateBaseContent = styled.div`
  overflow: auto;
  margin-left: ${sideBarWidth}px;
  width: calc(100% - ${sideBarWidth}px);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 10px;
  padding: 32px 49px;

  @media (max-width: 768px) {
    padding: 4vh 8vw;
    margin-left: 0;
    width: 100%;
  }
`;

export const BaseContainer = styled.div`
  flex: 1;
  width: 100%;
`;

export const LogoutButton = styled.span`
  color: ${(props) => props.theme.colors.main};
  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: ${(props) => props.theme.fontWeight.medium};
`;

export const Title = styled.h1`
  margin-bottom: 12px;
  color: ${(props) => props.theme.colors.gray.twelve};
  font-size: ${(props) => props.theme.fontSize.big};
  font-weight: ${(props) => props.theme.fontWeight.semiBold};
`;

export const ProfileMenuItem = styled(B2MenuItem)`
  color: ${(props) => props.theme.colors.main};
  display: flex;
  align-items: center;
  font-size: 16px;
  padding: 10px;
`;

export const ProfileMenuItemText = styled.span`
  color: ${(props) => props.theme.colors.gray.twelve};
  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  margin-left: 20px;
`;

export const RadioButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const InputGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  &,
  label {
    cursor: pointer;
    width: fit-content;
    margin: 0;
    font-size: ${({ theme }) => theme.fontSize.small};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
  }
`;
