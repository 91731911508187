import React from 'react';

import { Container, StyledSelect } from './styles';

interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  invalidValue?: boolean;
  isLoading?: boolean;
}

const Select = ({ isLoading, disabled, children, ...props }: SelectProps) => {
  return (
    <Container>
      <StyledSelect {...props} disabled={isLoading || disabled}>
        {isLoading ? <option value="">Carregando...</option> : children}
      </StyledSelect>
    </Container>
  );
};

export default Select;
