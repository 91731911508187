import { color } from 'b2utils';
import styled, { css } from 'styled-components';

interface Tab {
  selected?: boolean;
}

export const TabNames = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const TabName = styled.span<Tab>`
  font-size: ${(props) => props.theme.fontSize.small};
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: 14px 65px 14px 16px;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &:after {
    content: '';
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transition: width 0.5s;
  }

  ${(props) =>
    props.selected
      ? css`
          font-weight: ${(props) => props.theme.fontWeight.bold};
          border-right: 4px solid ${props.theme.colors.main};
          color: ${props.theme.colors.main};
          &:after {
            width: 100%;
            background-color: ${(props) =>
              color.addOpacityOnHexColor(props.theme.colors.main, 0.12)};
          }
        `
      : css`
          color: ${props.theme.colors.gray.six};
          font-weight: ${(props) => props.theme.fontWeight.semiBold};
          border-right: 1px solid ${(props) => props.theme.colors.gray.one};
          padding-right: 68px;
          &:after {
            width: 0px;
            background-color: transparent;
          }
        `}
`;
