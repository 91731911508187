import React from 'react';

import useAnalytics from 'hooks/Analytics';
import { MdPhone, MdRefresh } from 'react-icons/md';
import { useQuery } from 'react-query';
import { useTheme } from 'styled-components';

import InformativeCard from '@components/InformativeCard';

const PhoneClicks: React.FC = () => {
  const theme = useTheme();
  const { getPostsAvgClicksInPhone } = useAnalytics();

  const fetchPostsAvgClicksInPhone = useQuery(['postsAvgClicksInPhone'], () =>
    getPostsAvgClicksInPhone()
  );

  return (
    <>
      <InformativeCard
        dataCy="total-phone-clicks-card"
        title="Total de cliques no telefone"
        value={(fetchPostsAvgClicksInPhone.data?.clicks_count ?? 0).toString()}
        icon={{
          component: MdPhone,
          color: theme.colors.main,
        }}
        request={fetchPostsAvgClicksInPhone}
      />
      <InformativeCard
        dataCy="avg-phone-clicks-card"
        title="Média de cliques no telefone"
        value={(fetchPostsAvgClicksInPhone.data?.avg ?? 0).toString()}
        icon={{
          component: MdRefresh,
          color: theme.colors.danger,
        }}
        request={fetchPostsAvgClicksInPhone}
      />
    </>
  );
};

export default PhoneClicks;
