import React, { forwardRef, useImperativeHandle, useMemo } from 'react';

import { cnpj, cpf, phone } from 'b2utils';
import { FormikProvider, useFormik } from 'formik';
import { StoreType } from 'utils/enums';
import * as yup from 'yup';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input, {
  RadioButtonsContainer,
  RadioButtonsGroup,
} from '@components/Input';
import Label from '@components/Label';
import YesOrNoSwitch from '@components/YesOrNoSwitch';

import { errors } from '@utils';

const selectableValues = [
  { label: 'Pessoa física', value: StoreType.PHYSICAL },
  { label: 'Pessoa jurídica', value: StoreType.LEGAL },
];

interface ClientUserInfoProps {
  initialValues?: FormValuesClientUserInfo;
  onFinish: (data: FormValuesClientUserInfo) => void;
}

const ClientUserInfo: React.ForwardRefRenderFunction<
  FormStepRef,
  ClientUserInfoProps
> = ({ initialValues, onFinish }, ref) => {
  const formikInitialValues: FormValuesClientUserInfo = {
    name: '',
    type: StoreType.PHYSICAL,
    document: '',
    storeName: '',
    phone: '',
    phoneIsWhatsapp: false,
    email: '',
    ...initialValues,
  };

  const formikValidationSchema = yup.object().shape({
    name: yup.string().trim().required(errors.required),
    type: yup
      .string()
      .trim()
      .oneOf(selectableValues.map((option) => option.value))
      .required(errors.required),
    document: yup
      .string()
      .trim()
      .when('type', {
        is: (type: StoreType) => type === StoreType.PHYSICAL,
        then: (schema) =>
          schema.test(
            'valid-cpf',
            errors.cpf,
            (value) => !!value && cpf.validate(value)
          ),
        otherwise: (schema) =>
          schema.test(
            'valid-cnpj',
            errors.cnpj,
            (value) => !!value && cnpj.validate(value)
          ),
      })
      .required(errors.required),
    storeName: yup
      .string()
      .trim()
      .when('type', {
        is: (type: StoreType) => type === StoreType.LEGAL,
        then: (schema) => schema.required(errors.required),
      }),
    phone: yup
      .string()
      .trim()
      .test(
        'valid-phone',
        errors.phone,
        (value) => !!value && phone.validate(value)
      )
      .min(10, errors.phone)
      .required(errors.required),
    email: yup.string().trim().email(errors.email).required(errors.required),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  const maskedDocument = useMemo(() => {
    const { type, document } = formik.values;

    if (type === StoreType.PHYSICAL) {
      return cpf.mask(document);
    } else {
      return cnpj.mask(document);
    }
  }, [formik.values]);

  return (
    <FormikProvider value={formik}>
      <FormRow>
        <FormGroup>
          <Label htmlFor="name">Nome completo</Label>
          <Input
            type="text"
            name="name"
            placeholder="Digite aqui"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            invalidValue={!!formik.touched.name && !!formik.errors.name}
          />
          <FormError name="name" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label>Tipo</Label>
          <RadioButtonsContainer>
            {selectableValues.map((option) => (
              <RadioButtonsGroup key={option.value}>
                <Input
                  type="radio"
                  id={`radio${option.value}`}
                  name="type"
                  checked={formik.values.type === option.value}
                  onChange={() => {
                    formik.setFieldValue('type', option.value);
                    formik.validateField('document');
                  }}
                  onBlur={formik.handleBlur}
                  value={option.value}
                />
                <Label htmlFor={`radio${option.value}`}>{option.label}</Label>
              </RadioButtonsGroup>
            ))}
          </RadioButtonsContainer>
          <FormError name="type" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="document">
            {formik.values.type === StoreType.PHYSICAL ? 'CPF' : 'CNPJ'}
          </Label>
          <Input
            type="text"
            name="document"
            placeholder="Digite aqui"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={maskedDocument}
            invalidValue={!!formik.touched.document && !!formik.errors.document}
          />
          <FormError name="document" />
        </FormGroup>
      </FormRow>
      {formik.values.type === StoreType.LEGAL && (
        <FormRow>
          <FormGroup>
            <Label htmlFor="storeName">Nome da loja</Label>
            <Input
              type="text"
              name="storeName"
              placeholder="Digite aqui"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.storeName}
              invalidValue={
                !!formik.touched.storeName && !!formik.errors.storeName
              }
            />
            <FormError name="storeName" />
          </FormGroup>
        </FormRow>
      )}
      <FormRow>
        <FormGroup>
          <Label htmlFor="phone">Telefone</Label>
          <Input
            type="text"
            name="phone"
            placeholder="(##) #### ####"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={phone.mask(formik.values.phone)}
            invalidValue={!!formik.touched.phone && !!formik.errors.phone}
            maxLength={19}
          />
          <FormError name="phone" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="phoneIsWhatsapp">O telefone é WhatsApp?</Label>
          <YesOrNoSwitch
            isChecked={formik.values.phoneIsWhatsapp}
            onChange={() =>
              formik.setFieldValue(
                'phoneIsWhatsapp',
                !formik.values.phoneIsWhatsapp
              )
            }
          />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="email">E-mail</Label>
          <Input
            type="text"
            name="email"
            placeholder="Digite aqui"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            invalidValue={!!formik.touched.email && !!formik.errors.email}
          />
          <FormError name="email" />
        </FormGroup>
      </FormRow>
    </FormikProvider>
  );
};

export default forwardRef(ClientUserInfo);
