import RoutesPath from '@router/routes';

const routes = [
  {
    path: RoutesPath.private.plans.list,
    label: 'Lista de planos',
  },
  {
    path: RoutesPath.private.plans.detail,
    label: 'Detalhes do plano',
  },
];

export default routes;
