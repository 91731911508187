import React from 'react';

import errorAnimation from './errorAnimation.json';
import { AnimationContainer, ErrorLottie } from './styles';

interface ErrorAnimationProps {
  className?: string;
}

const ErrorAnimation: React.FC<ErrorAnimationProps> = ({ className }) => {
  return (
    <AnimationContainer className={className}>
      <h1>Nada por aqui</h1>
      <ErrorLottie loop={true} animationData={errorAnimation} />
    </AnimationContainer>
  );
};

export default ErrorAnimation;
