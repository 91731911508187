import React from 'react';

import { Anchor, DeleteIcon, EmptyText, Image, ImageList } from './styles';

export interface ImageHorizontalListProps {
  images: Array<Image>;
  onDeleteImage?: (imagePk: number) => void;
  isDeleteDisabled?: boolean;
  emptyText?: string;
  dataCy?: string;
}

const ImageHorizontalList: React.FC<ImageHorizontalListProps> = ({
  images,
  onDeleteImage,
  isDeleteDisabled = false,
  emptyText = 'Nenhuma imagem anexada',
  dataCy,
}) => {
  const isEmpty = images.length === 0;

  if (isEmpty) {
    return (
      <EmptyText>
        <em>{emptyText}</em>
      </EmptyText>
    );
  }

  return (
    <ImageList data-cy={`image-horizontal-list-${dataCy}`}>
      {images.map((image) => (
        <Anchor
          key={image.id}
          href={image.high}
          download
          target="_blank"
          rel="noreferrer"
        >
          <Image src={image.low} alt="Imagem" />
          {onDeleteImage && (
            <DeleteIcon
              disabled={isDeleteDisabled}
              onClick={(event) => {
                event.preventDefault();
                onDeleteImage(image.id);
              }}
            />
          )}
        </Anchor>
      ))}
    </ImageList>
  );
};

export default ImageHorizontalList;
