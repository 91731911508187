import React from 'react';

import { useNavigate } from 'react-router-dom';

import { Content } from '@components/BaseForm/styles';
import PageHeader from '@components/PageHeader';
import Tabs from '@components/Tabs';

import RoutesPath from '@router/routes';

import ListFinishedPosts from './ListFinishedPosts';
import ListPendingPosts from './ListPendingPosts';

const ListPosts: React.FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <PageHeader
        title="Anúncios"
        subtitle="Aqui estão todos os anúncios cadastrados no sistema"
        primaryButton={{
          text: 'Cadastrar anúncio',
          onClick: () => {
            navigate(RoutesPath.private.posts.create);
          },
        }}
      />
      <Content>
        <Tabs
          tabs={[
            {
              title: 'Finalizados',
              component: <ListFinishedPosts />,
              path: RoutesPath.private.posts.list.complete,
            },
            {
              title: 'Rascunhos',
              component: <ListPendingPosts />,
              path: RoutesPath.private.posts.list.draft,
            },
          ]}
        />
      </Content>
    </>
  );
};

export default ListPosts;
