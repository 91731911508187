import React from 'react';

import { useParams } from 'react-router-dom';

import { Content } from '@components/BaseForm/styles';
import PageHeader from '@components/PageHeader';
import Tabs from '@components/Tabs';

import RoutesPath from '@router/routes';

import LeadInfo from './LeadInfo';
import LeadPosts from './LeadPosts';
import routes from './routes';

const DetailLead: React.FC = () => {
  const { leadId } = useParams<{ leadId: string }>();

  return (
    <>
      <PageHeader title="Detalhes do Lead" routes={routes} />
      <Content>
        <Tabs
          tabs={[
            {
              title: 'Lead',
              component: <LeadInfo customer={Number(leadId)} />,
              path: RoutesPath.private.leads.details.leadInfo.replace(
                ':leadId',
                leadId!
              ),
            },
            {
              title: 'Anúncios',
              component: <LeadPosts customer={Number(leadId)} />,
              path: RoutesPath.private.leads.details.posts.replace(
                ':leadId',
                leadId!
              ),
            },
          ]}
        />
      </Content>
    </>
  );
};

export default DetailLead;
