import React, { useMemo, useState } from 'react';

import moment from 'moment';

import { useDebounce } from '@hooks';

import { Content } from '@components/BaseForm/styles';
import FormGroup from '@components/FormGroup';
import Label from '@components/Label';
import PageHeader from '@components/PageHeader';

import ChargesBalance from './ChargesBalance';
import ChargesHistory from './ChargesHistory';
import SubscriptionsCount from './SubscriptionsCount';
import SubscriptionsHistogram from './SubscriptionsHistogram';
import { DateInput, Grid } from './styles';

const Financial: React.FC = () => {
  const [sinceDate, setSinceDate] = useState<string>(
    moment().format('YYYY-MM-DD')
  );
  const [untilDate, setUntilDate] = useState<string>(
    moment().format('YYYY-MM-DD')
  );

  const onChangeSinceDate = useDebounce(setSinceDate, 1000);
  const onChangeUntilDate = useDebounce(setUntilDate, 1000);

  const period = useMemo(
    () => ({
      since: sinceDate,
      until: untilDate,
    }),
    [sinceDate, untilDate]
  );

  return (
    <>
      <PageHeader
        title="Financeiro"
        subtitle="Aqui estão as informações relacionadas ao financeiro do sistema"
      />
      <Content>
        <Grid>
          <FormGroup>
            <Label>De</Label>
            <DateInput
              name="since"
              type="date"
              onChange={(event) => onChangeSinceDate(event.target.value)}
              defaultValue={moment().format('YYYY-MM-DD')}
            />
          </FormGroup>
          <FormGroup>
            <Label>Até</Label>
            <DateInput
              name="until"
              type="date"
              onChange={(event) => onChangeUntilDate(event.target.value)}
              defaultValue={moment().format('YYYY-MM-DD')}
            />
          </FormGroup>
          <ChargesBalance period={period} />
          <SubscriptionsCount period={period} />
          <SubscriptionsHistogram />
          <ChargesHistory period={period} />
        </Grid>
      </Content>
    </>
  );
};

export default Financial;
