import React from 'react';

import { Icon } from 'iconsax-react';

import { BaseCardProps } from '@components/BaseCard';

import { Card, CardIconContainer, CardTitle, CardValue } from './styles';

interface InformativeCardProps {
  title: string;
  value: string;
  icon: {
    component: Icon;
    color: string;
  };
  request: BaseCardProps['request'];
  dataCy?: string;
}

const InformativeCard: React.FC<InformativeCardProps> = ({
  title,
  value,
  icon,
  request,
  dataCy,
}) => {
  const { component: IconComponent } = icon;

  return (
    <Card dataCy={dataCy} request={request}>
      <>
        <div>
          <CardTitle>{title}</CardTitle>
          <CardValue data-cy="card-value">{value}</CardValue>
        </div>
        <CardIconContainer color={icon.color}>
          <IconComponent size="32" variant="Bold" />
        </CardIconContainer>
      </>
    </Card>
  );
};

export default InformativeCard;
