import React from 'react';

import { useFormikContext } from 'formik';
import { useMutation } from 'react-query';

import { useToast } from '@contexts/Toast';
import { usePosts } from '@hooks';

import { enums } from '@utils';

import SearchInput from '../SearchInput';

interface ChassiSearchProps {
  category: number;
  onSearch: () => void;
}

const ChassiSearch: React.FC<ChassiSearchProps> = ({ category, onSearch }) => {
  const { searchByChassi } = usePosts();
  const { addToast } = useToast();

  const formik = useFormikContext<FormValuesVehicleInfo>();

  const convertApiObjectIntoFormikObject = (data: {
    id: number;
    name: string;
  }) => {
    return {
      label: data.name,
      value: data.id.toString(),
    };
  };

  const emptyFormikObject = {
    label: '',
    value: '',
  };

  const requestSearchByChassi = useMutation(
    (search: string) => searchByChassi(category, search),
    {
      onSuccess: (data) => {
        formik.setFieldValue(
          'brand',
          convertApiObjectIntoFormikObject(data.brand)
        );
        formik.setFieldValue('model', {
          ...convertApiObjectIntoFormikObject(data.model),
          manufacturingYears: data.model.manufacturing_years,
        });
        formik.setFieldValue('manufacturingYear', data.manufacturing_year);
        formik.setFieldValue('modelYear', data.model_year);
        const fuel = data.tags.find((tag) => tag.type === enums.TagType.FUEL);
        if (fuel) {
          formik.setFieldValue('fuel', convertApiObjectIntoFormikObject(fuel));
        }
      },
      onError: () => {
        formik.setFieldValue('brand', emptyFormikObject);
        formik.setFieldValue('model', {
          ...emptyFormikObject,
          manufacturingYears: [],
        });
        formik.setFieldValue('manufacturingYear', '');
        formik.setFieldValue('modelYear', '');
        formik.setFieldValue('fuel', emptyFormikObject);

        addToast(
          'Não foi possível encontrar os dados do veículo baseado na chassi',
          'error'
        );
      },
      onSettled: () => {
        onSearch();
      },
    }
  );

  return (
    <SearchInput
      label="Chassi do veículo"
      searchMutation={requestSearchByChassi}
      maxLength={17}
    />
  );
};

export default ChassiSearch;
