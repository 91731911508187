import styled from 'styled-components';

export const Container = styled.div`
  height: 70px;
  width: 100vw;
  padding: ${({ theme }) => `${theme.spacing.three} ${theme.spacing.eight}`};
  display: flex;
  align-items: center;
  justify-content: space-between;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.06));
  background-color: ${({ theme }) => theme.colors.white.main};
  position: relative;
  z-index: 1;
`;

export const Logo = styled.img`
  height: 20px;

  @media (max-width: 540px) {
    display: none;
  }
`;
