import React, { useState } from 'react';

import { currency } from 'b2utils';
import { B2TableDataCell, B2TableRow } from 'react-b2components';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { usePayments } from '@hooks';

import { Content } from '@components/BaseForm/styles';
import PageHeader from '@components/PageHeader';
import SearchFilter from '@components/SearchFilter';
import Status from '@components/Status';
import Table from '@components/Table';

import RoutesPath from '@router/routes';

const fields = [
  'id',
  'name',
  'interval',
  'interval_count',
  'price',
  'cycles',
  'max_number_active_posts',
  'max_number_of_posts',
  'is_active',
] as const;

type ListPlansFields = (typeof fields)[number];

const ListPlans: React.FC = () => {
  const [queryParams, setQueryParams] = useState<PlansQuery>({
    fields: fields as unknown as Array<keyof Plan>,
    page: 1,
  });

  const { listPlans } = usePayments();
  const navigate = useNavigate();

  const fetchPlan = useQuery(['plans', queryParams], () =>
    listPlans<ListPlansFields>(queryParams)
  );

  return (
    <>
      <PageHeader
        title="Planos"
        subtitle="Aqui estão todos os planos cadastrados no sistema"
        primaryButton={{
          text: 'Cadastrar plano',
          onClick: () => {
            navigate(RoutesPath.private.plans.create);
          },
        }}
      />
      <Content>
        <SearchFilter
          onSearch={(search) => {
            setQueryParams((prevState) => ({
              ...prevState,
              search,
            }));
          }}
        />
        <Table
          fetchData={fetchPlan}
          headerData={[
            'Nome',
            'Valor',
            'Max. ativos',
            'Total máximo',
            'Status',
          ]}
          renderRow={(plan) => (
            <B2TableRow
              key={plan.id}
              onClick={() => {
                navigate(
                  RoutesPath.private.plans.detail.replace(
                    ':planId',
                    plan.id.toString()
                  )
                );
              }}
            >
              <B2TableDataCell>{plan.name}</B2TableDataCell>
              <B2TableDataCell>
                {currency.centsToBrl(plan.price)}
              </B2TableDataCell>
              <B2TableDataCell>{plan.max_number_active_posts}</B2TableDataCell>
              <B2TableDataCell>{plan.max_number_of_posts}</B2TableDataCell>
              <B2TableDataCell>
                <Status isActive={plan.is_active} />
              </B2TableDataCell>
            </B2TableRow>
          )}
          paginator
          changePage={(newPage) =>
            setQueryParams((prevState) => ({
              ...prevState,
              page: newPage,
            }))
          }
          currentPage={queryParams.page}
        />
      </Content>
    </>
  );
};

export default ListPlans;
