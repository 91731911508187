import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  color: ${(props) => props.theme.colors.gray.ten};
  font-size: ${({ theme }) => theme.fontSize.medium};
  cursor: pointer;
`;
