import React, { useMemo } from 'react';

import { phone, cpf, cnpj } from 'b2utils';

import DetailPerson from '@components/DetailPerson';

import RoutesPath from '@router/routes';
import { enums } from '@utils';

import CollapsibleCard from '../CollapsibleCard';

interface ClientInfoProps {
  post: Post;
}

const ClientInfo: React.FC<ClientInfoProps> = ({ post }) => {
  const storeType = useMemo(() => {
    if (post.store_info.document.length === 11) {
      return enums.StoreType.PHYSICAL;
    }

    return enums.StoreType.LEGAL;
  }, [post.store_info.document.length]);

  return (
    <CollapsibleCard dataCy="ClientInfo" title="Cliente" startOpen={true}>
      <DetailPerson
        avatar={post.store_info.logo?.medium}
        title={post.store_info.name}
        link={
          storeType === enums.StoreType.LEGAL
            ? RoutesPath.private.clients.detail.legal.detail.replace(
                ':clientId',
                post.id.toString()
              )
            : RoutesPath.private.clients.detail.physical.detail.replace(
                ':clientId',
                post.id.toString()
              )
        }
        infos={[
          {
            label: 'Telefone',
            value: phone.fromObject({
              countryCode: post.store_info.phone.country_code,
              areaCode: post.store_info.phone.area_code,
              number: post.store_info.phone.number,
            }),
          },
          storeType === enums.StoreType.PHYSICAL
            ? {
                label: 'CPF',
                value: cpf.mask(post.store_info.document),
              }
            : {
                label: 'CNPJ',
                value: cnpj.mask(post.store_info.document),
              },
        ]}
      />
    </CollapsibleCard>
  );
};

export default ClientInfo;
