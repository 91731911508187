import styled from 'styled-components';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${({ theme }) => theme.spacing.four};

  @media (min-width: 955px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1192px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1770px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;
