import { color } from 'b2utils';
import { Trash } from 'iconsax-react';
import styled, { css } from 'styled-components';

import BaseCarPhoto from '../BaseCarPhoto';

export const Container = styled(BaseCarPhoto)<{ isMain: boolean }>`
  background-color: ${({ theme }) =>
    color.addOpacityOnHexColor(theme.colors.success, 0.1)};
  border: 1px solid ${({ theme }) => theme.colors.gray.one};
  outline: none;
  cursor: default;

  ${({ isMain }) =>
    !isMain &&
    css`
      background-color: ${({ theme }) =>
        color.addOpacityOnHexColor(theme.colors.main, 0.1)};
    `}
`;

export const TrashIcon = styled(Trash)`
  position: absolute;
  top: ${({ theme }) => theme.spacing.four};
  right: ${({ theme }) => theme.spacing.four};
  cursor: pointer;
  color: ${({ theme }) => theme.colors.error};
  flex-shrink: 0;
`;

export const Image = styled.img`
  width: 50%;
  height: 50%;
  object-fit: contain;
  min-height: 50%;
  max-height: 50%;
`;

export const Divider = styled.hr`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.four};
  margin-bottom: ${({ theme }) => theme.spacing.four};
  color: ${({ theme }) => theme.colors.gray.four};
`;

export const MainPhotoText = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  padding-top: ${({ theme }) => theme.spacing.three};
  padding-bottom: ${({ theme }) => theme.spacing.three};
  color: ${({ theme }) => theme.colors.main};
`;
