import styled from 'styled-components';

export default styled.div`
  display: flex;
  gap: 1rem;

  @media (max-width: 960px) {
    flex-direction: column;

    select {
      width: 100%;
    }
  }
`;
