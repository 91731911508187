import React from 'react';

import loadingAnimation from './loadingAnimation.json';
import { AnimationContainer, LoadingLottie } from './styles';

interface LoadingProps {
  className?: string;
}

const Loading: React.FC<LoadingProps> = ({ className }) => {
  return (
    <AnimationContainer className={className}>
      <h1>Carregando...</h1>
      <LoadingLottie loop={true} animationData={loadingAnimation} />
    </AnimationContainer>
  );
};

export default Loading;
