import React, { useState } from 'react';

import { B2TableDataCell, B2TableRow } from 'react-b2components';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useStores } from '@hooks';

import { Content } from '@components/BaseForm/styles';
import PageHeader from '@components/PageHeader';
import SearchFilter from '@components/SearchFilter';
import Table from '@components/Table';

import RoutesPath from '@router/routes';

const fields = ['id', 'question'] as const;

type ListFaqFields = (typeof fields)[number];

const ListFaq: React.FC = () => {
  const [queryParams, setQueryParams] = useState<FaqQuery>({
    fields: fields as unknown as Array<keyof Faq>,
    page: 1,
  });

  const { listFaq } = useStores();
  const navigate = useNavigate();

  const fetchFaq = useQuery(['faq', queryParams], () =>
    listFaq<ListFaqFields>(queryParams)
  );

  return (
    <>
      <PageHeader
        title="FAQ"
        subtitle="Aqui estão todos as perguntas frequentes cadastradas no sistema"
        primaryButton={{
          text: 'Cadastrar FAQ',
          onClick: () => {
            navigate(RoutesPath.private.faq.create);
          },
        }}
      />
      <Content>
        <SearchFilter
          onSearch={(search) => {
            setQueryParams((prevState) => ({
              ...prevState,
              search,
            }));
          }}
        />
        <Table
          fetchData={fetchFaq}
          headerData={['Pergunta']}
          renderRow={(faq) => (
            <B2TableRow
              key={faq.id}
              onClick={() =>
                navigate(
                  RoutesPath.private.faq.detail.replace(
                    ':faqId',
                    faq.id.toString()
                  )
                )
              }
            >
              <B2TableDataCell>{faq.question}</B2TableDataCell>
            </B2TableRow>
          )}
          paginator
          changePage={(newPage) =>
            setQueryParams((prevState) => ({
              ...prevState,
              page: newPage,
            }))
          }
          currentPage={queryParams.page}
        />
      </Content>
    </>
  );
};

export default ListFaq;
