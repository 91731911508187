import React, { useCallback } from 'react';

import { AxiosError } from 'axios';
import { Formik } from 'formik';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';
import * as yup from 'yup';

import { useToast } from '@contexts/Toast';
import { useAuthentication } from '@hooks';

import AuthInput from '@components/AuthInput';
import { CardTitle, Form, AuthButton } from '@components/Base/AuthBase/styles';
import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';

import RoutesPath from '@router/routes';

import { ForgotText } from './styles';

interface RequestChangePasswordParams {
  email: string;
}

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const { requestChangePassword } = useAuthentication();
  const { addToast } = useToast();

  const requestChangePasswordRequest = useMutation(
    ({ email }: RequestChangePasswordParams) => requestChangePassword(email),
    {
      onSuccess: () => {
        addToast(
          'Enviamos um email com um link para a recuperação da sua senha',
          'success'
        );
        navigate(RoutesPath.auth.login);
      },
      onError: (error: AxiosError) => {
        addToast(error?.message, 'error');
      },
    }
  );

  const handleSubmit = useCallback(
    async (values: RequestChangePasswordParams) => {
      await requestChangePasswordRequest.mutateAsync(values);
    },
    [requestChangePasswordRequest]
  );

  return (
    <>
      <CardTitle>Esqueceu sua senha?</CardTitle>
      <ForgotText>
        Informe seu e-mail para enviarmos um link de redefinição da sua senha{' '}
      </ForgotText>
      <Formik
        initialValues={{ email: '' }}
        onSubmit={handleSubmit}
        validationSchema={yup.object({
          email: yup
            .string()
            .email('Email inválido')
            .required('Esse campo é obrigatório'),
        })}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <FormGroup>
              <AuthInput
                label="E-mail"
                type="email"
                placeholder="Digite aqui"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              <FormError name="email" />
            </FormGroup>
            <AuthButton
              variant="primary"
              type="submit"
              isLoading={requestChangePasswordRequest.isLoading}
            >
              Enviar link para e-mail
            </AuthButton>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ForgotPassword;
