import React from 'react';

import { Routes, Route, Navigate } from 'react-router-dom';

import PrivateBase from '@components/Base/PrivateBase';

import RoutesPath from '@router/routes';

import pages from './pages';

const PrivatePages: React.FC = () => {
  return (
    <PrivateBase>
      <Routes>
        {pages.map((page) => (
          <Route
            key={page.route}
            path={page.route}
            element={<page.component />}
          />
        ))}
        <Route
          path="*"
          element={<Navigate to={RoutesPath.private.home} replace />}
        />
      </Routes>
    </PrivateBase>
  );
};

export default PrivatePages;
