import styled from 'styled-components';
import { commonInputStyle } from 'utils/styles';

interface InputProps {
  invalidValue?: boolean;
}

export const CustomInput = styled.input<InputProps>`
  ${commonInputStyle};
  padding: 15px 46px 15px 16px;

  &:focus {
    border: none;
    border: 1px solid ${({ theme }) => theme.colors.info};
    outline: 0;
  }

  &[type='radio'] {
    width: 20px;
    height: 20px;
    appearance: none;
    display: inline-block;
    padding: 3px;
    background-clip: content-box;
    border: 2px solid ${({ theme }) => theme.colors.gray.four};
    background-color: ${({ theme }) => theme.colors.white.main};
    transition: all ease-in 0.25s;
    &:checked {
      background-color: ${({ theme }) => theme.colors.main};
      border: 2px solid ${({ theme }) => theme.colors.main};
    }
  }

  &[type='checkbox'] {
    width: 20px;
    height: 20px;
    accent-color: ${({ theme }) => theme.colors.main};
    transition: all ease-in 0.25s;
  }
`;

export const RadioButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const RadioButtonsGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  &,
  label {
    cursor: pointer;
    width: fit-content;
    margin: 0;
    font-size: ${({ theme }) => theme.fontSize.small};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
  }

  input {
    flex-shrink: 0;
  }
`;
