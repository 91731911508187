import React, { useMemo } from 'react';

import useAnalytics from 'hooks/Analytics';
import { useQuery } from 'react-query';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useTheme } from 'styled-components';

import ChartCard from '@components/ChartCard';

import { enums } from '@utils';

const StoresHistogram: React.FC = () => {
  const theme = useTheme();
  const { getActiveStoresHistogram } = useAnalytics();

  const fetchActiveStoresHistogram = useQuery(['activeStoresHistogram'], () =>
    getActiveStoresHistogram()
  );

  const chartData = useMemo(() => {
    if (!fetchActiveStoresHistogram.data) {
      return [];
    }

    const results = fetchActiveStoresHistogram.data.histogram.map((item) => ({
      month: enums.MonthsMap[item.month],
      'Número de clientes': item.count,
    }));

    return results.sort((month1, month2) =>
      month1.month < month2.month ? 1 : -1
    );
  }, [fetchActiveStoresHistogram.data]);

  return (
    <ChartCard
      dataCy="histogram-card"
      title="Clientes ativos por mês"
      request={fetchActiveStoresHistogram}
    >
      <ResponsiveContainer width="100%" height={300}>
        <LineChart width={400} height={400} data={chartData}>
          <Line
            type="natural"
            dataKey="Número de clientes"
            stroke={theme.colors.main}
            strokeWidth={5}
            dot={{
              strokeWidth: 1,
            }}
          />
          <XAxis dataKey="month" />
          <YAxis />
          <CartesianGrid vertical={false} />
          <Tooltip />
          <Legend verticalAlign="top" align="right" iconType="plainline" />
        </LineChart>
      </ResponsiveContainer>
    </ChartCard>
  );
};

export default StoresHistogram;
