import styled from 'styled-components';

export const AddressContainer = styled.div`
  font-size: ${({ theme }) => theme.fontSize.small};
`;

export const AddressTitle = styled.div`
  color: ${({ theme }) => theme.colors.black.two};
  margin-bottom: 10px;
`;

export const AddressGroup = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.white.two};
  border-radius: 16px;
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
`;

export const AddressLabel = styled.span`
  color: ${({ theme }) => theme.colors.black.two};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 10px;
`;

export const AddressText = styled.span`
  color: ${({ theme }) => theme.colors.gray.twelve};
`;
