import React from 'react';

import CircleProgress from './CircularProgress';
import { Container, Subtitle, Title, TitlesContainer } from './styles';

interface FormStepsProps {
  title: string;
  subtitle?: string;
  currentStep: number;
  stepsLength: number;
}

const FormSteps: React.FC<FormStepsProps> = ({
  title,
  subtitle,
  currentStep,
  stepsLength,
}) => {
  return (
    <Container>
      <CircleProgress
        percentage={((currentStep + 1) / stepsLength) * 100}
        text={`${currentStep + 1} de ${stepsLength}`}
      />
      <TitlesContainer>
        <Title>{title}</Title>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </TitlesContainer>
    </Container>
  );
};

export default FormSteps;
