import { useCallback } from 'react';

import { useApi, endpoints } from '@contexts/Api';

import { enums } from '@utils';

const usePosts = () => {
  const { request } = useApi();

  const generatePostBaseObject = useCallback((data: FormValuesVehicleInfo) => {
    return {
      store: Number(data.store.value),
      vehicle_brand: Number(data.brand.value),
      vehicle_model: Number(data.model.value),
      vehicle_manufacturing_year: Number(data.manufacturingYear),
      vehicle_year: Number(data.modelYear),
      vehicle_version: Number(data.version.value),
      tags: [
        Number(data.color.value),
        Number(data.fuel.value),
        Number(data.numberOfDoors.value),
      ],
      is_armored: data.isArmored === 'true',
    };
  }, []);

  const listBrands = useCallback(
    async <BrandFields extends keyof Brand>(params: BrandQueryParams) => {
      const response = await request<Paginated<Brand, BrandFields>>({
        method: 'get',
        url: endpoints.posts.brands.list,
        params: {
          ...params,
          categories: params.categories.join(','),
          fields: params.fields.join(','),
        },
      });

      return response.data;
    },
    [request]
  );

  const listModels = useCallback(
    async <ModelFields extends keyof Model>(params: ModelQueryParams) => {
      const response = await request<Paginated<Model, ModelFields>>({
        method: 'get',
        url: endpoints.posts.models.list,
        params: {
          ...params,
          fields: params.fields.join(','),
        },
      });

      return response.data;
    },
    [request]
  );

  const listModelVersions = useCallback(
    async <VersionFields extends keyof Version>(params: VersionQueryParams) => {
      const response = await request<Paginated<Version, VersionFields>>({
        method: 'get',
        url: endpoints.posts.modelVersions.list,
        params: {
          ...params,
          fields: params.fields.join(','),
        },
      });

      return response.data;
    },
    [request]
  );

  const listTags = useCallback(
    async <TagFields extends keyof Tag>(params: TagQueryParams) => {
      const response = await request<Array<Pick<Tag, TagFields>>>({
        method: 'get',
        url: endpoints.posts.tags.list,
        params: {
          ...params,
          paginate: false,
        },
      });

      return response.data;
    },
    [request]
  );

  const listPaginatedTags = useCallback(
    async <TagFields extends keyof Tag>(params: PaginatedTagQueryParams) => {
      const response = await request<Paginated<Tag, TagFields>>({
        method: 'get',
        url: endpoints.posts.tags.list,
        params: {
          ...params,
          fields: params.fields.join(','),
        },
      });

      return response.data;
    },
    [request]
  );

  const createPost = useCallback(
    async (data: FormValuesVehicleInfo) => {
      const response = await request<Post>({
        method: 'post',
        url: endpoints.posts.post,
        data: generatePostBaseObject(data),
      });

      return response.data;
    },
    [generatePostBaseObject, request]
  );

  const updatePost = useCallback(
    async (id: number, data: FormValuesPost) => {
      const postBaseObject = generatePostBaseObject(data);

      const response = await request<Post>({
        method: 'patch',
        url: endpoints.posts.patch.replace(':id', id.toString()),
        data: {
          ...postBaseObject,
          tags: [
            ...postBaseObject.tags,
            ...(data.tags?.map((tag) => Number(tag)) ?? []),
          ],
          description: data.description || undefined,
          vehicle_price: data.price
            ? Number(data.price.replace(/\D/g, ''))
            : undefined,
          is_complete: data.isComplete,
        },
      });

      return response.data;
    },
    [generatePostBaseObject, request]
  );

  const listVehicleConditions = useCallback(
    async <VehicleConditionFields extends keyof VehicleCondition>(
      params: VehicleConditionQueryParams
    ) => {
      const response = await request<
        Array<Pick<VehicleCondition, VehicleConditionFields>>
      >({
        method: 'get',
        url: endpoints.posts.vehicleConditions.list,
        params: {
          ...params,
          paginate: false,
          fields: params.fields.join(','),
        },
      });

      return response.data;
    },
    [request]
  );

  const updateVehicleCondition = useCallback(
    async (id: number, state: enums.VehicleConditionState) => {
      const response = await request<Array<VehicleCondition>>({
        method: 'patch',
        url: endpoints.posts.vehicleConditions.patch.replace(
          ':id',
          id.toString()
        ),
        data: {
          state,
        },
      });

      return response.data;
    },
    [request]
  );

  const createPostImage = useCallback(
    async (data: FormValuesPostImage) => {
      const response = await request<PostImage>({
        method: 'post',
        url: endpoints.posts.images.post,
        data,
      });

      return response.data;
    },
    [request]
  );

  const getSuggestedPrices = useCallback(
    async (post: number) => {
      const response = await request<SuggestedPrice>({
        method: 'get',
        url: endpoints.posts.suggestedPrices.get.replace(
          ':id',
          post.toString()
        ),
      });

      return response.data;
    },
    [request]
  );

  const getFipeTable = useCallback(
    async (post: number) => {
      const response = await request<FipeTable>({
        method: 'get',
        url: endpoints.posts.fipeTable.get.replace(':id', post.toString()),
      });

      return response.data;
    },
    [request]
  );

  const listPostImages = useCallback(
    async <PostImageFields extends keyof PostImage>(
      params: PostImageQueryParams
    ) => {
      const response = await request<Array<Pick<PostImage, PostImageFields>>>({
        method: 'get',
        url: endpoints.posts.images.list,
        params: {
          ...params,
          paginate: false,
          fields: params.fields.join(','),
        },
      });

      return response.data;
    },
    [request]
  );

  const deletePostImage = useCallback(
    async (id: number) => {
      const response = await request<void>({
        method: 'delete',
        url: endpoints.posts.images.delete.replace(':id', id.toString()),
      });

      return response.data;
    },
    [request]
  );

  const completePost = useCallback(
    async (id: number) => {
      const response = await request<Post>({
        method: 'post',
        url: endpoints.posts.complete.replace(':id', id.toString()),
      });

      return response.data;
    },
    [request]
  );

  const listPosts = useCallback(
    async <PostFields extends keyof Post>(params: PostsQuery) => {
      const response = await request<Paginated<Post, PostFields>>({
        method: 'get',
        url: endpoints.posts.list,
        params: {
          fields: params.fields.join(','),
          page: params.page,
          page_size: params.pageSize,
          search: params.search,
          is_complete: params.isComplete,
          store__address__city__state: params.state,
          store__address__city: params.city,
          store: params.store,
          status: params.status,
          modified__lte: params.modifiedLte || undefined,
          modified__gte: params.modifiedGte || undefined,
          customers: params.lead,
        },
      });

      return response.data;
    },
    [request]
  );

  const getPost = useCallback(
    async (id: number) => {
      const response = await request<Post>({
        method: 'get',
        url: endpoints.posts.get.replace(':id', id.toString()),
      });

      return response.data;
    },
    [request]
  );

  const listVehicleCategories = useCallback(
    async <VehicleCategoryFields extends keyof VehicleCategory>(
      params: Omit<QueryParams<VehicleCategory>, 'page'>
    ) => {
      const response = await request<
        Array<Pick<VehicleCategory, VehicleCategoryFields>>
      >({
        method: 'get',
        url: endpoints.posts.vehicleCategories.list,
        params: {
          ...params,
          paginate: false,
          fields: params.fields.join(','),
        },
      });

      return response.data;
    },
    [request]
  );

  const getVehicleCategory = useCallback(
    async (id: number) => {
      const response = await request<VehicleCategory>({
        method: 'get',
        url: endpoints.posts.vehicleCategories.get.replace(
          ':id',
          id.toString()
        ),
      });

      return response.data;
    },
    [request]
  );

  const deletePost = useCallback(
    async (post: number) => {
      const response = await request<void>({
        method: 'delete',
        url: endpoints.posts.delete.replace(':id', post.toString()),
      });

      return response.data;
    },
    [request]
  );

  const inactivatePost = useCallback(
    async (id: number) => {
      const response = await request<void>({
        method: 'post',
        url: endpoints.posts.inactivate.replace(':id', id.toString()),
      });

      return response.data;
    },
    [request]
  );

  const activatePost = useCallback(
    async (id: number) => {
      const response = await request<void>({
        method: 'post',
        url: endpoints.posts.activate.replace(':id', id.toString()),
      });

      return response.data;
    },
    [request]
  );

  const listCustomFields = useCallback(
    async (params: CustomFieldQueryParams) => {
      const response = await request<Array<CustomField>>({
        method: 'get',
        url: endpoints.posts.customFields.list,
        params: {
          ...params,
          paginate: false,
        },
      });

      return response.data;
    },
    [request]
  );

  const listCustomFieldAnswers = useCallback(
    async <CustomFieldAnswersFields extends keyof CustomFieldAnswer>(
      params: CustomFieldAnswerQueryParams
    ) => {
      const response = await request<
        Array<Pick<CustomFieldAnswer, CustomFieldAnswersFields>>
      >({
        method: 'get',
        url: endpoints.posts.customFieldAnswers.list,
        params: {
          ...params,
          fields: params.fields.join(','),
          paginate: false,
        },
      });

      return response.data;
    },
    [request]
  );

  const updatePostCustomFieldAnswers = useCallback(
    async (data: {
      post: number;
      customFieldsAnswers: Array<{
        customField: number;
        answer?: string;
      }>;
    }) => {
      const response = await request<void>({
        method: 'post',
        url: endpoints.posts.customFieldAnswers.answer,
        data: {
          post: data.post,
          custom_fields_answers: data.customFieldsAnswers.map((answer) => ({
            custom_field: answer.customField,
            answer: answer.answer,
          })),
        },
      });

      return response.data;
    },
    [request]
  );

  const inspectPost = useCallback(
    async (id: number, document: number) => {
      const response = await request<void>({
        method: 'post',
        url: endpoints.posts.inspect.post.replace(':id', id.toString()),
        data: {
          inspection_document: document,
        },
      });

      return response.data;
    },
    [request]
  );

  const deleteInspection = useCallback(
    async (id: number) => {
      const response = await request<void>({
        method: 'delete',
        url: endpoints.posts.inspect.delete.replace(':id', id.toString()),
      });

      return response.data;
    },
    [request]
  );

  const approvePost = useCallback(
    async (id: number) => {
      const response = await request<void>({
        method: 'post',
        url: endpoints.posts.approve.replace(':id', id.toString()),
      });

      return response.data;
    },
    [request]
  );

  const rejectPost = useCallback(
    async (id: number, rejectionReason: string) => {
      const response = await request<void>({
        method: 'post',
        url: endpoints.posts.reject.replace(':id', id.toString()),
        data: {
          rejection_reason: rejectionReason,
        },
      });

      return response.data;
    },
    [request]
  );

  const searchByPlate = useCallback(
    async (category: number, plate: string) => {
      const response = await request<PlateResponse>({
        method: 'post',
        url: endpoints.posts.plate,
        data: {
          category,
          plate: plate.toUpperCase(),
        },
      });

      return response.data;
    },
    [request]
  );

  const searchByChassi = useCallback(
    async (category: number, chassi: string) => {
      const response = await request<ChassiResponse>({
        method: 'post',
        url: endpoints.posts.chassis,
        data: {
          category,
          chassis: chassi.toUpperCase(),
        },
      });

      return response.data;
    },
    [request]
  );

  const bulkingAprove = useCallback(
    async (posts: Array<number>) => {
      const response = await request<void>({
        method: 'post',
        url: endpoints.posts.bulkingApprove,
        data: { posts },
      });

      return response.data;
    },
    [request]
  );

  return {
    listBrands,
    listModels,
    listModelVersions,
    listTags,
    listPaginatedTags,
    createPost,
    updatePost,
    listVehicleConditions,
    updateVehicleCondition,
    createPostImage,
    getSuggestedPrices,
    getFipeTable,
    listPostImages,
    deletePostImage,
    completePost,
    listPosts,
    getPost,
    listVehicleCategories,
    getVehicleCategory,
    deletePost,
    inactivatePost,
    activatePost,
    listCustomFields,
    listCustomFieldAnswers,
    updatePostCustomFieldAnswers,
    inspectPost,
    deleteInspection,
    approvePost,
    rejectPost,
    searchByPlate,
    searchByChassi,
    bulkingAprove,
  };
};

export default usePosts;
