import styled from 'styled-components';

const Label = styled.label`
  width: 100%;
  margin-bottom: 8px;
  color: ${(props) => props.theme.colors.gray.twelve};
  font-weight: ${(props) => props.theme.fontWeight.semiBold};
`;

export default Label;
