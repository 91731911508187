import React, { useRef } from 'react';

import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useToast } from '@contexts/Toast';
import { useAdvertisements } from '@hooks';

import BaseForm from '@components/BaseForm';
import PageHeader from '@components/PageHeader';

import RoutesPath from '@router/routes';

import AdvertisementInfo from '../Form/AdvertisementInfo';
import routes from './routes';

const CreateAdvertisement = () => {
  const advertisementInfoRef = useRef<FormStepRef>(null);

  const { createAdvertisement } = useAdvertisements();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const createRequest = useMutation(createAdvertisement, {
    onSuccess: () => {
      addToast('Publicidade criada com sucesso', 'success');
      navigate(RoutesPath.private.advertisements.list, { replace: true });
      queryClient.invalidateQueries('advertisements');
    },
  });

  return (
    <>
      <PageHeader title="Cadastro de publicidade" routes={routes} />
      <BaseForm
        steps={[
          {
            title: 'Dados da publicidade',
            ref: advertisementInfoRef,
            component: AdvertisementInfo,
          },
        ]}
        createRequest={createRequest}
      />
    </>
  );
};

export default CreateAdvertisement;
