import React, { forwardRef, useImperativeHandle } from 'react';

import { FormikProvider, useFormik } from 'formik';
import { SystemManagerRole, SystemManagerRoleMap } from 'utils/enums';
import * as yup from 'yup';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';
import Select from '@components/Select';

import { errors } from '@utils';

interface UserInfoProps {
  initialValues?: FormValuesUserInfo;
  onFinish: (data: FormValuesUserInfo) => void;
}

const UserInfo: React.ForwardRefRenderFunction<FormStepRef, UserInfoProps> = (
  { initialValues, onFinish },
  ref
) => {
  const formikInitialValues: FormValuesUserInfo = {
    name: '',
    email: '',
    role: SystemManagerRole.SUPPORT,
    ...initialValues,
  };

  const rolesArray = Object.values(SystemManagerRole);

  const formikValidationSchema = yup.object().shape({
    name: yup.string().trim().required(errors.required),
    email: yup.string().trim().email(errors.email).required(errors.required),
    role: yup.string().trim().oneOf(rolesArray).required(errors.required),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  return (
    <FormikProvider value={formik}>
      <FormRow>
        <FormGroup>
          <Label htmlFor="name">Nome</Label>
          <Input
            type="text"
            name="name"
            placeholder="Digite aqui"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            invalidValue={!!formik.touched.name && !!formik.errors.name}
          />
          <FormError name="name" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="email">E-mail</Label>
          <Input
            type="text"
            name="email"
            placeholder="Digite aqui"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            invalidValue={!!formik.touched.email && !!formik.errors.email}
          />
          <FormError name="email" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="role">Cargo (nível de acesso)</Label>
          <Select
            name="role"
            placeholder="Digite aqui"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.role}
            invalidValue={!!formik.touched.role && !!formik.errors.role}
          >
            {rolesArray.map((role) => (
              <option key={role} value={role}>
                {SystemManagerRoleMap[role]}
              </option>
            ))}
          </Select>
          <FormError name="role" />
        </FormGroup>
      </FormRow>
    </FormikProvider>
  );
};

export default forwardRef(UserInfo);
