import React, { forwardRef, useImperativeHandle } from 'react';

import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import { errors } from '@utils';

import BasePlanInfo from '../BasePlanInfo';

interface PlanInfoProps {
  initialValues?: FormValuesEditPlanInfo;
  onFinish: (data: FormValuesEditPlanInfo) => void;
}

const PlanInfo: React.ForwardRefRenderFunction<FormStepRef, PlanInfoProps> = (
  { initialValues, onFinish },
  ref
) => {
  const formikInitialValues: FormValuesEditPlanInfo = {
    name: '',
    description: '',
    isHidden: false,
    ...initialValues,
  };

  const formikValidationSchema = yup.object().shape({
    name: yup.string().trim().required(errors.required),
    description: yup.string().trim(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  return (
    <FormikProvider value={formik}>
      <BasePlanInfo />
    </FormikProvider>
  );
};

export default forwardRef(PlanInfo);
