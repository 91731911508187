import styled from 'styled-components';

export const PlateQuestion = styled.p`
  margin-top: 8px;
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.colors.main};
  cursor: pointer;
`;

export const PlateQuestionHighlight = styled.b`
  color: ${({ theme }) => theme.colors.gray.eight};
`;
