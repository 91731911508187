import { B2Card } from 'react-b2components';
import { MdGroup } from 'react-icons/md';
import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const MainCard = styled(B2Card)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;

  @media (max-width: 930px) {
    flex-direction: column;
  }
`;

export const MainImage = styled.img`
  height: 240px;
  max-width: 100%;
  object-fit: contain;
  border-radius: 8px;
  align-self: center;
`;

export const MainCardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const PostTitle = styled.span`
  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.black.two};
`;

export const Subtitle = styled.span`
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.gray.five};
`;

export const Description = styled.span`
  font-size: ${({ theme }) => theme.fontSize.smaller};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.gray.twelve};
  word-break: break-all;

  a {
    color: ${({ theme }) => theme.colors.info};
  }
`;

export const Divider = styled.hr`
  margin: 24px 0px;
  border-color: ${({ theme }) => theme.colors.white.three};
  border-style: solid;
`;

export const ExtraInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ExtraInfo = styled.span`
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  color: ${({ theme }) => theme.colors.gray.twelve};

  b {
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  }
`;

export const MetricCard = styled(B2Card)`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const MetricTitle = styled.span`
  font-size: ${({ theme }) => theme.fontSize.regular};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.gray.eight};
`;

export const MetricValue = styled.span`
  font-size: ${({ theme }) => theme.fontSize.big};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.black.two};
`;

export const MetricIcon = styled(MdGroup)`
  position: absolute;
  top: 20px;
  right: 20px;
  flex-shrink: 0;
  color: ${({ theme }) => theme.colors.info};
`;
