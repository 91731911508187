import React, { useState } from 'react';

import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useAuthentication } from '@hooks';

import { Content } from '@components/BaseForm/styles';
import FiltersContainer from '@components/FiltersContainer';
import OrderingButton from '@components/OrderingButton';
import PageHeader from '@components/PageHeader';
import SearchFilter from '@components/SearchFilter';
import SelectFilter from '@components/SelectFilter';
import UserTable, {
  ListUserWithRoleFields,
  UserWithRoleFields,
} from '@components/UserTable';

import RoutesPath from '@router/routes';
import { enums } from '@utils';

const ListUsers: React.FC = () => {
  const [queryParams, setQueryParams] = useState<SystemManagersQuery>({
    page: 1,
    fields: UserWithRoleFields as unknown as Array<keyof StoreUser>,
  });

  const { listSystemManagers } = useAuthentication();
  const navigate = useNavigate();

  const fetchUsers = useQuery(['users', queryParams], () =>
    listSystemManagers<ListUserWithRoleFields>(queryParams)
  );

  return (
    <>
      <PageHeader
        title="Usuários"
        subtitle="Aqui estão todos os usuários cadastrados no sistema"
        primaryButton={{
          text: 'Cadastrar usuário',
          onClick: () => {
            navigate(RoutesPath.private.users.create);
          },
        }}
      />
      <Content>
        <FiltersContainer>
          <SearchFilter
            onSearch={(search) => {
              setQueryParams((prevState) => ({
                ...prevState,
                search,
              }));
            }}
          />
          <SelectFilter
            data-cy="role-filter"
            onChange={(event) => {
              setQueryParams((prevState) => ({
                ...prevState,
                role: event.target.value as enums.SystemManagerRole,
              }));
            }}
            value={queryParams.role}
          >
            <option value="">Cargo: Todos</option>
            {Object.values(enums.SystemManagerRole).map((role) => (
              <option key={role} value={role}>
                {enums.SystemManagerRoleMap[role]}
              </option>
            ))}
          </SelectFilter>
          <OrderingButton
            isActive={queryParams.ordering === 'name'}
            onClick={() => {
              setQueryParams((prevState) => ({
                ...prevState,
                ordering: prevState.ordering === 'name' ? '' : 'name',
              }));
            }}
          />
        </FiltersContainer>
        <UserTable
          fetchUsers={fetchUsers}
          page={queryParams.page}
          onChangePage={(newPage) => {
            setQueryParams((prevState) => ({
              ...prevState,
              page: newPage,
            }));
          }}
          roleMap={enums.SystemManagerRoleMap}
          onClick={(user) => {
            navigate(
              RoutesPath.private.users.detail.replace(
                ':userId',
                user.id.toString()
              )
            );
          }}
        />
      </Content>
    </>
  );
};

export default ListUsers;
