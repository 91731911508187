import { color } from 'b2utils';
import { B2Table } from 'react-b2components';
import styled, { StyledComponent, css } from 'styled-components';

import EmptyAnimation from '@components/EmptyAnimation';
import ErrorAnimation from '@components/ErrorAnimation';
import Loading from '@components/Loading';

export const TableStyled = styled(B2Table)<{ canHover: boolean }>`
  color: ${(props) => props.theme.colors.gray.ten};
  overflow-x: auto;
  table {
    border-spacing: 0 !important;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.06);
    border-collapse: collapse;
  }

  thead,
  tr {
    height: 64px;
    border-bottom: 1px solid ${(props) => props.theme.colors.gray.one};
    background-color: ${(props) => props.theme.colors.white.main};
  }

  ${(props) =>
    props.canHover &&
    css`
      tbody > tr {
        cursor: pointer;
      }

      tbody > tr:hover {
        background-color: ${(props) =>
          color.addOpacityOnHexColor(props.theme.colors.secondary, 0.1)};
      }
    `}

  th > div {
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: row;
    font-size: ${(props) => props.theme.fontSize.small};
  }

  td {
    text-align: center;
    max-width: 200px;
    min-width: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 10px;
    font-size: 12px;
  }
` as StyledComponent<
  React.ComponentType<any>,
  typeof B2Table & { canHover: boolean }
>;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
`;

export const TableTotalCountContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const TableTotalCount = styled.span`
  background-color: ${(props) => props.theme.colors.white.main};
  padding: 8px 10px;
  border-radius: ${(props) => props.theme.borderRadius};
  border: 1px solid ${(props) => props.theme.colors.gray.one};
  color: ${(props) => props.theme.colors.gray.ten};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  margin-bottom: 12px;
`;

export const TableLoading = styled(Loading)`
  margin-top: 24px;
`;

export const TableError = styled(ErrorAnimation)`
  margin-top: 24px;
`;

export const TableEmpty = styled(EmptyAnimation)`
  margin-top: 24px;
`;
