import React from 'react';

import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { SystemManagerRoleMap } from 'utils/enums';

import { useToast } from '@contexts/Toast';
import { useAuthentication } from '@hooks';

import DetailPersonCard from '@components/DetailPersonCard';
import PageHeader from '@components/PageHeader';
import RequestContainer from '@components/RequestContainer';

import RoutesPath from '@router/routes';

import routes from './routes';

const DetailUser = () => {
  const { userId } = useParams<{ userId: string }>();
  const navigate = useNavigate();
  const { getSystemManager, inactivateSystemManagers, activateSystemManagers } =
    useAuthentication();
  const { addToast } = useToast();

  const fetchUser = useQuery(
    ['user', userId],
    () => getSystemManager(Number(userId)),
    {
      enabled: !!userId,
    }
  );

  const requestUpdateUserStatus = useMutation(
    () => {
      if (fetchUser.data?.is_active) {
        return inactivateSystemManagers(Number(userId));
      }

      return activateSystemManagers(Number(userId));
    },
    {
      onSuccess: () => {
        addToast('Status alterado com sucesso', 'success');
        fetchUser.refetch();
      },
      onError: () => {
        addToast('Falha ao alterar o status', 'error');
      },
    }
  );

  return (
    <RequestContainer requests={[fetchUser]}>
      <>
        <PageHeader
          title="Detalhes do usuário"
          routes={routes}
          primaryButton={{
            text: 'Editar usuário',
            onClick: () =>
              navigate(
                RoutesPath.private.users.edit.replace(
                  ':userId',
                  userId!.toString()
                )
              ),
          }}
        />
        {fetchUser.data && (
          <DetailPersonCard
            avatar={fetchUser.data.avatar?.medium}
            title={fetchUser.data.name}
            infos={[
              {
                label: 'Permissão',
                value: SystemManagerRoleMap[fetchUser.data.role],
              },
              {
                label: 'E-mail',
                value: fetchUser.data.email,
              },
            ]}
            isActive={!!fetchUser.data.is_active}
            requestUpdateStatus={requestUpdateUserStatus.mutate}
          />
        )}
      </>
    </RequestContainer>
  );
};

export default DetailUser;
