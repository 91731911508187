import React from 'react';

import useAnalytics from 'hooks/Analytics';
import { MdVerified } from 'react-icons/md';
import { useQuery } from 'react-query';
import { useTheme } from 'styled-components';

import InformativeCard from '@components/InformativeCard';

const ActivePosts: React.FC = () => {
  const theme = useTheme();
  const { getActivePostsCount } = useAnalytics();

  const fetchActivePostsCount = useQuery(['activePostsCount'], () =>
    getActivePostsCount()
  );

  return (
    <InformativeCard
      dataCy="active-posts-card"
      title="Anúncios ativos"
      value={(fetchActivePostsCount.data?.count ?? 0).toString()}
      icon={{
        component: MdVerified,
        color: theme.colors.secondary,
      }}
      request={fetchActivePostsCount}
    />
  );
};

export default ActivePosts;
