import React from 'react';

import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import BaseModal from '@components/BaseModal';
import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Label from '@components/Label';
import ModalFooterButtons from '@components/ModalFooterButtons';
import TextArea from '@components/TextArea';

import { errors } from '@utils';

import { BodyMessage, FormContainer } from './styles';

interface RejectionModalProps {
  title: string;
  message?: string;
  isOpen: boolean;
  isLoading: boolean;
  confirmButtonText: string;
  onConfirm: (rejectionReason: string) => void;
  cancelButtonText: string;
  onClose: () => void;
  onCancel: () => void;
}

const RejectionModal: React.FC<RejectionModalProps> = ({
  title,
  message,
  isOpen,
  isLoading,
  confirmButtonText,
  onConfirm,
  cancelButtonText,
  onClose,
  onCancel,
}) => {
  const formik = useFormik({
    initialValues: {
      rejectionReason: '',
    },
    validationSchema: yup.object({
      rejectionReason: yup.string().trim().required(errors.required),
    }),
    onSubmit: ({ rejectionReason }, { resetForm }) => {
      onConfirm(rejectionReason);
      resetForm();
    },
  });

  return (
    <BaseModal
      isOpen={isOpen}
      title={title}
      onClose={() => {
        onClose();
        formik.resetForm();
      }}
    >
      {message && <BodyMessage>{message}</BodyMessage>}
      <FormikProvider value={formik}>
        <FormContainer>
          <FormRow>
            <FormGroup>
              <Label>Qual o motivo da rejeição?</Label>
              <TextArea
                name="rejectionReason"
                placeholder="Digite aqui"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.rejectionReason}
                invalidValue={
                  !!formik.touched.rejectionReason &&
                  !!formik.errors.rejectionReason
                }
              />
              <FormError name="rejectionReason" />
            </FormGroup>
          </FormRow>
        </FormContainer>
      </FormikProvider>
      <ModalFooterButtons
        isLoading={isLoading}
        confirmText={confirmButtonText}
        onConfirm={formik.handleSubmit}
        cancelText={cancelButtonText}
        onCancel={() => {
          onCancel();
          formik.resetForm();
        }}
      />
    </BaseModal>
  );
};

export default RejectionModal;
