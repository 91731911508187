import React, { useState } from 'react';

import { useFormikContext } from 'formik';
import { useInfiniteQuery } from 'react-query';
import { getNextPageParam } from 'utils/helpers';

import { useToast } from '@contexts/Toast';
import { usePosts } from '@hooks';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import Label from '@components/Label';
import SearchSelect from '@components/SearchSelect';

interface VersionSelectProps {
  model: number;
}

const VersionSelect: React.FC<VersionSelectProps> = ({ model }) => {
  const [search, setSearch] = useState('');

  const formik = useFormikContext<FormValuesVehicleInfo>();

  const { listModelVersions } = usePosts();
  const { addToast } = useToast();

  const {
    data: versions,
    isLoading,
    fetchNextPage,
  } = useInfiniteQuery(
    ['versions', search, model],
    ({ pageParam = 1 }) =>
      listModelVersions({
        fields: ['id', 'name'],
        page: pageParam,
        search,
        model,
      }),
    {
      getNextPageParam,
      onError: () => {
        addToast('Falha ao carregar as versões de veículos.', 'error');
      },
      enabled: !!model,
    }
  );

  return (
    <FormGroup>
      <Label htmlFor="version">Versão</Label>
      <SearchSelect
        name="version"
        onSearch={setSearch}
        options={
          versions?.pages
            .flatMap((page) => page.results)
            .map((version) => ({
              label: version.name,
              value: version.id.toString(),
            })) || []
        }
        value={formik.values.version.label}
        onChange={(value) => {
          setSearch('');
          formik.setFieldValue('version', value);
        }}
        isLoading={isLoading}
        fetchMore={fetchNextPage}
        onBlur={() => formik.setFieldTouched('version.value', true)}
        invalidValue={
          !!formik.touched.version?.value && !!formik.errors.version?.value
        }
        disabled={!formik.values.model.value}
      />
      <FormError name="version.value" />
    </FormGroup>
  );
};

export default VersionSelect;
