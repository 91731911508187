import { useCallback } from 'react';

import { endpoints, useApi } from '@contexts/Api';

const useAnalytics = () => {
  const { request } = useApi();

  const getChargesBalance = useCallback(
    async ({ since, until }: Period) => {
      const response = await request<ChargesBalance>({
        method: 'get',
        url: endpoints.analytics.charges.balance,
        params: {
          created__gte: since,
          created__lte: until,
        },
      });

      return response.data;
    },
    [request]
  );

  const getSubscriptionsCount = useCallback(
    async (period: Period) => {
      const response = await request<SubscriptionsCount>({
        method: 'get',
        url: endpoints.analytics.subscriptions.count,
        params: {
          created__gte: period.since,
          created__lte: period.until,
        },
      });

      return response.data;
    },
    [request]
  );

  const getSubscriptionsHistogram = useCallback(async () => {
    const response = await request<SubscriptionsHistogram>({
      method: 'get',
      url: endpoints.analytics.subscriptions.histogram,
    });

    return response.data;
  }, [request]);

  const getActivePostsCount = useCallback(async () => {
    const response = await request<ActivePostsCountResponse>({
      method: 'get',
      url: endpoints.analytics.post.activeCount,
    });

    return response.data;
  }, [request]);

  const getPostsAvgClicksInPhone = useCallback(async () => {
    const response = await request<PostAvgClicksInPhoneResponse>({
      method: 'get',
      url: endpoints.analytics.post.avgClicksInPhone,
    });

    return response.data;
  }, [request]);

  const getActiveStoresHistogram = useCallback(async () => {
    const response = await request<ActiveStoresHistogramResponse>({
      method: 'get',
      url: endpoints.analytics.stores.activeHistogram,
    });

    return response.data;
  }, [request]);

  return {
    getChargesBalance,
    getSubscriptionsCount,
    getSubscriptionsHistogram,
    getActivePostsCount,
    getPostsAvgClicksInPhone,
    getActiveStoresHistogram,
  };
};

export default useAnalytics;
