import React from 'react';

import { B2Switch, IB2Switch } from 'react-b2components';

import { SwitchContainer } from './styles';

const YesOrNoSwitch: React.FC<IB2Switch> = (props) => {
  return (
    <SwitchContainer>
      <p>Não</p>
      <B2Switch {...props} />
      <p>Sim</p>
    </SwitchContainer>
  );
};

export default YesOrNoSwitch;
