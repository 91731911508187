import React from 'react';

import { useFormikContext } from 'formik';

import ClientsSearchSelect from '@components/ClientsSearchSelect';
import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import Label from '@components/Label';

const ClientsSelect = () => {
  const formik = useFormikContext<FormValuesVehicleInfo>();

  return (
    <FormGroup>
      <Label htmlFor="store">Cliente</Label>
      <ClientsSearchSelect
        name="store"
        value={formik.values.store.label}
        onChange={(value) => {
          formik.setFieldValue('store', value);
        }}
        onBlur={() => formik.setFieldTouched('store.value', true)}
        invalidValue={
          !!formik.touched.store?.value && !!formik.errors.store?.value
        }
      />
      <FormError name="store.value" />
    </FormGroup>
  );
};

export default ClientsSelect;
