import React, { forwardRef, useImperativeHandle } from 'react';

import { currency } from 'b2utils';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';

import { errors } from '@utils';

interface PriceFormProps {
  initialValues: FormValuesPrice;
  onFinish: (data: FormValuesPrice) => void;
}

const PriceForm: React.ForwardRefRenderFunction<FormStepRef, PriceFormProps> = (
  { initialValues, onFinish },
  ref
) => {
  const formikValidationSchema = yup.object().shape({
    price: yup.string().trim().required(errors.required),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: formikValidationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  return (
    <FormikProvider value={formik}>
      <FormRow>
        <FormGroup>
          <Label htmlFor="price">Preço</Label>
          <Input
            type="text"
            name="price"
            placeholder="Digite aqui"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={
              formik.values.price ? currency.brlMask(formik.values.price) : ''
            }
            invalidValue={!!formik.touched.price && !!formik.errors.price}
          />
          <FormError name="price" />
        </FormGroup>
      </FormRow>
    </FormikProvider>
  );
};

export default forwardRef(PriceForm);
