import React, { useRef } from 'react';

import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useToast } from '@contexts/Toast';
import { useStores } from '@hooks';

import BaseForm from '@components/BaseForm';
import PageHeader from '@components/PageHeader';

import RoutesPath from '@router/routes';

import FaqInfo from '../Form/FaqInfo';
import routes from './routes';

const CreateFaq: React.FC = () => {
  const faqInfoRef = useRef<FormStepRef>(null);

  const { createFaq } = useStores();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const createRequest = useMutation(createFaq, {
    onSuccess: () => {
      addToast('FAQ adicionado com sucesso', 'success');
      navigate(RoutesPath.private.faq.list, { replace: true });
      queryClient.invalidateQueries('faq');
    },
  });

  return (
    <>
      <PageHeader title="Cadastro de FAQ" routes={routes} />
      <BaseForm
        steps={[
          {
            title: 'Dados do pergunta',
            ref: faqInfoRef,
            component: FaqInfo,
          },
        ]}
        createRequest={createRequest}
      />
    </>
  );
};

export default CreateFaq;
