import React, { useState } from 'react';

import { AxiosError } from 'axios';
import { transformObjectInArray } from 'b2utils';
import { FormikProvider, useFormik } from 'formik';
import { useMutation } from 'react-query';
import * as yup from 'yup';

import { useToast } from '@contexts/Toast';
import { useAuthentication } from '@hooks';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import Input from '@components/Input';
import Label from '@components/Label';
import PasswordInput from '@components/PasswordInput';
import SectionHeader from '@components/SectionHeader';
import SuccessModal from '@components/SuccessModal';

import { errors } from '@utils';

import { Footer, FooterButton, ProfileCard } from '../styles';

interface ChangeEmailParams {
  email: string;
  confirmEmail: string;
  password: string;
}

export const UpdateEmail = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { updateEmail } = useAuthentication();
  const { addToast } = useToast();

  const changeEmailRequest = useMutation(
    ({ email, password }: ChangeEmailParams) => updateEmail(email, password),
    {
      onSuccess: () => {
        setIsModalOpen(true);
        formik.resetForm();
      },
      onError: ({ response }: AxiosError) => {
        if (response?.data) {
          const messages = transformObjectInArray(response.data);
          messages.forEach((message) => addToast(message, 'error'));
        }
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      email: '',
      confirmEmail: '',
      password: '',
    },
    validationSchema: yup.object({
      email: yup.string().trim().email(errors.email).required(errors.required),
      confirmEmail: yup
        .string()
        .trim()
        .email(errors.email)
        .equals([yup.ref('email')], errors.emailConfirm)
        .required(errors.required),
      password: yup.string().required(errors.required),
    }),
    onSubmit: (values) => changeEmailRequest.mutate(values),
  });

  return (
    <>
      <ProfileCard>
        <SectionHeader title="Atualizar Email" />
        <FormikProvider value={formik}>
          <FormGroup>
            <Label htmlFor="email">Novo email</Label>
            <Input
              type="email"
              id="email"
              name="email"
              placeholder="Novo e-mail"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            <FormError name="email" />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="confirmEmail">Confirmação do novo e-mail</Label>
            <Input
              type="email"
              id="confirmEmail"
              name="confirmEmail"
              placeholder="Confirme a nova senha"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirmEmail}
            />
            <FormError name="confirmEmail" />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="password">Senha</Label>
            <PasswordInput
              name="password"
              placeholder="Nova senha"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            <FormError name="password" />
          </FormGroup>
        </FormikProvider>
      </ProfileCard>
      <Footer>
        <FooterButton
          type="submit"
          variant="primary"
          onClick={() => formik.handleSubmit()}
        >
          Salvar
        </FooterButton>
      </Footer>
      <SuccessModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Mudança solicitada"
        message="Verifique seu novo e-mail para confirmar a alteração"
      />
    </>
  );
};
