import React from 'react';

import BaseImagePicker from '@components/BaseImagePicker';
import ImageHorizontalList, {
  ImageHorizontalListProps,
} from '@components/ImageHorizontalList';
import PickerButton, { PickerButtonProps } from '@components/PickerButton';

interface ImageSinglePickerProps
  extends Pick<
      PickerButtonProps,
      'disabled' | 'disabledMessage' | 'suggestedResolution'
    >,
    Pick<ImageHorizontalListProps, 'onDeleteImage'> {
  onAddImage: (image: Image) => void;
  image?: Image;
  dataCy?: string;
}

const ImageSinglePicker: React.FC<ImageSinglePickerProps> = ({
  onAddImage,
  onDeleteImage,
  image,
  disabled,
  disabledMessage,
  suggestedResolution,
  dataCy,
}) => {
  return (
    <BaseImagePicker
      onAddImages={(images) => onAddImage(images[0])}
      inputAccept="image/JPEG,image/PNG"
      disabled={disabled}
      dataCy={dataCy}
    >
      {({ fileName, openFileExplorer, isLoading }) => (
        <>
          <PickerButton
            fileName={fileName}
            buttonText="Adicionar imagem"
            onButtonClick={openFileExplorer}
            isLoading={isLoading}
            suggestedResolution={suggestedResolution}
            disabled={disabled}
            disabledMessage={disabledMessage}
          />
          <ImageHorizontalList
            dataCy={dataCy}
            images={image ? [image] : []}
            onDeleteImage={onDeleteImage}
          />
        </>
      )}
    </BaseImagePicker>
  );
};

export default ImageSinglePicker;
