import RoutesPath from 'router/routes';

const getRoutes = (planId: string) => {
  return [
    {
      path: RoutesPath.private.plans.list,
      label: 'Lista de planos',
    },
    {
      path: RoutesPath.private.plans.detail.replace(
        ':planId',
        planId.toString()
      ),
      label: 'Detalhes do plano',
    },
    {
      path: RoutesPath.private.plans.edit,
      label: 'Editar plano',
    },
  ];
};

export default getRoutes;
