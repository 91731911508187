import styled from 'styled-components';

import Spinner from '@components/Spinner';

interface ButtonProps {
  disabled?: boolean;
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.small};
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) => theme.colors.white.two};
  overflow: hidden;

  @media (max-width: 580px) {
    flex-direction: column;
  }
`;

export const Button = styled.div<ButtonProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px 20px;
  color: ${({ theme }) => theme.colors.white.main};
  background-color: ${({ theme }) => theme.colors.main};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  svg {
    z-index: 10;
    font-size: ${({ theme }) => theme.fontSize.large};
  }

  &:hover {
    opacity: 0.7;
  }
`;

export const FirstLineContent = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonText = styled.span`
  z-index: 10;
  color: ${({ theme }) => theme.colors.white.main};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const InfoText = styled.span`
  color: ${({ theme }) => theme.colors.white.main};
  font-size: ${({ theme }) => theme.fontSize.smaller};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

export const FileName = styled.div`
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.gray.six};
  font-size: ${({ theme }) => theme.fontSize.small};
`;

export const CustomSpinner = styled(Spinner)`
  background: ${() => `url(/images/logo-white.svg)`};
`;
