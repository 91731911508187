import React, { useState } from 'react';

import { IoMdEye, IoMdEyeOff } from 'react-icons/io';

import Input from '@components/Input';

import { ButtonContainer, Container } from './styles';

const PasswordInput: React.FC<React.InputHTMLAttributes<HTMLInputElement>> = (
  props
) => {
  const [isShowingPassword, setIsShowingPassword] = useState(false);
  return (
    <Container>
      <Input {...props} type={isShowingPassword ? 'text' : 'password'} />
      <ButtonContainer>
        {isShowingPassword ? (
          <IoMdEyeOff onClick={() => setIsShowingPassword(false)} />
        ) : (
          <IoMdEye onClick={() => setIsShowingPassword(true)} />
        )}
      </ButtonContainer>
    </Container>
  );
};

export default PasswordInput;
