import { useCallback } from 'react';

import { cep } from 'b2utils';

import { useApi, brasilApiEndpoints } from '@contexts/Api';

const useBrasilApi = () => {
  const { requestBrasilApi } = useApi();

  const getAddressByCep = useCallback(
    async (value: string) => {
      const unmaskedCep = value.replace(/\D/g, '');
      const maskedCep = cep.mask(unmaskedCep);

      let response;

      try {
        response = await requestBrasilApi<BrasilApiAddress>({
          method: 'get',
          url: brasilApiEndpoints.cep.get.replace(':cep', unmaskedCep),
        });
      } catch (error) {
        response = await requestBrasilApi<BrasilApiAddress>({
          method: 'get',
          url: brasilApiEndpoints.cep.get.replace(':cep', maskedCep),
        });
      }

      return response.data;
    },
    [requestBrasilApi]
  );

  return {
    getAddressByCep,
  };
};

export default useBrasilApi;
