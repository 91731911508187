import React from 'react';

import { useFormikContext } from 'formik';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import Label from '@components/Label';
import Select from '@components/Select';

interface SelectCustomFieldProps {
  customField: CustomField;
}

const SelectCustomField: React.FC<SelectCustomFieldProps> = ({
  customField,
}) => {
  const formik = useFormikContext<FormValuesExtraInfo>();

  return (
    <FormGroup>
      <Label>{customField.name}</Label>
      <Select
        name={`customFields.${customField.id.toString()}.answer`}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.customFields[customField.id]?.answer ?? ''}
        invalidValue={
          !!formik.touched.customFields?.[customField.id]?.answer &&
          !!formik.errors.customFields?.[customField.id]?.answer
        }
      >
        <option value="">Selecione</option>
        {customField.values?.map((option, index) => (
          <option
            key={`customField-${customField.id.toString()}-option-${index}`}
            value={option}
          >
            {option}
          </option>
        ))}
      </Select>
      <FormError name={`customFields.${customField.id.toString()}.answer`} />
    </FormGroup>
  );
};

export default SelectCustomField;
