import { Trash } from 'iconsax-react';
import styled, { css } from 'styled-components';

interface IconProps {
  disabled?: boolean;
}

export const EmptyText = styled.pre`
  margin-right: auto;
  color: ${({ theme }) => theme.colors.gray.twelve};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

export const ImageList = styled.div`
  padding-top: 10px;
  gap: 16px;
  display: flex;
  overflow-x: auto;
`;

export const Anchor = styled.a`
  position: relative;
`;

export const Image = styled.img`
  width: 7.5rem;
  height: 7.5rem;
  object-fit: cover;
  border-radius: ${({ theme }) => theme.borderRadius};

  &:hover {
    opacity: 0.7;
  }
`;

export const DeleteIcon = styled(Trash)<IconProps>`
  position: absolute;
  top: -10px;
  right: -10px;
  color: ${({ theme }) => theme.colors.error};
  font-size: ${({ theme }) => theme.fontSize.large};

  ${({ disabled }) =>
    disabled
      ? css`
          cursor: not-allowed;
        `
      : css`
          cursor: pointer;
          &:hover {
            opacity: 0.7;
          }
        `}
`;
