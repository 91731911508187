import CreateAdvertisement from '@pages/Advertisements/CreateAdvertisement';
import DetailAdvertisement from '@pages/Advertisements/DetailAdvertisement';
import EditAdvertisement from '@pages/Advertisements/EditAdvertisement';
import ListAdvertisements from '@pages/Advertisements/ListAdvertisements';
import CreateClient from '@pages/Clients/CreateClient';
import DetailClient from '@pages/Clients/DetailClient';
import AddPrivilege from '@pages/Clients/DetailClient/AddPrivilege';
import DetailStoreUser from '@pages/Clients/DetailStoreUser';
import EditClient from '@pages/Clients/EditClient';
import ListClients from '@pages/Clients/ListClients';
import ConfirmEmail from '@pages/ConfirmEmail';
import Dashboard from '@pages/Dashboard';
import CreateFaq from '@pages/FAQ/CreateFaq';
import DetailFaq from '@pages/FAQ/DetailFaq';
import EditFaq from '@pages/FAQ/EditFaq';
import ListFaq from '@pages/FAQ/ListFaq';
import Financial from '@pages/Financial';
import DetailLead from '@pages/Leads/DetailLead';
import ListLeads from '@pages/Leads/ListLeads';
import CreatePlan from '@pages/Plan/CreatePlan';
import DetailPlan from '@pages/Plan/DetailPlan';
import EditPlan from '@pages/Plan/EditPlan';
import ListPlans from '@pages/Plan/ListPlans';
import CreatePost from '@pages/Posts/CreatePost';
import DetailPost from '@pages/Posts/DetailPost';
import EditPost from '@pages/Posts/EditPost';
import ListPosts from '@pages/Posts/ListPosts';
import Profile from '@pages/Profile';
import CreateUser from '@pages/Users/CreateUser';
import DetailUser from '@pages/Users/DetailUser';
import EditUser from '@pages/Users/EditUser';
import ListUsers from '@pages/Users/ListUsers';
import Voucher from '@pages/Voucher';
import RoutesPath from '@router/routes';

export default [
  {
    route: RoutesPath.private.home,
    component: Dashboard,
  },
  {
    route: RoutesPath.private.profile,
    component: Profile,
  },
  {
    route: RoutesPath.private.clients.list.physical,
    component: ListClients,
  },
  {
    route: RoutesPath.private.clients.create,
    component: CreateClient,
  },
  {
    route: RoutesPath.private.clients.list.legal,
    component: ListClients,
  },
  {
    route: RoutesPath.private.clients.detail.physical.detail,
    component: DetailClient,
  },
  {
    route: RoutesPath.private.clients.detail.physical.posts.list,
    component: DetailClient,
  },
  {
    route: RoutesPath.private.clients.detail.physical.posts.detail,
    component: DetailPost,
  },
  {
    route: RoutesPath.private.clients.detail.physical.charges,
    component: DetailClient,
  },
  {
    route: RoutesPath.private.clients.detail.legal.detail,
    component: DetailClient,
  },
  {
    route: RoutesPath.private.clients.detail.legal.posts.list,
    component: DetailClient,
  },
  {
    route: RoutesPath.private.clients.detail.legal.posts.detail,
    component: DetailPost,
  },
  {
    route: RoutesPath.private.clients.detail.legal.users.list,
    component: DetailClient,
  },
  {
    route: RoutesPath.private.clients.detail.legal.users.detail,
    component: DetailStoreUser,
  },
  {
    route: RoutesPath.private.clients.detail.legal.charges,
    component: DetailClient,
  },
  {
    route: RoutesPath.private.clients.detail.legal.addPrivilege,
    component: AddPrivilege,
  },
  {
    route: RoutesPath.private.clients.edit,
    component: EditClient,
  },
  {
    route: RoutesPath.private.posts.list.complete,
    component: ListPosts,
  },
  {
    route: RoutesPath.private.posts.list.draft,
    component: ListPosts,
  },
  {
    route: RoutesPath.private.posts.detail,
    component: DetailPost,
  },
  {
    route: RoutesPath.private.posts.create,
    component: CreatePost,
  },
  {
    route: RoutesPath.private.posts.edit,
    component: EditPost,
  },
  {
    route: RoutesPath.private.advertisements.list,
    component: ListAdvertisements,
  },
  {
    route: RoutesPath.private.advertisements.detail,
    component: DetailAdvertisement,
  },
  {
    route: RoutesPath.private.advertisements.create,
    component: CreateAdvertisement,
  },
  {
    route: RoutesPath.private.advertisements.edit,
    component: EditAdvertisement,
  },
  {
    route: RoutesPath.private.plans.list,
    component: ListPlans,
  },
  {
    route: RoutesPath.private.plans.detail,
    component: DetailPlan,
  },
  {
    route: RoutesPath.private.plans.create,
    component: CreatePlan,
  },
  {
    route: RoutesPath.private.plans.edit,
    component: EditPlan,
  },
  {
    route: RoutesPath.private.voucher,
    component: Voucher,
  },
  {
    route: RoutesPath.private.financial,
    component: Financial,
  },
  {
    route: RoutesPath.private.users.list,
    component: ListUsers,
  },
  {
    route: RoutesPath.private.users.detail,
    component: DetailUser,
  },
  {
    route: RoutesPath.private.users.create,
    component: CreateUser,
  },
  {
    route: RoutesPath.private.users.edit,
    component: EditUser,
  },
  {
    route: RoutesPath.private.faq.list,
    component: ListFaq,
  },
  {
    route: RoutesPath.private.faq.detail,
    component: DetailFaq,
  },
  {
    route: RoutesPath.private.faq.create,
    component: CreateFaq,
  },
  {
    route: RoutesPath.private.faq.edit,
    component: EditFaq,
  },
  {
    route: RoutesPath.private.leads.list,
    component: ListLeads,
  },
  {
    route: RoutesPath.private.leads.details.leadInfo,
    component: DetailLead,
  },
  {
    route: RoutesPath.private.leads.details.posts,
    component: DetailLead,
  },
  {
    route: RoutesPath.public.confirmEmail,
    component: ConfirmEmail,
  },
];
