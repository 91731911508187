import { useCallback } from 'react';

import { endpoints, useApi } from '@contexts/Api';

const useIntegrations = () => {
  const { request } = useApi();

  const listIntegrations = useCallback(
    async <IntegrationFields extends keyof Integration>(
      params: IntegrationsQuery
    ) => {
      const response = await request<Paginated<Integration, IntegrationFields>>(
        {
          method: 'get',
          url: endpoints.integrations.list,
          params: {
            fields: params.fields.join(','),
            page: params.page,
            search: params.search || undefined,
          },
        }
      );

      return response.data;
    },
    [request]
  );

  return { listIntegrations };
};

export default useIntegrations;
