import RoutesPath from 'router/routes';

const getRoutes = (userId: string) => {
  return [
    {
      path: RoutesPath.private.users.list,
      label: 'Lista de usuários',
    },
    {
      path: RoutesPath.private.users.detail.replace(
        ':userId',
        userId.toString()
      ),
      label: 'Detalhes do usuário',
    },
    {
      path: RoutesPath.private.users.edit,
      label: 'Editar usuário',
    },
  ];
};

export default getRoutes;
