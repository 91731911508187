import React from 'react';

import Loading from '@components/Loading';

import { CancelButton, Container, CustomButton } from './styles';

interface ModalFooterButtonsProps {
  isLoading: boolean;
  confirmText: string;
  onConfirm: () => void;
  cancelText: string;
  onCancel: () => void;
}

const ModalFooterButtons: React.FC<ModalFooterButtonsProps> = ({
  isLoading,
  confirmText,
  onConfirm,
  cancelText,
  onCancel,
}) => {
  return (
    <Container>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <CancelButton variant="outline" onClick={onCancel}>
            {cancelText}
          </CancelButton>
          <CustomButton type="button" variant="primary" onClick={onConfirm}>
            {confirmText}
          </CustomButton>
        </>
      )}
    </Container>
  );
};

export default ModalFooterButtons;
