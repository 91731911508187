import React from 'react';

import { Content } from '@components/BaseForm/styles';
import PageHeader from '@components/PageHeader';

import ActivePosts from './ActivePosts';
import PhoneClicks from './PhoneClicks';
import StoresHistogram from './StoresHistogram';
import { Grid } from './styles';

const Dashboard: React.FC = () => {
  return (
    <>
      <PageHeader
        title="Dashboard"
        subtitle="Aqui estão as métricas do sistema"
      />
      <Content>
        <Grid>
          <ActivePosts />
          <PhoneClicks />
          <StoresHistogram />
        </Grid>
      </Content>
    </>
  );
};

export default Dashboard;
