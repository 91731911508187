import RoutesPath from '@router/routes';

const routes = [
  {
    path: RoutesPath.private.leads.list,
    label: 'Lista de Leads',
  },
  {
    path: RoutesPath.private.leads.details.leadInfo,
    label: 'Detalhes do lead',
  },
];

export default routes;
