import React, { useRef, useState } from 'react';

import { MdLogout, MdManageAccounts } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '@contexts/Auth';
import { useOutsideClick } from '@hooks';

import RoutesPath from '@router/routes';

import MenuItem from './MenuItem';
import {
  ArrowDropDown,
  Container,
  ProfileMenu,
  Role,
  UserAvatar,
  UserInfo,
  Username,
} from './styles';

const HeaderMenu: React.FC = () => {
  const menuRef = useRef<HTMLDivElement | null>(null);

  const [isOpen, setIsOpen] = useState(false);

  const { userInfo } = useAuth();

  const { logout } = useAuth();
  const navigate = useNavigate();

  useOutsideClick(menuRef, () => setIsOpen(false));

  return (
    <Container
      ref={menuRef}
      onClick={(event) => {
        event.stopPropagation();
        setIsOpen(!isOpen);
      }}
    >
      <UserInfo>
        <Username>{userInfo?.name || 'Usuário'}</Username>
        <Role>Administrador</Role>
      </UserInfo>
      <ArrowDropDown />
      <UserAvatar
        src={userInfo?.avatar?.high ?? '/images/logo.svg'}
        alt="User"
      />
      <ProfileMenu $isOpen={isOpen} onClick={() => setIsOpen(false)}>
        <div>
          <MenuItem
            text="Perfil"
            icon={MdManageAccounts}
            onClick={() => {
              navigate(RoutesPath.private.profile);
              setIsOpen(!isOpen);
            }}
          />
          <MenuItem text="Sair" icon={MdLogout} onClick={logout} isLogoutItem />
        </div>
      </ProfileMenu>
    </Container>
  );
};

export default HeaderMenu;
