import React, { useRef } from 'react';

import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useToast } from '@contexts/Toast';
import { usePayments } from '@hooks';

import BaseForm from '@components/BaseForm';
import { Content } from '@components/BaseForm/styles';
import PageHeader from '@components/PageHeader';
import RequestContainer from '@components/RequestContainer';

import PriceForm from './Form/PriceForm';

const fields = ['id', 'price'] as const;

type ListVouchersFields = (typeof fields)[number];

const Voucher: React.FC = () => {
  const priceFormRef = useRef<FormStepRef>(null);

  const { listVouchers, updateVoucher } = usePayments();
  const { addToast } = useToast();
  const queryClient = useQueryClient();

  const fetchVoucher = useQuery(
    ['voucher'],
    () =>
      listVouchers<ListVouchersFields>(
        fields as unknown as Array<keyof Voucher>
      ),
    {
      select: (data) => data.results[0],
    }
  );

  const updateRequest = useMutation(
    ({ price }: FormValuesPrice) => updateVoucher(fetchVoucher.data!.id, price),
    {
      onSuccess: () => {
        addToast('Plano atualizado com sucesso', 'success');
        queryClient.invalidateQueries(['voucher']);
      },
    }
  );

  return (
    <>
      <PageHeader
        title="Plano pré-pago"
        subtitle="Aqui está o plano pré-pago que permite o usuário comprar uma unidade de anuncio"
      />
      <Content>
        <RequestContainer requests={[fetchVoucher]}>
          <BaseForm
            steps={[
              {
                title: '',
                ref: priceFormRef,
                component: PriceForm,
              },
            ]}
            initialValues={{ price: fetchVoucher.data?.price.toString() }}
            createRequest={updateRequest}
          />
        </RequestContainer>
      </Content>
    </>
  );
};

export default Voucher;
