import React from 'react';

import { phone } from 'b2utils';
import { useQuery } from 'react-query';

import { useStores } from '@hooks';

import DetailPersonCard from '@components/DetailPersonCard';
import RequestContainer from '@components/RequestContainer';

interface LeadInfoProps {
  customer: number;
}

const LeadInfo: React.FC<LeadInfoProps> = ({ customer }) => {
  const { getCustomer } = useStores();

  const fetchCustomer = useQuery(['customer', customer], () =>
    getCustomer(customer)
  );

  return (
    <RequestContainer requests={[fetchCustomer]}>
      <>
        {fetchCustomer.data && (
          <>
            <DetailPersonCard
              title={fetchCustomer.data.full_name}
              infos={[
                {
                  label: 'Telefone',
                  value: phone.mask(fetchCustomer.data.phone_number),
                },
                {
                  label: 'E-mail',
                  value: fetchCustomer.data.email,
                },
              ]}
            />
          </>
        )}
      </>
    </RequestContainer>
  );
};

export default LeadInfo;
