import React, { useMemo } from 'react';

import useAnalytics from 'hooks/Analytics';
import { useQuery } from 'react-query';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useTheme } from 'styled-components';

import ChartCard from '@components/ChartCard';

import { enums } from '@utils';

const SubscriptionsHistogram: React.FC = () => {
  const theme = useTheme();
  const { getSubscriptionsHistogram } = useAnalytics();

  const fetchSubscriptionsHistogram = useQuery(['subscriptionsHistogram'], () =>
    getSubscriptionsHistogram()
  );

  const chartData = useMemo(() => {
    if (!fetchSubscriptionsHistogram.data) {
      return [];
    }

    const results = fetchSubscriptionsHistogram.data.actives.map(
      (activeItem) => ({
        month: enums.MonthsMap[activeItem.month],
        [enums.ChartSubscriptionType.ACTIVE]: activeItem.count,
        [enums.ChartSubscriptionType.CANCELED]: 0,
      })
    );

    fetchSubscriptionsHistogram.data.canceled.forEach((canceledItem) => {
      const monthItemIndex = results.findIndex(
        (item) => item.month === enums.MonthsMap[canceledItem.month]
      );
      if (monthItemIndex !== -1) {
        results[monthItemIndex][enums.ChartSubscriptionType.CANCELED] =
          canceledItem.count;
      } else {
        results.push({
          month: enums.MonthsMap[canceledItem.month],
          [enums.ChartSubscriptionType.ACTIVE]: 0,
          [enums.ChartSubscriptionType.CANCELED]: canceledItem.count,
        });
      }
    });

    return results.sort((month1, month2) =>
      month1.month < month2.month ? 1 : -1
    );
  }, [fetchSubscriptionsHistogram.data]);

  return (
    <ChartCard
      dataCy="histogram-card"
      title="Assinantes"
      request={fetchSubscriptionsHistogram}
    >
      <ResponsiveContainer width="100%" height={300}>
        <LineChart width={400} height={400} data={chartData}>
          <Line
            type="natural"
            dataKey={enums.ChartSubscriptionType.ACTIVE}
            stroke={theme.colors.main}
            strokeWidth={5}
            dot={{
              strokeWidth: 1,
            }}
          />
          <Line
            type="natural"
            dataKey={enums.ChartSubscriptionType.CANCELED}
            stroke={theme.colors.secondary}
            strokeWidth={5}
            dot={{
              strokeWidth: 1,
            }}
          />
          <XAxis dataKey="month" />
          <YAxis />
          <CartesianGrid vertical={false} />
          <Tooltip />
          <Legend verticalAlign="top" align="right" iconType="plainline" />
        </LineChart>
      </ResponsiveContainer>
    </ChartCard>
  );
};

export default SubscriptionsHistogram;
