import React from 'react';

import { B2Button, IB2Button } from 'react-b2components';

import Spinner from '@components/Spinner';

interface ButtonProps extends IB2Button {
  isLoading?: boolean;
}

const Button: React.FC<ButtonProps> = ({ isLoading, children, ...props }) => {
  return (
    <B2Button {...props} disabled={isLoading}>
      {isLoading ? <Spinner /> : children}
    </B2Button>
  );
};

export default Button;
