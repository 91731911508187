import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';

import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input, {
  RadioButtonsContainer,
  RadioButtonsGroup,
} from '@components/Input';
import Label from '@components/Label';

import { enums, errors } from '@utils';

import BrandSelect from './BrandSelect';
import ChassiSearch from './ChassiSearch';
import ClientsSelect from './ClientsSelect';
import ModelSelect from './ModelSelect';
import PlateSearch from './PlateSearch';
import TagSelect from './TagSelect';
import VersionSelect from './VersionSelect';
import YearsSelects from './YearsSelects';
import { PlateQuestion, PlateQuestionHighlight } from './styles';

interface VehicleInfoProps {
  initialValues?: FormValuesVehicleInfo;
  onFinish: (data: FormValuesVehicleInfo) => void;
  category: VehicleCategory;
}

const searchTypeSelectableValues = [
  {
    label: 'Placa',
    value: enums.VehicleSearchType.PLATE,
  },
  {
    label: 'Chassi',
    value: enums.VehicleSearchType.CHASSI,
  },
];

const isArmoredSelectableValues = [
  { label: 'Sim', value: 'true' },
  { label: 'Não', value: 'false' },
];

const VehicleInfo: React.ForwardRefRenderFunction<
  FormStepRef,
  VehicleInfoProps
> = ({ initialValues, onFinish, category }, ref) => {
  const [searchType, setSearchType] = useState<enums.VehicleSearchType>(
    enums.VehicleSearchType.PLATE
  );

  const validateObjectField = yup.object().when('hasSearchResult', {
    is: true,
    then: (schema) =>
      schema.shape({
        value: yup.string().required(errors.required),
      }),
  });

  const validateStringField = yup.string().when('hasSearchResult', {
    is: true,
    then: (schema) => schema.required(errors.required),
  });

  const formikInitialValues: FormValuesVehicleInfo = {
    store: {
      label: '',
      value: '',
    },
    hasSearchResult: !!initialValues?.brand?.value,
    brand: {
      label: '',
      value: '',
    },
    manufacturingYear: '',
    model: {
      label: '',
      value: '',
      manufacturingYears: [],
    },
    modelYear: '',
    version: {
      label: '',
      value: '',
    },
    color: {
      label: '',
      value: '',
    },
    fuel: {
      label: '',
      value: '',
    },
    numberOfDoors: {
      label: '',
      value: '',
    },
    isArmored: 'false',
    ...initialValues,
  };

  const formikValidationSchema = yup.object().shape({
    store: yup.object().shape({
      value: yup.string().required(errors.required),
    }),
    hasSearchResult: yup.boolean().isTrue(errors.required),
    brand: validateObjectField,
    model: validateObjectField,
    manufacturingYear: validateStringField,
    modelYear: validateStringField,
    version: validateObjectField,
    color: validateObjectField,
    fuel: validateObjectField,
    numberOfDoors: validateObjectField,
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  const shouldShowVehicleFields =
    formik.values.hasSearchResult || !category.has_suggested_prices;

  const onSearch = useCallback(() => {
    formik.setTouched({
      store: formik.touched.store,
      hasSearchResult: true,
    });
    formik.setFieldValue('hasSearchResult', true);
  }, [formik]);

  return (
    <FormikProvider value={formik}>
      <ClientsSelect />
      {category.has_suggested_prices && (
        <>
          <FormRow>
            <FormGroup>
              <Label>Tipo de pesquisa</Label>
              <RadioButtonsContainer>
                {searchTypeSelectableValues.map((option) => (
                  <RadioButtonsGroup key={option.value}>
                    <Input
                      type="radio"
                      id={`radio${option.value}`}
                      name="searchType"
                      checked={searchType === option.value}
                      onChange={() => {
                        setSearchType(option.value);
                      }}
                      value={option.value}
                    />
                    <Label htmlFor={`radio${option.value}`}>
                      {option.label}
                    </Label>
                  </RadioButtonsGroup>
                ))}
              </RadioButtonsContainer>
            </FormGroup>
          </FormRow>
          <FormRow>
            <div>
              {searchType === enums.VehicleSearchType.PLATE ? (
                <PlateSearch category={category.id} onSearch={onSearch} />
              ) : (
                <ChassiSearch category={category.id} onSearch={onSearch} />
              )}
              {!formik.values.hasSearchResult && (
                <PlateQuestion onClick={onSearch}>
                  <PlateQuestionHighlight>
                    Não tem a placa?
                  </PlateQuestionHighlight>{' '}
                  Clique aqui
                </PlateQuestion>
              )}
            </div>
          </FormRow>
        </>
      )}
      {shouldShowVehicleFields && (
        <>
          <FormRow>
            <BrandSelect category={category.id} />
            <ModelSelect
              brand={Number(formik.values.brand.value)}
              category={category.id}
            />
          </FormRow>
          <YearsSelects />
          <FormRow>
            <VersionSelect model={Number(formik.values.model.value)} />
            <TagSelect
              label="Combustível"
              type={enums.TagType.FUEL}
              formikKey="fuel"
            />
          </FormRow>
          <FormRow>
            <TagSelect
              label="Cor"
              type={enums.TagType.COLOR}
              formikKey="color"
            />
            <TagSelect
              label="Quantidade de portas"
              type={enums.TagType.NUMBER_OF_DOORS}
              formikKey="numberOfDoors"
            />
          </FormRow>
          <FormRow>
            <FormGroup>
              <Label>Blindado</Label>
              <RadioButtonsContainer>
                {isArmoredSelectableValues.map((option) => (
                  <RadioButtonsGroup key={option.value}>
                    <Input
                      type="radio"
                      id={`radio${option.value}`}
                      name="type"
                      checked={formik.values.isArmored === option.value}
                      onChange={() => {
                        formik.setFieldValue('isArmored', option.value);
                      }}
                      onBlur={formik.handleBlur}
                      value={option.value}
                    />
                    <Label htmlFor={`radio${option.value}`}>
                      {option.label}
                    </Label>
                  </RadioButtonsGroup>
                ))}
              </RadioButtonsContainer>
              <FormError name="isArmored" />
            </FormGroup>
          </FormRow>
        </>
      )}
    </FormikProvider>
  );
};

export default forwardRef(VehicleInfo);
