import React, { useState } from 'react';

import { useFormikContext } from 'formik';
import { useInfiniteQuery } from 'react-query';
import { getNextPageParam } from 'utils/helpers';

import { useToast } from '@contexts/Toast';
import { usePosts } from '@hooks';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import Label from '@components/Label';
import SearchSelect from '@components/SearchSelect';

interface ModelSelectProps {
  brand: number;
  category: number;
}

const ModelSelect: React.FC<ModelSelectProps> = ({ brand, category }) => {
  const [search, setSearch] = useState('');

  const formik = useFormikContext<FormValuesVehicleInfo>();

  const { listModels } = usePosts();
  const { addToast } = useToast();

  const {
    data: models,
    isLoading,
    fetchNextPage,
  } = useInfiniteQuery(
    ['models', search, brand],
    ({ pageParam = 1 }) =>
      listModels({
        fields: ['id', 'name', 'manufacturing_years'],
        page: pageParam,
        search,
        brand,
        category,
      }),
    {
      getNextPageParam,
      onError: () => {
        addToast('Falha ao carregar os modelos de veículos.', 'error');
      },
      enabled: !!brand,
    }
  );

  return (
    <FormGroup>
      <Label htmlFor="model">Modelo</Label>
      <SearchSelect
        name="model"
        onSearch={setSearch}
        options={
          models?.pages
            .flatMap((page) => page.results)
            .map((model) => ({
              label: model.name,
              value: model.id.toString(),
              manufacturingYears: model.manufacturing_years,
            })) || []
        }
        value={formik.values.model.label}
        onChange={(value) => {
          setSearch('');
          formik.setValues({
            ...formik.values,
            model: value,
            manufacturingYear: '',
            modelYear: '',
            version: { label: '', value: '' },
          });
        }}
        isLoading={isLoading}
        fetchMore={fetchNextPage}
        onBlur={() => formik.setFieldTouched('model.value', true)}
        invalidValue={
          !!formik.touched.model?.value && !!formik.errors.model?.value
        }
        disabled={!formik.values.brand.value}
      />
      <FormError name="model.value" />
    </FormGroup>
  );
};

export default ModelSelect;
