import React, { useState } from 'react';

import { MdSearch } from 'react-icons/md';
import { UseMutationResult } from 'react-query';

import CircleLoading from '@components/CircleLoading';
import FormGroup from '@components/FormGroup';

import {
  InputButton,
  InputContainer,
  InputPrefix,
  StyledInput,
} from './styles';

interface SearchInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  searchMutation: UseMutationResult<any, unknown, string, unknown>;
}

const SearchInput: React.FC<SearchInputProps> = ({
  label,
  searchMutation,
  ...inputProps
}) => {
  const [search, setSearch] = useState('');

  return (
    <FormGroup>
      <InputContainer>
        <InputPrefix>
          <p>{label}:</p>
        </InputPrefix>
        <StyledInput
          {...inputProps}
          name="plate"
          placeholder="Pesquise aqui..."
          onChange={(event) => {
            setSearch(event.target.value);
          }}
          value={search}
        />
        <InputButton onClick={() => search && searchMutation.mutate(search)}>
          {searchMutation.isLoading ? (
            <CircleLoading />
          ) : (
            <MdSearch size={24} />
          )}
        </InputButton>
      </InputContainer>
    </FormGroup>
  );
};

export default SearchInput;
