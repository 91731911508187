import React from 'react';

import BaseModal, { BaseModalProps } from '@components/BaseModal';

import { CloseButton, Text } from './styles';

interface SuccessModalProps extends Omit<BaseModalProps, 'children'> {
  message: string;
}

const SuccessModal: React.FC<SuccessModalProps> = ({
  onClose,
  message,
  ...props
}) => {
  return (
    <BaseModal onClose={onClose} {...props}>
      <Text>{message}</Text>
      <CloseButton onClick={onClose}>Fechar</CloseButton>
    </BaseModal>
  );
};

export default SuccessModal;
