import React, { useRef } from 'react';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useToast } from '@contexts/Toast';
import { useAuthentication } from '@hooks';

import BaseForm from '@components/BaseForm';
import PageHeader from '@components/PageHeader';
import RequestContainer from '@components/RequestContainer';

import RoutesPath from '@router/routes';

import UserInfo from '../Form/UserInfo';
import getRoutes from './routes';

const EditUser: React.FC = () => {
  const userInfoRef = useRef<FormStepRef>(null);

  const { getSystemManager, updateSystemManager } = useAuthentication();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const location = useLocation();
  const { userId } = useParams<{ userId: string }>();

  const fetchUser = useQuery(
    ['user', userId],
    () => getSystemManager(Number(userId!)),
    {
      onError: () => {
        navigate(RoutesPath.private.users.detail, { replace: true });
      },
      enabled: !location.state,
    }
  );

  const updateRequest = useMutation(
    (user: FormValuesUserInfo) => updateSystemManager(Number(userId!), user),
    {
      onSuccess: () => {
        addToast('Usuário atualizado com sucesso', 'success');
        queryClient.invalidateQueries(['user', userId]);
        navigate(RoutesPath.private.users.detail.replace(':userId', userId!));
      },
    }
  );

  return (
    <>
      <PageHeader title="Edição de usuário" routes={getRoutes(userId!)} />
      <RequestContainer requests={[fetchUser]}>
        <BaseForm
          initialValues={fetchUser.data}
          steps={[
            {
              title: 'Dados do usuário',
              ref: userInfoRef,
              component: UserInfo,
            },
          ]}
          createRequest={updateRequest}
        />
      </RequestContainer>
    </>
  );
};

export default EditUser;
