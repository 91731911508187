import React, { forwardRef, useImperativeHandle } from 'react';

import { currency } from 'b2utils';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input, {
  RadioButtonsContainer,
  RadioButtonsGroup,
} from '@components/Input';
import Label from '@components/Label';
import Select from '@components/Select';

import { enums, errors } from '@utils';

import BasePlanInfo from '../BasePlanInfo';

interface PlanInfoProps {
  initialValues?: FormValuesPlanInfo;
  onFinish: (data: FormValuesPlanInfo) => void;
}

const selectableValues = [
  { label: 'Não', value: 'false' },
  { label: 'Sim', value: 'true' },
];

const PlanInfo: React.ForwardRefRenderFunction<FormStepRef, PlanInfoProps> = (
  { initialValues, onFinish },
  ref
) => {
  const formikInitialValues: FormValuesPlanInfo = {
    name: '',
    hasTrialPeriod: 'false',
    trialPeriodDays: '',
    interval: enums.PlanInterval.MONTH,
    intervalCount: '1',
    cycles: '',
    price: '0',
    installments: '1',
    maxNumberOfPosts: '',
    maxNumberActivePosts: '',
    description: '',
    isHidden: false,
    ...initialValues,
  };

  const formikValidationSchema = yup.object().shape({
    name: yup.string().required(errors.required),
    hasTrialPeriod: yup.string().required(errors.required),
    trialPeriodDays: yup
      .number()
      .min(1, errors.minValue(1))
      .when('hasTrialPeriod', {
        is: 'true',
        then: (schema) => schema.required(errors.required),
      }),
    interval: yup.string().required(errors.required),
    intervalCount: yup
      .number()
      .min(1, errors.minValue(1))
      .required(errors.required),
    cycles: yup.number().min(1, errors.minValue(1)).required(errors.required),
    price: yup.string().required(errors.required),
    installments: yup.string().required(errors.required),
    maxNumberOfPosts: yup.number().min(1, errors.minValue(1)),
    maxNumberActivePosts: yup
      .number()
      .min(1, errors.minValue(1))
      .max(
        yup.ref('maxNumberOfPosts'),
        'Esse campo deve ser menor do que o campo "Max. de anúncios"'
      )
      .required(errors.required),
    description: yup.string(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  return (
    <FormikProvider value={formik}>
      <BasePlanInfo />
      <FormRow>
        <FormGroup>
          <Label>Tem período de teste?</Label>
          <RadioButtonsContainer>
            {selectableValues.map((option) => {
              const inputId = `hasTrialPeriodRadio${option.value}`;

              return (
                <RadioButtonsGroup key={option.value}>
                  <Input
                    type="radio"
                    id={inputId}
                    name="hasTrialPeriod"
                    checked={formik.values.hasTrialPeriod === option.value}
                    onChange={() =>
                      formik.setFieldValue('hasTrialPeriod', option.value)
                    }
                    onBlur={formik.handleBlur}
                    value={option.value}
                  />
                  <Label htmlFor={inputId}>{option.label}</Label>
                </RadioButtonsGroup>
              );
            })}
          </RadioButtonsContainer>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="trialPeriodDays">Período de testes (em dias)</Label>
          <Input
            type="number"
            name="trialPeriodDays"
            placeholder="Digite aqui"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.trialPeriodDays}
            invalidValue={
              !!formik.touched.trialPeriodDays &&
              !!formik.errors.trialPeriodDays
            }
            min={1}
            disabled={formik.values.hasTrialPeriod === 'false'}
          />
          <FormError name="trialPeriodDays" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="interval">Intervalo</Label>
          <Select
            name="interval"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.interval}
            invalidValue={!!formik.touched.interval && !!formik.errors.interval}
          >
            {Object.values(enums.PlanInterval).map((interval) => (
              <option key={interval} value={interval}>
                {enums.PlanIntervalSelectMap[interval]}
              </option>
            ))}
          </Select>
          <FormError name="interval" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="intervalCount">
            Cobrar a cada x{' '}
            {enums.PlanPluralIntervalMap[formik.values.interval]}
          </Label>
          <Input
            type="number"
            name="intervalCount"
            placeholder="Digite aqui"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.intervalCount}
            invalidValue={
              !!formik.touched.intervalCount && !!formik.errors.intervalCount
            }
            min={1}
          />
          <FormError name="intervalCount" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="cycles">
            Duração de x {enums.PlanPluralIntervalMap[formik.values.interval]}
          </Label>
          <Input
            type="number"
            name="cycles"
            placeholder="Digite aqui"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.cycles}
            invalidValue={!!formik.touched.cycles && !!formik.errors.cycles}
            min={1}
          />
          <FormError name="cycles" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="price">Valor</Label>
          <Input
            type="text"
            name="price"
            placeholder="Digite aqui"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={currency.brlMask(formik.values.price)}
            invalidValue={!!formik.touched.price && !!formik.errors.price}
          />
          <FormError name="price" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="installments">Parcelar em até</Label>
          <Select
            name="installments"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.installments}
            invalidValue={
              !!formik.touched.installments && !!formik.errors.installments
            }
          >
            <option value="1">Sem parcelamento</option>
            {Array.from({ length: 11 }, (_, i) => i + 2).map((installment) => (
              <option key={installment} value={installment}>
                {installment}x
              </option>
            ))}
          </Select>
          <FormError name="installments" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="maxNumberOfPosts">Max. de anúncios</Label>
          <Input
            type="number"
            name="maxNumberOfPosts"
            placeholder="Digite aqui"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.maxNumberOfPosts}
            invalidValue={
              !!formik.touched.maxNumberOfPosts &&
              !!formik.errors.maxNumberOfPosts
            }
            min={1}
          />
          <FormError name="maxNumberOfPosts" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="maxNumberActivePosts">Max. de anúncios ativos</Label>
          <Input
            type="number"
            name="maxNumberActivePosts"
            placeholder="Digite aqui"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.maxNumberActivePosts}
            invalidValue={
              !!formik.touched.maxNumberActivePosts &&
              !!formik.errors.maxNumberActivePosts
            }
            min={1}
            max={formik.values.maxNumberOfPosts}
          />
          <FormError name="maxNumberActivePosts" />
        </FormGroup>
      </FormRow>
    </FormikProvider>
  );
};

export default forwardRef(PlanInfo);
