import React, { useRef, useState } from 'react';

import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

import Modal from './Modal';
import { Button, Container, Item, List } from './styles';

interface CarouselProps {
  images: Array<Image>;
}

const Carousel: React.FC<CarouselProps> = ({ images }) => {
  const carouselRef = useRef<HTMLDivElement>(null);

  const [selectedImageId, setSelectedImageId] = useState<number>();

  const goToPrevImage = () => {
    if (!carouselRef.current) {
      return;
    }

    if (carouselRef.current.scrollLeft === 0) {
      carouselRef.current.scrollLeft =
        carouselRef.current.scrollWidth - carouselRef.current.offsetWidth;
    } else {
      carouselRef.current.scrollLeft -= carouselRef.current.offsetWidth;
    }
  };

  const goToNextImage = () => {
    if (!carouselRef.current) {
      return;
    }

    if (
      carouselRef.current.scrollLeft ===
      carouselRef.current.scrollWidth - carouselRef.current.offsetWidth
    ) {
      carouselRef.current.scrollLeft = 0;
    } else {
      carouselRef.current.scrollLeft += carouselRef.current.offsetWidth;
    }
  };

  const goToPrevImageOnModal = () => {
    if (selectedImageId === 0) {
      setSelectedImageId(images.length - 1);
    } else {
      setSelectedImageId(selectedImageId! - 1);
    }
  };

  const goToNextImageOnModal = () => {
    if (images.length - 1 === selectedImageId) {
      setSelectedImageId(0);
    } else {
      setSelectedImageId(selectedImageId! + 1);
    }
  };

  return (
    <Container>
      <Button direction="left" onClick={goToPrevImage}>
        <MdKeyboardArrowLeft size={30} />
      </Button>
      <List ref={carouselRef}>
        {images.map((image, index) => (
          <Item
            key={image.id}
            src={image.medium}
            alt="Foto detalhada do veículo"
            onClick={() => {
              setSelectedImageId(index);
            }}
          />
        ))}
      </List>
      <Button direction="right" onClick={goToNextImage}>
        <MdKeyboardArrowRight size={30} />
      </Button>
      <Modal
        selectedImage={
          selectedImageId !== undefined ? images[selectedImageId] : undefined
        }
        onHide={() => setSelectedImageId(undefined)}
        onClickPrev={goToPrevImageOnModal}
        onClickNext={goToNextImageOnModal}
      />
    </Container>
  );
};

export default Carousel;
