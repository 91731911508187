import styled from 'styled-components';

export const ExtraInfoContent = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
`;

export const ExtraInfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 150px);
  gap: 16px 24px;
`;

export const ExtraInfoLabel = styled.span`
  margin-bottom: 8px;
  color: ${(props) => props.theme.colors.gray.twelve};
  font-weight: ${(props) => props.theme.fontWeight.semiBold};
`;

export const ExtraInfValue = styled.span`
  color: ${(props) => props.theme.colors.black.main};
  font-weight: ${(props) => props.theme.fontWeight.regular};
`;

export const OptionalsTitle = styled(ExtraInfoLabel)`
  display: inline-block;
  margin-bottom: 16px;
`;

export const OptionalTag = styled.div`
  background-color: ${({ theme }) => theme.colors.main};
  color: ${({ theme }) => theme.colors.white.main};
  padding: 8px;
  border-radius: 16px;
  word-break: break-word;
  text-align: center;
`;
