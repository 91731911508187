import styled from 'styled-components';

export const Container = styled.div`
  width: 50%;
`;

export const Table = styled.table`
  width: 100%;
  margin: 12px 0px;
  border-collapse: collapse;

  thead,
  tr {
    height: 30px;
  }

  tr {
    font-weight: ${(props) => props.theme.fontWeight.medium};
    font-size: ${(props) => props.theme.fontSize.smaller};
    color: ${(props) => props.theme.colors.gray.seven};
  }

  tr:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.colors.gray.one};
  }

  tr td {
    color: ${(props) => props.theme.colors.black.two};
    padding: 10px;
  }

  tr td:first-child {
    font-weight: ${(props) => props.theme.fontWeight.semiBold};
    font-size: ${(props) => props.theme.fontSize.small};
  }

  tr td:not(:first-child) {
    text-align: center;
  }
`;

export const FooterLeftSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const FipeTitle = styled.span`
  color: ${(props) => props.theme.colors.main};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  font-size: ${(props) => props.theme.fontSize.small};
`;

export const FipeLastUpdate = styled.span`
  font-size: ${(props) => props.theme.fontSize.smaller};
  color: ${(props) => props.theme.colors.gray.six};
  font-weight: ${(props) => props.theme.fontWeight.medium};
`;

export const FipeValue = styled.span`
  font-size: ${(props) => props.theme.fontSize.large};
  color: ${(props) => props.theme.colors.black.two};
  font-weight: ${(props) => props.theme.fontWeight.bold};
`;
