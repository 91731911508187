import React from 'react';

import { FormikProvider, useFormik } from 'formik';
import { useMutation } from 'react-query';
import * as yup from 'yup';

import { useToast } from '@contexts/Toast';
import { useAuthentication } from '@hooks';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import Label from '@components/Label';
import PasswordInput from '@components/PasswordInput';
import SectionHeader from '@components/SectionHeader';

import { errors } from '@utils';

import { Footer, FooterButton, ProfileCard } from '../styles';

interface ChangePasswordParams {
  password: string;
  confirmPassword: string;
}

export const UpdatePassword: React.FC = () => {
  const { updatePassword } = useAuthentication();
  const { addToast } = useToast();

  const changePasswordRequest = useMutation(
    ({ password, confirmPassword }: ChangePasswordParams) =>
      updatePassword(password, confirmPassword),
    {
      onSuccess: () => {
        addToast('Senha atualizada', 'success');
        formik.resetForm();
      },
      onError: () => {
        addToast('Falha ao trocar a senha, tente novamente', 'error');
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: yup.object({
      password: yup.string().required(errors.required),
      confirmPassword: yup
        .string()
        .equals([yup.ref('password')], errors.passwordConfirm)
        .required(errors.required),
    }),
    onSubmit: (values) => changePasswordRequest.mutate(values),
  });

  return (
    <>
      <ProfileCard>
        <SectionHeader title="Atualizar senha" />
        <FormikProvider value={formik}>
          <FormGroup>
            <Label htmlFor="password">Nova senha</Label>
            <PasswordInput
              id="password"
              name="password"
              placeholder="Nova senha"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            <FormError name="password" />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="confirmPassword">Confirmação da nova senha</Label>
            <PasswordInput
              id="confirmPassword"
              name="confirmPassword"
              placeholder="Confirme a nova senha"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirmPassword}
            />
            <FormError name="confirmPassword" />
          </FormGroup>
        </FormikProvider>
      </ProfileCard>
      <Footer>
        <FooterButton
          type="submit"
          variant="primary"
          onClick={() => formik.handleSubmit()}
        >
          Salvar
        </FooterButton>
      </Footer>
    </>
  );
};
