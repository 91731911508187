import React, { useState } from 'react';

import ConfirmationModal from '@components/ConfirmationModal';
import RejectionModal from '@components/RejectionModal';

import {
  AcceptButton,
  AproveButtonsContainer,
  AproveCardContainer,
  AproveCardTitle,
  CheckCircleIcon,
  RejectButton,
  CancelIcon,
} from './styles';

interface AproveCardProps {
  onClickAproveButton: () => void;
  onClickRejectModal: (rejectionReason: string) => void;
  isLoading: boolean;
  approveMessage: string;
  rejectMessage: string;
  children: React.ReactNode;
}

const AproveCard: React.FC<AproveCardProps> = ({
  onClickAproveButton,
  onClickRejectModal,
  isLoading,
  approveMessage,
  rejectMessage,
  children,
}) => {
  const [isOpenRejectModal, setIsOpenRejectModal] = useState(false);
  const [isOpenApproveModal, setIsOpenApproveModal] = useState(false);

  return (
    <AproveCardContainer>
      <AproveCardTitle>{children}</AproveCardTitle>
      <AproveButtonsContainer>
        <AcceptButton onClick={() => setIsOpenApproveModal(true)}>
          <CheckCircleIcon />
          Aprovar
        </AcceptButton>
        <RejectButton onClick={() => setIsOpenRejectModal(true)}>
          <CancelIcon />
          Rejeitar
        </RejectButton>
      </AproveButtonsContainer>
      <ConfirmationModal
        title={approveMessage}
        isOpen={isOpenApproveModal}
        isLoading={isLoading}
        confirmButtonText="Sim"
        onConfirm={() => {
          onClickAproveButton();
          setIsOpenApproveModal(false);
        }}
        cancelButtonText="Não"
        onCancel={() => setIsOpenApproveModal(false)}
        onClose={() => setIsOpenApproveModal(false)}
      />
      <RejectionModal
        title={rejectMessage}
        isOpen={isOpenRejectModal}
        isLoading={isLoading}
        confirmButtonText="Enviar"
        onConfirm={(rejectionReason) => {
          onClickRejectModal(rejectionReason);
          setIsOpenRejectModal(false);
        }}
        cancelButtonText="Cancelar"
        onCancel={() => setIsOpenRejectModal(false)}
        onClose={() => setIsOpenRejectModal(false)}
      />
    </AproveCardContainer>
  );
};

export default AproveCard;
