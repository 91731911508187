import React, { useRef, useState } from 'react';

import { useMutation, useQueryClient } from 'react-query';

import { usePostForm, usePosts } from '@hooks';

import BaseForm from '@components/BaseForm';
import PageHeader from '@components/PageHeader';

import ExtraInfo from '../Form/ExtraInfo';
import Photos from '../Form/Photos';
import Tags from '../Form/Tags';
import VehicleCategories from '../Form/VehicleCategories';
import VehicleConditions from '../Form/VehicleConditions';
import VehicleInfo from '../Form/VehicleInfo';
import VehicleValues from '../Form/VehicleValues';
import routes from './routes';

const CreatePost: React.FC = () => {
  const vehicleCategoriesRef = useRef<FormStepRef>(null);
  const vehicleInfoRef = useRef<FormStepRef>(null);
  const tagsRef = useRef<FormStepRef>(null);
  const photosRef = useRef<FormStepRef>(null);
  const vehicleConditionsRef = useRef<FormStepRef>(null);
  const extraInfoRef = useRef<FormStepRef>(null);
  const vehicleValuesRef = useRef<FormStepRef>(null);

  const [id, setId] = useState<number>();
  const [postCategory, setPostCategory] = useState<VehicleCategory>();

  const { createPost } = usePosts();
  const queryClient = useQueryClient();

  const {
    updateRequest,
    updateVehicleConditionsRequests,
    uploadVehiclePhotos,
    completeRequest,
    updateExtraInfo,
  } = usePostForm(id!);

  const saveCategory = useMutation((data: FormValuesVehicleCategories) => {
    setPostCategory(data.category);
    return Promise.resolve();
  });

  const createRequest = useMutation(
    (data: FormValuesVehicleInfo) => {
      return createPost(data);
    },
    {
      onSuccess: (data: Post) => {
        queryClient.invalidateQueries('posts');
        setId(data.id);
      },
    }
  );

  return (
    <>
      <PageHeader title="Cadastro de anúncio" routes={routes} />
      <BaseForm
        steps={[
          {
            title: 'Tipo do veículo',
            ref: vehicleCategoriesRef,
            component: VehicleCategories,
            saveStepData: saveCategory,
          },
          {
            title: 'Dados do veículo',
            ref: vehicleInfoRef,
            component: VehicleInfo,
            props: {
              category: postCategory,
            },
            saveStepData: id ? updateRequest : createRequest,
          },
          {
            title: 'Dados opcionais',
            ref: tagsRef,
            component: Tags,
            props: {
              category: postCategory?.id,
            },
            saveStepData: updateRequest,
          },
          {
            title: 'Fotos do veículo',
            ref: photosRef,
            component: Photos,
            props: {
              post: id,
            },
            saveStepData: uploadVehiclePhotos,
          },
          {
            title: 'Condições do veículo',
            ref: vehicleConditionsRef,
            component: VehicleConditions,
            props: {
              post: id,
            },
            saveStepData: updateVehicleConditionsRequests,
          },
          {
            title: 'Informações adicionais',
            ref: extraInfoRef,
            component: ExtraInfo,
            props: {
              post: id,
              category: postCategory?.id,
            },
            saveStepData: updateExtraInfo,
          },
          {
            title: 'Valores do veículo',
            ref: vehicleValuesRef,
            component: VehicleValues,
            props: {
              post: id,
              category: postCategory,
            },
            saveStepData: updateRequest,
          },
        ]}
        createRequest={completeRequest}
        saveEveryStep={true}
      />
    </>
  );
};

export default CreatePost;
