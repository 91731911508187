import { color } from 'b2utils';
import styled from 'styled-components';

import Input from '@components/Input';

export const InputContainer = styled.div`
  display: flex;
  border: 1px solid ${({ theme }) => theme.colors.white.three};
  border-radius: 12px;
`;

export const InputPrefix = styled.div`
  background-color: ${({ theme }) =>
    color.addOpacityOnHexColor(theme.colors.main, 0.1)};
  color: ${({ theme }) => theme.colors.gray.twelve};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  padding: 12px;

  p {
    text-align: center;
  }
`;

export const StyledInput = styled(Input)`
  background-color: ${({ theme }) => theme.colors.white.main};
  border-radius: 0px;
  text-transform: uppercase;

  ::placeholder {
    text-transform: none;
  }
`;

export const InputButton = styled.div`
  background-color: ${({ theme }) => theme.colors.main};
  color: ${({ theme }) => theme.colors.white.main};
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 12px;
  cursor: pointer;
`;
