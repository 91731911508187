import React, { useState } from 'react';

import { B2Button, B2ButtonsPage } from 'react-b2components';
import { UseQueryResult, useMutation } from 'react-query';

import { useToast } from '@contexts/Toast';
import { usePosts } from '@hooks';

import EmptyAnimation from '@components/EmptyAnimation';
import RequestContainer from '@components/RequestContainer';

import { enums } from '@utils';

import PostCard from './PostCard';
import { ApproveCard, ApproveText, Grid } from './styles';

export const listPostsFields = [
  'id',
  'vehicle_brand',
  'vehicle_model',
  'vehicle_price',
  'vehicle_version',
  'vehicle_year',
  'vehicle_manufacturing_year',
  'store_info',
  'main_image',
  'is_complete',
  'inspection_document',
  'status',
  'modified',
] as const;

export type ListPostFields = (typeof listPostsFields)[number];

export type ListPost = Pick<Post, ListPostFields>;

interface PostsListProps {
  fetchPosts: UseQueryResult<Paginated<Post, ListPostFields>, unknown>;
  page: number;
  onChangePage: (page: number) => void;
  onClick: (post: number) => void;
  pageSize?: number;
  showStatusIndicator?: boolean;
  canSelectPosts?: boolean;
}

const PostsList: React.FC<PostsListProps> = ({
  fetchPosts,
  page,
  onChangePage,
  onClick,
  pageSize = 6,
  showStatusIndicator = false,
  canSelectPosts,
}) => {
  const [selectedPosts, setSelectedPosts] = useState<Array<number>>([]);

  const { bulkingAprove } = usePosts();
  const { addToast } = useToast();

  const requestBulkingAprove = useMutation(() => bulkingAprove(selectedPosts), {
    onSuccess: () => {
      addToast('Anúncios atualizados com sucesso', 'success');
      fetchPosts.refetch();
      setSelectedPosts([]);
    },
    onError: () => {
      addToast('Falha ao atualizar os status', 'error');
    },
  });

  return (
    <>
      <RequestContainer requests={[fetchPosts]}>
        {fetchPosts.data?.results.length ? (
          <>
            {canSelectPosts && (
              <ApproveCard>
                <ApproveText>
                  <strong>{selectedPosts.length}</strong>
                  {selectedPosts.length !== 1
                    ? ' anúncios selecionados '
                    : ' anúncio selecionado '}
                </ApproveText>
                <B2Button
                  onClick={() => requestBulkingAprove.mutate()}
                  disabled={!selectedPosts.length}
                >
                  Aprovar ({selectedPosts.length})
                </B2Button>
              </ApproveCard>
            )}
            <Grid data-cy="posts-grid">
              {fetchPosts.data?.results.map((post) => (
                <PostCard
                  key={post.id}
                  post={post}
                  onClick={() => onClick(post.id)}
                  showStatusIndicator={showStatusIndicator}
                  checkboxOptions={
                    canSelectPosts && post.status === enums.PostStatus.PENDING
                      ? {
                          isSelected: selectedPosts.includes(post.id),
                          onSelect: () => {
                            setSelectedPosts((prevState) => [
                              ...prevState,
                              post.id,
                            ]);
                          },
                          onUnselect: () => {
                            setSelectedPosts((prevState) =>
                              prevState.filter((postId) => postId !== post.id)
                            );
                          },
                        }
                      : undefined
                  }
                />
              ))}
            </Grid>
            {fetchPosts.data.count > pageSize! && (
              <B2ButtonsPage
                pages={Math.ceil(fetchPosts.data?.count / pageSize!)}
                changePage={onChangePage}
                currentPage={page}
              />
            )}
          </>
        ) : (
          <EmptyAnimation />
        )}
      </RequestContainer>
    </>
  );
};

export default PostsList;
