import React, { forwardRef, useImperativeHandle } from 'react';

import { FormikProvider, useFormik } from 'formik';
import { useQuery } from 'react-query';
import * as yup from 'yup';

import { usePosts } from '@hooks';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import Label from '@components/Label';
import RequestContainer from '@components/RequestContainer';
import Select from '@components/Select';

import { enums } from '@utils';

import { Grid } from './styles';

interface VehicleConditionsProps {
  initialValues?: FormValuesVehicleConditions;
  onFinish: (data: FormValuesVehicleConditions) => void;
  post: number;
}

const fields = ['id', 'name', 'state'] as const;

type ListVehicleConditionFields = (typeof fields)[number];

const VehicleConditions: React.ForwardRefRenderFunction<
  FormStepRef,
  VehicleConditionsProps
> = ({ initialValues, onFinish, post }, ref) => {
  const { listVehicleConditions } = usePosts();

  const fetchVehicleConditions = useQuery(
    [
      'vehicleConditions',
      {
        post,
      },
    ],
    () =>
      listVehicleConditions<ListVehicleConditionFields>({
        fields: fields as unknown as Array<keyof VehicleCondition>,
        post,
      })
  );

  const formikInitialValues: FormValuesVehicleConditions = {
    vehicleConditions: fetchVehicleConditions.data ?? [],
    ...initialValues,
  };

  const formikValidationSchema = yup.object().shape({
    vehicleConditions: yup.array(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  return (
    <RequestContainer requests={[fetchVehicleConditions]}>
      <FormikProvider value={formik}>
        <Grid>
          {formik.values.vehicleConditions?.map((option, index) => (
            <FormGroup key={option.id}>
              <Label htmlFor={`vehicleConditions.${index}.state`}>
                {option.name}
              </Label>
              <Select
                name={`vehicleConditions.${index}.state`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.vehicleConditions[index].state}
              >
                {Object.values(enums.VehicleConditionState).map(
                  (value: enums.VehicleConditionState) => (
                    <option key={value} value={value}>
                      {enums.VehicleConditionStateMap[value]}
                    </option>
                  )
                )}
              </Select>
              <FormError name={`vehicleConditions.${index}.state`} />
            </FormGroup>
          ))}
        </Grid>
      </FormikProvider>
    </RequestContainer>
  );
};

export default forwardRef(VehicleConditions);
