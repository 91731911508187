import RoutesPath from '@router/routes';

const getRoutes = (id: number) => {
  return [
    {
      path: RoutesPath.private.clients.list.legal,
      label: 'Lista de clientes',
    },
    {
      path: RoutesPath.private.clients.detail.legal.users.list.replace(
        ':clientId',
        id.toString()
      ),
      label: 'Usuários do cliente',
    },
    {
      path: RoutesPath.private.clients.detail.legal.users.detail,
      label: 'Detalhes do usuário',
    },
  ];
};

export default getRoutes;
