import RoutesPath from 'router/routes';

const getRoutes = (advertisementId: string) => {
  return [
    {
      path: RoutesPath.private.advertisements.list,
      label: 'Lista de publicidades',
    },
    {
      path: RoutesPath.private.advertisements.detail.replace(
        ':advertisementId',
        advertisementId.toString()
      ),
      label: 'Detalhes da publicidade',
    },
    {
      path: RoutesPath.private.advertisements.edit,
      label: 'Editar publicidade',
    },
  ];
};

export default getRoutes;
