import React, { useRef } from 'react';

import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useToast } from '@contexts/Toast';
import { useAuthentication } from '@hooks';

import BaseForm from '@components/BaseForm';
import PageHeader from '@components/PageHeader';

import RoutesPath from '@router/routes';

import UserInfo from '../Form/UserInfo';
import routes from './routes';

const CreateUser = () => {
  const userInfoRef = useRef<FormStepRef>(null);

  const { createSystemManager } = useAuthentication();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const createRequest = useMutation(createSystemManager, {
    onSuccess: () => {
      addToast('Usuário criado com sucesso', 'success');
      navigate(RoutesPath.private.users.list, { replace: true });
      queryClient.invalidateQueries('users');
    },
  });

  return (
    <>
      <PageHeader title="Cadastro de usuário" routes={routes} />
      <BaseForm
        steps={[
          {
            title: 'Dados do usuário',
            ref: userInfoRef,
            component: UserInfo,
          },
        ]}
        createRequest={createRequest}
      />
    </>
  );
};

export default CreateUser;
