import React, { forwardRef, useImperativeHandle } from 'react';

import { FormikProvider, useFormik } from 'formik';
import { useQuery } from 'react-query';
import * as yup from 'yup';

import { usePosts } from '@hooks';

import FormRow from '@components/FormRow';
import RequestContainer from '@components/RequestContainer';

import { enums, errors } from '@utils';

import InputCustomField from './InputCustomField';
import SelectCustomField from './SelectCustomField';

interface ExtraInfoProps {
  initialValues?: FormValuesExtraInfo;
  onFinish: (data: FormValuesExtraInfo) => void;
  post: number;
  category: number;
}

const listCustomFieldAnswersFields = ['id', 'answer', 'custom_field'] as const;

type ListCustomFieldAnswers = (typeof listCustomFieldAnswersFields)[number];

const ExtraInfo: React.ForwardRefRenderFunction<FormStepRef, ExtraInfoProps> = (
  { initialValues, onFinish, post, category },
  ref
) => {
  const { listCustomFields, listCustomFieldAnswers } = usePosts();

  const fetchCustomFields = useQuery(
    ['customFields', category],
    () => listCustomFields({ category }),
    {
      cacheTime: 0,
    }
  );

  const fetchCustomFieldAnswers = useQuery(
    ['customFieldsAnswers', post],
    () =>
      listCustomFieldAnswers<ListCustomFieldAnswers>({
        fields: listCustomFieldAnswersFields as unknown as Array<
          keyof CustomFieldAnswer
        >,
        post,
      }),
    {
      cacheTime: 0,
    }
  );

  const transformArrayIntoObject = (array: Array<CustomField>) => {
    return array.reduce<FormValuesExtraInfo['customFields']>(
      (accumulator, currentValue) => {
        const customFieldAnswer = fetchCustomFieldAnswers.data?.find(
          (item) => item.custom_field.id === currentValue.id
        );

        if (customFieldAnswer) {
          return {
            ...accumulator,
            [currentValue.id.toString()]: {
              answer: customFieldAnswer.answer,
              customFieldAnswer: customFieldAnswer.id,
            },
          };
        }

        return {
          ...accumulator,
          [currentValue.id.toString()]: {
            answer: '',
          },
        };
      },
      {}
    );
  };

  const formikInitialValues: FormValuesExtraInfo = {
    ...initialValues,
    customFields: fetchCustomFields.data
      ? transformArrayIntoObject(fetchCustomFields.data)
      : initialValues?.customFields ?? {},
  };

  const customFieldAnswerValidations = {
    [enums.CustomFieldType.TEXT]: yup.string().trim(),
    [enums.CustomFieldType.NUMBER]: yup.number().typeError(errors.onlyNumbers),
    [enums.CustomFieldType.RADIO]: yup.string(),
  };

  const formikValidationSchema = yup.object().shape({
    customFields: yup.object().shape({
      ...fetchCustomFields.data?.reduce(
        (accumulator, currentValue) => ({
          ...accumulator,
          [currentValue.id.toString()]: yup.object().shape({
            answer: customFieldAnswerValidations[currentValue.type],
          }),
        }),
        {}
      ),
    }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  const renderCustomField = (customField: CustomField) => {
    return (
      <FormRow key={customField.id}>
        {customField.type === enums.CustomFieldType.RADIO ? (
          <SelectCustomField customField={customField} />
        ) : (
          <InputCustomField customField={customField} />
        )}
      </FormRow>
    );
  };

  return (
    <RequestContainer requests={[fetchCustomFields, fetchCustomFieldAnswers]}>
      <FormikProvider value={formik}>
        {fetchCustomFields.data?.map(renderCustomField)}
      </FormikProvider>
    </RequestContainer>
  );
};

export default forwardRef(ExtraInfo);
