import React, { useState } from 'react';

import moment from 'moment';
import { B2TableDataCell, B2TableRow } from 'react-b2components';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useAdvertisements } from '@hooks';

import { Content } from '@components/BaseForm/styles';
import FiltersContainer from '@components/FiltersContainer';
import OrderingButton from '@components/OrderingButton';
import PageHeader from '@components/PageHeader';
import SearchFilter from '@components/SearchFilter';
import Table from '@components/Table';

import RoutesPath from '@router/routes';
import { enums } from '@utils';

const fields = [
  'id',
  'title',
  'starts_at',
  'ends_at',
  'created',
  'category',
  'index',
] as const;

type ListAdvertisementsFields = (typeof fields)[number];

const ListAdvertisements: React.FC = () => {
  const [queryParams, setQueryParams] = useState<AdvertisementsQuery>({
    fields: fields as unknown as Array<keyof Advertisement>,
    page: 1,
  });

  const { listAdvertisements } = useAdvertisements();
  const navigate = useNavigate();

  const fetchAdvertisements = useQuery(['advertisements', queryParams], () =>
    listAdvertisements<ListAdvertisementsFields>(queryParams)
  );

  return (
    <>
      <PageHeader
        title="Publicidades"
        subtitle="Aqui estão todos as publicidades cadastrados no sistema"
        primaryButton={{
          text: 'Cadastrar publicidade',
          onClick: () => {
            navigate(RoutesPath.private.advertisements.create);
          },
        }}
      />
      <Content>
        <FiltersContainer>
          <SearchFilter
            onSearch={(search) => {
              setQueryParams((prevState) => ({
                ...prevState,
                search,
              }));
            }}
          />
          <OrderingButton
            isActive={queryParams.ordering === 'title'}
            onClick={() => {
              setQueryParams((prevState) => ({
                ...prevState,
                ordering: prevState.ordering === 'title' ? '' : 'title',
              }));
            }}
          />
        </FiltersContainer>
        <Table
          fetchData={fetchAdvertisements}
          headerData={[
            'Nome',
            'Data de lançamento',
            'Data de fim',
            'Data de criação',
            'Tipo',
            'Posição',
          ]}
          renderRow={(advertisement) => (
            <B2TableRow
              key={advertisement.id}
              onClick={() => {
                navigate(
                  RoutesPath.private.advertisements.detail.replace(
                    ':advertisementId',
                    advertisement.id.toString()
                  )
                );
              }}
            >
              <B2TableDataCell>{advertisement.title}</B2TableDataCell>
              <B2TableDataCell>
                {moment(advertisement.starts_at).format('DD/MM/YYYY')}
              </B2TableDataCell>
              <B2TableDataCell>
                {moment(advertisement.ends_at).format('DD/MM/YYYY')}
              </B2TableDataCell>
              <B2TableDataCell>
                {moment(advertisement.created).format('DD/MM/YYYY')}
              </B2TableDataCell>
              <B2TableDataCell>
                {enums.AdvertisementCategoryMap[advertisement.category]}
              </B2TableDataCell>
              <B2TableDataCell>{advertisement.index}</B2TableDataCell>
            </B2TableRow>
          )}
          paginator
          changePage={(newPage) =>
            setQueryParams((prevState) => ({
              ...prevState,
              page: newPage,
            }))
          }
          currentPage={queryParams.page}
        />
      </Content>
    </>
  );
};

export default ListAdvertisements;
