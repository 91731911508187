import React from 'react';

import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import { B2Theme } from 'react-b2components';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ThemeProvider } from 'styled-components';

import { ApiProvider } from '@contexts/Api';
import { AuthProvider } from '@contexts/Auth';
import { ToastProvider } from '@contexts/Toast';

import ErrorBoundary from '@components/ErrorBoundary';

import Router from '@router';
import { lightTheme } from '@themes/default';

import './index.css';

const container = document.getElementById('app');
const root = createRoot(container!);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (count, error) => {
        const axiosError: AxiosError = error as AxiosError;
        if (axiosError.response?.status === 401 || count >= 3) {
          return false;
        }
        return true;
      },
    },
  },
});

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_KEY,
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay({
      networkDetailAllowUrls: [import.meta.env.VITE_API_BASE_URL],
    }),
  ],
  environment: import.meta.env.VITE_ENVIRONMENT,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: import.meta.env.VITE_ENVIRONMENT !== 'local',
});

root.render(
  <React.StrictMode>
    <ThemeProvider theme={lightTheme}>
      <ErrorBoundary>
        <B2Theme theme={lightTheme}>
          <ToastProvider>
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools initialIsOpen={false} />
              <ApiProvider>
                <AuthProvider>
                  <Router />
                </AuthProvider>
              </ApiProvider>
            </QueryClientProvider>
          </ToastProvider>
        </B2Theme>
      </ErrorBoundary>
    </ThemeProvider>
  </React.StrictMode>
);
