import React from 'react';

import { useFormikContext } from 'formik';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Label from '@components/Label';
import Select from '@components/Select';

const YearsSelects = () => {
  const formik = useFormikContext<FormValuesVehicleInfo>();

  return (
    <FormRow>
      <FormGroup>
        <Label htmlFor="manufacturingYear">Ano de fabricação</Label>
        <Select
          name="manufacturingYear"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.manufacturingYear}
          invalidValue={
            !!formik.touched.manufacturingYear &&
            !!formik.errors.manufacturingYear
          }
          disabled={!formik.values.model.value}
        >
          <option value="">Selecione</option>
          {formik.values.model.manufacturingYears.map(
            (year: number, index: number) => (
              <option key={index} value={year}>
                {year}
              </option>
            )
          )}
        </Select>
        <FormError name="manufacturingYear" />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="modelYear">Ano do modelo</Label>
        <Select
          name="modelYear"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.modelYear}
          invalidValue={!!formik.touched.modelYear && !!formik.errors.modelYear}
          disabled={!formik.values.model.value}
        >
          <option value="">Selecione</option>
          {formik.values.model.manufacturingYears.map(
            (year: number, index: number) => (
              <option key={index} value={year}>
                {year}
              </option>
            )
          )}
        </Select>
        <FormError name="modelYear" />
      </FormGroup>
    </FormRow>
  );
};

export default YearsSelects;
