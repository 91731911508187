import React from 'react';

import { IconType } from 'react-icons';

import { Container, Text } from './styles';

interface MenuItemProps {
  text: string;
  icon: IconType;
  onClick: () => void;
  isLogoutItem?: boolean;
}

const MenuItem: React.FC<MenuItemProps> = ({
  text,
  icon: Icon,
  onClick,
  isLogoutItem,
}) => {
  return (
    <Container onClick={onClick} isLogoutItem={isLogoutItem}>
      <Icon />
      <Text>{text}</Text>
    </Container>
  );
};

export default MenuItem;
