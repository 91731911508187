import { useCallback } from 'react';

import { useApi, endpoints } from '@contexts/Api';

const useAuthentication = () => {
  const { request } = useApi();

  const getCitiesByState = useCallback(
    async <CityFields extends keyof City>(params: CitiesQuery) => {
      const response = await request<Array<Pick<City, CityFields>>>({
        method: 'get',
        url: endpoints.core.cities.list,
        params: {
          fields: params.fields.join(','),
          state: params.state,
          paginate: false,
        },
      });

      return response.data;
    },
    [request]
  );

  return {
    getCitiesByState,
  };
};

export default useAuthentication;
