import React, { useState } from 'react';

import { B2Button } from 'react-b2components';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAuth } from '@contexts/Auth';
import { useAuthentication } from '@hooks';

import RoutesPath from '@router/routes';

import {
  Container,
  CustomLoading,
  ErrorIcon,
  Message,
  MessageContainer,
  SuccessIcon,
} from './styles';

const ConfirmEmail: React.FC = () => {
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);

  const [searchParams] = useSearchParams();
  const { confirmEmail } = useAuthentication();
  const { logout, userInfo } = useAuth();
  const navigate = useNavigate();

  const confirmEmailRequest = useQuery(
    [],
    () => {
      if (userInfo) {
        logout?.();
      }
      return confirmEmail(searchParams.get('token')!);
    },
    {
      onSuccess: () => {
        setMessage('E-mail confirmado com sucesso');
      },
      onError: () => {
        setMessage('Não foi possível confirmar o e-mail, tente novamente');
        setError(true);
      },
    }
  );

  return (
    <>
      {confirmEmailRequest.isLoading ? (
        <CustomLoading />
      ) : (
        <Container>
          <MessageContainer>
            {error ? <ErrorIcon /> : <SuccessIcon />}
            <Message>{message}</Message>
          </MessageContainer>
          <B2Button
            onClick={() => {
              navigate(RoutesPath.auth.login);
            }}
          >
            Ir para o login
          </B2Button>
        </Container>
      )}
    </>
  );
};

export default ConfirmEmail;
