import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useToast } from '@contexts/Toast';
import { usePosts } from '@hooks';

import RoutesPath from '@router/routes';
import { enums } from '@utils';

const usePostForm = (post: number) => {
  const {
    updatePost,
    updateVehicleCondition,
    createPostImage,
    deletePostImage,
    completePost,
    updatePostCustomFieldAnswers,
  } = usePosts();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { addToast } = useToast();

  const updateRequest = useMutation(
    (data: FormValuesPost) => {
      return updatePost(post, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('posts');
      },
    }
  );

  const updateVehicleConditionsRequests = useMutation(
    (data: FormValuesVehicleConditions) => {
      return Promise.all(
        data.vehicleConditions.map((vehicleCondition) => {
          updateVehicleCondition(vehicleCondition.id, vehicleCondition.state);
        })
      );
    }
  );

  const deletePhotos = (photos: Array<FormPostImage>) => {
    const requests = photos.map((photo) => {
      return deletePostImage(photo.id);
    });

    return Promise.all(requests);
  };

  const createPhotos = (data: FormValuesPhotos) => {
    let mainPhotoRequest;
    if (data.mainPhoto?.new) {
      mainPhotoRequest = createPostImage({
        post,
        image: data.mainPhoto.id,
        type: enums.CarPhotoType.MAIN,
      });
    }

    const newOtherPhotos = data.photos.filter((photo) => photo.new);
    const newOtherPhotosRequests = newOtherPhotos.map((photo) => {
      createPostImage({
        post,
        image: photo.id,
        type: enums.CarPhotoType.OTHER,
      });
    });

    return Promise.all([mainPhotoRequest, ...newOtherPhotosRequests]);
  };

  const uploadVehiclePhotos = useMutation(async (data: FormValuesPhotos) => {
    await deletePhotos(data.excludedPhotos);
    await createPhotos(data);
    return;
  });

  const updateExtraInfo = useMutation(
    async ({ customFields }: FormValuesExtraInfo) =>
      updatePostCustomFieldAnswers({
        post,
        customFieldsAnswers: Object.keys(customFields).map((customFieldId) => ({
          customField: Number(customFieldId),
          answer:
            customFields[customFieldId].answer === ''
              ? undefined
              : customFields[customFieldId].answer,
        })),
      })
  );

  const completeRequest = useMutation(
    (data: FormValuesVehicleValues) => {
      if (!data.isComplete) {
        return Promise.resolve({});
      }

      return completePost(post);
    },
    {
      onSuccess: (_, variables) => {
        if (variables.isComplete) {
          addToast('Anúncio criado com sucesso', 'success');
          navigate(RoutesPath.private.posts.list.complete, { replace: true });
        } else {
          addToast('Anúncio salvo como rascunho', 'info');
          navigate(RoutesPath.private.posts.list.draft, { replace: true });
        }
        queryClient.invalidateQueries('posts');
      },
      onError: () => {
        addToast(
          'Falha ao salvar o anúncio, tente novamente mais tarde',
          'error'
        );
      },
    }
  );

  return {
    updateRequest,
    updateVehicleConditionsRequests,
    uploadVehiclePhotos,
    updateExtraInfo,
    completeRequest,
  };
};

export default usePostForm;
