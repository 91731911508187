import styled, { keyframes } from 'styled-components';
import { commonInputStyle } from 'utils/styles';

import Label from '@components/Label';

interface InputProps {
  invalidValue?: boolean;
}

export const CustomFormGroup = styled.div`
  position: relative;
  width: 100%;
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(10px);
  }
`;

export const CustomLabel = styled(Label)<{ isShowing: boolean }>`
  animation: ${({ isShowing }) => (isShowing ? fadeIn : fadeOut)} 0.25s
    ease-in-out;
  opacity: ${({ isShowing }) => (isShowing ? 1 : 0)};
  position: absolute;
  top: -10px;
`;

export const CustomInput = styled.input<InputProps>`
  ${commonInputStyle};
  width: 100%;
  padding: 15px 46px 15px 0px;
  border-radius: 0;
  background-color: ${({ theme }) => theme.colors.white.main};
  border-bottom: 1px solid ${({ theme }) => theme.colors.white.three};

  &:focus {
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.colors.main};
    outline: 0;
  }

  &[type='radio'] {
    width: 20px;
    height: 20px;
    appearance: none;
    display: inline-block;
    padding: 3px;
    background-clip: content-box;
    border: 2px solid ${({ theme }) => theme.colors.gray.four};
    background-color: ${({ theme }) => theme.colors.white.main};
    transition: all ease-in 0.25s;
    &:checked {
      background-color: ${({ theme }) => theme.colors.main};
      border: 2px solid ${({ theme }) => theme.colors.main};
    }
  }

  &[type='checkbox'] {
    width: 20px;
    height: 20px;
    accent-color: ${({ theme }) => theme.colors.main};
    transition: all ease-in 0.25s;
  }
`;
