import React, { useMemo } from 'react';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { useToast } from '@contexts/Toast';
import { usePosts } from '@hooks';

import AproveCard from '@components/AproveCard';
import PageHeader from '@components/PageHeader';
import RequestContainer from '@components/RequestContainer';

import RoutesPath from '@router/routes';
import { enums } from '@utils';

import ClientInfo from './ClientInfo';
import Inspection from './Inspection';
import MainCard from './MainCard';
import Photos from './Photos';
import VehicleInfo from './VehicleInfo';
import getRoutesFromClients from './routesFromClient';
import getRoutesFromList from './routesFromList';
import { Content } from './styles';

const DetailPost: React.FC = () => {
  const { clientId, postId } = useParams<{
    clientId?: string;
    postId: string;
  }>();
  const { getPost, deletePost, approvePost, rejectPost } = usePosts();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { addToast } = useToast();

  const fetchPost = useQuery(['post', postId], () => getPost(Number(postId)), {
    enabled: !!postId,
  });

  const requestDeletePost = useMutation(() => deletePost(Number(postId!)), {
    onSuccess: () => {
      addToast('Anúncio apagado com sucesso', 'success');
      if (fetchPost.data?.is_complete) {
        navigate(RoutesPath.private.posts.list.complete);
      } else {
        navigate(RoutesPath.private.posts.list.draft);
      }
      queryClient.invalidateQueries('posts');
    },
  });

  const requestChangeAproveStatus = useMutation(
    ({
      newStatus,
      rejectionReason,
    }: {
      newStatus: enums.Status;
      rejectionReason?: string;
    }) => {
      if (newStatus === enums.Status.APPROVED) {
        return approvePost(Number(postId!));
      }

      return rejectPost(Number(postId!), rejectionReason!);
    },
    {
      onSuccess: () => {
        addToast('Anúncio atualizado com sucesso', 'success');
        navigate(
          RoutesPath.private.posts.list.complete +
            `?status=${enums.PostStatusMap[enums.PostStatus.PENDING]}`
        );
      },
      onError: () => {
        addToast('Falha ao atualizar o status', 'error');
      },
    }
  );

  const storeType = useMemo(() => {
    if (fetchPost.data?.store_info.document.length === 11) {
      return enums.StoreType.PHYSICAL;
    }

    return enums.StoreType.LEGAL;
  }, [fetchPost.data?.store_info.document.length]);

  const routes = useMemo(() => {
    if (clientId) {
      return getRoutesFromClients(Number(clientId), storeType);
    }

    return getRoutesFromList(fetchPost.data?.is_complete ?? false);
  }, [clientId, fetchPost.data?.is_complete, storeType]);

  return (
    <RequestContainer requests={[fetchPost]}>
      <>
        {fetchPost.data?.is_complete ? (
          <PageHeader title="Detalhes do anúncios" routes={routes} />
        ) : (
          <PageHeader
            title="Detalhes do anúncios"
            routes={routes}
            deleteProps={{
              button: {
                text: 'Apagar anúncio',
              },
              modal: {
                title: 'Apagar anúncio',
                message: 'Tem certeza que deseja apagar esse anúncio?',
                isLoading: requestDeletePost.isLoading,
                onConfirm: requestDeletePost.mutate,
              },
            }}
            primaryButton={{
              text: 'Editar anúncio',
              onClick: () =>
                navigate(
                  RoutesPath.private.posts.edit.replace(':postId', postId!)
                ),
            }}
          />
        )}
        {fetchPost.data && (
          <Content>
            {fetchPost.data.status === enums.PostStatus.PENDING && (
              <AproveCard
                onClickAproveButton={() =>
                  requestChangeAproveStatus.mutate({
                    newStatus: enums.Status.APPROVED,
                  })
                }
                onClickRejectModal={(rejectionReason) =>
                  requestChangeAproveStatus.mutate({
                    newStatus: enums.Status.REJECTED,
                    rejectionReason,
                  })
                }
                approveMessage="Tem certeza que deseja aprovar esse anúncio?"
                rejectMessage="Tem certeza que deseja rejeitar esse anúncio?"
                isLoading={requestChangeAproveStatus.isLoading}
              >
                Deseja aprovar ou rejeitar o anúncio?
              </AproveCard>
            )}
            <MainCard
              post={fetchPost.data}
              onChangeStatus={() => {
                fetchPost.refetch();
              }}
            />
            <ClientInfo post={fetchPost.data} />
            <Inspection
              post={fetchPost.data.id}
              inspectionDocument={fetchPost.data.inspection_document}
              onChangeFile={fetchPost.refetch}
            />
            <VehicleInfo post={fetchPost.data} />
            <Photos post={fetchPost.data} />
          </Content>
        )}
      </>
    </RequestContainer>
  );
};

export default DetailPost;
