import { color } from 'b2utils';
import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const CustomInput = styled.input`
  width: 100%;
  padding: 15px 16px 15px 46px;
  background-position-y: 50%;
  background-repeat: no-repeat;
  background-position-x: 16px;
  background-image: url('/images/search.svg');
  background-color: ${({ theme }) => theme.colors.white.main};
  border: 1px solid ${({ theme }) => theme.colors.white.three};
  border-radius: 16px;

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray.six};
  }

  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.info};
    outline: 2px solid
      ${(props) => color.addOpacityOnHexColor(props.theme.colors.info, 0.1)};
  }
`;

const rotating = keyframes`
  to {
    transform: rotate(1turn);
  }
`;

export const Loading = styled.span`
  animation: ${rotating} 1s infinite;
  border: 6px solid ${(props) => props.theme.colors.gray.one};
  border-radius: 50%;
  border-top-color: ${(props) => props.theme.colors.main};
  height: 24px;
  width: 24px;
  position: absolute;
  top: 15px;
  right: 16px;
`;
