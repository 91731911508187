import React from 'react';

import { currency } from 'b2utils';
import moment from 'moment';

import {
  Container,
  FipeLastUpdate,
  FipeTitle,
  FipeValue,
  FooterLeftSide,
  Table,
} from './styles';

interface SuggestedPricesTableProps {
  systemPrices: SuggestedPrice;
  fipePrice: FipeTable;
}

const SuggestedPricesTable: React.FC<SuggestedPricesTableProps> = ({
  systemPrices,
  fipePrice,
}) => {
  return (
    <Container>
      <Table>
        <thead>
          <tr>
            <th></th>
            <th>Brasil</th>
            <th>Estado ({systemPrices.state.name})</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Mínimo</td>
            <td>
              {currency.centsToBrl(systemPrices.global.min_vehicle_price ?? 0)}
            </td>
            <td>
              {currency.centsToBrl(
                systemPrices.state.prices.min_vehicle_price ?? 0
              )}
            </td>
          </tr>
          <tr>
            <td>Médio</td>
            <td>{currency.centsToBrl(systemPrices.global.avg ?? 0)}</td>
            <td>{currency.centsToBrl(systemPrices.state.prices.avg ?? 0)}</td>
          </tr>
          <tr>
            <td>Máximo</td>
            <td>
              {currency.centsToBrl(systemPrices.global.max_vehicle_price ?? 0)}
            </td>
            <td>
              {currency.centsToBrl(
                systemPrices.state.prices.max_vehicle_price ?? 0
              )}
            </td>
          </tr>
          <tr>
            <td>
              <FooterLeftSide>
                <FipeTitle>Tabela Fipe</FipeTitle>
                <FipeLastUpdate>
                  Última atualização:{' '}
                  {moment(fipePrice.modified).format('DD/MM/YYYY')}
                </FipeLastUpdate>
              </FooterLeftSide>
            </td>
            <td>
              <FipeValue>{currency.centsToBrl(fipePrice.price ?? 0)}</FipeValue>
            </td>
          </tr>
        </tbody>
      </Table>
    </Container>
  );
};

export default SuggestedPricesTable;
