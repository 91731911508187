import { B2Button } from 'react-b2components';
import styled from 'styled-components';

import Button from '@components/Button';

export const Container = styled.div`
  background: ${(props) => props.theme.colors.main};
  display: flex;
  height: 100vh;
`;

export const LeftPanel = styled.div`
  width: 40%;
  display: flex;
  flex-direction: row;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const LeftPanelContainer = styled.div`
  width: 100%;
  display: grid;
  gap: 60px;
  padding: 24px;
`;

export const LeftPanelTop = styled.div`
  width: 100%;
  padding: 120px 0;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const LeftPanelCenter = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  color: ${(props) => props.theme.colors.white.one};
  font-size: ${(props) => props.theme.fontSize.medium};
`;

export const LeftPanelCenterBold = styled.span`
  font-weight: ${(props) => props.theme.fontWeight.semiBold};
`;
export const LeftPanelBottom = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;
  justify-content: start;
  color: ${(props) => props.theme.colors.white.one};
  gap: 8px;
`;

export const LeftPanelBottomBtn = styled(B2Button)`
  color: ${(props) => props.theme.colors.white.one};
  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: ${(props) => props.theme.fontWeight.regular};
  white-space: nowrap;
  padding: 2px;
`;

export const Logo = styled.img`
  width: 70%;
  animation: show-rightwards 1s ease-out, fade-in 1s ease-out;
  animation: float 5s ease-in-out infinite;
`;

export const RightPanel = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: show-downwards 1s ease-out, fade-in 1s ease-out;
  border-top-left-radius: ${(props) => props.theme.borderRadius};
  border-bottom-left-radius: ${(props) => props.theme.borderRadius};
  background: ${(props) => props.theme.colors.white.main};

  @media (max-width: 800px) {
    width: 100%;
    background-color: ${(props) => props.theme.colors.white.main};
  }
`;

export const Card = styled.div`
  width: min(405px, 80%);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  img {
    align-self: center;
    margin-bottom: 100px;

    @media (min-width: 800px) {
      display: none;
    }
  }
`;

export const CardTitle = styled.h2`
  margin-top: 0;
  font-size: ${(props) => props.theme.fontSize.big};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  color: ${(props) => props.theme.colors.black.two};
  align-self: flex-start;
`;

export const AuthButton = styled(Button)`
  margin-top: 25px;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
