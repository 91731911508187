import styled from 'styled-components';

export const Container = styled.svg`
  color: ${({ theme }) => theme.colors.black.two};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  flex-shrink: 0;
`;

export const Circle = styled.circle`
  transition: stroke-dashoffset 0.5s ease-out;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
`;

export const Text = styled.pre`
  font-family: inherit;
  font-size: ${({ theme }) => theme.fontSize.smaller};
  position: absolute;
  top: 15%;
  left: 15%;
  text-align: center;
`;
