import React from 'react';

import { Container, StatusActive, StatusInactive } from './styles';

interface Status {
  isActive: boolean;
}

const Status: React.FC<Status> = ({ isActive }) => {
  return (
    <Container>
      {isActive ? (
        <StatusActive>Ativo</StatusActive>
      ) : (
        <StatusInactive>Inativo</StatusInactive>
      )}
    </Container>
  );
};

export default Status;
