import styled from 'styled-components';

import Button from '@components/Button';

export const Text = styled.p`
  margin: 12px;
`;

export const CloseButton = styled(Button)`
  align-self: flex-end;
  margin: 12px;
`;
