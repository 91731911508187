import React, { useCallback } from 'react';

import { FormikHelpers, FormikProvider, useFormik } from 'formik';
import { B2ImagePicker } from 'react-b2components';
import { useMutation, useQueryClient } from 'react-query';
import * as yup from 'yup';

import { useAuth } from '@contexts/Auth';
import { useToast } from '@contexts/Toast';
import { useAssets, useAuthentication } from '@hooks';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';
import Loading from '@components/Loading';
import SectionHeader from '@components/SectionHeader';

import { errors } from '@utils';

import {
  Footer,
  FooterButton,
  ProfileCard,
  PictureContainer,
  ProfilePictureContainer,
  ProfilePictureGroup,
} from '../styles';
import { RemoveImageButton } from './styles';

interface ProfileFormData {
  avatar: Image | null;
  name: string;
}

export const ProfileInfo = () => {
  const { userInfo } = useAuth();
  const { getPresignedImageUrl, uploadS3Image, uploadImage } = useAssets();
  const { updateProfile } = useAuthentication();
  const { addToast } = useToast();
  const queryClient = useQueryClient();

  const formikInitialValues: ProfileFormData = {
    avatar: userInfo?.avatar ?? null,
    name: userInfo?.name ?? '',
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required(errors.required),
  });

  const { mutateAsync: requestPresignedUrl } =
    useMutation(getPresignedImageUrl);

  const { mutateAsync: sendS3UrlToApi } = useMutation(
    ({ presignedUrl }: { presignedUrl: PresignedUrl }) =>
      uploadImage(presignedUrl!)
  );

  const { mutateAsync: requestUploadS3Image } = useMutation(
    ({ presignedUrl, file }: { presignedUrl: PresignedUrl; file: File }) =>
      uploadS3Image(presignedUrl!, file)
  );

  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema,
    onSubmit: (values) => updateProfileRequest.mutate(values),
  });

  const updateProfileRequest = useMutation(updateProfile, {
    onSuccess: () => {
      addToast('Dados atualizados', 'success');
      queryClient.invalidateQueries('userInfo');
    },
  });

  const handleChooseImage = useCallback(
    async (file: File, formik: FormikHelpers<ProfileFormData>) => {
      try {
        const presignedUrl = await requestPresignedUrl();
        await requestUploadS3Image({ presignedUrl, file });
        const result = await sendS3UrlToApi({ presignedUrl });
        formik.setFieldValue('avatar', result);
      } catch (error) {
        addToast(
          'Não foi possível atualizar a imagem, tente novamente',
          'error'
        );
      }
    },
    [addToast, requestPresignedUrl, requestUploadS3Image, sendS3UrlToApi]
  );

  return (
    <>
      <ProfileCard>
        <SectionHeader title="Perfil" />
        <FormikProvider value={formik}>
          <ProfilePictureContainer>
            <PictureContainer>
              <ProfilePictureGroup>
                <Label>Avatar</Label>
                <B2ImagePicker
                  loadingComponent={Loading}
                  extensions={['image/jpg', 'image/jpeg', 'image/png']}
                  maxSize={5000000}
                  text="Escolha uma imagem"
                  imageAlt="Avatar"
                  imageUrl={formik.values.avatar?.high}
                  onChooseImage={(image) => handleChooseImage(image, formik)}
                  onInvalidExtension={() =>
                    addToast(
                      'Formato invalido, envie no formato jpg, jpeg ou png',
                      'error'
                    )
                  }
                  onInvalidSize={() =>
                    addToast('Tamanho máximo de 5MB', 'error')
                  }
                />
                {formik.values.avatar && (
                  <RemoveImageButton
                    variant="transparent"
                    onClick={() => formik.setFieldValue('avatar', null)}
                  >
                    Remover foto
                  </RemoveImageButton>
                )}
              </ProfilePictureGroup>
            </PictureContainer>
          </ProfilePictureContainer>
          <FormRow>
            <FormGroup>
              <Label htmlFor="name">Nome</Label>
              <Input
                type="text"
                id="name"
                name="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                invalidValue={!!formik.touched.name && !!formik.errors.name}
              />
              <FormError name="name" />
            </FormGroup>
          </FormRow>
        </FormikProvider>
      </ProfileCard>
      <Footer>
        <FooterButton
          type="submit"
          variant="primary"
          onClick={() => formik.handleSubmit()}
        >
          Salvar
        </FooterButton>
      </Footer>
    </>
  );
};
