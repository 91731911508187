import React from 'react';

import { UseQueryResult } from 'react-query';

import ErrorAnimation from '@components/ErrorAnimation';
import Loading from '@components/Loading';

interface RequestContainerProps {
  requests: Array<UseQueryResult<any, unknown>>;
  children: React.ReactElement;
}

const RequestContainer: React.FC<RequestContainerProps> = ({
  requests,
  children,
}) => {
  if (requests.some((request) => request.isLoading)) {
    return <Loading />;
  }

  if (requests.some((request) => request.isError)) {
    return <ErrorAnimation />;
  }

  return children;
};

export default RequestContainer;
