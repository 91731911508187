import React, { forwardRef, useImperativeHandle, useState } from 'react';

import { FormikProvider, useFormik } from 'formik';
import { useQuery } from 'react-query';
import * as yup from 'yup';

import { usePosts } from '@hooks';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input, { RadioButtonsGroup } from '@components/Input';
import Label from '@components/Label';
import SearchFilter from '@components/SearchFilter';

import { enums } from '@utils';

import { Grid } from './styles';

interface TagsProps {
  initialValues?: FormValuesTags;
  onFinish: (data: FormValuesTags) => void;
  category: number;
}

const fields = ['id', 'name'] as const;

type ListTagFields = (typeof fields)[number];

const Tags: React.ForwardRefRenderFunction<FormStepRef, TagsProps> = (
  { initialValues, onFinish, category },
  ref
) => {
  const [queryParams, setQueryParams] = useState<TagQueryParams>({
    fields: fields as unknown as Array<keyof Tag>,
    type: enums.TagType.OPTIONAL,
    category,
  });

  const { listTags } = usePosts();

  const { data: optionalTags } = useQuery(['optionalTags', queryParams], () =>
    listTags<ListTagFields>(queryParams)
  );

  const formikInitialValues: FormValuesTags = {
    tags: optionalTags?.map((tag) => tag.id.toString()) ?? [],
    ...initialValues,
  };

  const formikValidationSchema = yup.object().shape({
    tags: yup.array(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  return (
    <FormikProvider value={formik}>
      <FormRow>
        <FormGroup>
          <SearchFilter
            onSearch={(search) =>
              setQueryParams((prevState) => ({
                ...prevState,
                search,
              }))
            }
          />
        </FormGroup>
      </FormRow>
      <Grid>
        {optionalTags?.map((option) => (
          <FormGroup key={option.id}>
            <RadioButtonsGroup role="group">
              <Input
                type="checkbox"
                id={`checkbox${option.id}`}
                name="tags"
                checked={formik.values.tags.includes(option.id.toString())}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={option.id}
              />
              <Label htmlFor={`checkbox${option.id}`}>{option.name}</Label>
            </RadioButtonsGroup>
            <FormError name="isArmored" />
          </FormGroup>
        ))}
      </Grid>
    </FormikProvider>
  );
};

export default forwardRef(Tags);
