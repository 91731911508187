import React, { useState } from 'react';

import { currency } from 'b2utils';
import { B2TableDataCell, B2TableRow } from 'react-b2components';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { useToast } from '@contexts/Toast';
import { usePayments } from '@hooks';

import PageHeader from '@components/PageHeader';
import RequestContainer from '@components/RequestContainer';
import Table from '@components/Table';

import RoutesPath from '@router/routes';
import { enums } from '@utils';

import routes from './routes';
import {
  Card,
  Content,
  ExtraInfValue,
  ExtraInfoContent,
  ExtraInfoGrid,
  ExtraInfoLabel,
  PlanTitle,
  SectionTitle,
  Subtitle,
  Switch,
} from './styles';

const fields = ['id', 'customer_info', 'status'] as const;

type ListSubscriptionsFields = (typeof fields)[number];

const DetailPlan: React.FC = () => {
  const { planId } = useParams<{
    planId: string;
  }>();

  const [subscriptionsQueryParams, setSubscriptionsQueryParams] =
    useState<SubscriptionsQuery>({
      fields: fields as unknown as Array<keyof Subscription>,
      plan: Number(planId!),
      page: 1,
    });

  const { getPlan, listSubscriptions, inactivatePlan, activatePlan } =
    usePayments();
  const navigate = useNavigate();
  const { addToast } = useToast();

  const fetchPlan = useQuery(['plan', planId], () => getPlan(Number(planId)), {
    enabled: !!planId,
  });

  const requestUpdatePostStatus = useMutation(
    () => {
      if (fetchPlan.data?.is_active) {
        return inactivatePlan(fetchPlan.data!.id);
      }

      return activatePlan(fetchPlan.data!.id);
    },
    {
      onSuccess: () => {
        addToast('Status alterado com sucesso', 'success');
        fetchPlan.refetch();
      },
      onError: () => {
        addToast('Falha ao alterar o status', 'error');
      },
    }
  );

  const fetchSubscriptions = useQuery(
    ['subscriptions', subscriptionsQueryParams],
    () => listSubscriptions<ListSubscriptionsFields>(subscriptionsQueryParams),
    {
      enabled: !!planId,
    }
  );

  return (
    <RequestContainer requests={[fetchPlan, fetchSubscriptions]}>
      <>
        <PageHeader
          title="Detalhes do plano"
          routes={routes}
          primaryButton={{
            text: 'Editar plano',
            onClick: () =>
              navigate(
                RoutesPath.private.plans.edit.replace(
                  ':planId',
                  planId!.toString()
                )
              ),
          }}
        />
        {fetchPlan.data && (
          <Content>
            <Card>
              <Switch
                isActive={fetchPlan.data.is_active}
                onChange={requestUpdatePostStatus.mutate}
              />
              <PlanTitle>{fetchPlan.data.name}</PlanTitle>
              <Subtitle data-cy="planPrice">
                {currency.centsToBrl(fetchPlan.data.price)}
              </Subtitle>
              <ExtraInfoContent data-cy="descriptionContainer">
                <ExtraInfoLabel>Descrição:</ExtraInfoLabel>
                <ExtraInfValue>
                  {fetchPlan.data.description || 'Sem descrição'}
                </ExtraInfValue>
              </ExtraInfoContent>
              <ExtraInfoGrid>
                <ExtraInfoContent data-cy="maxNumberActivePostContainer">
                  <ExtraInfoLabel>Max. de anúncios ativos:</ExtraInfoLabel>
                  <ExtraInfValue>
                    {fetchPlan.data.max_number_active_posts}
                  </ExtraInfValue>
                </ExtraInfoContent>
                <ExtraInfoContent data-cy="maxNumberPostContainer">
                  <ExtraInfoLabel>Max. de anúncios anúncios:</ExtraInfoLabel>
                  <ExtraInfValue>
                    {fetchPlan.data.max_number_of_posts}
                  </ExtraInfValue>
                </ExtraInfoContent>
                <ExtraInfoContent data-cy="intervalContainer">
                  <ExtraInfoLabel>Intervalo de cobrança:</ExtraInfoLabel>
                  <ExtraInfValue>{`${fetchPlan.data.interval_count} vez${
                    fetchPlan.data.interval_count > 1 ? 'es' : ''
                  } por ${
                    enums.PlanIntervalMap[fetchPlan.data.interval]
                  }`}</ExtraInfValue>
                </ExtraInfoContent>
                <ExtraInfoContent data-cy="cyclesContainer">
                  <ExtraInfoLabel>Duração:</ExtraInfoLabel>
                  <ExtraInfValue>
                    {fetchPlan.data.cycles}{' '}
                    {enums.PlanPluralIntervalMap[fetchPlan.data.interval]}
                  </ExtraInfValue>
                </ExtraInfoContent>
                <ExtraInfoContent data-cy="installmentsContainer">
                  <ExtraInfoLabel>Quantidade de parcelas:</ExtraInfoLabel>
                  {fetchPlan.data.installments.at(-1)! > 1 ? (
                    <ExtraInfValue>
                      Em até {fetchPlan.data.installments.at(-1)} vezes
                    </ExtraInfValue>
                  ) : (
                    <ExtraInfValue>Sem parcelamento</ExtraInfValue>
                  )}
                </ExtraInfoContent>
                <ExtraInfoContent data-cy="ihHiddenContainer">
                  <ExtraInfoLabel>Plano público:</ExtraInfoLabel>
                  <ExtraInfValue>
                    {fetchPlan.data.is_hidden ? 'Não' : 'Sim'}
                  </ExtraInfValue>
                </ExtraInfoContent>
                {fetchPlan.data.is_hidden && (
                  <ExtraInfoContent data-cy="hiddenLinkContainer">
                    <ExtraInfoLabel>Link de assinatura:</ExtraInfoLabel>
                    <ExtraInfValue>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`${
                          import.meta.env.VITE_PUBLIC_SYSTEM_URL
                        }/planos/assinar/oculto/${
                          fetchPlan.data.hidden_look_up
                        }`}
                      >
                        {`${
                          import.meta.env.VITE_PUBLIC_SYSTEM_URL
                        }/planos/assinar/oculto/${
                          fetchPlan.data.hidden_look_up
                        }`}
                      </a>
                    </ExtraInfValue>
                  </ExtraInfoContent>
                )}
              </ExtraInfoGrid>
            </Card>
            <SectionTitle>Inscrições no plano:</SectionTitle>
            <Table
              fetchData={fetchSubscriptions}
              headerData={['Cliente', 'Status']}
              renderRow={(subscription) => (
                <B2TableRow
                  key={subscription.id}
                  onClick={() => {
                    navigate(
                      RoutesPath.private.clients.detail.legal.detail.replace(
                        ':clientId',
                        subscription.customer_info.store.id.toString()
                      )
                    );
                  }}
                >
                  <B2TableDataCell>
                    {subscription.customer_info.name}
                  </B2TableDataCell>
                  <B2TableDataCell>
                    {enums.SubscriptionStatusMap[subscription.status]}
                  </B2TableDataCell>
                </B2TableRow>
              )}
              paginator
              changePage={(newPage) =>
                setSubscriptionsQueryParams((prevState) => ({
                  ...prevState,
                  page: newPage,
                }))
              }
              currentPage={subscriptionsQueryParams.page}
            />
          </Content>
        )}
      </>
    </RequestContainer>
  );
};

export default DetailPlan;
