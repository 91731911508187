import React from 'react';

import { currency } from 'b2utils';
import moment from 'moment';
import { MdPlace } from 'react-icons/md';

import Input from '@components/Input';
import VerifyIcon from '@components/VerifyIcon';

import { enums } from '@utils';

import { ListPost } from '..';
import {
  Card,
  CardContent,
  CardRow,
  CardTitle,
  DraftIndicator,
  StatusIndicatorContainer,
  HighlightText,
  PendingIndicator,
  PostImage,
  RejectIndicator,
  StoreInfoContainer,
  StoreInfoText,
  StoreLogo,
  Subtitle,
  Text,
  CheckboxContainer,
} from './styles';

interface PostCardProps {
  post: ListPost;
  onClick: () => void;
  showStatusIndicator?: boolean;
  checkboxOptions?: {
    isSelected: boolean;
    onSelect: () => void;
    onUnselect: () => void;
  };
}

const PostCard: React.FC<PostCardProps> = ({
  post,
  onClick,
  showStatusIndicator = false,
  checkboxOptions,
}) => {
  const statusMap = {
    [enums.PostStatusIndicator.DRAFT]: (
      <StatusIndicatorContainer>
        <DraftIndicator>RASCUNHO</DraftIndicator>
      </StatusIndicatorContainer>
    ),
    [enums.PostStatusIndicator.PENDING]: (
      <StatusIndicatorContainer>
        <PendingIndicator>PENDENTE</PendingIndicator>
      </StatusIndicatorContainer>
    ),
    [enums.PostStatusIndicator.REJECTED]: (
      <StatusIndicatorContainer>
        <RejectIndicator>REJEITADO</RejectIndicator>
      </StatusIndicatorContainer>
    ),
  };

  const shouldShowStatusIndicator =
    showStatusIndicator &&
    (!post.is_complete ||
      post.status === enums.PostStatus.PENDING ||
      post.status === enums.PostStatus.REJECTED);

  return (
    <Card onClick={onClick}>
      {checkboxOptions && (
        <CheckboxContainer onClick={(event) => event.stopPropagation()}>
          <Input
            type="checkbox"
            onChange={
              checkboxOptions.isSelected
                ? checkboxOptions.onUnselect
                : checkboxOptions.onSelect
            }
            checked={checkboxOptions.isSelected}
          />
        </CheckboxContainer>
      )}
      <PostImage
        applyOpacity={shouldShowStatusIndicator}
        src={post.main_image?.image.medium ?? '/images/logo.svg'}
      />
      {shouldShowStatusIndicator && (
        <>
          {!post.is_complete
            ? statusMap[enums.PostStatusIndicator.DRAFT]
            : statusMap[post.status as unknown as enums.PostStatusIndicator]}
        </>
      )}
      <CardContent>
        <CardRow>
          <CardTitle>
            <HighlightText>
              {post.vehicle_brand.name}
              {post.inspection_document && (
                <VerifyIcon size="20" variant="Bold" />
              )}
            </HighlightText>
            <HighlightText>{post.vehicle_model.name}</HighlightText>
          </CardTitle>
          <HighlightText>
            {post.vehicle_price
              ? currency.centsToBrl(post.vehicle_price)
              : 'Sem preço'}
          </HighlightText>
        </CardRow>
        <Subtitle>{post.vehicle_version.name}</Subtitle>
        <CardRow>
          <Text>
            {post.vehicle_year}/{post.vehicle_manufacturing_year}
          </Text>
        </CardRow>
        <CardRow>
          <Text>
            Última atualização: {moment(post.modified).format('DD/MM/YYYY')}
          </Text>
        </CardRow>
        <hr />
        <CardRow>
          <StoreInfoContainer>
            <MdPlace />
            <StoreInfoText>
              {post.store_info.address?.city.name}(
              {post.store_info.address?.city.state})
            </StoreInfoText>
          </StoreInfoContainer>
          <StoreInfoContainer>
            <StoreLogo
              src={
                post.store_info.logo
                  ? post.store_info.logo.high
                  : '/images/icon.svg'
              }
            />
            <StoreInfoText>{post.store_info.name}</StoreInfoText>
          </StoreInfoContainer>
        </CardRow>
      </CardContent>
    </Card>
  );
};

export default PostCard;
