import React from 'react';

import { SidebarItems } from '../SidebarItems';
import SideBarItem from './SideBarItem';
import { Container } from './styles';

const Sidebar: React.FC = () => {
  return (
    <Container data-cy="sidebar">
      {SidebarItems.map(({ name, route, icon }, index) => (
        <SideBarItem
          key={index}
          index={index}
          icon={icon}
          name={name}
          route={route}
        />
      ))}
    </Container>
  );
};

export default Sidebar;
