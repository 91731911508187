import React, { useRef } from 'react';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { useToast } from '@contexts/Toast';
import { useStores } from '@hooks';

import BaseForm from '@components/BaseForm';
import PageHeader from '@components/PageHeader';
import RequestContainer from '@components/RequestContainer';

import RoutesPath from '@router/routes';

import FaqInfo from '../Form/FaqInfo';
import getRoutes from './routes';

const EditFaq: React.FC = () => {
  const faqInfoRef = useRef<FormStepRef>(null);

  const { faqId } = useParams<{ faqId: string }>();
  const { getFaq, updateFaq } = useStores();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const fetchFaq = useQuery(['faq', faqId], () => getFaq(Number(faqId)), {
    enabled: !!faqId,
  });

  const updateRequest = useMutation(
    (faq: FormValuesFaqInfo) => updateFaq(Number(faqId!), faq),
    {
      onSuccess: () => {
        addToast('FAQ atualizado com sucesso', 'success');
        navigate(RoutesPath.private.faq.detail.replace(':faqId', faqId!));
        queryClient.invalidateQueries(['faq', Number(faqId!)]);
      },
    }
  );

  return (
    <>
      <PageHeader title="Edição de FAQ" routes={getRoutes(faqId!)} />
      <RequestContainer requests={[fetchFaq]}>
        <>
          {fetchFaq.data && (
            <BaseForm
              initialValues={fetchFaq.data}
              steps={[
                {
                  title: 'Dados do pergunta',
                  ref: faqInfoRef,
                  component: FaqInfo,
                },
              ]}
              createRequest={updateRequest}
            />
          )}
        </>
      </RequestContainer>
    </>
  );
};

export default EditFaq;
