import { B2Button } from 'react-b2components';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  @media (max-width: 390px) {
    align-items: center;
  }
`;

export const PageTitles = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 390px) {
    align-items: center;
  }
`;

export const MainContent = styled.div`
  flex: 1;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 390px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const Title = styled.h1`
  color: ${(props) => props.theme.colors.gray.twelve};
  font-size: ${(props) => props.theme.fontSize.large};
  font-weight: ${(props) => props.theme.fontWeight.semiBold};
`;

export const Subtitle = styled.h2`
  margin-top: 4px;
  color: ${(props) => props.theme.colors.gray.seven};
  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: ${(props) => props.theme.fontWeight.regular};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const DeleteButton = styled(B2Button)`
  background-color: ${(props) => props.theme.colors.error};
`;
