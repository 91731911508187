import React from 'react';

import { ImportSquare } from 'iconsax-react';
import { Tooltip } from 'react-tooltip';

import Spinner from '@components/Spinner';

import {
  Button,
  ButtonText,
  Container,
  FileName,
  FirstLineContent,
  InfoText,
} from './styles';

export interface PickerButtonProps {
  fileName: string;
  buttonText: string;
  onButtonClick: () => void;
  isLoading?: boolean;
  disabled?: boolean;
  disabledMessage?: string;
  suggestedResolution?: string;
}

const PickerButton: React.FC<PickerButtonProps> = ({
  fileName,
  buttonText,
  onButtonClick,
  isLoading = false,
  disabled = false,
  disabledMessage,
  suggestedResolution,
}) => {
  return (
    <Container>
      {disabled && <Tooltip id="disabled-tooltip" />}
      <Button
        onClick={onButtonClick}
        disabled={disabled}
        data-tooltip-id="disabled-tooltip"
        data-tooltip-content={disabledMessage}
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <FirstLineContent>
              <ButtonText>{buttonText}</ButtonText>
              <ImportSquare size={16} />
            </FirstLineContent>
            <InfoText>Tamanho máx.: 5MB</InfoText>
            {suggestedResolution && (
              <InfoText>Resolução sugerida: {suggestedResolution}</InfoText>
            )}
          </>
        )}
      </Button>
      <FileName>{fileName}</FileName>
    </Container>
  );
};

export default PickerButton;
