import RoutesPath from 'router/routes';

import { enums } from '@utils';

const getRoutes = (id: number, type: enums.StoreType) => {
  if (type === enums.StoreType.PHYSICAL) {
    return [
      {
        path: RoutesPath.private.clients.list.physical,
        label: 'Lista de clientes',
      },
      {
        path: RoutesPath.private.clients.detail.physical.detail.replace(
          ':clientId',
          id.toString()
        ),
        label: 'Detalhes do cliente',
      },
      {
        path: RoutesPath.private.clients.edit,
        label: 'Editar cliente',
      },
    ];
  }

  return [
    {
      path: RoutesPath.private.clients.list.legal,
      label: 'Lista de clientes',
    },
    {
      path: RoutesPath.private.clients.detail.legal.detail.replace(
        ':clientId',
        id.toString()
      ),
      label: 'Detalhes do cliente',
    },
    {
      path: RoutesPath.private.clients.edit,
      label: 'Editar cliente',
    },
  ];
};

export default getRoutes;
