import { color } from 'b2utils';
import { css } from 'styled-components';

interface InputProps {
  invalidValue?: boolean;
}

export const commonInputStyle = css<InputProps>`
  width: 100%;
  border-radius: 12px;
  padding: 15px 16px;
  border: 1px solid transparent;
  color: ${({ theme }) => theme.colors.black.two};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  background-color: ${({ theme }) => theme.colors.white.two};

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray.four};
  }

  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.info};
    outline: 2px solid
      ${(props) => color.addOpacityOnHexColor(props.theme.colors.info, 0.1)};
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  ${({ theme, invalidValue }) =>
    invalidValue &&
    css`
      border: 1px solid ${theme.colors.error};
      padding: 15px 46px 15px 16px;
      background-position-y: 50%;
      background-repeat: no-repeat;
      background-position-x: calc(100% - 16px);
      background-image: url('/images/close.svg');
      outline: 2px solid
        ${(props) => color.addOpacityOnHexColor(props.theme.colors.error, 0.1)};

      &:focus {
        border: 1px solid ${theme.colors.error};
        outline: 2px solid
          ${(props) =>
            color.addOpacityOnHexColor(props.theme.colors.error, 0.1)};
      }
    `}
`;

export const addSpacing = (
  top: string,
  right: string = top,
  bottom: string = top,
  left: string = right
) => {
  return `${top} ${right} ${bottom} ${left}`;
};
