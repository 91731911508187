import React, { useMemo, useState } from 'react';

import { useQuery } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { usePayments, useStores } from '@hooks';

import { Content } from '@components/BaseForm/styles';
import PageHeader, { ButtonProps } from '@components/PageHeader';
import RequestContainer from '@components/RequestContainer';

import RoutesPath from '@router/routes';
import { enums } from '@utils';

import AddCreditsModal from './AddCreditsModal';
import DeletePrivilegeConfirmationModal from './DeletePrivilegeConfirmationModal';
import DeleteSubscriptionConfirmationModal from './DeleteSubscriptionConfirmationModal';
import LegalClientTabs from './LegalClientTabs';
import PhysicalClientTabs from './PhysicalClientTabs';
import getRoutes from './routes';

const listSubscriptionsOrPrivilegesFields = ['id', 'plan'] as const;

type ListSubscriptionsOrPrivilegesFields =
  (typeof listSubscriptionsOrPrivilegesFields)[number];

const DetailClient: React.FC = () => {
  const [isDeletePrivilegeModalOpen, setIsPrivilegeModalOpen] = useState(false);
  const [isDeleteSubscriptionModalOpen, setIsSubscriptionModalOpen] =
    useState(false);
  const [isCreditsModalOpen, setIsCreditsModalOpen] = useState(false);

  const { clientId } = useParams<{ clientId: string }>();
  const { pathname } = useLocation();
  const { getStore } = useStores();
  const { listPrivileges, listSubscriptions } = usePayments();

  const navigate = useNavigate();
  const theme = useTheme();

  const storeType = useMemo(() => {
    if (pathname.includes('pessoa-fisica')) {
      return enums.StoreType.PHYSICAL;
    }

    return enums.StoreType.LEGAL;
  }, [pathname]);

  const privilegeQueryParams: PrivilegesQuery = useMemo(
    () => ({
      fields: listSubscriptionsOrPrivilegesFields as unknown as Array<
        keyof Privilege
      >,
      page: 1,
      store: Number(clientId),
    }),
    [clientId]
  );

  const subscriptionsQueryParams: SubscriptionsQuery = useMemo(
    () => ({
      ...privilegeQueryParams,
      fields: privilegeQueryParams.fields as unknown as Array<
        keyof Subscription
      >,
      status: enums.SubscriptionStatus.ACTIVE,
    }),
    [privilegeQueryParams]
  );

  const fetchStore = useQuery(
    ['store', Number(clientId)],
    () => getStore(Number(clientId)),
    {
      enabled: !!clientId,
    }
  );

  const fetchSubscription = useQuery(
    ['subscriptions', subscriptionsQueryParams],
    () =>
      listSubscriptions<ListSubscriptionsOrPrivilegesFields>(
        subscriptionsQueryParams
      ),
    {
      enabled:
        storeType === enums.StoreType.LEGAL && !fetchStore.data?.has_privilege,
      select: (data) => data.results[0],
    }
  );

  const fetchPrivilege = useQuery(
    ['privileges', privilegeQueryParams],
    () =>
      listPrivileges<ListSubscriptionsOrPrivilegesFields>(privilegeQueryParams),
    {
      enabled:
        storeType === enums.StoreType.LEGAL && fetchStore.data?.has_privilege,
      select: (data) => data.results[0],
    }
  );

  const secondaryButtonProps: ButtonProps | undefined = useMemo(() => {
    if (storeType == enums.StoreType.PHYSICAL) {
      return {
        text: 'Adicionar créditos',
        onClick: () => setIsCreditsModalOpen(true),
      };
    }

    const deletePlanButtonProps = {
      style: {
        borderColor: theme.colors.error,
        color: theme.colors.error,
      },
      text: 'Desassinalar plano',
    };

    if (fetchStore.data?.has_privilege) {
      return {
        ...deletePlanButtonProps,
        onClick: () => {
          setIsPrivilegeModalOpen(true);
        },
      };
    }

    if (fetchSubscription.data) {
      return {
        ...deletePlanButtonProps,
        onClick: () => {
          setIsSubscriptionModalOpen(true);
        },
      };
    }

    return {
      text: 'Assinalar plano',
      onClick: () => {
        navigate(
          RoutesPath.private.clients.detail.legal.addPrivilege.replace(
            ':clientId',
            clientId!.toString()
          )
        );
      },
    };
  }, [
    clientId,
    fetchStore.data?.has_privilege,
    fetchSubscription.data,
    navigate,
    storeType,
    theme.colors.error,
  ]);

  return (
    <RequestContainer
      requests={[fetchStore, fetchSubscription, fetchPrivilege]}
    >
      <>
        {fetchStore.data && (
          <>
            <PageHeader
              title="Detalhes do cliente"
              routes={getRoutes(storeType)}
              primaryButton={{
                text: 'Editar cliente',
                onClick: () =>
                  navigate(
                    RoutesPath.private.clients.edit.replace(
                      ':clientId',
                      clientId!.toString()
                    )
                  ),
              }}
              secondaryButton={secondaryButtonProps}
            />
            <Content>
              {storeType === enums.StoreType.LEGAL ? (
                <LegalClientTabs
                  store={fetchStore.data}
                  privilege={fetchPrivilege.data}
                  subscription={fetchSubscription.data}
                />
              ) : (
                <PhysicalClientTabs store={fetchStore.data} />
              )}
            </Content>
          </>
        )}
        {fetchPrivilege.data && (
          <DeletePrivilegeConfirmationModal
            isOpen={isDeletePrivilegeModalOpen}
            privilege={fetchPrivilege.data?.id}
            onConfirm={() => {
              setIsPrivilegeModalOpen(false);
              fetchPrivilege.refetch();
              fetchStore.refetch();
            }}
            onCancel={() => setIsPrivilegeModalOpen(false)}
          />
        )}
        {fetchSubscription.data && (
          <DeleteSubscriptionConfirmationModal
            isOpen={isDeleteSubscriptionModalOpen}
            subscription={fetchSubscription.data?.id}
            onConfirm={() => {
              setIsSubscriptionModalOpen(false);
              fetchSubscription.refetch();
            }}
            onCancel={() => setIsSubscriptionModalOpen(false)}
          />
        )}
        {fetchStore.data && (
          <AddCreditsModal
            isOpen={isCreditsModalOpen}
            onClose={() => setIsCreditsModalOpen(false)}
            store={fetchStore.data.id}
          />
        )}
      </>
    </RequestContainer>
  );
};

export default DetailClient;
