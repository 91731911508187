import React from 'react';

import { B2TableDataCell, B2TableRow } from 'react-b2components';
import { UseQueryResult } from 'react-query';

import Table from '@components/Table';

import Status from '../Status';

type UserWithRole = UserInfo & {
  role: string;
};

export const UserWithRoleFields = [
  'id',
  'name',
  'email',
  'role',
  'is_active',
] as const;

export type ListUserWithRoleFields = (typeof UserWithRoleFields)[number];

interface UserTableProps<Type extends UserWithRole> {
  fetchUsers: UseQueryResult<Paginated<Type, ListUserWithRoleFields>, unknown>;
  page: number;
  onChangePage: (newPage: number) => void;
  roleMap: any;
  onClick?: (user: Pick<Type, ListUserWithRoleFields>) => void;
}

const UserTable = <Type extends UserWithRole>({
  fetchUsers,
  page,
  onChangePage,
  roleMap,
  onClick,
}: UserTableProps<
  Type & {
    role: string;
  }
>) => {
  return (
    <Table
      disableHover={!onClick}
      fetchData={fetchUsers}
      headerData={['Nome', 'E-mail', 'Cargo', 'Status']}
      renderRow={(user) => (
        <B2TableRow
          key={user.id}
          onClick={() => {
            onClick?.(user);
          }}
        >
          <B2TableDataCell>{user.name}</B2TableDataCell>
          <B2TableDataCell>{user.email}</B2TableDataCell>
          <B2TableDataCell>{roleMap[user.role]}</B2TableDataCell>
          <B2TableDataCell>
            <Status isActive={user.is_active} />
          </B2TableDataCell>
        </B2TableRow>
      )}
      paginator
      changePage={onChangePage}
      currentPage={page}
    />
  );
};

export default UserTable;
