import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import styled from 'styled-components';

const CustomCalendar = styled(Calendar)`
  position: absolute;
  right: 0;
  top: 55px;
  color: ${({ theme }) => theme.colors.black.two};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  border-radius: ${({ theme }) => theme.borderRadius};
  animation: show-downwards 0.5s ease-out, fade-in 0.5s ease-out;
  transition: opacity 0.25s ease-out;
  overflow: hidden;
  z-index: 100;

  .react-calendar__tile:disabled {
    color: ${({ theme }) => theme.colors.gray.two};
    background: ${({ theme }) => theme.colors.white.main};
    cursor: not-allowed;
  }

  .react-calendar__month-view__days__day--weekend,
  .react-calendar__month-view__days__day--neighboringMonth {
    color: ${({ theme }) => theme.colors.black.two};
  }

  .react-calendar__tile--active,
  .react-calendar__tile--hasActive {
    color: ${({ theme }) => theme.colors.white.main};
    background: ${({ theme }) => theme.colors.main};
  }

  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus,
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: ${({ theme }) => theme.colors.main};
    opacity: 0.8;
  }

  .react-calendar__tile--now {
    color: ${({ theme }) => theme.colors.white.main};
    background: ${({ theme }) => theme.colors.main};
  }

  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: ${({ theme }) => theme.colors.main};
    opacity: 0.8;
  }

  .react-calendar__tile--now {
    &.react-calendar__tile--active {
      background: ${({ theme }) => theme.colors.main};
    }
  }

  .react-calendar__month-view__weekdays {
    text-transform: capitalize;
    color: ${({ theme }) => theme.colors.gray.nine};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    cursor: default;

    abbr {
      text-decoration: none;
    }
  }

  .react-calendar__navigation button {
    color: ${({ theme }) => theme.colors.black.two};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    text-transform: capitalize;

    span {
      font-weight: ${({ theme }) => theme.fontWeight.bold};
      color: ${({ theme }) => theme.colors.gray.ten};
    }
  }

  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: ${({ theme }) => theme.colors.white.main};
    opacity: 0.6;
  }
`;

export default CustomCalendar;
