import RoutesPath from 'router/routes';

const getRoutes = (id: number) => {
  return [
    {
      path: RoutesPath.private.posts.list.draft,
      label: 'Lista de anúncios',
    },
    {
      path: RoutesPath.private.posts.detail.replace(':postId', id.toString()),
      label: 'Detalhes do anúncio',
    },
    {
      path: RoutesPath.private.posts.edit,
      label: 'Editar anúncio',
    },
  ];
};

export default getRoutes;
