import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  position: relative;
`;

export const Button = styled.div<{
  direction: 'left' | 'right';
}>`
  background-color: ${(props) => props.theme.colors.main};
  color: ${(props) => props.theme.colors.white.main};
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: calc(50% - 30px);
  ${(props) => props.direction}: 5px;
  border-radius: 20px;
  cursor: pointer;
`;

export const List = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
  overflow-x: hidden;
  scroll-behavior: smooth;
  user-select: none;
`;

export const Item = styled.img`
  height: 200px;
  object-fit: contain;
  max-width: 100%;
  cursor: pointer;
`;
