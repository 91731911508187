import React, { useState } from 'react';

import { useFormikContext } from 'formik';
import { useInfiniteQuery } from 'react-query';
import { getNextPageParam } from 'utils/helpers';

import { useToast } from '@contexts/Toast';
import { usePayments } from '@hooks';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import Label from '@components/Label';
import SearchSelect from '@components/SearchSelect';

const PlansSelect: React.FC = () => {
  const [search, setSearch] = useState('');

  const formik = useFormikContext<FormValuesChoosePlan>();

  const { listPlans } = usePayments();
  const { addToast } = useToast();

  const {
    data: plans,
    isLoading,
    fetchNextPage,
  } = useInfiniteQuery(
    ['plans', search],
    ({ pageParam = 1 }) =>
      listPlans({
        fields: ['id', 'name'],
        page: pageParam,
        search,
      }),
    {
      getNextPageParam,
      onError: () => {
        addToast('Falha ao carregar os planos.', 'error');
      },
    }
  );

  return (
    <FormGroup>
      <Label htmlFor="plan">Plano</Label>
      <SearchSelect
        name="plan"
        onSearch={setSearch}
        options={
          plans?.pages
            .flatMap((page) => page.results)
            .map((plan) => ({
              label: plan.name,
              value: plan.id.toString(),
            })) || []
        }
        value={formik.values.plan.label}
        onChange={(value) => {
          formik.setFieldValue('plan', value);
          setSearch('');
        }}
        isLoading={isLoading}
        fetchMore={fetchNextPage}
        onBlur={() => formik.setFieldTouched('plan.value', true)}
        invalidValue={
          !!formik.touched.plan?.value && !!formik.errors.plan?.value
        }
      />
      <FormError name="plan.value" />
    </FormGroup>
  );
};

export default PlansSelect;
