import { useCallback } from 'react';

import { endpoints, useApi } from '@contexts/Api';

const useAdvertisements = () => {
  const { request } = useApi();

  const generateAdvertisementInfo = useCallback(
    (data: EditFormValuesAdvertisement) => {
      return {
        image: data.bigImage.id,
        mobile_image: data.smallImage.id,
        title: data.title,
        index: data.index || undefined,
        starts_at: data.startDate,
        ends_at: data.endDate || undefined,
        link: data.link,
        category: data.category,
      };
    },
    []
  );

  const listAdvertisements = useCallback(
    async <AdvertisementsFields extends keyof Advertisement>(
      params: AdvertisementsQuery
    ) => {
      const response = await request<
        Paginated<Advertisement, AdvertisementsFields>
      >({
        method: 'get',
        url: endpoints.advertisements.list,
        params: {
          fields: params.fields.join(','),
          page: params.page,
          search: params.search || undefined,
          ordering: params.ordering || undefined,
        },
      });

      return response.data;
    },
    [request]
  );

  const getAdvertisement = useCallback(
    async (id: number) => {
      const response = await request<Advertisement>({
        method: 'get',
        url: endpoints.advertisements.get.replace(':id', id.toString()),
      });

      return response.data;
    },
    [request]
  );

  const createAdvertisement = useCallback(
    async (data: FormValuesAdvertisement) => {
      const response = await request<Advertisement>({
        method: 'post',
        url: endpoints.advertisements.post,
        data: generateAdvertisementInfo(data),
      });

      return response.data;
    },
    [generateAdvertisementInfo, request]
  );

  const updateAdvertisement = useCallback(
    async (id: number, data: EditFormValuesAdvertisement) => {
      const response = await request<Advertisement>({
        method: 'patch',
        url: endpoints.advertisements.patch.replace(':id', id.toString()),
        data: generateAdvertisementInfo(data),
      });

      return response.data;
    },
    [generateAdvertisementInfo, request]
  );

  const deleteAdvertisement = useCallback(
    async (id: number) => {
      const response = await request<void>({
        method: 'delete',
        url: endpoints.advertisements.delete.replace(':id', id.toString()),
      });

      return response.data;
    },
    [request]
  );

  return {
    listAdvertisements,
    getAdvertisement,
    createAdvertisement,
    updateAdvertisement,
    deleteAdvertisement,
  };
};

export default useAdvertisements;
