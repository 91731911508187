import styled from 'styled-components';

export const Body = styled.div`
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const CancelImage = styled.img`
  height: 200px;
`;

export const BodyMessage = styled.p`
  padding: 24px 32px;
  color: ${({ theme }) => theme.colors.gray.five};
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  border-bottom: 1px solid ${({ theme }) => theme.colors.white.three};
`;
