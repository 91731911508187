import React, { useMemo, useRef, useState } from 'react';

import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { usePostForm, usePosts } from '@hooks';

import BaseForm from '@components/BaseForm';
import PageHeader from '@components/PageHeader';
import RequestContainer from '@components/RequestContainer';

import { enums } from '@utils';

import ExtraInfo from '../Form/ExtraInfo';
import Photos from '../Form/Photos';
import Tags from '../Form/Tags';
import VehicleCategories from '../Form/VehicleCategories';
import VehicleConditions from '../Form/VehicleConditions';
import VehicleInfo from '../Form/VehicleInfo';
import VehicleValues from '../Form/VehicleValues';
import getRoutes from './routes';

const EditPost: React.FC = () => {
  const vehicleCategoriesRef = useRef<FormStepRef>(null);
  const vehicleInfoRef = useRef<FormStepRef>(null);
  const tagsRef = useRef<FormStepRef>(null);
  const photosRef = useRef<FormStepRef>(null);
  const vehicleConditionsRef = useRef<FormStepRef>(null);
  const extraInfoRef = useRef<FormStepRef>(null);
  const vehicleValuesRef = useRef<FormStepRef>(null);

  const [postCategory, setPostCategory] = useState<VehicleCategory>();

  const { getPost, getVehicleCategory } = usePosts();
  const { postId } = useParams<{ postId: string }>();
  const {
    updateRequest,
    updateVehicleConditionsRequests,
    uploadVehiclePhotos,
    completeRequest,
    updateExtraInfo,
  } = usePostForm(Number(postId));

  const fetchPost = useQuery(['post', postId], () => getPost(Number(postId)), {
    enabled: !!postId,
  });

  const fetchVehicleCategory = useQuery(
    ['vehicleCategory', fetchPost.data?.vehicle_model.category.id],
    () => getVehicleCategory(fetchPost.data!.vehicle_model.category.id),
    {
      enabled: !!fetchPost.data,
      onSuccess: (data) => {
        setPostCategory(data);
      },
    }
  );

  const saveCategory = useMutation((data: FormValuesVehicleCategories) => {
    setPostCategory(data.category);
    return Promise.resolve();
  });

  const postFormValues = useMemo(() => {
    if (!fetchPost.data) return;

    const post = fetchPost.data;

    const colorTag = post.tags.find((tag) => tag.type === enums.TagType.COLOR);

    const fuelTag = post.tags.find((tag) => tag.type === enums.TagType.FUEL);

    const numberOfDoorsTag = post.tags.find(
      (tag) => tag.type === enums.TagType.NUMBER_OF_DOORS
    );

    const tags = post.tags
      .filter(
        (tag) =>
          tag.type !== enums.TagType.COLOR &&
          tag.type !== enums.TagType.FUEL &&
          tag.type !== enums.TagType.NUMBER_OF_DOORS
      )
      .map((tag) => tag.id.toString());

    const photos = post.images
      .filter((image) => image.type === enums.CarPhotoType.OTHER)
      .map((postImage) => postImage.image);

    return {
      category: fetchVehicleCategory.data,
      store: {
        label: post.store_info.name,
        value: post.store_info.id,
      },
      brand: {
        label: post.vehicle_brand.name,
        value: post.vehicle_brand.id,
      },
      model: {
        label: post.vehicle_model.name,
        value: post.vehicle_model.id,
        manufacturingYears: post.vehicle_model.manufacturing_years,
      },
      manufacturingYear: post.vehicle_manufacturing_year,
      modelYear: post.vehicle_year,
      version: {
        label: post.vehicle_version.name,
        value: post.vehicle_version.id,
      },
      color: {
        label: colorTag?.name,
        value: colorTag?.id,
      },
      fuel: {
        label: fuelTag?.name,
        value: fuelTag?.id,
      },
      numberOfDoors: {
        label: numberOfDoorsTag?.name,
        value: numberOfDoorsTag?.id,
      },
      isArmored: post.is_armored ? 'true' : 'false',
      tags,
      mainPhoto: post.main_image?.image,
      photos,
      description: post.description ?? '',
      price: post.vehicle_price?.toString() ?? '',
    };
  }, [fetchPost.data, fetchVehicleCategory.data]);

  return (
    <RequestContainer requests={[fetchPost, fetchVehicleCategory]}>
      <>
        {fetchPost.data && (
          <>
            <PageHeader
              title="Edição de anúncio"
              routes={getRoutes(fetchPost.data.id)}
            />
            <BaseForm
              steps={[
                {
                  title: 'Tipo do veículo',
                  ref: vehicleCategoriesRef,
                  component: VehicleCategories,
                  saveStepData: saveCategory,
                },
                {
                  title: 'Dados do veículo',
                  ref: vehicleInfoRef,
                  component: VehicleInfo,
                  props: {
                    category: postCategory,
                  },
                  saveStepData: updateRequest,
                },
                {
                  title: 'Dados opcionais',
                  ref: tagsRef,
                  component: Tags,
                  props: {
                    category: postCategory?.id,
                  },
                  saveStepData: updateRequest,
                },
                {
                  title: 'Fotos do veículo',
                  ref: photosRef,
                  component: Photos,
                  saveStepData: uploadVehiclePhotos,
                  props: {
                    post: fetchPost.data.id,
                  },
                },
                {
                  title: 'Condições do veículo',
                  ref: vehicleConditionsRef,
                  component: VehicleConditions,
                  props: {
                    post: fetchPost.data.id,
                  },
                  saveStepData: updateVehicleConditionsRequests,
                },
                {
                  title: 'Informações adicionais',
                  ref: extraInfoRef,
                  component: ExtraInfo,
                  props: {
                    post: fetchPost.data.id,
                    category: postCategory?.id,
                  },
                  saveStepData: updateExtraInfo,
                },
                {
                  title: 'Valores do veículo',
                  ref: vehicleValuesRef,
                  component: VehicleValues,
                  props: {
                    post: fetchPost.data.id,
                    category: postCategory,
                  },
                  saveStepData: updateRequest,
                },
              ]}
              createRequest={completeRequest}
              saveEveryStep={true}
              initialValues={postFormValues}
            />
          </>
        )}
      </>
    </RequestContainer>
  );
};

export default EditPost;
