import { B2Spinner } from 'react-b2components';
import styled from 'styled-components';

export default styled(B2Spinner)`
  height: 25px;
  margin: auto;
  background: ${() => `url(/images/logo.svg)`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;
