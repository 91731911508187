export enum SystemManagerRole {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  SUPPORT = 'SUPPORT',
}

export const SystemManagerRoleMap = {
  [SystemManagerRole.ADMIN]: 'Administrador',
  [SystemManagerRole.MANAGER]: 'Gerente',
  [SystemManagerRole.SUPPORT]: 'Suporte',
};

export enum StoreType {
  PHYSICAL = 'PHYSICAL',
  LEGAL = 'LEGAL',
}

export enum TagType {
  COLOR = 'COLOR',
  FUEL = 'FUEL',
  NUMBER_OF_DOORS = 'NUMBER_OF_DOORS',
  OPTIONAL = 'OPTIONAL_FEATURE',
}

export const TagTypeMap = {
  [TagType.COLOR]: 'Cor',
  [TagType.FUEL]: 'Combustível',
  [TagType.NUMBER_OF_DOORS]: 'Número de portas',
  [TagType.OPTIONAL]: 'Opcionais',
};

export enum VehicleConditionState {
  BAD = 'BAD',
  REGULAR = 'REGULAR',
  GOOD = 'GOOD',
}

export const VehicleConditionStateMap = {
  [VehicleConditionState.BAD]: 'Ruim',
  [VehicleConditionState.REGULAR]: 'Regular',
  [VehicleConditionState.GOOD]: 'Bom',
};

export enum CarPhotoType {
  MAIN = 'MAIN',
  OTHER = 'OTHER',
}

export enum StoreUserRole {
  OWNER = 'OWNER',
  MANAGER = 'MANAGER',
}

export const StoreUserRoleMap = {
  [StoreUserRole.OWNER]: 'Proprietário',
  [StoreUserRole.MANAGER]: 'Gerente',
};

export enum AdvertisementCategory {
  MAIN_BANNER = 'MAIN_BANNER',
  SECONDARY_BANNER = 'SECONDARY_BANNER',
}

export const AdvertisementCategoryMap = {
  [AdvertisementCategory.MAIN_BANNER]: 'Principal',
  [AdvertisementCategory.SECONDARY_BANNER]: 'Secundário',
};

export enum PostType {
  COMPLETE = 'COMPLETE',
  DRAFT = 'DRAFT',
}

export enum CustomFieldType {
  NUMBER = 'NUMBER',
  RADIO = 'RADIO',
  TEXT = 'TEXT',
}

export enum PlanInterval {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export const PlanIntervalMap = {
  [PlanInterval.DAY]: 'dia',
  [PlanInterval.WEEK]: 'semana',
  [PlanInterval.MONTH]: 'mês',
  [PlanInterval.YEAR]: 'ano',
};

export const PlanPluralIntervalMap = {
  [PlanInterval.DAY]: 'dias',
  [PlanInterval.WEEK]: 'semanas',
  [PlanInterval.MONTH]: 'meses',
  [PlanInterval.YEAR]: 'anos',
};

export const PlanIntervalSelectMap = {
  [PlanInterval.DAY]: 'Diário',
  [PlanInterval.WEEK]: 'Semanal',
  [PlanInterval.MONTH]: 'Mensal',
  [PlanInterval.YEAR]: 'Anual',
};

export enum PlanBillingType {
  PREPAID = 'prepaid',
  POSTPAID = 'postpaid',
  EXACT_DAY = 'exact_day',
}

export enum PlanStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  DELETED = 'deleted',
}

export const PlanStatusMap = {
  [PlanStatus.ACTIVE]: 'Ativo',
  [PlanStatus.INACTIVE]: 'Inativo',
  [PlanStatus.DELETED]: 'Deletado',
};

export enum SubscriptionStatus {
  ACTIVE = 'active',
  CANCELED = 'canceled',
  FUTURE = 'future',
}

export const SubscriptionStatusMap = {
  [SubscriptionStatus.ACTIVE]: 'Ativo',
  [SubscriptionStatus.CANCELED]: 'Cancelado',
  [SubscriptionStatus.FUTURE]: 'Período grátis',
};

export enum Status {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum PostStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export const PostStatusMap = {
  [PostStatus.PENDING]: 'Pendente',
  [PostStatus.APPROVED]: 'Aprovado',
  [PostStatus.REJECTED]: 'Rejeitado',
};

export const PostStatusReverseMap = {
  [PostStatusMap[PostStatus.PENDING]]: PostStatus.PENDING,
  [PostStatusMap[PostStatus.APPROVED]]: PostStatus.APPROVED,
  [PostStatusMap[PostStatus.REJECTED]]: PostStatus.REJECTED,
};

export enum PostStatusIndicator {
  PENDING = 'PENDING',
  DRAFT = 'DRAFT',
  REJECTED = 'REJECTED',
}

export enum UserType {
  MANAGEMENT = 'SystemManager',
  STORE = 'StoreUser',
}

export enum ChargeStatus {
  PENDING = 'pending',
  PAID = 'paid',
  CANCELED = 'canceled',
  PROCESSING = 'processing',
  FAILED = 'failed',
  OVERPAID = 'overpaid',
  UNDERPAID = 'underpaid',
  CHARGED_BACK = 'chargedback',
}

export const ChargeStatusMap = {
  [ChargeStatus.PENDING]: 'Pendente',
  [ChargeStatus.PAID]: 'Pago',
  [ChargeStatus.CANCELED]: 'Cancelado',
  [ChargeStatus.PROCESSING]: 'Em processamento',
  [ChargeStatus.FAILED]: 'Falha',
  [ChargeStatus.OVERPAID]: 'Pago a mais',
  [ChargeStatus.UNDERPAID]: 'Pago a menos',
  [ChargeStatus.CHARGED_BACK]: 'Reembolsado',
};

export enum Months {
  JANUARY = 'Jan',
  FEBRUARY = 'Fev',
  MARCH = 'Mar',
  APRIL = 'Abr',
  MAY = 'Mai',
  JUNE = 'Jun',
  JULY = 'Jul',
  AUGUST = 'Ago',
  SEPTEMBER = 'Set',
  OCTOBER = 'Out',
  NOVEMBER = 'Nov',
  DECEMBER = 'Dez',
}

export const MonthsMap: { [key: number]: Months } = {
  1: Months.JANUARY,
  2: Months.FEBRUARY,
  3: Months.MARCH,
  4: Months.APRIL,
  5: Months.MAY,
  6: Months.JUNE,
  7: Months.JULY,
  8: Months.AUGUST,
  9: Months.SEPTEMBER,
  10: Months.OCTOBER,
  11: Months.NOVEMBER,
  12: Months.DECEMBER,
};

export enum ChartSubscriptionType {
  CANCELED = 'Assinaturas canceladas',
  ACTIVE = 'Assinaturas ativas',
}

export enum VehicleSearchType {
  PLATE = 'PLATE',
  CHASSI = 'CHASSI',
}
