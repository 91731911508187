import React, { useState } from 'react';

import { IoMdEye, IoMdEyeOff } from 'react-icons/io';

import AuthInput, { AuthInputProps } from '@components/AuthInput';

import { ButtonContainer, Container } from './styles';

const AuthPasswordInput: React.FC<AuthInputProps> = (props) => {
  const [isShowingPassword, setIsShowingPassword] = useState(false);
  return (
    <Container>
      <AuthInput {...props} type={isShowingPassword ? 'text' : 'password'} />
      <ButtonContainer>
        {isShowingPassword ? (
          <IoMdEyeOff onClick={() => setIsShowingPassword(false)} />
        ) : (
          <IoMdEye onClick={() => setIsShowingPassword(true)} />
        )}
      </ButtonContainer>
    </Container>
  );
};

export default AuthPasswordInput;
