import { B2Button, B2Card } from 'react-b2components';
import { MdModeEditOutline } from 'react-icons/md';
import styled from 'styled-components';

import FormGroup from '@components/FormGroup';

export const Container = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  gap: 36px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const ProfileCard = styled(B2Card)`
  width: 100%;
  background-color: ${(props) => props.theme.colors.white.main};
`;

export const Footer = styled.footer`
  background-color: #ffffff;
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 10px;
  box-sizing: border-box;
  z-index: 1001;
`;

export const FooterButton = styled(B2Button)`
  padding: 12px 64px;
  font-weight: 700;
`;

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
`;

export const PictureContainer = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
`;

export const ProfilePictureContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProfilePictureGroup = styled(FormGroup)`
  width: fit-content;
  text-align: center;
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const EditIcon = styled(MdModeEditOutline)`
  color: ${(props) => props.theme.colors.main};
  cursor: pointer;
  font-size: ${(props) => props.theme.fontSize.medium};
  position: absolute;
  right: 10px;
  bottom: 12px;
`;

export const InputDisplay = styled.div`
  width: 100%;
  height: 40px;
  background: ${(props) => props.theme.colors.white.three};
  color: ${(props) => props.theme.colors.gray.twelve};
  border: 2px solid transparent;
  border-radius: ${(props) => props.theme.borderRadius};
  font-size: ${(props) => props.theme.fontSize.small};
  box-sizing: border-box;
  padding: 0 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  & span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 210px;
  }
`;
