import React, { useCallback, useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { Container, Title } from './styles';

interface Tab {
  title: string;
  component: React.ReactNode;
  path?: string;
}

interface TabsProps {
  tabs: Array<Tab>;
}

const Tabs: React.FC<TabsProps> = ({ tabs }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const getCurrentTab = useCallback(() => {
    const currentTab = tabs.findIndex((tab) => tab.path === location.pathname);

    return currentTab !== -1 ? currentTab : 0;
  }, [location.pathname, tabs]);

  const [selectTab, setSelectTab] = useState(getCurrentTab());

  const disableCheckTab = useCallback(
    (tab: Tab, index: number) => {
      setSelectTab(index);
      if (tab.path) {
        return navigate(tab.path);
      }
    },
    [navigate]
  );

  useEffect(() => {
    const newTab = getCurrentTab();

    setSelectTab(newTab);
  }, [getCurrentTab]);

  return (
    <>
      <div>
        <Container data-cy="tabs">
          {tabs.map((tab, index) => (
            <Title
              key={index}
              isSelected={selectTab === index}
              onClick={() => disableCheckTab(tab, index)}
            >
              {tab.title}
            </Title>
          ))}
        </Container>
      </div>
      {tabs[selectTab].component}
    </>
  );
};

export default Tabs;
