import RoutesPath from '@router/routes';

const routes = [
  {
    path: RoutesPath.private.advertisements.list,
    label: 'Lista de publicidades',
  },
  {
    path: RoutesPath.private.advertisements.create,
    label: 'Cadastro de publicidade',
  },
];

export default routes;
