import Lottie from 'lottie-react';
import styled from 'styled-components';

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const EmptyLottie = styled(Lottie)`
  width: 30%;
`;
