import { color } from 'b2utils';
import { CloseCircle } from 'iconsax-react';
import styled, { css } from 'styled-components';
import { commonInputStyle } from 'utils/styles';

interface FakeSelectProps {
  invalidValue?: boolean;
  isFocused?: boolean;
  disabled?: boolean;
}

export const Container = styled.div`
  position: relative;
`;

export const FakeSelect = styled.div<FakeSelectProps>`
  ${commonInputStyle};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-image: none;
  padding: 15px 16px;

  ${({ theme, isFocused }) =>
    isFocused &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.info};
      outline: 2px solid ${color.addOpacityOnHexColor(theme.colors.info, 0.1)};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6;
      cursor: not-allowed;
    `}
`;

export const ListContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white.main};
  width: 100%;
  position: absolute;
  box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 16px;
  z-index: 1002;
  border-radius: 16px;
`;

export const OptionsList = styled.ul`
  max-height: 215px;
  list-style-type: none;
  overflow-y: auto;
  margin-top: 5px;
`;

export const ListOption = styled.li`
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.colors.gray.two};
  }
  padding: 5px;
`;

export const ValueContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.two};
`;

export const CloseIcon = styled(CloseCircle)`
  width: ${({ theme }) => theme.spacing.five};
  height: ${({ theme }) => theme.spacing.five};
  color: ${({ theme }) => theme.colors.error};

  &:hover {
    opacity: 0.7;
  }
`;
