import React, { useCallback, useEffect, useRef, useState } from 'react';

import SearchFilter from '@components/SearchFilter';

import {
  Container,
  FakeSelect,
  OptionsList,
  ListOption,
  ListContainer,
  CloseIcon,
  ValueContainer,
} from './styles';

export interface SelectOption {
  label: string;
  value: string;
}

export interface SearchSelectProps<Type extends SelectOption> {
  name: string;
  onSearch: (search: string) => void;
  options: Array<Type>;
  value: string;
  onChange: (value: Type) => void;
  isLoading: boolean;
  fetchMore: () => void;
  invalidValue?: boolean;
  onBlur?: () => void;
  onClear?: () => void;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
}

const SearchSelect = <Type extends SelectOption>({
  name,
  onSearch,
  options,
  value,
  onChange,
  isLoading,
  fetchMore,
  invalidValue,
  onBlur,
  onClear,
  disabled,
  placeholder = 'Selecione',
  className,
}: SearchSelectProps<Type>) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [isShowingOptions, setIsShowingOptions] = useState(false);

  const hide = useCallback(
    (event: Event) => {
      const node = containerRef.current;
      if (
        node &&
        event.target !== node &&
        !node.contains(event.target as HTMLElement)
      ) {
        onBlur?.();
        setIsShowingOptions(false);
      }
    },
    [onBlur]
  );

  useEffect(() => {
    if (isShowingOptions) {
      document.addEventListener('click', hide);
    }

    return () => document.removeEventListener('click', hide);
  }, [hide, isShowingOptions]);

  const onScroll = useCallback(
    (event: React.UIEvent<HTMLUListElement, UIEvent>) => {
      const element = event.target as HTMLElement;
      if (
        !isLoading &&
        element.offsetHeight + element.scrollTop >= element.scrollHeight
      ) {
        fetchMore();
      }
    },
    [fetchMore, isLoading]
  );

  return (
    <Container ref={containerRef}>
      <FakeSelect
        className={className}
        data-cy={`search-select-${name}`}
        onClick={() => !disabled && setIsShowingOptions(true)}
        invalidValue={invalidValue}
        isFocused={isShowingOptions}
        disabled={disabled}
      >
        <ValueContainer>
          {value && onClear && (
            <CloseIcon
              onClick={(event) => {
                event.stopPropagation();
                onClear?.();
              }}
            />
          )}
          <span>{value || placeholder}</span>
        </ValueContainer>
        <img src="/images/arrow-drop-down-circle.svg" />
      </FakeSelect>
      {isShowingOptions && (
        <ListContainer>
          <SearchFilter onSearch={onSearch} isLoading={isLoading} />
          <OptionsList
            data-cy={`search-select-list-${name}`}
            onScroll={onScroll}
          >
            {options.map((option) => (
              <ListOption
                key={option.value}
                onClick={() => {
                  onChange(option);
                  setIsShowingOptions(false);
                }}
              >
                {option.label}
              </ListOption>
            ))}
          </OptionsList>
        </ListContainer>
      )}
    </Container>
  );
};

export default SearchSelect;
