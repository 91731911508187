import { color } from 'b2utils';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

interface SidebarItemProps {
  selected?: boolean;
  index: number;
}

export const Container = styled(Link)<SidebarItemProps>`
  display: flex;
  align-items: center;
  opacity: 0;
  animation: show-rightwards 0.5s ease-out ${({ index }) => index * 0.1}s
      forwards,
    fade-in 0.5s ease-out ${(props) => props.index * 0.1}s forwards;
  cursor: pointer;
`;

export const Content = styled.div<SidebarItemProps>`
  flex: 1;
  display: flex;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
  word-break: break-all;

  svg {
    margin-right: 16px;
    flex-shrink: 0;
    transition: all 0.5s ease;
    font-size: ${({ theme }) => theme.sidebarIconSize};
  }

  ${({ theme, selected }) =>
    selected
      ? css`
          margin: 0 16px;
          padding: 16px 8px;
          background-color: ${color.addOpacityOnHexColor(
            theme.colors.main,
            0.1
          )};
          svg {
            margin-left: 10px;
            color: ${theme.colors.main};
          }
        `
      : css`
          padding: 16px 24px;
          svg {
            color: ${theme.colors.gray.six};
          }
        `};
`;

export const Label = styled.p<SidebarItemProps>`
  ${({ theme, selected }) =>
    selected
      ? css`
          font-weight: ${theme.fontWeight.semiBold};
          color: ${theme.colors.black.two};
        `
      : css`
          font-weight: ${theme.fontWeight.medium};
          color: ${theme.colors.gray.six};
        `};
`;
