import React, { useState } from 'react';

import { useInfiniteQuery } from 'react-query';
import { getNextPageParam } from 'utils/helpers';

import { useToast } from '@contexts/Toast';
import { useStores } from '@hooks';

import SearchSelect, {
  SearchSelectProps,
  SelectOption,
} from '@components/SearchSelect';

export type ClientsSearchSelectProps = Omit<
  SearchSelectProps<SelectOption>,
  'onSearch' | 'options' | 'isLoading' | 'fetchMore'
>;

const ClientsSearchSelect: React.FC<ClientsSearchSelectProps> = ({
  onChange,
  ...props
}) => {
  const [search, setSearch] = useState('');

  const { listStores } = useStores();
  const { addToast } = useToast();

  const {
    data: stores,
    isLoading,
    fetchNextPage,
  } = useInfiniteQuery(
    ['stores', search],
    ({ pageParam = 1 }) =>
      listStores({
        fields: ['id', 'name'],
        page: pageParam,
        search,
      }),
    {
      getNextPageParam,
      onError: () => {
        addToast('Falha ao carregar os clientes.', 'error');
      },
    }
  );

  return (
    <SearchSelect
      onSearch={setSearch}
      options={
        stores?.pages
          .flatMap((page) => page.results)
          .map((store) => ({
            label: store.name,
            value: store.id.toString(),
          })) || []
      }
      onChange={(value) => {
        onChange(value);
        setSearch('');
      }}
      isLoading={isLoading}
      fetchMore={fetchNextPage}
      {...props}
    />
  );
};

export default ClientsSearchSelect;
