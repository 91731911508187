import React from 'react';

import BaseFilePicker, { BaseFilePickerProps } from 'components/BaseFilePicker';

import FilesList, { FilesListProps } from '@components/FilesList';
import PickerButton, { PickerButtonProps } from '@components/PickerButton';

import { Container } from './styles';

interface FileSinglePickerProps
  extends Pick<BaseFilePickerProps, 'onAddFile'>,
    Pick<
      PickerButtonProps,
      'disabled' | 'disabledMessage' | 'suggestedResolution'
    >,
    Pick<FilesListProps, 'onDeleteFile'> {
  file?: ApiFile;
  dataCy?: string;
}

const FileSinglePicker: React.FC<FileSinglePickerProps> = ({
  onAddFile,
  onDeleteFile,
  file,
  disabled = false,
  disabledMessage,
  suggestedResolution,
  dataCy,
}) => {
  return (
    <BaseFilePicker dataCy={dataCy} onAddFile={onAddFile} disabled={disabled}>
      {({ fileName, openFileExplorer, isLoading }) => (
        <Container>
          <PickerButton
            fileName={fileName}
            buttonText="Adicionar arquivos"
            onButtonClick={openFileExplorer}
            isLoading={isLoading}
            suggestedResolution={suggestedResolution}
            disabled={disabled}
            disabledMessage={disabledMessage}
          />
          <FilesList
            dataCy={dataCy}
            files={file ? [file] : []}
            onDeleteFile={onDeleteFile}
          />
        </Container>
      )}
    </BaseFilePicker>
  );
};

export default FileSinglePicker;
