import React, { useCallback, useEffect, useState } from 'react';

import { currency } from 'b2utils';
import moment from 'moment';
import { B2TableRow, B2TableDataCell } from 'react-b2components';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { usePayments } from '@hooks';

import ChartCard from '@components/ChartCard';
import Table from '@components/Table';

import RoutesPath from '@router/routes';
import { enums } from '@utils';

const fields = [
  'id',
  'created',
  'subscription_info',
  'customer_info',
  'amount',
  'status',
] as const;

type ChargesFields = (typeof fields)[number];

interface ChargesHistoryProps {
  period: Period;
}

const ChargesHistory: React.FC<ChargesHistoryProps> = ({ period }) => {
  const [queryParams, setQueryParams] = useState<ChargesQuery>({
    fields: fields as unknown as Array<keyof Charge>,
    page: 1,
    period,
  });

  useEffect(() => {
    setQueryParams((prevState) => ({
      ...prevState,
      period,
    }));
  }, [period]);

  const { listCharges } = usePayments();
  const navigate = useNavigate();

  const fetchCharges = useQuery(['charges', queryParams], () =>
    listCharges<ChargesFields>(queryParams)
  );

  const handleClick = useCallback(
    (charge: Pick<Charge, ChargesFields>) => {
      const route = charge.subscription_info
        ? RoutesPath.private.clients.detail.legal.detail
        : RoutesPath.private.clients.detail.physical.detail;

      navigate(
        route.replace(
          ':clientId',
          charge.customer_info.user.store.id.toString()
        )
      );
    },
    [navigate]
  );

  return (
    <ChartCard
      dataCy="charges-history-card"
      title="Histórico de pagamento"
      request={fetchCharges}
    >
      <Table
        fetchData={fetchCharges}
        headerData={['Data', 'Cliente', 'Plano', 'Valor', 'Status']}
        renderRow={(charge) => (
          <B2TableRow
            data-cy={`charge-${charge.id}`}
            key={charge.id}
            onClick={() => handleClick(charge)}
          >
            <B2TableDataCell>
              {moment(charge.created).format('DD/MM/YYYY')}
            </B2TableDataCell>
            <B2TableDataCell>
              {charge.customer_info.user.store.name}
            </B2TableDataCell>
            <B2TableDataCell>
              {charge.subscription_info?.plan.name ?? 'Compra avulsa'}
            </B2TableDataCell>
            <B2TableDataCell>
              {currency.centsToBrl(charge.amount)}
            </B2TableDataCell>
            <B2TableDataCell>
              {enums.ChargeStatusMap[charge.status]}
            </B2TableDataCell>
          </B2TableRow>
        )}
        paginator
        changePage={(newPage) =>
          setQueryParams((prevState) => ({
            ...prevState,
            page: newPage,
          }))
        }
        currentPage={queryParams.page}
      />
    </ChartCard>
  );
};

export default ChargesHistory;
