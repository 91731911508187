import { B2Button, B2Modal } from 'react-b2components';
import styled from 'styled-components';

export const Container = styled(B2Modal)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  padding: 0;
`;

export const Image = styled.img`
  width: 90%;
  height: 90%;
  object-fit: contain;
`;

export const PassButton = styled(B2Button)`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 0;
  margin: 0px 16px;
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 12px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
