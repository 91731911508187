import React, { useState } from 'react';

import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { usePosts } from '@hooks';

import PostsList, {
  ListPostFields,
  listPostsFields,
} from '@components/PostsList';

import RoutesPath from '@router/routes';

interface LeadPostsProps {
  customer: number;
}

const LeadPosts: React.FC<LeadPostsProps> = ({ customer }) => {
  const [queryParams, setQueryParams] = useState<PostsQuery>({
    fields: listPostsFields as unknown as Array<keyof Post>,
    page: 1,
    pageSize: 6,
    isComplete: true,
    lead: customer,
  });

  const { listPosts } = usePosts();

  const navigate = useNavigate();

  const fetchPosts = useQuery(['posts', queryParams], () =>
    listPosts<ListPostFields>(queryParams)
  );

  return (
    <PostsList
      fetchPosts={fetchPosts}
      page={queryParams.page}
      onChangePage={(page) => setQueryParams({ ...queryParams, page })}
      onClick={(post) => {
        navigate(
          RoutesPath.private.posts.detail.replace(':postId', post.toString())
        );
      }}
    />
  );
};

export default LeadPosts;
