import styled from 'styled-components';
import { commonInputStyle } from 'utils/styles';

interface TextAreaProps {
  invalidValue?: boolean;
}

const TextArea = styled.textarea<TextAreaProps>`
  ${commonInputStyle};
`;

export default TextArea;
