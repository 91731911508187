import React, { useMemo, useRef, useState } from 'react';

import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useToast } from '@contexts/Toast';
import { useStores } from '@hooks';

import BaseForm from '@components/BaseForm';
import PageHeader from '@components/PageHeader';

import RoutesPath from '@router/routes';
import { enums } from '@utils';

import Address from '../Form/Address';
import ClientUserInfo from '../Form/ClientUserInfo';
import Integration from '../Form/Integration';
import routes from './routes';

const CreateClient: React.FC = () => {
  const clientUserInfoRef = useRef<FormStepRef>(null);
  const clientAddressInfoRef = useRef<FormStepRef>(null);
  const integrationsRef = useRef<FormStepRef>(null);

  const [storeType, setStoreType] = useState<enums.StoreType>();

  const { createStore } = useStores();
  const { addToast } = useToast();

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const saveStoreType = useMutation((data: FormValuesClientUserInfo) => {
    setStoreType(data.type);
    return Promise.resolve();
  });

  const createRequest = useMutation(createStore, {
    onSuccess: (data) => {
      addToast('Cliente criado com sucesso', 'success');
      if (data.document.length === 11) {
        navigate(RoutesPath.private.clients.list.physical, { replace: true });
      } else {
        navigate(RoutesPath.private.clients.list.legal, { replace: true });
      }
      queryClient.invalidateQueries('stores');
    },
  });

  const steps = useMemo(() => {
    const baseSteps: Array<Step> = [
      {
        title: 'Dados do cliente',
        ref: clientUserInfoRef,
        component: ClientUserInfo,
        saveStepData: saveStoreType,
      },
      {
        title: 'Dados da localização',
        ref: clientAddressInfoRef,
        component: Address,
      },
    ];

    if (storeType === enums.StoreType.LEGAL) {
      baseSteps.push({
        title: 'Integração',
        ref: integrationsRef,
        component: Integration,
      });
    }

    return baseSteps;
  }, [saveStoreType, storeType]);

  return (
    <>
      <PageHeader title="Cadastro de cliente" routes={routes} />
      <BaseForm
        steps={steps}
        createRequest={createRequest}
        saveEveryStep={true}
      />
    </>
  );
};

export default CreateClient;
