import React, { useCallback, useState } from 'react';

import { MdOutlineAddCircle, MdOutlineRemoveCircle } from 'react-icons/md';
import { useMutation, useQueryClient } from 'react-query';

import { useToast } from '@contexts/Toast';
import { usePayments } from '@hooks';

import BaseModal from '@components/BaseModal';
import Button from '@components/Button';
import CircleLoading from '@components/CircleLoading';

import { Content, FooterButtons, LoadingContainer } from './styles';

interface AddCreditsModalProps {
  isOpen: boolean;
  onClose: () => void;
  store: number;
}

const AddCreditsModal: React.FC<AddCreditsModalProps> = ({
  isOpen,
  onClose,
  store,
}) => {
  const [quantityOfCredits, setQuantityOfCredits] = useState(1);

  const { doCharity } = usePayments();
  const { addToast } = useToast();

  const queryClient = useQueryClient();

  const handleClose = useCallback(() => {
    onClose();
    queryClient.invalidateQueries(['credits', store]);
    setQuantityOfCredits(1);
  }, [onClose, queryClient, store]);

  const requestDoCharity = useMutation(
    () => doCharity(quantityOfCredits, store),
    {
      onSuccess: () => {
        addToast('Créditos adicionados com sucesso!', 'success');
        handleClose();
      },
      onError: () => {
        addToast('Erro ao adicionar o(s) créditos, tente novamente', 'error');
      },
    }
  );

  return (
    <BaseModal title="Adicionar créditos" onClose={handleClose} isOpen={isOpen}>
      <Content>
        <Button
          variant="transparent"
          onClick={() => {
            if (quantityOfCredits === 1) {
              return;
            }
            setQuantityOfCredits((prevState) => prevState - 1);
          }}
        >
          <MdOutlineRemoveCircle size={24} />
        </Button>
        <p>{quantityOfCredits}</p>
        <Button
          variant="transparent"
          onClick={() => setQuantityOfCredits((prevState) => prevState + 1)}
        >
          <MdOutlineAddCircle size={24} />
        </Button>
      </Content>
      {requestDoCharity.isLoading ? (
        <LoadingContainer>
          <CircleLoading />
        </LoadingContainer>
      ) : (
        <FooterButtons>
          <Button variant="outline" onClick={handleClose}>
            Cancelar
          </Button>
          <Button onClick={() => requestDoCharity.mutate()}>Adicionar</Button>
        </FooterButtons>
      )}
    </BaseModal>
  );
};

export default AddCreditsModal;
