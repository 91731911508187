import React, { useState } from 'react';

import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { useAuthentication } from '@hooks';

import { Content } from '@components/BaseForm/styles';
import UserTable, {
  UserWithRoleFields,
  ListUserWithRoleFields,
} from '@components/UserTable';

import RoutesPath from '@router/routes';
import { enums } from '@utils';

const UsersOfStore: React.FC = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const { listStoreUsers } = useAuthentication();
  const navigate = useNavigate();

  const [queryParams, setQueryParams] = useState<StoreUsersQuery>({
    fields: UserWithRoleFields as unknown as Array<keyof StoreUser>,
    store: Number(clientId),
    page: 1,
  });

  const fetchStoreUsers = useQuery(['storeUsers', queryParams], () =>
    listStoreUsers<ListUserWithRoleFields>(queryParams)
  );

  return (
    <Content>
      <UserTable
        fetchUsers={fetchStoreUsers}
        page={queryParams.page}
        onChangePage={(page) => {
          setQueryParams((prevState) => ({
            ...prevState,
            page,
          }));
        }}
        roleMap={enums.StoreUserRoleMap}
        onClick={(user) =>
          navigate(
            RoutesPath.private.clients.detail.legal.users.detail
              .replace(':clientId', clientId!)
              .replace(':storeUserId', user.id.toString())
          )
        }
      />
    </Content>
  );
};

export default UsersOfStore;
