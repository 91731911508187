import React, { useEffect, useMemo } from 'react';

import { Formik } from 'formik';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router';
import * as yup from 'yup';

import { useToast } from '@contexts/Toast';
import { useAuthentication } from '@hooks';

import AuthPasswordInput from '@components/AuthPasswordInput';
import { AuthButton, Form, CardTitle } from '@components/Base/AuthBase/styles';
import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';

import RoutesPath from '@router/routes';
import { errors } from '@utils';

import { ChangePasswordText } from './styles';

interface ChangePasswordParams {
  password: string;
  confirmPassword: string;
}

const ChangePassword: React.FC = () => {
  const { search } = useLocation();
  const { changePassword } = useAuthentication();
  const { addToast } = useToast();
  const navigate = useNavigate();

  const token = useMemo(() => new URLSearchParams(search).get('j8'), [search]);

  useEffect(() => {
    if (!token) {
      navigate(RoutesPath.auth.login);
    }
  }, [navigate, token]);

  const changePasswordRequest = useMutation(
    ({ password, confirmPassword }: ChangePasswordParams) =>
      changePassword(password, confirmPassword, token!),
    {
      onSuccess: () => {
        addToast('Senha atualizada', 'success');
        navigate(RoutesPath.auth.login);
      },
      onError: () => {
        addToast('Falha ao trocar a senha, tente novamente', 'error');
      },
    }
  );

  return (
    <>
      <CardTitle>Redefinir senha</CardTitle>
      <ChangePasswordText>
        Após confirmar o seu e-mail insira a sua nova senha e confirme para
        poder entrar no sistema.
      </ChangePasswordText>
      <Formik
        initialValues={{ password: '', confirmPassword: '' }}
        onSubmit={({ password, confirmPassword }) => {
          changePasswordRequest.mutateAsync({
            password,
            confirmPassword,
          });
        }}
        validationSchema={yup.object({
          password: yup.string().required(errors.required),
          confirmPassword: yup
            .string()
            .oneOf([yup.ref('password')], errors.passwordConfirm)
            .required(errors.required),
        })}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <FormGroup>
              <AuthPasswordInput
                label="Nova senha"
                type="password"
                placeholder="Insira sua senha"
                name="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
              <FormError name="password" />
            </FormGroup>
            <FormGroup>
              <AuthPasswordInput
                label="Confirme sua senha"
                type="password"
                placeholder="Confirme sua senha novamente"
                name="confirmPassword"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
              />
              <FormError name="confirmPassword" />
            </FormGroup>
            <AuthButton
              variant="primary"
              type="submit"
              isLoading={changePasswordRequest.isLoading}
            >
              Salvar
            </AuthButton>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ChangePassword;
