import React from 'react';

import { SwitchContainer, SwitchButton, SwitchBackground } from './styles';

interface InactivateSwitchProps {
  isActive: boolean;
  onChange: () => void;
  className?: string;
}

export const InactivateSwitch: React.FC<InactivateSwitchProps> = ({
  isActive,
  onChange,
  className,
}) => {
  return (
    <SwitchContainer className={className}>
      <SwitchBackground isActive={isActive} />
      <SwitchButton isActive={isActive} onClick={() => !isActive && onChange()}>
        Ativo
      </SwitchButton>
      <SwitchButton isActive={!isActive} onClick={() => isActive && onChange()}>
        Inativo
      </SwitchButton>
    </SwitchContainer>
  );
};
