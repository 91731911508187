import Lottie from 'lottie-react';
import { B2Card } from 'react-b2components';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Card = styled(B2Card)`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.span`
  color: ${(props) => props.theme.colors.main};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  font-size: 6.25rem;
  text-shadow: 6.10204px 0px 0px #d9261c33;
  margin-top: 20px;
`;

export const Text = styled.span`
  font-weight: ${(props) => props.theme.fontWeight.bold};
  font-size: ${(props) => props.theme.fontSize.large};
  color: ${(props) => props.theme.colors.black.two};
  text-align: center;
`;

export const ErrorLottie = styled(Lottie)`
  width: 30%;

  @media (max-width: 1000px) {
    width: 50%;
  }

  @media (max-width: 600px) {
    width: 80%;
  }
`;
